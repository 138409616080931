<template>
    <!-- 产品需求---发布问题、解答问题详情页 -->
    <div class="demandIssue">
        <Header></Header>
        <Nav></Nav>
        <div class="minkave-wrap">
            <!-- 顶部图片 -->
            <Advertising></Advertising>
            <!-- <div class="index-headImg">
                <div class="index-headImg-left">
                    <img src="../../../assets/pc/Index/headL.png" alt="" />
                </div>
                <div class="index-headImg-right">
                    <img src="../../../assets/pc/Index/headR.png" alt="" />
                </div>
            </div> -->

            <!-- 关于学会tap -->
            <div class="survey-nav">
                <!-- <Nav></Nav> -->
                <div class="survey-nav-title">
                    <div class="survey-title-cn">{{ navtl }}</div>
                    <!-- <div class="survey-title-sn">About the Institute</div> -->
                </div>

                <!-- <div style="font-size: 30px">详情页暂未写tap切换</div> -->
            </div>

            <!-- 内容区域 -->
            <div class="survey-cont">
                <div class="survey-content">
                    <!-- 内容区域左侧 -->
                    <!-- 技术难题 -->
                    <div class="survey-content-left">
                        <div class="survey-detail-left">
                            <!-- 左侧 -->
                            <div class="minkaveDetails-introduce5-left">
                                <!-- 技术难题标题 -->
                                <div class="minkaveDetails-introduce5-title">
                                    <ul>
                                        <li
                                            :class="
                                                issueIndex == 0
                                                    ? 'activeli'
                                                    : ''
                                            "
                                        >
                                            发布问题
                                        </li>
                                        <li
                                            :class="
                                                issueIndex == 1
                                                    ? 'activeli'
                                                    : ''
                                            "
                                        >
                                            我来解答
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="survey-detail-right">
                            <div class="detail-right">
                                <div class="detail-right-place">
                                    当前位置：网站首页>产业需求>{{ navtl }}
                                </div>
                                <div class="detail-right-cont">
                                    <!-- 发布问题 -->
                                    <div v-show="issueIndex == 0">
                                        <div class="detail-right-name">
                                            <label for="">*难题名称</label>
                                            <input
                                                class="name"
                                                type="表单类型"
                                                v-model="form.name"
                                                placeholder="请输入难题名称"
                                            />
                                        </div>
                                        <div class="detail-right-select">
                                            <label for="">*应用领域</label>
                                            <select @change="selectChangetwo">
                                                <option
                                                    :value="item.id"
                                                    v-for="(
                                                        item, index
                                                    ) in yingyong"
                                                    :key="index"
                                                >
                                                    {{ item.name }}
                                                </option>
                                            </select>
                                        </div>
                                        <div class="detail-right-select">
                                            <label for="">*分类</label>
                                            <select @change="selectChange">
                                                <option
                                                    :value="item.id"
                                                    v-for="(
                                                        item, index
                                                    ) in needCatelist"
                                                    :key="index"
                                                >
                                                    {{ item.name }}
                                                </option>
                                            </select>
                                        </div>
                                        <div class="detail-right-area">
                                            <label for="">*难题描述</label>
                                            <textarea
                                                rows="行数"
                                                cols="列数"
                                                v-model="form.area"
                                                class="area"
                                                placeholder="请输入难题描述"
                                            >
                                            </textarea>
                                        </div>
                                        <div class="detail-right-firm">
                                            <label for="">公司名称</label>
                                            <input
                                                class="phone"
                                                type="表单类型"
                                                v-model="form.firm"
                                                placeholder="请输入公司名称"
                                            />
                                        </div>
                                        <div class="detail-right-firm">
                                            <label for="">合作方式</label>
                                            <input
                                                class="phone"
                                                type="表单类型"
                                                v-model="form.lable"
                                                placeholder="请输入合作方式"
                                            />
                                        </div>
                                        <div class="detail-right-firm">
                                            <label for="">预算费用</label>
                                            <input
                                                class="phone"
                                                type="表单类型"
                                                v-model="form.money"
                                                placeholder="请输入预算费用"
                                            />
                                        </div>

                                        <div class="relation-right-linkman">
                                            <label for="">联系人</label>
                                            <input
                                                class="linkman"
                                                type="表单类型"
                                                v-model="form.linkman"
                                                placeholder="请输入联系人"
                                            />

                                            <input
                                                type="radio"
                                                name="gender"
                                                value="1"
                                                checked
                                                @change="changeRadio"
                                            />
                                            <span>先生</span>
                                            <input
                                                type="radio"
                                                name="gender"
                                                value="2"
                                                @change="changeRadio"
                                            />
                                            <span>女士</span>
                                        </div>
                                        <div class="relation-right-phone">
                                            <label for="">*联系电话</label>
                                            <input
                                                class="phone"
                                                type="表单类型"
                                                v-model="form.phone"
                                                placeholder="请输入联系电话"
                                            />
                                        </div>
                                        <div
                                            class="relation-right-phone"
                                            v-if="false"
                                        >
                                            <label for="">*标签</label>
                                            <input
                                                class="phone"
                                                type="表单类型"
                                                v-model="form.lable"
                                                placeholder="请输入联系电话"
                                            />
                                        </div>
                                        <div class="relation-right-mail">
                                            <label for="">电子邮件</label>
                                            <input
                                                class="mail"
                                                type="表单类型"
                                                v-model="form.mail"
                                                placeholder="请输入电子邮件"
                                            />
                                        </div>

                                        <div class="relation-right-code">
                                            <label for="">*验证码</label>
                                            <input
                                                class="mail"
                                                type="表单类型"
                                                v-model="form.code"
                                                placeholder="请输入验证码"
                                            />

                                            <div>
                                                <Code
                                                    ref="ref_validateCode"
                                                    @change="changeCode1"
                                                ></Code>
                                            </div>
                                        </div>
                                        <div>
                                            <div @click="submit">提交</div>
                                            <div @click="rewrite">重写</div>
                                        </div>
                                    </div>

                                    <!-- 我来解答 -->
                                    <div v-show="issueIndex == 1">
                                        <div class="detail-right-title">
                                            <label for="">*标题</label>
                                            <input
                                                class="name"
                                                type="表单类型"
                                                v-model="formNew.titleNew"
                                                placeholder="请输入标题"
                                            />
                                        </div>
                                        <div class="detail-right-areaNew">
                                            <label for="">*服务描述</label>
                                            <textarea
                                                rows="行数"
                                                cols="列数"
                                                v-model="formNew.areaNew"
                                                class="area"
                                                placeholder="请输入服务描述"
                                            >
                                            </textarea>
                                        </div>
                                        <div class="detail-right-firmNew">
                                            <label for="">专家名称</label>
                                            <input
                                                class="phone"
                                                type="表单类型"
                                                v-model="formNew.firmNew"
                                                placeholder="请输入专家名称"
                                            />
                                        </div>

                                        <div class="relation-right-phoneNew">
                                            <label for="">*联系电话</label>
                                            <input
                                                class="phone"
                                                type="表单类型"
                                                v-model="formNew.phoneNew"
                                                placeholder="请输入联系电话"
                                            />
                                        </div>
                                        <div class="relation-right-mailNew">
                                            <label for="">电子邮件</label>
                                            <input
                                                class="mail"
                                                type="表单类型"
                                                v-model="formNew.mailNew"
                                                placeholder="请输入电子邮件"
                                            />
                                        </div>

                                        <div class="relation-right-codeNew">
                                            <label for="">*验证码</label>
                                            <input
                                                class="mail"
                                                type="表单类型"
                                                v-model="formNew.codeNew"
                                                placeholder="请输入验证码"
                                            />

                                            <div>
                                                <Code
                                                    ref="ref_validateCode"
                                                    @change="changeCode2"
                                                ></Code>
                                            </div>
                                        </div>

                                        <div>
                                            <div @click="submitNew">提交</div>
                                            <div @click="rewriteNew">重写</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- 内容区域右侧 -->
                    <div class="survey-content-right">
                        <Right></Right>
                    </div>
                </div>
            </div>
            <!-- 内容区域 -->
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import Nav from "@/components/Nav.vue";
import Header from "@/components/Header.vue"; //头部
import Footer from "@/components/Footer.vue"; //尾部
import Right from "@/components/Right.vue"; //右侧
// import Left3 from "@/components/Science/Left3.vue"; //左侧
import Code from "@/components/Code.vue"; //验证码
import Advertising from "@/components/Advertising.vue"; //广告图
import {
    setproblem,
    setanswer,
    needCatelist,
    yingyonglist,
} from "../../../network/data";

export default {
    // 组件名称
    name: "demand",
    // 组件参数 接收来自父组件的数据
    props: {},
    // 组件状态值
    data() {
        return {
            // 判断是否能发布问题，1==不可以，2==可以
            companyType: 0,
            // 当前解答项id
            id: "",
            // 分类id
            lable_id: "",
            // 左侧tap索引
            issueIndex: 0,
            // 存储企业会员登录返回的id
            memberId: "",
            // 发布问题输入框数据
            form: {
                name: "",
                area: "",
                firm: "",
                linkman: "",
                phone: "",
                mail: "",
                code: "",
                lable: "",
                money: "",
                lable: "",
                use_id: "",
            },
            // 我来解答输入框数据
            formNew: {
                titleNew: "",
                areaNew: "",
                firmNew: "",
                phoneNew: "",
                mailNew: "",
                codeNew: "",
            },
            identifyCode: "", // 生成的验证
            // 选中的性别,1=先生,2=女士
            sex: "1",
            // 选中需求分类id
            skill_id: "",
            // 需求分类数据
            needCatelist: [],
            // 验证码发布问题
            checkCode1: "",
            // 验证码我来解答
            checkCode2: "",
            yingyong: "", //应用领域
        };
    },
    // 计算属性
    computed: {
        navtl() {
            return this.issueIndex == 0 ? "发布问题" : "我来解答";
        },
    },
    // 侦听器
    watch: {},
    // 组件方法
    created() {
        if (JSON.stringify(this.$route.query) != "{}") {
            this.issueIndex = this.$route.query.indexa;
            this.lable_id = this.$route.query.lable_id;
            this.id = this.$route.query.id;
        }

        // 判断是否能发布问题，1==不可以，2==可以
        this.companyType = sessionStorage.getItem("companyType");

        // 存储企业会员登录返回的id
        this.memberId = sessionStorage.getItem("id");

        this.needCatelistRe();
        this.getyingyong();
    },
    methods: {
        //获取应用领域列表
        getyingyong() {
            yingyonglist().then((res) => {
                if (res.code == 1) {
                    this.yingyong = res.data;
                    this.yingyong.unshift({ id: 0, name: "请选择" });
                }
            });
        },
        // 获取需求分类
        needCatelistRe() {
            needCatelist().then((res) => {
                if (res.code == 1) {
                    console.log(res.data);
                    this.needCatelist = res.data;
                    this.needCatelist.unshift({ id: 0, name: "请选择" });
                } else {
                    this.message(res.msg);
                }
            });
        },
        // 发布问题、我来解答切换
        issueEv(index) {
            this.issueIndex = index;
        },
        // 我来解答验证码
        changeCode2(value) {
            this.checkCode2 = value;
            // this.$refs["ref_validateCode"].draw();
            console.log(value, "验证码初始化2");
        },
        // 问题发布验证码
        changeCode1(value) {
            this.checkCode1 = value;
            // this.$refs["ref_validateCode"].draw();
            console.log(value, "验证码初始化1");
        },

        // 发布问题重写
        rewrite() {
            this.form.name = "";
            this.form.area = "";
            this.form.firm = "";
            this.form.linkman = "";
            this.form.phone = "";
            this.form.mail = "";
            this.form.code = "";
        },

        // 我来解答重写
        rewriteNew() {
            this.formNew.titleNew = "";
            this.formNew.areaNew = "";
            this.formNew.firmNew = "";
            this.formNew.phoneNew = "";
            this.formNew.mailNew = "";
            this.formNew.codeNew = "";
        },
        // 发布问题提交
        submit() {
            console.log(this.checkCode1, "提交验证码");
            this.$refs["ref_validateCode"].draw();
            let phoneDta = this.form.phone;
            let refPhone =
                /^[1](([3][0-9])|([4][0,1,4-9])|([5][0-3,5-9])|([6][2,5,6,7])|([7][0-8])|([8][0-9])|([9][0-3,5-9]))[0-9]{8}$/;
            if (this.form.name == "") {
                this.$message.warning({
                    message: "难题名称为空",
                    duration: 1000,
                    customClass: "myBox",
                });
                return;
            } else if (this.cate_id == "") {
                this.$message.warning({
                    message: "分类为空",
                    duration: 1000,
                    customClass: "myBox",
                });
                return;
            } else if (this.form.area == "") {
                this.$message.warning({
                    message: "难题描述为空",
                    duration: 1000,
                    customClass: "myBox",
                });
                return;
            } else if (this.form.firm == "") {
                this.$message.warning({
                    message: "公司名称为空",
                    duration: 1000,
                    customClass: "myBox",
                });
                return;
            } else if (this.form.linkman == "") {
                this.$message.warning({
                    message: "联系人为空",
                    duration: 1000,
                    customClass: "myBox",
                });
                return;
            } else if (this.sex == "") {
                this.$message.warning({
                    message: "性别为空",
                    duration: 1000,
                    customClass: "myBox",
                });
                return;
            } else if (!refPhone.test(phoneDta)) {
                this.$message.warning({
                    message: "联系电话错误",
                    duration: 1000,
                    customClass: "myBox",
                });
                return;
            } else if (this.form.lable == "") {
                this.$message.warning({
                    message: "标签为空",
                    duration: 1000,
                    customClass: "myBox",
                });
                return;
            } else if (this.form.mail == "") {
                this.$message.warning({
                    message: "邮箱为空",
                    duration: 1000,
                    customClass: "myBox",
                });
                return;
            } else if (this.form.code != this.checkCode1) {
                this.$message.warning({
                    message: "验证码错误",
                    duration: 1000,
                    customClass: "myBox",
                });
                return;
            } else {
                this.setproblemRe();
            }
        },
        // 技术难题发布
        setproblemRe() {
            let data = {
                id: sessionStorage.getItem("companyId"), //企业ID
                skill_id: this.skill_id, //分类id
                lable: this.form.lable, //标签
                title: this.form.name, //标题
                content: this.form.area, //难题描述
                company: this.form.firm, //公司名称
                author: this.form.linkman, //联系人
                sex: this.sex, //性别
                mobile: this.form.phone, //联系方式
                email: this.form.mail, //邮箱
                lable: this.form.lable,
                use_id: this.form.use_id,
                money: this.form.money,
            };
            setproblem(data).then((res) => {
                if (res.code == 1) {
                    // console.log(res.data);
                    this.$message.success({
                        message: "提交成功",
                        duration: 1000,
                        customClass: "myBox",
                    });
                } else {
                    this.$message.success({
                        message: res.msg,
                        duration: 1000,
                        customClass: "myBox",
                    });
                }
            });
        },
        // 我来解答提交
        submitNew() {
            console.log("我来解答测试");
            let phoneDta = this.formNew.phoneNew;
            let refPhone =
                /^[1](([3][0-9])|([4][0,1,4-9])|([5][0-3,5-9])|([6][2,5,6,7])|([7][0-8])|([8][0-9])|([9][0-3,5-9]))[0-9]{8}$/;
            if (this.formNew.titleNew == "") {
                this.$message.warning({
                    message: "标题为空",
                    duration: 1000,
                    customClass: "myBox",
                });
                return;
            } else if (this.formNew.areaNew == "") {
                this.$message.warning({
                    message: "难题描述为空",
                    duration: 1000,
                    customClass: "myBox",
                });
                return;
            } else if (this.formNew.firmNew == "") {
                this.$message.warning({
                    message: "专家名称为空",
                    duration: 1000,
                    customClass: "myBox",
                });
                return;
            } else if (!refPhone.test(phoneDta)) {
                this.$message.warning({
                    message: "联系电话错误",
                    duration: 1000,
                    customClass: "myBox",
                });
                return;
            } else if (this.formNew.mailNew == "") {
                this.$message.warning({
                    message: "邮箱为空",
                    duration: 1000,
                    customClass: "myBox",
                });
                return;
            } else if (this.formNew.codeNew != this.checkCode2) {
                this.$message.warning({
                    message: "验证码为空",
                    duration: 1000,
                    customClass: "myBox",
                });
                return;
            } else {
                let data = {
                    id: this.id,
                    userid: sessionStorage.getItem("companyId"), //企业ID
                    title: this.formNew.titleNew, //标题
                    name: this.formNew.firmNew, //专家名称
                    mobile: this.formNew.phoneNew, //手机
                    email: this.formNew.mailNew, //邮箱
                    content: this.formNew.areaNew, //描述
                };
                setanswer(data).then((res) => {
                    if (res.code == 1) {
                        this.$message.success({
                            message: "提交成功",
                            duration: 1000,
                            customClass: "myBox",
                        });
                    } else {
                        this.$message.success({
                            message: res.msg,
                            duration: 1000,
                            customClass: "myBox",
                        });
                    }
                });
            }
        },
        // 选择性别
        changeRadio(e) {
            this.sex = e.target.value;
            // console.log(e.target.value, "性别");
        },
        // 选择需求分类
        selectChange(e) {
            this.skill_id = e.target.value;
            // console.log(this.cate_id);
        },
        selectChangetwo(e) {
            this.form.use_id = e.target.value;
            // console.log(this.cate_id);
        },
    },
    mounted() {},
    // 局部注册的组件
    components: {
        Header,
        Footer,
        Right,
        // Left3,
        Code,
        Nav,
        Advertising,
    },
};
</script>

<style lang="scss">
// 修改message弹框样式
.myBox {
    min-width: 130px !important;
    height: 25px !important;
    padding: 3px !important;
    text-align: center !important;
    display: flex !important;
    justify-content: center !important;
    .el-message__icon {
        font-size: 10px;
    }
    .el-message__content {
        font-size: 8px;
    }
}

.demandIssue {
    width: 100%;

    .minkave-wrap {
        width: 70%;
        // height: 1000px;
        margin: 0 auto;
        margin-top: 20px;
        margin-bottom: 40px;
        // border: 1px solid red;

        /*顶部图片start*/
        .index-headImg {
            position: relative;
            z-index: 0;
            width: 100%;
            height: 50px;
            margin-top: 15px;
            display: flex;
            justify-content: space-between;

            // 左侧图片
            .index-headImg-left {
                width: 48%;
                height: 100%;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            // 右侧图片
            .index-headImg-right {
                width: 48%;
                height: 100%;

                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
        /*顶部图片end*/

        /*关于学会tap start*/
        .survey-nav {
            display: flex;
            align-items: center;
            width: 100%;
            padding: 10px 0;
            box-sizing: border-box;
            border-bottom: 0.5px solid #e4e4e4;

            .survey-nav-title {
                width: 20%;
                display: flex;
                align-items: center;
                // border: 1px solid red;

                .survey-title-cn {
                    font-size: 10px;
                    color: #0056b0;
                    margin-right: 15px;
                }

                .survey-title-sn {
                    height: 20px;
                    font-size: 6px;
                    color: #fff;
                    padding: 0 6px;
                    background-color: #0056b0;
                }
            }
        }
        /*关于学会tap end*/

        /*内容区域 start*/
        .survey-cont {
            width: 100%;

            .survey-content {
                display: flex;
                width: 100%;
                margin-top: 10px;
                // height: 300px;
                // border: 1px solid red;

                // 内容区域左侧1(技术难题)
                .survey-content-left {
                    display: flex;
                    width: 68%;
                    height: 100%;
                    // border: 1px solid blue;

                    .survey-detail-left {
                        width: 26%;
                        // border: 1px solid red;

                        // 详情左侧内容
                        .minkaveDetails-introduce5-left {
                            width: 100%;
                            // height: 216px;
                            // border: 1px solid red;

                            // 发布问题、我来解答
                            .minkaveDetails-introduce5-title {
                                > ul {
                                    list-style: none;

                                    .activeli {
                                        color: #1866b7;
                                    }

                                    li {
                                        cursor: pointer;
                                        width: 100%;
                                        height: 20px;
                                        text-align: center;
                                        line-height: 20px;
                                        font-size: 9px;
                                        background-color: #e6e6e6;
                                        border-bottom: 0.5px dashed #adadad;
                                    }
                                }
                            }
                        }
                    }

                    // 详情右侧内容
                    .survey-detail-right {
                        width: 70%;
                        margin-left: auto;
                        // border: 1px solid red;

                        .detail-right {
                            width: 100%;
                            font-size: 12px;
                            background-color: #f8f8f8;

                            .detail-right-place {
                                font-size: 10px;
                                padding: 5px;
                                box-sizing: border-box;
                                border-bottom: 0.5px solid #e6e6e6;
                            }

                            .detail-right-cont {
                                padding: 5px;
                                font-size: 8px;

                                //发布问题
                                > div:nth-child(1) {
                                    // 难题名称
                                    .detail-right-name {
                                        display: flex;
                                        align-items: center;
                                        margin-bottom: 10px;

                                        > label {
                                            width: 50px;
                                        }

                                        > input {
                                            width: 200px;
                                            height: 13px;
                                        }
                                    }

                                    .detail-right-select {
                                        display: flex;
                                        align-items: center;
                                        margin-bottom: 10px;

                                        > label {
                                            width: 50px;
                                        }

                                        > select {
                                            width: 200px;
                                            height: 15px;
                                        }
                                    }

                                    // 难题描述
                                    .detail-right-area {
                                        display: flex;
                                        margin-bottom: 10px;
                                        > label {
                                            width: 50px;
                                        }

                                        > textarea {
                                            width: 200px;
                                            height: 33px;
                                        }
                                    }

                                    // 公司名称
                                    .detail-right-firm {
                                        display: flex;
                                        align-items: center;
                                        margin-bottom: 10px;

                                        > label {
                                            width: 50px;
                                        }

                                        > input {
                                            width: 200px;
                                            height: 13px;
                                        }
                                    }

                                    // 联系人
                                    .relation-right-linkman {
                                        display: flex;
                                        align-items: center;
                                        margin-bottom: 10px;

                                        > label {
                                            width: 50px;
                                        }

                                        > input:nth-child(2) {
                                            width: 40%;
                                            height: 13px;
                                        }

                                        > input:nth-child(3) {
                                            margin: 0 5px 0 5px;
                                        }

                                        > input:nth-child(4) {
                                            margin: 0 5px 0 5px;
                                        }
                                    }
                                    // 联系电话
                                    .relation-right-phone {
                                        display: flex;
                                        align-items: center;
                                        margin-bottom: 10px;

                                        > label {
                                            width: 50px;
                                        }

                                        > input {
                                            width: 200px;
                                            height: 13px;
                                        }
                                    }
                                    // 电子邮件
                                    .relation-right-mail {
                                        display: flex;
                                        align-items: center;
                                        margin-bottom: 10px;

                                        > label {
                                            width: 50px;
                                        }

                                        > input {
                                            width: 200px;
                                            height: 13px;
                                        }
                                    }

                                    // 验证码
                                    .relation-right-code {
                                        display: flex;
                                        align-items: center;
                                        margin-bottom: 10px;

                                        > label {
                                            width: 50px;
                                        }

                                        > input {
                                            width: 100px;
                                            height: 13px;
                                        }

                                        > div {
                                            margin-left: 5px;
                                            width: 50px;
                                        }
                                    }

                                    // 提交、重写
                                    > div:last-child {
                                        display: flex;
                                        margin-left: 50px;

                                        > div:nth-child(1) {
                                            cursor: pointer;
                                            margin-right: 5px;
                                            color: #fff;
                                            padding: 3px 7px;
                                            background-color: #0056b0;
                                        }

                                        > div:nth-child(2) {
                                            cursor: pointer;
                                            margin-right: 5px;
                                            color: #fff;
                                            padding: 3px 7px;
                                            background-color: #f77132;
                                        }
                                    }
                                }

                                // 我来解答
                                > div:nth-child(2) {
                                    // 难题名称
                                    .detail-right-title {
                                        display: flex;
                                        align-items: center;
                                        margin-bottom: 10px;

                                        > label {
                                            width: 50px;
                                        }

                                        > input {
                                            width: 200px;
                                            height: 13px;
                                        }
                                    }

                                    // 难题描述
                                    .detail-right-areaNew {
                                        display: flex;
                                        margin-bottom: 10px;
                                        > label {
                                            width: 50px;
                                        }

                                        > textarea {
                                            width: 200px;
                                            height: 33px;
                                        }
                                    }

                                    // 公司名称
                                    .detail-right-firmNew {
                                        display: flex;
                                        align-items: center;
                                        margin-bottom: 10px;

                                        > label {
                                            width: 50px;
                                        }

                                        > input {
                                            width: 200px;
                                            height: 13px;
                                        }
                                    }

                                    // 联系电话
                                    .relation-right-phoneNew {
                                        display: flex;
                                        align-items: center;
                                        margin-bottom: 10px;

                                        > label {
                                            width: 50px;
                                        }

                                        > input {
                                            width: 200px;
                                            height: 13px;
                                        }
                                    }
                                    // 电子邮件
                                    .relation-right-mailNew {
                                        display: flex;
                                        align-items: center;
                                        margin-bottom: 10px;

                                        > label {
                                            width: 50px;
                                        }

                                        > input {
                                            width: 200px;
                                            height: 13px;
                                        }
                                    }

                                    // 验证码
                                    .relation-right-codeNew {
                                        display: flex;
                                        align-items: center;
                                        margin-bottom: 10px;

                                        > label {
                                            width: 50px;
                                        }

                                        > input {
                                            width: 100px;
                                            height: 13px;
                                        }

                                        > div {
                                            margin-left: 5px;
                                            // width: 30px;
                                            // border: 1px solid red;
                                        }
                                    }

                                    // 提交、重写
                                    > div:last-child {
                                        display: flex;
                                        margin-left: 50px;

                                        > div:nth-child(1) {
                                            cursor: pointer;
                                            margin-right: 5px;
                                            color: #fff;
                                            padding: 3px 7px;
                                            background-color: #0056b0;
                                        }

                                        > div:nth-child(2) {
                                            cursor: pointer;
                                            margin-right: 5px;
                                            color: #fff;
                                            padding: 3px 7px;
                                            background-color: #f77132;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                // 内容区域右侧
                .survey-content-right {
                    margin-left: auto;
                    width: 30%;
                    // border: 1px solid red;
                }
            }
        }

        /*内容区域 end*/
    }
}

//当屏幕最小宽度为992px
@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .demandIssue {
        width: 100%;

        .minkave-wrap {
            width: 70%;
            // height: 1000px;
            margin: 0 auto;
            margin-top: 20px;
            margin-bottom: 40px;
            // border: 1px solid red;

            /*顶部图片start*/
            .index-headImg {
                position: relative;
                z-index: 0;
                width: 100%;
                height: 50px;
                margin-top: 15px;
                display: flex;
                justify-content: space-between;

                // 左侧图片
                .index-headImg-left {
                    width: 48%;
                    height: 100%;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }

                // 右侧图片
                .index-headImg-right {
                    width: 48%;
                    height: 100%;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
            /*顶部图片end*/

            /*关于学会tap start*/
            .survey-nav {
                display: flex;
                align-items: center;
                width: 100%;
                padding: 10px 0;
                box-sizing: border-box;
                border-bottom: 0.5px solid #e4e4e4;

                .survey-nav-title {
                    width: 20%;
                    display: flex;
                    align-items: center;
                    // border: 1px solid red;

                    .survey-title-cn {
                        font-size: 10px;
                        color: #0056b0;
                        margin-right: 15px;
                    }

                    .survey-title-sn {
                        height: 20px;
                        font-size: 6px;
                        color: #fff;
                        padding: 0 6px;
                        background-color: #0056b0;
                    }
                }
            }
            /*关于学会tap end*/

            /*内容区域 start*/
            .survey-cont {
                width: 100%;

                .survey-content {
                    display: flex;
                    width: 100%;
                    margin-top: 10px;
                    // height: 300px;
                    // border: 1px solid red;

                    // 内容区域左侧1(技术难题)
                    .survey-content-left {
                        display: flex;
                        width: 68%;
                        height: 100%;
                        // border: 1px solid blue;

                        .survey-detail-left {
                            width: 26%;
                            // border: 1px solid red;

                            // 详情左侧内容
                            .minkaveDetails-introduce5-left {
                                width: 100%;
                                // height: 216px;
                                // border: 1px solid red;

                                // 发布问题、我来解答
                                .minkaveDetails-introduce5-title {
                                    > ul {
                                        list-style: none;

                                        .activeli {
                                            color: #1866b7;
                                        }

                                        li {
                                            cursor: pointer;
                                            width: 100%;
                                            height: 20px;
                                            text-align: center;
                                            line-height: 20px;
                                            font-size: 9px;
                                            background-color: #e6e6e6;
                                            border-bottom: 0.5px dashed #adadad;
                                        }
                                    }
                                }
                            }
                        }

                        // 详情右侧内容
                        .survey-detail-right {
                            width: 70%;
                            margin-left: auto;
                            // border: 1px solid red;

                            .detail-right {
                                width: 100%;
                                font-size: 12px;
                                background-color: #f8f8f8;

                                .detail-right-place {
                                    font-size: 10px;
                                    padding: 5px;
                                    box-sizing: border-box;
                                    border-bottom: 0.5px solid #e6e6e6;
                                }

                                .detail-right-cont {
                                    padding: 5px;
                                    font-size: 8px;

                                    //发布问题
                                    > div:nth-child(1) {
                                        // 难题名称
                                        .detail-right-name {
                                            display: flex;
                                            align-items: center;
                                            margin-bottom: 10px;

                                            > label {
                                                width: 50px;
                                            }

                                            > input {
                                                width: 200px;
                                                height: 13px;
                                            }
                                        }

                                        .detail-right-select {
                                            display: flex;
                                            align-items: center;
                                            margin-bottom: 10px;

                                            > label {
                                                width: 50px;
                                            }

                                            > select {
                                                width: 200px;
                                                height: 15px;
                                            }
                                        }

                                        // 难题描述
                                        .detail-right-area {
                                            display: flex;
                                            margin-bottom: 10px;
                                            > label {
                                                width: 50px;
                                            }

                                            > textarea {
                                                width: 200px;
                                                height: 33px;
                                            }
                                        }

                                        // 公司名称
                                        .detail-right-firm {
                                            display: flex;
                                            align-items: center;
                                            margin-bottom: 10px;

                                            > label {
                                                width: 50px;
                                            }

                                            > input {
                                                width: 200px;
                                                height: 13px;
                                            }
                                        }

                                        // 联系人
                                        .relation-right-linkman {
                                            display: flex;
                                            align-items: center;
                                            margin-bottom: 10px;
                                            // border: 1px solid red;

                                            > label {
                                                width: 50px;
                                            }

                                            > input:nth-child(2) {
                                                width: 60px;
                                                height: 13px;
                                                // border: 1px solid red;
                                            }

                                            > input:nth-child(3) {
                                                // width: 10px;
                                                // border: 1px solid red;
                                            }

                                            > span:nth-child(4) {
                                                display: block;
                                                width: 30px;
                                                margin: 0 5px 0 5px;
                                            }

                                            > input:nth-child(5) {
                                                // width: 20px;
                                                margin: 0 5px 0 5px;
                                            }
                                            > span:nth-child(6) {
                                                display: block;
                                                width: 30px;
                                                margin: 0 5px 0 5px;
                                            }
                                        }
                                        // 联系电话
                                        .relation-right-phone {
                                            display: flex;
                                            align-items: center;
                                            margin-bottom: 10px;

                                            > label {
                                                width: 50px;
                                            }

                                            > input {
                                                width: 200px;
                                                height: 13px;
                                            }
                                        }
                                        // 电子邮件
                                        .relation-right-mail {
                                            display: flex;
                                            align-items: center;
                                            margin-bottom: 10px;

                                            > label {
                                                width: 50px;
                                            }

                                            > input {
                                                width: 200px;
                                                height: 13px;
                                            }
                                        }

                                        // 验证码
                                        .relation-right-code {
                                            display: flex;
                                            align-items: center;
                                            margin-bottom: 10px;

                                            > label {
                                                width: 50px;
                                            }

                                            > input {
                                                width: 70px;
                                                height: 13px;
                                            }

                                            > div {
                                                margin-left: 5px;
                                                width: 70px;
                                                // border: 1px solid red;
                                            }
                                        }

                                        // 提交、重写
                                        > div:last-child {
                                            display: flex;
                                            margin-left: 50px;

                                            > div:nth-child(1) {
                                                cursor: pointer;
                                                margin-right: 5px;
                                                color: #fff;
                                                padding: 3px 7px;
                                                background-color: #0056b0;
                                            }

                                            > div:nth-child(2) {
                                                cursor: pointer;
                                                margin-right: 5px;
                                                color: #fff;
                                                padding: 3px 7px;
                                                background-color: #f77132;
                                            }
                                        }
                                    }

                                    // 我来解答
                                    > div:nth-child(2) {
                                        // 难题名称
                                        .detail-right-title {
                                            display: flex;
                                            align-items: center;
                                            margin-bottom: 10px;

                                            > label {
                                                width: 50px;
                                            }

                                            > input {
                                                width: 200px;
                                                height: 13px;
                                            }
                                        }

                                        // 难题描述
                                        .detail-right-areaNew {
                                            display: flex;
                                            margin-bottom: 10px;
                                            > label {
                                                width: 50px;
                                            }

                                            > textarea {
                                                width: 200px;
                                                height: 33px;
                                            }
                                        }

                                        // 公司名称
                                        .detail-right-firmNew {
                                            display: flex;
                                            align-items: center;
                                            margin-bottom: 10px;

                                            > label {
                                                width: 50px;
                                            }

                                            > input {
                                                width: 200px;
                                                height: 13px;
                                            }
                                        }

                                        // 联系电话
                                        .relation-right-phoneNew {
                                            display: flex;
                                            align-items: center;
                                            margin-bottom: 10px;

                                            > label {
                                                width: 50px;
                                            }

                                            > input {
                                                width: 200px;
                                                height: 13px;
                                            }
                                        }
                                        // 电子邮件
                                        .relation-right-mailNew {
                                            display: flex;
                                            align-items: center;
                                            margin-bottom: 10px;

                                            > label {
                                                width: 50px;
                                            }

                                            > input {
                                                width: 200px;
                                                height: 13px;
                                            }
                                        }

                                        // 验证码
                                        .relation-right-codeNew {
                                            display: flex;
                                            align-items: center;
                                            margin-bottom: 10px;

                                            > label {
                                                width: 50px;
                                            }

                                            > input {
                                                width: 100px;
                                                height: 13px;
                                            }

                                            > div {
                                                margin-left: 5px;
                                                // width: 30px;
                                                // border: 1px solid red;
                                            }
                                        }

                                        // 提交、重写
                                        > div:last-child {
                                            display: flex;
                                            margin-left: 50px;

                                            > div:nth-child(1) {
                                                cursor: pointer;
                                                margin-right: 5px;
                                                color: #fff;
                                                padding: 3px 7px;
                                                background-color: #0056b0;
                                            }

                                            > div:nth-child(2) {
                                                cursor: pointer;
                                                margin-right: 5px;
                                                color: #fff;
                                                padding: 3px 7px;
                                                background-color: #f77132;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    // 内容区域右侧
                    .survey-content-right {
                        margin-left: auto;
                        width: 30%;
                        // border: 1px solid red;
                    }
                }
            }

            /*内容区域 end*/
        }
    }
}

//当屏幕宽度 最小为768px 最大为991px时
@media screen and (min-width: 768px) and (max-width: 1200px) {
    // 修改message弹框样式
    .myBox {
        min-width: 130px !important;
        height: 25px !important;
        padding: 3px !important;
        text-align: center !important;
        display: flex !important;
        justify-content: center !important;
        .el-message__icon {
            font-size: 10px;
        }
        .el-message__content {
            font-size: 8px;
        }
    }

    .demandIssue {
        width: 100%;

        .minkave-wrap {
            width: 80%;
            // height: 1000px;
            margin: 0 auto;
            margin-top: 20px;
            margin-bottom: 40px;
            // border: 1px solid red;

            /*顶部图片start*/
            .index-headImg {
                position: relative;
                z-index: 0;
                width: 100%;
                height: 50px;
                margin-top: 15px;
                display: flex;
                justify-content: space-between;

                // 左侧图片
                .index-headImg-left {
                    width: 48%;
                    height: 100%;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }

                // 右侧图片
                .index-headImg-right {
                    width: 48%;
                    height: 100%;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
            /*顶部图片end*/

            /*关于学会tap start*/
            .survey-nav {
                display: flex;
                align-items: center;
                width: 100%;
                padding: 10px 0;
                box-sizing: border-box;
                border-bottom: 1px solid #e4e4e4;

                .survey-nav-title {
                    width: 20%;
                    display: flex;
                    align-items: center;
                    // border: 1px solid red;

                    .survey-title-cn {
                        font-size: 10px;
                        color: #0056b0;
                        margin-right: 15px;
                    }

                    .survey-title-sn {
                        height: 20px;
                        font-size: 6px;
                        color: #fff;
                        padding: 0 6px;
                        background-color: #0056b0;
                    }
                }
            }
            /*关于学会tap end*/

            /*内容区域 start*/
            .survey-cont {
                width: 100%;

                .survey-content {
                    display: flex;
                    width: 100%;
                    // height: 300px;
                    // border: 1px solid red;

                    // 内容区域左侧1(技术难题)
                    .survey-content-left {
                        display: flex;
                        width: 68%;
                        height: 100%;
                        // border: 1px solid blue;

                        .survey-detail-left {
                            width: 26%;
                            // border: 1px solid red;

                            // 详情左侧内容
                            .minkaveDetails-introduce5-left {
                                width: 100%;
                                // height: 216px;
                                // border: 1px solid red;

                                // 发布问题、我来解答
                                .minkaveDetails-introduce5-title {
                                    > ul {
                                        list-style: none;

                                        .activeli {
                                            color: #1866b7;
                                        }

                                        li {
                                            cursor: pointer;
                                            width: 100%;
                                            height: 20px;
                                            text-align: center;
                                            line-height: 20px;
                                            font-size: 9px;
                                            // color: #1866b7;
                                            border-bottom: 1px solid #e9e9e9;
                                        }
                                    }
                                }
                            }
                        }

                        // 详情右侧内容
                        .survey-detail-right {
                            width: 70%;
                            margin-left: auto;
                            // border: 1px solid red;

                            .detail-right {
                                width: 100%;
                                font-size: 12px;
                                background-color: #f8f8f8;

                                .detail-right-place {
                                    font-size: 7px;
                                    padding: 5px;
                                    box-sizing: border-box;
                                    border-bottom: 1px solid #e6e6e6;
                                }

                                .detail-right-cont {
                                    padding: 5px;
                                    font-size: 8px;

                                    //发布问题
                                    > div:nth-child(1) {
                                        // 难题名称
                                        .detail-right-name {
                                            display: flex;
                                            align-items: center;
                                            margin-bottom: 10px;

                                            > label {
                                                width: 50px;
                                                font-size: 7px;
                                            }

                                            > input {
                                                width: 200px;
                                                height: 13px;
                                            }
                                        }

                                        .detail-right-select {
                                            display: flex;
                                            align-items: center;
                                            margin-bottom: 10px;

                                            > label {
                                                width: 35px;
                                            }

                                            > select {
                                                width: 83%;
                                                height: 15px;
                                            }
                                        }

                                        // 难题描述
                                        .detail-right-area {
                                            display: flex;
                                            margin-bottom: 10px;
                                            > label {
                                                width: 50px;
                                                font-size: 7px;
                                            }

                                            > textarea {
                                                width: 200px;
                                                height: 33px;
                                            }
                                        }

                                        // 公司名称
                                        .detail-right-firm {
                                            display: flex;
                                            align-items: center;
                                            margin-bottom: 10px;

                                            > label {
                                                width: 50px;
                                                font-size: 7px;
                                            }

                                            > input {
                                                width: 200px;
                                                height: 13px;
                                            }
                                        }

                                        // 联系人
                                        .relation-right-linkman {
                                            display: flex;
                                            align-items: center;
                                            margin-bottom: 10px;
                                            // border: 1px solid red;

                                            > label {
                                                width: 50px;
                                            }

                                            > input:nth-child(2) {
                                                width: 40px;
                                                height: 13px;
                                                // border: 1px solid red;
                                            }

                                            > input:nth-child(3) {
                                                // width: 10px;
                                                // border: 1px solid red;
                                            }

                                            > span:nth-child(4) {
                                                display: block;
                                                width: 30px;
                                                margin: 0 5px 0 5px;
                                            }

                                            > input:nth-child(5) {
                                                // width: 20px;
                                                margin: 0 5px 0 5px;
                                            }
                                            > span:nth-child(6) {
                                                display: block;
                                                width: 30px;
                                                margin: 0 5px 0 5px;
                                            }
                                        }
                                        // 联系电话
                                        .relation-right-phone {
                                            display: flex;
                                            align-items: center;
                                            margin-bottom: 10px;

                                            > label {
                                                width: 50px;
                                                font-size: 7px;
                                            }

                                            > input {
                                                width: 200px;
                                                height: 13px;
                                            }
                                        }
                                        // 电子邮件
                                        .relation-right-mail {
                                            display: flex;
                                            align-items: center;
                                            margin-bottom: 10px;

                                            > label {
                                                width: 50px;
                                                font-size: 7px;
                                            }

                                            > input {
                                                width: 200px;
                                                height: 13px;
                                            }
                                        }

                                        // 验证码
                                        .relation-right-code {
                                            display: flex;
                                            align-items: center;
                                            margin-bottom: 10px;

                                            > label {
                                                width: 110px;
                                                font-size: 7px;
                                            }

                                            > input {
                                                width: 65px;
                                                height: 13px;
                                            }

                                            > div {
                                                margin-left: 5px;
                                                width: 80%;
                                                // border: 1px solid red;
                                            }
                                        }

                                        // 提交、重写
                                        > div:last-child {
                                            display: flex;
                                            margin-left: 50px;

                                            > div:nth-child(1) {
                                                cursor: pointer;
                                                margin-right: 5px;
                                                color: #fff;
                                                padding: 3px 7px;
                                                background-color: #0056b0;
                                            }

                                            > div:nth-child(2) {
                                                cursor: pointer;
                                                margin-right: 5px;
                                                color: #fff;
                                                padding: 3px 7px;
                                                background-color: #f77132;
                                            }
                                        }
                                    }

                                    // 我来解答
                                    > div:nth-child(2) {
                                        // 难题名称
                                        .detail-right-title {
                                            display: flex;
                                            align-items: center;
                                            margin-bottom: 10px;

                                            > label {
                                                width: 50px;
                                                font-size: 7px;
                                            }

                                            > input {
                                                width: 200px;
                                                height: 13px;
                                            }
                                        }

                                        // 难题描述
                                        .detail-right-areaNew {
                                            display: flex;
                                            margin-bottom: 10px;
                                            > label {
                                                width: 50px;
                                                font-size: 7px;
                                            }

                                            > textarea {
                                                width: 200px;
                                                height: 33px;
                                            }
                                        }

                                        // 公司名称
                                        .detail-right-firmNew {
                                            display: flex;
                                            align-items: center;
                                            margin-bottom: 10px;

                                            > label {
                                                width: 50px;
                                                font-size: 7px;
                                            }

                                            > input {
                                                width: 200px;
                                                height: 13px;
                                            }
                                        }

                                        // 联系电话
                                        .relation-right-phoneNew {
                                            display: flex;
                                            align-items: center;
                                            margin-bottom: 10px;

                                            > label {
                                                width: 50px;
                                                font-size: 7px;
                                            }

                                            > input {
                                                width: 200px;
                                                height: 13px;
                                            }
                                        }
                                        // 电子邮件
                                        .relation-right-mailNew {
                                            display: flex;
                                            align-items: center;
                                            margin-bottom: 10px;

                                            > label {
                                                width: 50px;
                                                font-size: 7px;
                                            }

                                            > input {
                                                width: 200px;
                                                height: 13px;
                                            }
                                        }

                                        // 验证码
                                        .relation-right-codeNew {
                                            display: flex;
                                            align-items: center;
                                            margin-bottom: 10px;

                                            > label {
                                                width: 110px;
                                                font-size: 7px;
                                            }

                                            > input {
                                                width: 70px;
                                                height: 13px;
                                            }

                                            > div {
                                                margin-left: 5px;
                                                width: 100%;
                                                // border: 1px solid red;
                                            }
                                        }

                                        // 提交、重写
                                        > div:last-child {
                                            display: flex;
                                            margin-left: 50px;

                                            > div:nth-child(1) {
                                                cursor: pointer;
                                                margin-right: 5px;
                                                color: #fff;
                                                padding: 3px 7px;
                                                background-color: #0056b0;
                                            }

                                            > div:nth-child(2) {
                                                cursor: pointer;
                                                margin-right: 5px;
                                                color: #fff;
                                                padding: 3px 7px;
                                                background-color: #f77132;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    // 内容区域右侧
                    .survey-content-right {
                        margin-left: auto;
                        width: 30%;
                        // border: 1px solid red;
                    }
                }
            }

            /*内容区域 end*/
        }
    }
}

//当屏幕最大宽度为767px
@media screen and (max-width: 767px) {
}
</style>
