<template>
    <!-- 助企活动---详情页 -->
    <div class="activityDetails">
        <Header></Header>
        <Nav></Nav>
        <div class="activityDetails-wrap">
            <!-- 顶部图片 -->
            <Advertising></Advertising>
            <!-- 内容区域 -->
            <div class="survey-cont">
                <div class="survey-content">
                    <!-- 关于学会tap -->
                    
                    <!-- <div class="survey-img">
                        <img :src="detail.image" alt="">
                    </div> -->
                    <!-- <div class="f-z">活动分类:{{ detail.catename }}</div> -->
                   <div class="survey-nav">{{detail.title}}</div>
                    <div class="survey-content-left">
                        <div v-html="detail.content"></div>
                    </div>
                    <!-- <div class="suy-sty">
                        <div>活动时间:{{ detail.createtime }}</div>
                        <div>活动地点:{{ detail.address }}</div>
                        <div>活动连接:<a :href="detail.url">{{ detail.url }}</a></div>
                    </div> -->
                </div>
                <div class="survey-content-right">
                    <Right></Right>
                </div>
            </div>
            <!-- 内容区域 -->
        </div>
    </div>
</template>

<script>
import Header from "@/components/Header.vue"; //头部
import Footer from "@/components/Footer.vue"; //尾部
import Right from "@/components/Right.vue";
import Nav from "@/components/Nav.vue"; //导航
import { activityDetail } from "../../../network/data";
import Advertising from "@/components/Advertising.vue"; //广告图
export default {
    // 组件名称
    name: "activityDetails",
    // 组件参数 接收来自父组件的数据
    props: {},
    // 组件状态值
    data() {
        return {
            // 详情页id
            id: "",
            // 当前tap索引
            currentIndex: 0,
            detail: {},
        };
    },
    // 计算属性
    computed: {},
    // 侦听器
    watch: {},
    // 组件方法
    created() {
        if (this.$route.query != "") {
            this.id = this.$route.query.id;
            this.activityDetailRe();
        }
    },
    methods: {
        // 获取详情页数据
        activityDetailRe() {
            let data = {
                id: this.id,
            };
            activityDetail(data).then((res) => {
                if (res.code == 1) {
                    // console.log(res.data);
                    this.detail = res.data;
                } else {
                    this.message(res.msg);
                }
            });
        },
    },
    mounted() {},
    // 局部注册的组件
    components: {
        Header,
        Footer,
        Right,
        Nav,
        Advertising,
    },
};
</script>

<style lang="scss">
.activityDetails {
    width: 100%;

    .activityDetails-wrap {
        width: 70%;
        margin: 0 auto;
        /*内容区域 start*/
        .survey-cont {
            width: 100%;
            display: flex;
            margin-top: 30px;
            .survey-content {
                width: 100%;
                .survey-nav {
                    font-size: 16px;
                    text-align: center;
                    font-weight: 600;
                    margin: 15px 0;
                }
                .survey-img{
                    width: 100%;
                    // height: 150px;
                    img{
                        width: 100%;
                        height: 100%;
                    }
                }
                .f-z{
                    font-size: 8px;
                    padding: 5px 0;
                    font-weight: 600;
                }
                .survey-content-left {
                    width: 98%;

                    > div:nth-child(1) {
                        width: 100%;
                        font-size: 6px;
                        border:1px  solid blue;

                        .table{
                            width: 100%;
                            tbody{
                                tr{
                                    td{
                                        img{
                                            width: 100%;
                                        }
                                    }
                                }
                            }
                        }

                        // >img {
                        //     width: 100% !important;
                        //     height: 100% !important;
                        // }
                    }
                    
                }
                .suy-sty{
                    font-size: 8px;
                    font-weight: 600;
                    div{
                        padding: 5px 0;
                    }
                }
            }
            .survey-content-right {
                margin-left: auto;
                width: 30%;
                // border: 1px solid red;
            }
            // 内容区域右侧
            
        }

        /*内容区域 end*/
    }
}
@media screen and (min-width: 768px) and (max-width: 1200px){
    .activityDetails {
        width: 100%;

        .activityDetails-wrap {
            width: 70%;
            margin: 0 auto;
            /*内容区域 start*/
            .survey-cont {
                width: 100%;
                display: flex;
                margin-top: 30px;
                .survey-content {
                    width: 76%;
                    .survey-nav {
                        font-size: 16px;
                        text-align: center;
                        font-weight: 600;
                        margin: 15px 0;
                    }
                    .survey-img{
                        width: 100%;
                        img{
                            width: 100%;
                            height: 100%;
                        }
                    }
                    .f-z{
                        font-size: 8px;
                        padding: 5px 0;
                        font-weight: 600;
                    }
                    .survey-content-left {
                        width: 100%;
                        // height: 100%;

                        > div:nth-child(1) {
                            width: 100%;
                            font-size: 6px;
                            border:1px  solid red;
                            .table{
                            width: 100%;
                                tbody{
                                    tr{
                                        td{
                                            img{
                                                width: 100%;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        
                    }
                    .suy-sty{
                        font-size: 8px;
                        font-weight: 600;
                        div{
                            padding: 5px 0;
                        }
                    }
                }
                .survey-content-right {
                    margin-left: auto;
                    width: 23%;
                }
            }
        }
    }
}
</style>
