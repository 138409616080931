<template>
    <div class="productDetail">
        <Mheader :currentIndex="3"></Mheader>

        <!-- 轮播图 -->
        <div class="mIndex-swiper">
            <MeSwiper></MeSwiper>
        </div>

        <div class="introduces-wrap">
            <div class="introduces-cont">
                <div>当前位置：{{ tab }}</div>

                <!-- 拌馅机 -->
                <div class="introduces-cont-bxj">
                    <!-- 拌馅机标题 -->
                    <div class="introduces-cont-title">
                        {{ productdetailDe.title }}
                    </div>
                    <!-- 拌馅机内容区域 -->
                    <div class="introduces-cont-box">
                        <img :src="productdetailDe.image" alt="" />
                        <div class="introduces-right-text">
                            <div class="introduces-right-item">
                                <div>产品名称：{{ productdetailDe.title }}</div>
                                <div>
                                    产品规格：{{ productdetailDe.modelnum }}
                                </div>
                            </div>
                            <div class="introduces-right-item">
                                <div>
                                    产品分类：{{ productdetailDe.catename }}
                                </div>
                                <div>
                                    招商渠道：{{ productdetailDe.address }}
                                </div>
                            </div>

                            <!-- 联系我们右侧 -->
                            <div class="mIndex-contact-right">
                                <!-- 意向品类 -->
                                <div class="mIndex-contact-name">
                                    <label for="">
                                        <span style="color: #00479d">*</span>
                                        意向品类:</label
                                    >
                                    <select @change="intentionCh">
                                        <option
                                            :key="index"
                                            :value="item.name"
                                            v-for="(item, index) in intention"
                                        >
                                            {{ item.name }}
                                        </option>
                                    </select>
                                </div>
                                <!-- 意向区域 -->
                                <div class="mIndex-contact-dl">
                                    <label for=""
                                        ><span style="color: #00479d">*</span
                                        >意向区域:</label
                                    >
                                    <input
                                        type="text"
                                        v-model="form.area"
                                        placeholder="请输入您的意向区域"
                                    />
                                </div>
                                <!-- 招商电话 -->
                                <div class="mIndex-contact-phone">
                                    <label for=""
                                        ><span style="color: #00479d">*</span
                                        >联系方式:</label
                                    >
                                    <input
                                        type="text"
                                        v-model="form.phone"
                                        placeholder="请输入您的手机号"
                                    />
                                </div>

                                <!-- 留言内容 -->
                                <div class="mIndex-contact-ly">
                                    <label for=""
                                        ><span style="color: #00479d">*</span
                                        >留言内容:</label
                                    >
                                    <div>
                                        <select @change="areaCh">
                                            <option
                                                :key="index"
                                                :value="item.name"
                                                v-for="(item, index) in cities"
                                            >
                                                {{ item.name }}
                                            </option>
                                        </select>
                                    </div>
                                </div>

                                <!-- 立即留言 -->
                                <button @click="messageRe">立即留言</button>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- 产品信息 -->
                <div class="introduces-info">
                    <div>产品信息</div>
                    <div v-html="productdetailDe.content"></div>
                </div>
            </div>
        </div>

        <Footer></Footer>
    </div>
</template>

<script>
import Mheader from "../../../components/Mheader.vue";
import Footer from "../../../components/Footer";
import MeSwiper from "../../../components/Member/swiper.vue";
import {
    productdetail,
    companycate,
    message,
    messagecause,
} from "../../../network/data";
export default {
    // 组件名称
    name: "introduces",
    // 组件参数 接收来自父组件的数据
    props: {},
    // 组件状态值
    data() {
        return {
            message: "",
            cities: "",
            // 意向品类
            intention: [],
            // 选中的意向品类
            intentionID: "",
            form: {
                name: "",
                phone: "",

                area: "",
            },
            // 当前详情页id
            menu_id: "",
            // 首页轮播图数据
            swiperData: [
                {
                    image: "http://47.92.152.75/mswiper.png",
                },
                {
                    image: "http://47.92.152.75/mswiper.png",
                },
                {
                    image: "http://47.92.152.75/mswiper.png",
                },
            ],
            // 产品展示详情数据
            productdetailDe: {},
            // 当前位置
            tab: "",
            // 是否显示留言
            show: false,
        };
    },
    // 计算属性
    computed: {},
    // 侦听器
    watch: {},
    // 组件方法
    created() {
        if (this.$route.query != "") {
            this.menu_id = this.$route.query.menu_id;
            // 获取产品展示详情数据
            this.productdetailRe();
        }
        this.companycateRe();
        this.messagecauseRe();
    },
    methods: {
        messagecauseRe() {
            messagecause().then((res) => {
                if (res.code == 1) {
                    this.cities = res.data;
                    this.cities.unshift({ id: "", name: "请选择" });
                } else {
                    this.message(res.msg);
                }
            });
        },
        messageRe() {
            let data = {
                id: 1,
                name: this.intentionID,
                phone: this.form.phone,
                area: this.form.area,
                // wxnum: "",
                // province: this.areasheng,
                // city: this.areacity,
                content: this.message,
            };
            message(data).then((res) => {
                if (res.code == 1) {
                    this.$message.warning({
                        message: res.msg,
                        duration: 1000,
                        customClass: "myBox",
                    });
                } else {
                    this.$message.warning({
                        message: res.msg,
                        duration: 1000,
                        customClass: "myBox",
                    });
                }
            });
        },
        companycateRe() {
            let data = { typeid: 1 };
            companycate(data).then((res) => {
                if (res.code == 1) {
                    // console.log(res.data);
                    this.intention = res.data;
                    this.intention.unshift({ id: "", name: "请选择" });
                } else {
                    this.message(res.msg);
                }
            });
        },
        areaCh(e) {
            this.message = e.target.value;
        },
        // 监听意向品类
        intentionCh(e) {
            this.intentionID = e.target.value;
        },
        // 获取产品展示详情页
        productdetailRe() {
            let data = {
                id: this.menu_id,
                menuid: this.menu_id,
            };
            productdetail(data).then((res) => {
                if (res.code == 1) {
                    // console.log(res.data);
                    this.tab = res.data.tab;
                    this.productdetailDe = res.data.detail;
                } else {
                    this.message(res.msg);
                }
            });
        },
        // 是否显示留言
        Leaving() {
            this.show = !this.show;
        },
    },
    mounted() {},
    // 局部注册的组件
    components: {
        Mheader,
        Footer,
        MeSwiper,
    },
};
</script>

<style lang="scss">
.productDetail {
    width: 100%;

    img {
        display: block;
        height: 100%;
        width: 100%;
    }

    // 版心
    .introduces-wrap {
        width: 70%;
        margin: 0 auto;
        margin-bottom: 40px;
        // border: 1px solid red;

        .introduces-cont {
            width: 100%;
            // height: 800px;
            margin-top: 20px;
            // border: 1px solid red;

            > div:nth-child(1) {
                margin: 10px 0 10px 0;
                font-size: 8px;
                color: #999999;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            // 拌馅机
            .introduces-cont-bxj {
                padding-bottom: 30px;
                border: 1px solid #666666;

                // 拌馅机标题
                .introduces-cont-title {
                    margin: 15px 8px;
                    font-size: 20px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                // 拌馅机内容区域
                .introduces-cont-box {
                    display: flex;
                    margin: 10px;
                    box-sizing: border-box;

                    // 左侧图片
                    img {
                        width: 40%;
                        height: 180px;
                    }

                    .introduces-right-text {
                        display: flex;
                        flex-direction: column;
                        width: 55%;
                        margin-left: auto;
                        font-size: 12px;
                        color: #666666;

                        .introduces-right-item {
                            display: flex;
                            font-size: 11px;
                            margin-bottom: 10px;

                            > div {
                                width: 50%;
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            }
                        }
                    }
                    .mIndex-contact-right {
                        margin-left: -10px;
                        width: 100%;
                        // 意向品类
                        .mIndex-contact-name {
                            display: flex;
                            width: 100%;
                            margin-top: 10px;
                            font-size: 10px;

                            > label {
                                display: block;
                                width: 25%;
                                text-align: end;
                                box-sizing: border-box;
                                padding-right: 10px;
                                // border: 1px solid red;
                            }

                            > select {
                                width: 75%;
                                height: 15px;
                            }
                        }

                        // 联系方式
                        .mIndex-contact-phone {
                            display: flex;
                            width: 100%;
                            margin-top: 10px;
                            font-size: 10px;

                            > label {
                                display: block;
                                width: 25%;
                                text-align: end;
                                box-sizing: border-box;
                                padding-right: 10px;
                                // border: 1px solid red;
                            }

                            > input {
                                width: 75%;
                                height: 15px;
                            }
                        }

                        // 意向区域
                        .mIndex-contact-dl {
                            display: flex;
                            width: 100%;
                            margin-top: 10px;
                            font-size: 10px;

                            > label {
                                display: block;
                                width: 25%;
                                text-align: end;
                                box-sizing: border-box;
                                padding-right: 10px;
                                // border: 1px solid red;
                            }

                            > input {
                                width: 75%;
                                height: 15px;
                            }
                        }

                        // 留言内容
                        .mIndex-contact-ly {
                            display: flex;
                            width: 100%;
                            margin-top: 10px;
                            font-size: 10px;
                            // border: 1px solid red;

                            > label {
                                display: block;
                                width: 25%;
                                text-align: end;
                                box-sizing: border-box;
                                padding-right: 10px;
                                // border: 1px solid red;
                            }

                            // 留言选择
                            > div {
                                width: 75%;
                                // border: 1px solid red;
                                > select {
                                    width: 100%;
                                    height: 15px;
                                }
                            }
                        }

                        // 立即留言
                        > button {
                            margin-left: 40%;
                            margin-top: 40px;
                            width: 100px;
                            height: 25px;
                            font-size: 11px;
                            border: 0;
                            color: #fff;
                            border-radius: 4px;
                            background-color: #00479d;
                        }
                    }
                }
            }

            // 产品信息
            .introduces-info {
                margin-top: 10px;

                > div:nth-child(1) {
                    font-size: 14px;
                }

                > div:nth-child(2) {
                    width: 100%;
                    // height: 300px;
                    margin-top: 20px;
                    font-size: 10px;
                    // border: 1px solid red;
                }

                > div:nth-child(3) {
                    width: 100%;
                    height: 100%;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
    }
}

//当屏幕最小宽度为992px
@media screen and (min-width: 1200px) {
}

//当屏幕宽度 最小为768px 最大为991px时
@media screen and (min-width: 768px) and (max-width: 1200px) {
    .productDetail {
        width: 100%;

        img {
            display: block;
            height: 100%;
            width: 100%;
        }

        // 版心
        .introduces-wrap {
            width: 70%;
            margin: 0 auto;
            margin-bottom: 40px;

            .introduces-cont {
                width: 100%;
                // height: 800px;
                margin-top: 20px;
                // border: 1px solid red;

                > div:nth-child(1) {
                    margin: 10px 0 10px 0;
                    font-size: 8px;
                    color: #999999;
                }

                // 拌馅机
                .introduces-cont-bxj {
                    padding-bottom: 30px;
                    border: 1px solid #666666;

                    // 拌馅机标题
                    .introduces-cont-title {
                        margin: 15px 8px;
                        font-size: 20px;
                    }

                    // 拌馅机内容区域
                    .introduces-cont-box {
                        display: flex;
                        margin: 5px;

                        // 左侧图片
                        img {
                            width: 40%;
                            height: 120px;
                        }

                        .introduces-right-text {
                            display: flex;
                            flex-direction: column;
                            width: 55%;
                            margin-left: auto;
                            font-size: 8px;
                            color: #666666;

                            .introduces-right-item {
                                display: flex;
                                font-size: 11px;
                                margin-bottom: 10px;

                                > div {
                                    width: 50%;
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                }
                            }
                        }
                        .mIndex-contact-right {
                            margin-left: -10px;
                            width: 100%;
                            // 意向品类
                            .mIndex-contact-name {
                                display: flex;
                                width: 100%;
                                margin-top: 10px;
                                font-size: 10px;

                                > label {
                                    display: block;
                                    width: 25%;
                                    text-align: end;
                                    box-sizing: border-box;
                                    padding-right: 10px;
                                    // border: 1px solid red;
                                }

                                > select {
                                    width: 75%;
                                    height: 15px;
                                }
                            }

                            // 联系方式
                            .mIndex-contact-phone {
                                display: flex;
                                width: 100%;
                                margin-top: 10px;
                                font-size: 10px;

                                > label {
                                    display: block;
                                    width: 25%;
                                    text-align: end;
                                    box-sizing: border-box;
                                    padding-right: 10px;
                                    // border: 1px solid red;
                                }

                                > input {
                                    width: 75%;
                                    height: 15px;
                                }
                            }

                            // 意向区域
                            .mIndex-contact-dl {
                                display: flex;
                                width: 100%;
                                margin-top: 10px;
                                font-size: 10px;

                                > label {
                                    display: block;
                                    width: 25%;
                                    text-align: end;
                                    box-sizing: border-box;
                                    padding-right: 10px;
                                    // border: 1px solid red;
                                }

                                > input {
                                    width: 75%;
                                    height: 15px;
                                }
                            }

                            // 留言内容
                            .mIndex-contact-ly {
                                display: flex;
                                width: 100%;
                                margin-top: 10px;
                                font-size: 10px;
                                // border: 1px solid red;

                                > label {
                                    display: block;
                                    width: 25%;
                                    text-align: end;
                                    box-sizing: border-box;
                                    padding-right: 10px;
                                    // border: 1px solid red;
                                }

                                // 留言选择
                                > div {
                                    width: 75%;
                                    // border: 1px solid red;
                                    > select {
                                        width: 100%;
                                        height: 15px;
                                    }
                                }
                            }

                            // 立即留言
                            > button {
                                margin-left: 40%;
                                margin-top: 40px;
                                width: 100px;
                                height: 25px;
                                font-size: 11px;
                                border: 0;
                                color: #fff;
                                border-radius: 4px;
                                background-color: #00479d;
                            }
                        }
                    }
                }

                // 产品信息
                .introduces-info {
                    margin-top: 10px;

                    > div:nth-child(1) {
                        font-size: 15px;
                        font-weight: 700;
                    }

                    > div:nth-child(2) {
                        width: 100%;
                        // height: 300px;
                        margin-top: 20px;
                        font-size: 10px;
                        // border: 1px solid red;
                    }

                    > div:nth-child(3) {
                        width: 100%;
                        height: 100%;

                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
            }
        }
    }
}

//当屏幕最大宽度为767px
@media screen and (max-width: 767px) {
}
</style>
