<template>
    <!-- 学院动态 -->
    <div class="academy-dynamic">
        <AHeader :currentIndex="4"></AHeader>
        <MeSwiper></MeSwiper>

        <!-- 内容区域 -->
        <div class="academy-dynamic-content">
            <div>您当前的位置：首页>学院动态</div>
            <div>
                <h6>学院</h6>
                <span>动态</span>
            </div>
            <div>
                <div class="journalism-body">
                    <div class="journalism-body1">
                        <!-- 左侧 -->
                        <div
                            class="journalism-body1-left"
                            @click="goAcademyDetail(newsleft)"
                        >
                            <div class="journalism-left-img">
                                <img :src="newsleft.image" alt="" />
                            </div>
                            <h3 class="journalism-left-title">
                                {{ newsleft.title }}
                            </h3>
                            <div class="journalism-left-describe">
                                {{ newsleft.description }}<span>《更多》</span>
                            </div>
                        </div>
                        <!-- 右侧 -->
                        <div class="journalism-body1-right">
                            <!-- 科研平台列表 -->
                            <div
                                :key="indexb"
                                class="describe-right-item"
                                @click="goAcademyDetail(itemb)"
                                v-for="(itemb, indexb) in newsright"
                            >
                                <div class="journalism-right-time">
                                    <span>{{ itemb.year }}</span>
                                    <span>{{ itemb.day }}</span>
                                </div>
                                <div class="journalism-right-describe">
                                    <h3 class="journalism-right-title">
                                        {{ itemb.title }}
                                    </h3>
                                    <div class="journalism-right-text">
                                        {{ itemb.description }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 左侧内容区域分页 -->
            <div class="pageDown">
                <Pagination
                    :pageNo="searchParams.page"
                    :pageSize="searchParams.pageSize"
                    :total="total"
                    :continues="5"
                    @getPageNo="getPageEv"
                />
            </div>
        </div>

        <Footer></Footer>
    </div>
</template>

<script>
import AHeader from "@/components/Aheader.vue";
import Footer from "@/components/Footer.vue";
import Pagination from "@/components/Pagination";
import MeSwiper from "../../../components/College/swiper.vue";
import { schoolNews } from "../../../network/data";
export default {
    // 组件名称
    name: "dynamic",
    // 组件参数 接收来自父组件的数据
    props: {},
    // 组件状态值
    data() {
        return {
            // 院校导航栏id
            menu_id: "",
            // 分页
            searchParams: {
                page: 1,
                pageSize: 5,
            },
            total: 10,
            // 科研平台左侧数据
            newsleft: {
                // title: "食品科技是什么？能带来哪些好处？",
                // description:
                //     "我们的生活和食品一直是息息相关，密不可分，那么什么是食品科技？食品科技，简称ECO，是一种新型视频我们的生活和食品一直是息息相关，密不可分，那么什么是食品科技？食品科技，简称ECO，是一种新型视频",
            },

            // 科研平台右侧数据
            newsright: [
                // {
                //     month: "06/28",
                //     year: "2022",
                //     title: "食品科技是什么？能带来哪些好处？",
                //     description:
                //         "我们的生活和食品一直是息息相关，密不可分，那么什么是食品科技？食品科技，简称ECO，是一种新型称ECO，是一种新型食品科技？食品科技，简称ECO，是一种新型称ECO，是一种新型",
                // },
                // {
                //     month: "06/28",
                //     year: "2022",
                //     title: "食品科技是什么？能带来哪些好处？",
                //     description:
                //         "我们的生活和食品一直是息息相关，密不可分，那么什么是食品科技？食品科技，简称ECO，是一种新型称ECO，是一种新型食品科技？食品科技，简称ECO，是一种新型称ECO，是一种新型",
                // },
                // {
                //     month: "06/28",
                //     year: "2022",
                //     title: "食品科技是什么？能带来哪些好处？",
                //     description:
                //         "我们的生活和食品一直是息息相关，密不可分，那么什么是食品科技？食品科技，简称ECO，是一种新型称ECO，是一种新型食品科技？食品科技，简称ECO，是一种新型称ECO，是一种新型",
                // },
                // {
                //     month: "06/28",
                //     year: "2022",
                //     title: "食品科技是什么？能带来哪些好处？",
                //     description:
                //         "我们的生活和食品一直是息息相关，密不可分，那么什么是食品科技？食品科技，简称ECO，是一种新型称ECO，是一种新型食品科技？食品科技，简称ECO，是一种新型称ECO，是一种新型",
                // },
            ],
        };
    },
    // 计算属性
    computed: {},
    // 侦听器
    watch: {},
    // 组件方法
    created() {
        if (this.$route.query != "") {
            this.menu_id = this.$route.query.menu_id;

            //获取学院动态
            this.schoolNewsRe();
        }
    },
    methods: {
        // 获取院校动态数据
        schoolNewsRe() {
            let data = {
                id: sessionStorage.getItem("school_id"),
                menuid: this.menu_id,
                page: this.searchParams.page,
                pagesize: this.searchParams.pageSize,
            };
            schoolNews(data).then((res) => {
                if (res.code == 1) {
                    // console.log(res.data);
                    this.searchParams.page = res.data.list.current_page;
                    this.searchParams.pageSize = res.data.list.per_page;
                    this.total = res.data.list.total;
                    this.newsleft = res.data.list.data[0];
                    this.newsright = res.data.list.data;
                } else {
                    this.message(res.msg);
                }
            });
        },
        // 分页点击事件
        getPageEv(e) {
            this.searchParams.page = e;
            this.schoolNewsRe();
        },
        // 跳转到院校详情页
        goAcademyDetail(item) {
            this.$router.push({
                path: "/AcademyDetail",
                query: {
                    sonId: item.id,
                    // 导航栏选中样式索引
                    current: "4",
                },
            });
        },
    },
    mounted() {},
    // 局部注册的组件
    components: { AHeader, Footer, Pagination, MeSwiper },
};
</script>

<style scoped lang="scss">
.academy-dynamic {
    .academy-dynamic-img {
        width: 100%;
        height: 150px;

        > img {
            width: 100%;
            height: 100%;
        }
    }

    .academy-dynamic-content {
        width: 70%;
        margin: 0 auto;

        > div:nth-child(1) {
            margin: 10px 0 10px 0;
            font-size: 8px;
            color: #999999;
        }

        > div:nth-child(2) {
            display: flex;
            padding: 5px 0;
            border-bottom: 1px solid #0056b0;

            > h6 {
                font-size: 12px;
                color: #0056b0;
            }

            > span {
                margin-top: 1px;
                margin-left: 3px;
                font-size: 8px;
            }
        }

        > div:nth-child(3) {
            // height: 300px;

            /*科研平台内容 start*/
            .journalism-body {
                width: 100%;
                height: 246px;
                margin-top: 20px;
                .journalism-body1 {
                    display: flex;
                    // 科研平台左侧
                    .journalism-body1-left {
                        overflow: hidden;
                        width: 40%;
                        // 图片
                        .journalism-left-img {
                            width: 100%;
                            height: 180px;
                            img {
                                width: 100%;
                                height: 100%;
                            }
                        }
                        // 标题
                        .journalism-left-title {
                            padding: 10px 0 8px 0;
                            font-size: 10px;
                            border-bottom: 1px solid #e1e1e1;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                        // 描述
                        .journalism-left-describe {
                            margin-top: 3px;
                            font-size: 8px;
                            color: #999999;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 2; //行数
                            -webkit-box-orient: vertical;
                            > span {
                                cursor: pointer;
                                color: #337136;
                            }
                        }
                    }

                    // 科研平台右侧
                    .journalism-body1-right {
                        margin-left: auto;
                        width: 57%;
                        // border: 1px solid red;
                        .tapActive {
                            color: #337136;
                        }
                        .describe-right-item:hover {
                            color: #337136;
                        }
                        // 列表
                        .describe-right-item {
                            cursor: pointer;
                            display: flex;
                            margin-bottom: 10px;
                            // 日期时间
                            .journalism-right-time {
                                display: flex;
                                flex-direction: column;
                                justify-content: flex-end;
                                padding: 12px 10px;
                                border-right: 1px solid #e1e1e1;
                                span:nth-child(1) {
                                    display: block;
                                    font-size: 10px;
                                    color: #337136;
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                }
                                span:nth-child(2) {
                                    font-size: 10px;
                                    color: #337136;
                                    margin-left: auto;
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                }
                            }
                            // 描述
                            .journalism-right-describe {
                                padding: 0 10px;
                                .journalism-right-title {
                                    width: 200px;
                                    margin-bottom: 5px;
                                    font-size: 10px;
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                }
                                .journalism-right-text {
                                    font-size: 8px;
                                    color: #999999;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    display: -webkit-box;
                                    -webkit-line-clamp: 2; //行数
                                    -webkit-box-orient: vertical;
                                }
                            }
                        }
                    }
                }
            }
            /*科研平台内容 end*/
        }

        .pageDown {
            display: flex;
            justify-content: center;
            margin: 20px;
        }
    }
}

//当屏幕最小宽度为992px
@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .academy-dynamic {
        .academy-dynamic-img {
            width: 100%;
            height: 150px;

            > img {
                width: 100%;
                height: 100%;
            }
        }

        .academy-dynamic-content {
            width: 70%;
            margin: 0 auto;

            > div:nth-child(1) {
                margin: 10px 0 10px 0;
                font-size: 8px;
                color: #999999;
            }

            > div:nth-child(2) {
                display: flex;
                padding: 5px 0;
                border-bottom: 1px solid #0056b0;

                > h6 {
                    font-size: 12px;
                    color: #0056b0;
                }

                > span {
                    margin-top: 1px;
                    margin-left: 3px;
                    font-size: 8px;
                }
            }

            > div:nth-child(3) {
                // height: 300px;

                /*科研平台内容 start*/
                .journalism-body {
                    width: 100%;
                    height: 246px;
                    margin-top: 20px;
                    .journalism-body1 {
                        display: flex;
                        // 科研平台左侧
                        .journalism-body1-left {
                            overflow: hidden;
                            width: 40%;
                            // 图片
                            .journalism-left-img {
                                width: 100%;
                                height: 140px;
                                img {
                                    width: 100%;
                                    height: 100%;
                                }
                            }
                            // 标题
                            .journalism-left-title {
                                padding: 10px 0 8px 0;
                                font-size: 10px;
                                border-bottom: 1px solid #e1e1e1;
                            }
                            // 描述
                            .journalism-left-describe {
                                margin-top: 3px;
                                font-size: 8px;
                                color: #999999;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                display: -webkit-box;
                                -webkit-line-clamp: 2; //行数
                                -webkit-box-orient: vertical;
                                > span {
                                    cursor: pointer;
                                    color: #337136;
                                }
                            }
                        }

                        // 科研平台右侧
                        .journalism-body1-right {
                            margin-left: auto;
                            width: 57%;
                            // border: 1px solid red;
                            .tapActive {
                                color: #337136;
                            }
                            .describe-right-item:hover {
                                color: #337136;
                            }
                            // 列表
                            .describe-right-item {
                                cursor: pointer;
                                display: flex;
                                margin-bottom: 10px;
                                // 日期时间
                                .journalism-right-time {
                                    display: flex;
                                    flex-direction: column;
                                    justify-content: flex-end;
                                    padding: 10px 8px;
                                    border-right: 1px solid #e1e1e1;
                                    span:nth-child(1) {
                                        display: block;
                                        font-size: 8px;
                                        color: #337136;
                                    }
                                    span:nth-child(2) {
                                        font-size: 8px;
                                        color: #337136;
                                        margin-left: auto;
                                    }
                                }
                                // 描述
                                .journalism-right-describe {
                                    padding: 0 10px;
                                    .journalism-right-title {
                                        margin-bottom: 3px;
                                        font-size: 8px;
                                        white-space: nowrap;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                    }
                                    .journalism-right-text {
                                        font-size: 8px;
                                        color: #999999;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        display: -webkit-box;
                                        -webkit-line-clamp: 2; //行数
                                        -webkit-box-orient: vertical;
                                    }
                                }
                            }
                        }
                    }
                }
                /*科研平台内容 end*/
            }

            .pageDown {
                display: flex;
                justify-content: center;
                margin: 20px;
            }
        }
    }
}

//当屏幕宽度 最小为768px 最大为991px时
@media screen and (min-width: 768px) and (max-width: 1200px) {
    .academy-dynamic {
        .academy-dynamic-img {
            width: 100%;
            height: 150px;

            > img {
                width: 100%;
                height: 100%;
            }
        }

        .academy-dynamic-content {
            width: 70%;
            height: 60vh;
            margin: 0 auto;
            // border: 1px solid red;

            > div:nth-child(1) {
                margin: 10px 0 10px 0;
                font-size: 8px;
                color: #999999;
            }

            > div:nth-child(2) {
                display: flex;
                padding: 5px 0;
                border-bottom: 1px solid #0056b0;

                > h6 {
                    font-size: 12px;
                    color: #0056b0;
                }

                > span {
                    margin-top: 1px;
                    margin-left: 3px;
                    font-size: 8px;
                }
            }

            > div:nth-child(3) {
                // height: 300px;

                /*科研平台内容 start*/
                .journalism-body {
                    width: 100%;
                    height: 246px;
                    margin-top: 10px;
                    .journalism-body1 {
                        display: flex;
                        // 科研平台左侧
                        .journalism-body1-left {
                            overflow: hidden;
                            width: 40%;
                            // 图片
                            .journalism-left-img {
                                width: 100%;
                                height: 90px;
                                img {
                                    width: 100%;
                                    height: 100%;
                                }
                            }
                            // 标题
                            .journalism-left-title {
                                padding: 10px 0 8px 0;
                                font-size: 10px;
                                border-bottom: 1px solid #e1e1e1;
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            }
                            // 描述
                            .journalism-left-describe {
                                margin-top: 3px;
                                font-size: 8px;
                                color: #999999;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                display: -webkit-box;
                                -webkit-line-clamp: 2; //行数
                                -webkit-box-orient: vertical;
                                > span {
                                    cursor: pointer;
                                    color: #337136;
                                }
                            }
                        }

                        // 科研平台右侧
                        .journalism-body1-right {
                            margin-left: auto;
                            width: 57%;
                            // border: 1px solid red;
                            .tapActive {
                                color: #337136;
                            }
                            .describe-right-item:hover {
                                color: #337136;
                            }
                            // 列表
                            .describe-right-item {
                                width: 100%;
                                display: flex;
                                margin-bottom: 4px;
                                // 日期时间
                                .journalism-right-time {
                                    display: flex;
                                    flex-direction: column;
                                    justify-content: flex-end;
                                    padding: 8px 8px;
                                    border-right: 1px solid #e1e1e1;

                                    span:nth-child(1) {
                                        display: block;
                                        font-size: 8px;
                                        color: #337136;
                                    }

                                    span:nth-child(2) {
                                        font-size: 8px;
                                        color: #337136;
                                        margin-left: auto;
                                    }
                                }
                                // 描述
                                .journalism-right-describe {
                                    width: 100%;
                                    padding: 0 5px;
                                    // border: 1px solid red;
                                    .journalism-right-title {
                                        width: 160px;
                                        margin-bottom: 2px;
                                        font-size: 10px;
                                        white-space: nowrap;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        // border: 1px solid red;
                                    }
                                    .journalism-right-text {
                                        width: 160px;
                                        font-size: 8px;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        display: -webkit-box;
                                        -webkit-line-clamp: 2; //行数
                                        -webkit-box-orient: vertical;
                                    }
                                }
                            }
                        }
                    }
                }
                /*科研平台内容 end*/
            }
        }
    }
}

//当屏幕最大宽度为767px
@media screen and (max-width: 767px) {
}
</style>
