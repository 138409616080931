var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"demandInviteDetail"},[_c('Header'),_c('HeadNav'),_c('div',{staticClass:"minkave-wrap"},[_c('Advertising'),_vm._m(0),_c('div',{staticClass:"survey-cont"},[_c('div',{staticClass:"survey-content"},[_c('div',{staticClass:"survey-content-left"},[_c('div',{staticClass:"survey-detail-left"},[_c('div',{staticClass:"minkaveDetails-introduce5-left"},[_c('div',{staticClass:"minkaveDetails-introduce5-title"},[_vm._m(1),_c('ul',_vm._l((_vm.jobcateList),function(itemb,indexb){return _c('li',{key:indexb,class:_vm.currentPuzzle == indexb
                                                ? 'puzzleActive'
                                                : ''},[_vm._v(" "+_vm._s(itemb.name)+" ")])}),0)]),_c('div',{staticClass:"minkaveDetails-introduce5-title2"},[_c('div',{},[_vm._v("相关技术难题推荐")]),_c('ul',_vm._l((_vm.recommend),function(itemc,indexc){return _c('li',{key:indexc},[_c('div',[_vm._v(_vm._s(itemc.title))]),_c('div',[_vm._v(" 状态:"+_vm._s(itemc.recommend)+" ")])])}),0)])])]),_c('div',{staticClass:"survey-detail-right"},[_c('div',{staticClass:"detail-right"},[_c('div',{staticClass:"detail-right-place"},[_vm._v(" 当前位置：网站首页>产业需求>就业招聘>招聘 ")]),_c('div',{staticClass:"detail-right-cont"},[_c('div',[_vm._v(_vm._s(_vm.jobdetailObj.title))]),_c('div',[_vm._v(" "+_vm._s(_vm.jobdetailObj.createtime)+" 更新 ")]),(_vm.jobdetailObj.salarymoney)?_c('div',[_vm._v(" "+_vm._s(_vm.jobdetailObj.salarymoney)+"/月 ")]):_vm._e(),(
                                        _vm.ity &&
                                        _vm.jobdetailObj.workyear &&
                                        _vm.jobdetailObj.education
                                    )?_c('div',[_vm._v(" "+_vm._s(_vm.city)+" | "+_vm._s(_vm.jobdetailObj.workyear)+" | "+_vm._s(_vm.jobdetailObj.education)+" ")]):_vm._e(),_c('div',[_vm._v("职位详情")]),_c('div',[_c('ul',{domProps:{"innerHTML":_vm._s(_vm.jobdetailObj.content)}})]),_c('div',[_vm._v("工作地址")]),_c('div',[_vm._v(_vm._s(_vm.jobdetailObj.address))]),_c('div',[(_vm.companyType == 1)?_c('div',{on:{"click":_vm.obtain}},[_vm._v(" 就业发布 ")]):_vm._e(),(_vm.companyType == 2)?_c('div',{on:{"click":_vm.bring}},[_vm._v(" 招聘发布 ")]):_vm._e()])])])])]),_c('div',{staticClass:"survey-content-right"},[_c('Right')],1)])])],1),_c('Footer')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"survey-nav"},[_c('div',{staticClass:"survey-nav-title"},[_c('div',{staticClass:"survey-title-cn"},[_vm._v("产业需求")])]),_c('div',{staticStyle:{"font-size":"30px"}},[_vm._v("就业招聘详情页")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div'),_c('span',[_vm._v("技术难题")])])
}]

export { render, staticRenderFns }