<template>
    <!-- 助企名家-论文论著详情页 -->
    <div class="workdetail">
        <Header></Header>
        <div class="workdetail-wrap">
            <!-- 顶部图片 -->
            <Advertising></Advertising>
            <!-- <div class="index-headImg">
                <div class="index-headImg-left">
                    <img src="../../../assets/pc/Index/headL.png" alt="" />
                </div>
                <div class="index-headImg-right">
                    <img src="../../../assets/pc/Index/headR.png" alt="" />
                </div>
            </div> -->
            <div class="local">
                河南省食品科学技术学会 > 科技赋能 > 助企名家>{{ name }} > 详情
            </div>
            <div class="workdetail-content">
                <!-- <div :v-html="workdetailDe.content">{{ workdetailDe.content }}</div> -->
                <div v-html="workdetailDe.content"></div>
                <div>
                    <div @click="pre()">
                        【上一篇】：{{ workdetailDe.pre_article.title }}
                    </div>
                    <div @click="next()">
                        【下一篇】：{{ workdetailDe.next_artilce.title }}
                    </div>
                </div>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import Header from "@/components/Header.vue"; //头部
import Footer from "@/components/Footer.vue"; //尾部
import { workdetailw, wnewdetail } from "../../../network/data";
import Advertising from "@/components/Advertising.vue"; //广告图
export default {
    // 组件名称
    name: "workdetail",
    // 组件参数 接收来自父组件的数据
    props: {},
    // 组件状态值
    data() {
        return {
            // 论文论著详情页id
            id: "",
            // 论文论著、助企交流详情页数据
            workdetailDe: {},
            // 判断是1==论文论著详情页还是2==助力交流详情页
            type: "",
        };
    },
    // 计算属性
    computed: {
        name() {
            return this.type == 1 ? "论文论著" : "助企交流";
        },
    },
    // 侦听器
    watch: {},
    // 组件方法
    created() {
        if (this.$route.query) {
            this.id = this.$route.query.id;
            this.type = this.$route.query.type;
            if (this.type == 1) {
                this.workdetailRe();
            } else {
                this.wnewdetailRe();
            }
        }
    },
    methods: {
        // 获取论文论著详情页数据
        workdetailRe() {
            let data = {
                id: this.id,
            };
            workdetailw(data).then((res) => {
                if (res.code == 1) {
                    // console.log(res.data);
                    this.workdetailDe = res.data;
                } else {
                    this.message(res.msg);
                }
            });
        },
        // 获取助企名家交流详情页
        wnewdetailRe() {
            let data = {
                id: this.id,
            };
            wnewdetail(data).then((res) => {
                if (res.code == 1) {
                    this.workdetailDe = res.data;
                } else {
                    this.message(res.msg);
                }
            });
        },
        // 上一篇
        pre() {
            this.id = this.workdetailDe.pre_article.id;
            this.workdetailRe();
        },
        // 下一篇
        next() {
            this.id = this.workdetailDe.next_artilce.id;
            this.workdetailRe();
        },
    },
    mounted() {},
    // 局部注册的组件
    components: {
        Header,
        Footer,
        Advertising,
    },
};
</script>

<style scoped lang="scss">
.workdetail {
    .workdetail-wrap {
        width: 70%;
        margin: 0 auto;
        margin-top: 35px;

        /*顶部图片start*/
        .index-headImg {
            position: relative;
            z-index: 0;
            width: 100%;
            height: 50px;
            margin-top: 15px;
            display: flex;
            justify-content: space-between;

            // 左侧图片
            .index-headImg-left {
                width: 48%;
                height: 100%;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            // 右侧图片
            .index-headImg-right {
                width: 48%;
                height: 100%;

                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
        /*顶部图片end*/

        /*位置 start */
        .local {
            margin: 5px 0;
            font-size: 10px;
            color: #999999;
        }
        /*位置 end*/

        /*内容区域 start*/
        .workdetail-content {
            > div:nth-child(1) {
                margin-top: 20px;
                font-size: 10px;
                padding: 10px;
                border: 1px solid #6f6f6f;
            }

            > div:nth-child(2) {
                text-align: center;
                font-size: 8px;
                margin: 10px 0;
                // right: 10px;

                > div:nth-child(1) {
                    cursor: pointer;
                }

                > div:nth-child(2) {
                    cursor: pointer;
                }
            }
        }
        /*内容区域 end*/
    }
}
</style>
