<template>
    <div class="">
        <router-view />
    </div>
</template>

<script>
export default {
    name: "made",
    data() {
        return {};
    },
    computed: {},
    watch: {},
    methods: {},
    created() {},
    mounted() {},
    beforeCreate() {},
    beforeMount() {},
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
    activated() {},
    components: {},
};
</script>

<style lang="less" scoped></style>
