<template>
    <div id="pic_container">
        <!-- 这个样子的，只改变img的src不好过渡 -->
        <div id="pics">
            <img id="pic" :key="i" v-for="(item, i) in pic_src" @mouseover="mouseOver()" @mouseout="mouseOut()"
                @click="toUrl()" :src="item.image" ref="pic" />
        </div>

        <!-- 这个是下面的点，用v-for生成的点，用pic_src的大小确定的点，并且要知道这个点的index -->
        <div id="dots" @mouseover="mouseOver()" @mouseout="mouseOut()">
            <div :key="i" v-for="(count, i) in pic_src.length" @click="index = i" :class="i === index ? 'focus' : ''"></div>
        </div>

        <div class="arrow_left" @click="onLeft" @mouseover="mouseOver()" @mouseout="mouseOut()">
            <img src="../assets/pc/academy/academy-left.png" alt="" />
        </div>

        <div class="arrow_right" @click="onRight" @mouseover="mouseOver()" @mouseout="mouseOut()">
            <img src="../assets/pc/academy/academy-right.png" alt="" />
        </div>
    </div>
</template>

<script>
export default {
    // 组件名称
    name: "aIndex",
    data() {
        return {
            timer: null,
            index: 1, // 作为指示的数值，其最大数值和pic_src.length，即为数组的大小挂钩（因为我想之后的后端传数据可能是json字符串，经过处理到pic_src这里，是个对象数组
            // pic_src: [
            // "https://p.qqan.com/up/2022-12/202212314325245.jpg",
            // "https://p.qqan.com/up/2022-12/20221211346411765.jpg",
            // "https://p.qqan.com/up/2022-11/20221117143461483.jpg",
            // "https://p.qqan.com/up/2022-11/202211141338536701.jpg",
            // ],
        };
    },
    props: {
        pic_src: {
            type: Array,
            default: [],
        },
    },
    created() {
        console.log(this.pic_src, "pic_src");
        (this.index = 0), console.log(this.index), this.mouseOut();
    },

    methods: {
        toUrl() {
            if (this.pic_src[this.index].url != "") {
                this.$router.push({
                    path: this.pic_src[this.index].url,
                });
            } else {
                this.$router.push({
                    path: `/about/newsDetail/0`,
                    query: {
                        ids: this.pic_src[this.index].id,
                    },
                });
            }
        },
        mouseOut() {
            let that = this;
            this.timer = setInterval(function () {
                that.index = (that.index + 1) % that.pic_src.length;
                // console.log(that.index);
            }, 3000);
        },
        mouseOver() {
            if (this.timer) clearInterval(this.timer);
        },
        // 左箭头
        onLeft() {
            // 左箭头其实就是减少1，我们通过 判断是否大于零，大于零 就是index - 1 ，小于零就用 图片数组的长度减去
            // index - 1的绝对值 再进行取余 得到最终的index
            this.index =
                this.index - 1 >= 0
                    ? (this.index - 1) % this.pic_src.length
                    : this.pic_src.length -
                    (Math.abs(this.index - 1) % this.pic_src.length);
            // console.log(this.index);
        },
        onRight() {
            this.index = (this.index + 1) % this.pic_src.length;
            // console.log(this.index);
        },

        trans() {
            // this.$refs.pic[this.index].style.opacity =  '1';
            for (let i = 0; i < this.pic_src.length; i++) {
                this.$refs.pic[i].style.opacity = i === this.index ? "1" : "0";
            }
        },
    },

    watch: {
        index: {
            // immediate:true,
            handler: function (newValue, oldValue) {
                // console.log(''+newValue+' '+oldValue+' '+this.index)
                this.trans();
            },
        },
    },

    beforeDestroy() {
        clearInterval(this.timer);
        this.timer = null;
    },
};
</script>

<style scoped lang="scss">
#pic_container {
    width: 100%;
    height: 100%;
    // margin: 20px auto;
    position: relative;

    /* 轮播图片 start */
    #pics {
        width: 100%;
        height: 100%;

        #pic {
            width: 100%;
            height: 100%;
            display: block;
            position: absolute;
            transition: all 0.5s;
        }
    }

    /* 轮播图片 end */

    /* 下面的点start */
    #dots {
        position: absolute;
        margin: 0 auto;
        bottom: 0px;
        right: 45%;

        >div {
            cursor: pointer;
            width: 6px;
            height: 6px;
            margin: 12px 2px 12px 2px;
            border-radius: 5px;
            transition: background-color 0.2s;
            background-color: hsla(202, 60%, 100%, 0.3);
            display: inline-block;
        }

        .focus {
            background-color: hsla(202, 60%, 100%, 0.8);
        }
    }

    /* 下面的点end */

    .arrow_left,
    .arrow_right {
        width: 25px;
        height: 25px;
        line-height: 25px;
        position: absolute;
        top: 45%;
        cursor: pointer;
        transition: all 0.5s;
        // border: 1px solid red;

        >img {
            width: 100%;
            height: 100%;
        }
    }

    /*左边距离父盒子边框*/
    .arrow_left {
        left: 0px;
    }

    /*右边距离父盒子边框*/
    .arrow_right {
        right: 0px;
    }

    .arrow_right:hover {
        background: linear-gradient(to left,
                rgba(171, 218, 244, 0.3) 5%,
                rgba(171, 218, 244, 0) 95%);
    }

    .arrow_left:hover {
        background: linear-gradient(to right,
                rgba(171, 218, 244, 0.3) 5%,
                rgba(171, 218, 244, 0) 95%);
    }
}
</style>
