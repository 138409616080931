<template>
    <!-- 关于学会页面 -->
    <div class="academyDetail">
        <AHeader :currentIndex="current"></AHeader>
        <div class="survey-wrap">
            <!-- 关于学会tap -->
            <div class="survey-nav-title">
                <div class="survey-title-cn">{{ name }}--详情页</div>
            </div>

            <div class="survey-cont">
                <div class="survey-content">
                    <div class="survey-content-left">
                        <div class="survey-left-title">
                            <div>
                                <div>{{ academyDe.title }}</div>
                                <div>{{ academyDe.subtitle }}</div>
                            </div>
                            <div>
                                时间：{{ academyDe.createtime }}来源：{{
                                    academyDe.source
                                }}作者：{{ academyDe.author }}
                                原文
                            </div>
                        </div>
                        <div class="survey-left-contn" v-html="academyDe.content"></div>
                    </div>
                    <div class="survey-content-right">
                        <Right></Right>
                    </div>
                </div>
            </div>

            <!-- 内容区域 -->
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import AHeader from "@/components/Aheader.vue";
import Footer from "@/components/Footer.vue";
import Right from "@/components/Right.vue"; //关于右侧组件
import {
    researchdetail,
    schoolNewdetail,
    tschoolTeachdetail,
} from "../../../network/data";
export default {
    name: "academyDetail",
    data() {
        return {
            // 当前详情页id
            sonId: "",
            academyDe: {},
        };
    },
    computed: {
        name() {
            return this.current == 2
                ? "科研平台"
                : this.current == 3
                    ? "教授风采"
                    : "学院动态";
        },
    },
    watch: {},
    created() {
        if (this.$route.query) {
            this.sonId = this.$route.query.sonId;
            // 科研平台
            if (this.$route.query.current == 2) {
                this.current = 2;
                this.researchdetailRe();
                // 教授风采
            } else if (this.$route.query.current == 3) {
                this.current = 3;
                this.tschoolTeachdetailRe();
                // 学院动态
            } else if (this.$route.query.current == 4) {
                this.current = 4;
                this.schoolNewdetailRe();
            }
        }
    },
    methods: {
        // 获取院校科研平台详情页数据
        researchdetailRe() {
            let data = {
                id: this.sonId,
            };
            researchdetail(data).then((res) => {
                if (res.code == 1) {
                    // console.log(res.data);
                    this.academyDe = res.data.detail;
                } else {
                    this.message(res.msg);
                }
            });
        },
        // 获取院校动态详情页数据
        schoolNewdetailRe() {
            let data = {
                id: this.sonId,
            };
            schoolNewdetail(data).then((res) => {
                if (res.code == 1) {
                    this.academyDe = res.data.detail;
                } else {
                    this.message(res.msg);
                }
            });
        },
        // 获取教授风采详情页数据
        tschoolTeachdetailRe() {
            let data = {
                id: this.sonId,
            };
            tschoolTeachdetail(data).then((res) => {
                if (res.code == 1) {
                    this.academyDe = res.data.detail;
                } else {
                    this.message(res.msg);
                }
            });
        },
    },
    mounted() { },
    beforeCreate() { },
    beforeMount() { },
    beforeUpdate() { },
    updated() { },
    beforeDestroy() { },
    destroyed() { },
    activated() { },
    components: {
        AHeader,
        Footer,
        Right,
    },
};
</script>
<style>
/* .academyDetail .survey-left-contn img:nth-child(1) {
    width: 70px !important;
    height: 100px !important;
    margin: 0 auto;
} */
</style>
<style lang="scss" scoped>
.academyDetail {
    width: 100%;

    .survey-wrap {
        width: 70%;
        // height: 1000px;
        margin: 0 auto;
        margin-bottom: 40px;
        // border: 1px solid red;

        .survey-title-cn {
            font-size: 12px;
            color: #0056b0;
            margin: 10px 0;
        }

        /*内容区域 start*/
        .survey-cont {
            width: 100%;

            .survey-content {
                display: flex;
                width: 100%;
                margin: 0 auto;
                // border: 1px solid red;

                // 内容区域左侧
                .survey-content-left {
                    width: 72%;
                    height: 100%;
                    // border: 1px solid blue;

                    .survey-left-title {
                        padding: 5px;
                        font-size: 12px;
                        border-bottom: 0.5px solid #cacaca;

                        >div:nth-child(1) {
                            display: inline-block;
                            font-size: 15px;
                            white-space: pre-wrap;
                        }

                        >div:nth-child(2) {
                            font-size: 8px;
                            color: #999999;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                    }

                    .survey-left-contn {
                        font-size: 10px;
                        margin: 20px 0;
                    }
                }

                // 内容区域右侧
                .survey-content-right {
                    margin-left: auto;
                    width: 25%;
                    // border: 1px solid red;
                }
            }
        }

        /*内容区域 end*/
    }
}

//当屏幕最小宽度为992px
@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .academyDetail {
        width: 100%;

        .survey-wrap {
            width: 70%;
            // height: 1000px;
            margin: 0 auto;
            margin-bottom: 40px;
            // border: 1px solid red;

            .survey-title-cn {
                font-size: 12px;
                color: #0056b0;
                margin: 10px 0;
            }

            /*内容区域 start*/
            .survey-cont {
                width: 100%;

                .survey-content {
                    display: flex;
                    width: 100%;
                    margin: 0 auto;
                    // border: 1px solid red;

                    // 内容区域左侧
                    .survey-content-left {
                        width: 72%;
                        height: 100%;
                        // border: 1px solid blue;

                        .survey-left-title {
                            padding: 5px;
                            font-size: 12px;
                            border-bottom: 0.5px solid #cacaca;

                            >div:nth-child(1) {
                                display: block;
                                font-size: 10px;
                            }

                            >div:nth-child(2) {
                                font-size: 8px;
                                color: #999999;
                            }
                        }

                        .survey-left-contn {
                            font-size: 10px;
                            margin: 10px 0;
                        }
                    }

                    // 内容区域右侧
                    .survey-content-right {
                        margin-left: auto;
                        width: 25%;
                        // border: 1px solid red;
                    }
                }
            }

            /*内容区域 end*/
        }
    }
}

//当屏幕宽度 最小为768px 最大为991px时
@media screen and (min-width: 768px) and (max-width: 1200px) {
    .academyDetail {
        width: 100%;

        .survey-wrap {
            width: 70%;
            // height: 1000px;
            margin: 0 auto;
            margin-bottom: 40px;
            // border: 1px solid red;

            .survey-title-cn {
                font-size: 12px;
                color: #0056b0;
                margin: 10px 0;
            }

            /*内容区域 start*/
            .survey-cont {
                width: 100%;

                .survey-content {
                    display: flex;
                    width: 100%;
                    margin: 0 auto;
                    // border: 1px solid red;

                    // 内容区域左侧
                    .survey-content-left {
                        width: 72%;
                        height: 100%;
                        // border: 1px solid blue;

                        .survey-left-title {
                            padding: 5px;
                            font-size: 10px;
                            border-bottom: 0.5px solid #cacaca;

                            >div:nth-child(1) {
                                h7 {
                                    display: block;
                                    font-size: 10px;
                                }
                            }

                            >div:nth-child(2) {
                                font-size: 8px;
                                color: #999999;
                            }
                        }

                        .survey-left-contn {
                            border: 1px solid pink;
                            font-size: 10px;
                            margin: 10px 0;

                            >img:nth-child(1) {
                                width: 100% !important;
                                height: 300px !important;
                                border: 1px solid red !important;
                            }
                        }
                    }

                    // 内容区域右侧
                    .survey-content-right {
                        margin-left: auto;
                        width: 25%;
                        // border: 1px solid red;
                    }
                }
            }

            /*内容区域 end*/
        }
    }
}

//当屏幕最大宽度为767px
@media screen and (max-width: 767px) {}
</style>
