<template>
    <!-- 助企名家 -->
    <div class="minkave">
        <Header></Header>
        <HeadNav></HeadNav>
        <div class="minkave-wrap">
            <!-- 顶部图片 -->
            <Advertising></Advertising>
            <!-- <div class="index-headImg">
                <div class="index-headImg-left">
                    <img src="../../../assets/pc/Index/headL.png" alt="" />
                </div>
                <div class="index-headImg-right">
                    <img src="../../../assets/pc/Index/headR.png" alt="" />
                </div>
            </div> -->

            <!-- 关于学会tap -->
            <div class="survey-nav">
                <!-- <Nav></Nav> -->
                <div class="survey-nav-title">
                    <div class="survey-title-cn">助企名家</div>
                    <div class="survey-title-sn">About the Institute</div>
                </div>
                <ul>
                    <li
                        :key="index"
                        v-for="(item, index) in machiningTap"
                        @click="machiningTapEv(item, index)"
                        :class="currentIndex == index ? 'active' : ''"
                    >
                        {{ item.name }}
                    </li>
                </ul>
            </div>

            <!-- 内容区域 -->
            <div class="survey-cont">
                <div class="survey-content">
                    <div class="survey-content-left">
                        <Left
                            v-if="JSON.stringify(memberlistDe) != ''"
                            :memberlist="memberlistDe"
                            @page="memberlistPage"
                        ></Left>
                    </div>
                    <div class="survey-content-right">
                        <Right></Right>
                    </div>
                </div>
            </div>
            <!-- 内容区域 -->
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import Header from "@/components/Header.vue"; //头部
import Footer from "@/components/Footer.vue"; //尾部
import Right from "@/components/Right.vue"; //右侧
import Left from "@/components/Science/Left.vue"; //左侧
import Advertising from "@/components/Advertising.vue"; //广告图
import HeadNav from "@/components/Nav.vue"; //导航
import { memberNavlist, memberlist } from "../../../network/data";


export default {
    // 组件名称
    name: "minkave",
    // 组件参数 接收来自父组件的数据
    props: {},
    // 组件状态值
    data() {
        return {
            // 当前tap索引
            currentIndex: 0,
            // 当前导航栏id
            skillid: "9",
            // 关于学会tap数据
            machiningTap: [],
            // 助企名家分页数据
            memberlistParams: {
                page: 1,
                pageSize: 3,
            },
            // 获取助企名家列表数据
            memberlistDe: {},
        };
    },
    // 计算属性
    computed: {},
    // 侦听器
    watch: {},
    // 组件方法
    created() {
        // 获取企业名家面包屑
        this.memberNavlistRe();
        // 获取助企名家数据
        this.memberlistRe();
    },
    methods: {
        machiningTapEv(item, index) {
            // this.surveyData = [];
            this.memberlistParams.page = 1;
            this.currentIndex = index;
            this.skillid = item.id;
            this.memberlistRe();
        },
        // 获取企业名家导航栏
        memberNavlistRe() {
            let data = {
                menuid: 18,
            };
            memberNavlist(data).then((res) => {
                if (res.code == 1) {
                    // console.log(res.data);
                    this.machiningTap = res.data.list;
                } else {
                    this.message(res.msg);
                }
            });
        },
        // 获取企业名家列表
        memberlistRe() {
            let data = {
                skillid: this.skillid,
                page: this.memberlistParams.page,
                pagesize: this.memberlistParams.pageSize,
            };
            memberlist(data).then((res) => {
                if (res.code == 1) {
                    // console.log(res.data);
                    this.memberlistDe = res.data.list;
                } else {
                    this.message(res.msg);
                }
            });
        },
        // 助企名家子组件分页事件
        memberlistPage(page) {
            this.memberlistParams.page = page;
            this.memberlistRe();
        },
    },
    mounted() {},
    // 局部注册的组件
    components: {
        Header,
        Footer,
        Right,
        Left,
        HeadNav,
        Advertising,
    },
};
</script>

<style scoped lang="scss">
.minkave {
    width: 100%;
    .minkave-wrap {
        width: 70%;
        // height: 6000px;
        margin: 0 auto;
        margin-top: 10px;
        margin-bottom: 40px;

        /*顶部图片start*/
        .index-headImg {
            position: relative;
            z-index: 0;
            width: 100%;
            height: 50px;
            margin-top: 10px;
            display: flex;
            justify-content: space-between;

            // 左侧图片
            .index-headImg-left {
                width: 48%;
                height: 100%;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            // 右侧图片
            .index-headImg-right {
                width: 48%;
                height: 100%;

                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
        /*顶部图片end*/

        /*关于学会tap start*/
        .survey-nav {
            display: flex;
            align-items: center;
            width: 100%;
            padding: 10px 0;
            box-sizing: border-box;
            border-bottom: 1px solid #e4e4e4;

            .survey-nav-title {
                width: 25%;
                display: flex;
                align-items: center;
                // border: 1px solid red;

                .survey-title-cn {
                    font-size: 10px;
                    color: #0056b0;
                    margin-right: 15px;
                }

                .survey-title-sn {
                    height: 10px;
                    line-height: 10px;
                    font-size: 6px;
                    color: #fff;
                    padding: 0 6px;
                    background-color: #0056b0;
                }
            }

            > ul {
                width: 80%;
                list-style: none;
                display: flex;
                // border: 1px solid red;

                .active {
                    color: #0068d5;
                }

                li {
                    cursor: pointer;
                    margin-left: 5px;
                    padding-left: 5px;
                    font-size: 10px;
                    border-left: 1px solid #e9e9e9;
                }
            }
        }
        /*关于学会tap end*/

        /*内容区域 start*/
        .survey-cont {
            width: 100%;

            .survey-content {
                display: flex;
                width: 100%;
                // height: 300px;
                // border: 1px solid red;

                // 内容区域左侧
                .survey-content-left {
                    width: 68%;
                    height: 100%;
                    // border: 1px solid blue;
                }

                // 内容区域右侧
                .survey-content-right {
                    margin-left: auto;
                    width: 30%;
                    // border: 1px solid red;
                }
            }
        }

        /*内容区域 end*/
    }
}

//当屏幕最小宽度为992px
@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .minkave {
        width: 100%;

        .minkave-wrap {
            width: 70%;
            // height: 6000px;
            margin: 0 auto;
            margin-top: 10px;
            margin-bottom: 40px;

            /*顶部图片start*/
            .index-headImg {
                position: relative;
                z-index: 0;
                width: 100%;
                height: 50px;
                margin-top: 10px;
                display: flex;
                justify-content: space-between;

                // 左侧图片
                .index-headImg-left {
                    width: 48%;
                    height: 100%;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }

                // 右侧图片
                .index-headImg-right {
                    width: 48%;
                    height: 100%;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
            /*顶部图片end*/

            /*关于学会tap start*/
            .survey-nav {
                display: flex;
                align-items: center;
                width: 100%;
                padding: 10px 0;
                box-sizing: border-box;
                border-bottom: 1px solid #e4e4e4;

                .survey-nav-title {
                    width: 30%;
                    display: flex;
                    align-items: center;
                    // border: 1px solid red;

                    .survey-title-cn {
                        text-align: center;
                        width: 70px;
                        font-size: 12px;
                        color: #0056b0;
                        margin-right: 6px;
                        // border: 1px solid red;
                    }

                    .survey-title-sn {
                        text-align: center;
                        width: 70px;
                        height: 10px;
                        line-height: 10px;
                        font-size: 6px;
                        color: #fff;
                        padding: 0 6px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        background-color: #0056b0;
                        // border: 1px solid red;
                    }
                }

                > ul {
                    width: 70%;
                    list-style: none;
                    display: flex;
                    // border: 1px solid red;

                    .active {
                        color: #0068d5;
                    }

                    li {
                        cursor: pointer;
                        width: 30px;
                        margin-left: 10px;
                        padding-left: 10px;
                        font-size: 7.5px;
                        border-left: 1px solid #e9e9e9;
                    }
                }
            }
            /*关于学会tap end*/

            /*内容区域 start*/
            .survey-cont {
                width: 100%;

                .survey-content {
                    display: flex;
                    width: 100%;
                    margin-bottom: 40px;
                    // height: 300px;
                    // border: 1px solid red;

                    // 内容区域左侧
                    .survey-content-left {
                        width: 68%;
                        height: 100%;
                        // border: 1px solid blue;
                    }

                    // 内容区域右侧
                    .survey-content-right {
                        margin-left: auto;
                        width: 30%;
                        // border: 1px solid red;
                    }
                }
            }

            /*内容区域 end*/
        }
    }
}

//当屏幕宽度 最小为768px 最大为991px时
@media screen and (min-width: 768px) and (max-width: 1200px) {
    .minkave {
        width: 100%;

        .minkave-wrap {
            width: 80%;
            // height: 6000px;
            margin: 0 auto;
            margin-top: 10px;
            margin-bottom: 40px;
            // border: 1px solid red;

            /*顶部图片start*/
            .index-headImg {
                position: relative;
                z-index: 0;
                width: 100%;
                height: 50px;
                margin-top: 10px;
                display: flex;
                justify-content: space-between;

                // 左侧图片
                .index-headImg-left {
                    width: 48%;
                    height: 100%;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }

                // 右侧图片
                .index-headImg-right {
                    width: 48%;
                    height: 100%;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
            /*顶部图片end*/

            /*关于学会tap start*/
            .survey-nav {
                display: flex;
                align-items: center;
                width: 100%;
                padding: 10px 0;
                box-sizing: border-box;
                border-bottom: 1px solid #e4e4e4;

                .survey-nav-title {
                    width: 30%;
                    display: flex;
                    align-items: center;
                    // border: 1px solid red;

                    .survey-title-cn {
                        width: 70px;
                        font-size: 10px;
                        color: #0056b0;
                        margin-right: 0px;
                    }

                    .survey-title-sn {
                        text-align: center;
                        width: 70px;
                        height: 10px;
                        line-height: 10px;
                        font-size: 6px;
                        color: #fff;
                        padding: 0 6px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        background-color: #0056b0;
                        // border: 1px solid red;
                    }
                }

                > ul {
                    width: 70%;
                    list-style: none;
                    display: flex;
                    // border: 1px solid red;

                    .active {
                        color: #0068d5;
                    }

                    li {
                        cursor: pointer;
                        margin-left: 5px;
                        padding-left: 5px;
                        font-size: 6px;
                        border-left: 1px solid #e9e9e9;
                    }
                }
            }
            /*关于学会tap end*/

            /*内容区域 start*/
            .survey-cont {
                width: 100%;

                .survey-content {
                    display: flex;
                    width: 100%;
                    // height: 300px;
                    // border: 1px solid red;

                    // 内容区域左侧
                    .survey-content-left {
                        width: 68%;
                        height: 100%;
                        // border: 1px solid blue;
                    }

                    // 内容区域右侧
                    .survey-content-right {
                        margin-left: auto;
                        width: 30%;
                        // border: 1px solid red;
                    }
                }
            }

            /*内容区域 end*/
        }
    }
}

//当屏幕最大宽度为767px
@media screen and (max-width: 767px) {
}
</style>
