<template>
    <!-- 产品需求---成果转化发布页面 -->
    <div class="demandResultIssue">
        <Header></Header>
        <div class="minkave-wrap">
            <!-- 顶部图片 -->
            <Advertising></Advertising>
            <!-- <div class="index-headImg">
                <div class="index-headImg-left">
                    <img src="../../../assets/pc/Index/headL.png" alt="" />
                </div>
                <div class="index-headImg-right">
                    <img src="../../../assets/pc/Index/headR.png" alt="" />
                </div>
            </div> -->

            <!-- 关于学会tap -->
            <div class="survey-nav">
                <!-- <Nav></Nav> -->
                <div class="survey-nav-title">
                    <div class="survey-title-cn">发布推广</div>
                    <!-- <div class="survey-title-sn">About the Institute</div> -->
                </div>

                <!-- <div style="font-size: 30px">详情页暂未写tap切换</div> -->
            </div>

            <!-- 内容区域 -->
            <div class="survey-cont">
                <div class="survey-content">
                    <!-- 内容区域左侧 -->
                    <!-- 技术难题 -->
                    <div class="survey-content-left">
                        <div class="detail-right">
                            <div class="detail-right-place">
                                当前位置：网站首页>{{ navtl }}
                            </div>
                            <div class="detail-right-cont">
                                <!-- 发布问题 -->
                                <div>
                                    <div class="detail-right-name">
                                        <label for="">*标题</label>
                                        <input
                                            type="表单类型"
                                            v-model="form.name"
                                            placeholder="请输入名称"
                                        />
                                    </div>

                                    <div class="detail-right-select">
                                        <label for="">*标签</label>
                                        <select @change="setoutChange">
                                            <option
                                                :value="item.id"
                                                v-for="(
                                                    item, index
                                                ) in setoutOptionDe"
                                                :key="index"
                                            >
                                                {{ item.name }}
                                            </option>
                                        </select>
                                    </div>

                                    <div class="detail-right-unit">
                                        <label for="">*完成单位</label>
                                        <input
                                            type="表单类型"
                                            v-model="form.unit"
                                            placeholder="请输入完成单位"
                                        />
                                    </div>

                                    <div class="relation-right-author">
                                        <label for="">*完成作者</label>
                                        <input
                                            class="linkman"
                                            type="表单类型"
                                            v-model="form.author"
                                            placeholder="请输入作者"
                                        />
                                    </div>
                                    <div class="relation-right-phone">
                                        <label for="">*联系电话</label>
                                        <input
                                            class="phone"
                                            type="表单类型"
                                            v-model="form.phone"
                                            placeholder="请输入联系电话"
                                        />
                                    </div>
                                    <div class="relation-right-phone">
                                        <label for="">*成果类型</label>
                                        <input
                                            class="phone"
                                            type="表单类型"
                                            v-model="form.catename"
                                            placeholder="请输入成果类型"
                                        />
                                    </div>
                                    <div class="relation-right-prove">
                                        <label for="">*评价单位</label>
                                        <input
                                            class="mail"
                                            type="表单类型"
                                            v-model="form.prove"
                                            placeholder="请输入评价单位"
                                        />
                                    </div>

                                    <div class="relation-right-result">
                                        <label for="">*成果阶段</label>
                                        <input
                                            class="mail"
                                            type="表单类型"
                                            v-model="form.result"
                                            placeholder="请输入成果阶段"
                                        />
                                    </div>
                                    <div class="relation-right-domain">
                                        <label for="">*应用行业</label>
                                        <input
                                            class="mail"
                                            type="表单类型"
                                            v-model="form.domain"
                                            placeholder="请输入应用行业"
                                        />
                                    </div>
                                    <div class="relation-right-rotate">
                                        <label for="">*合作方式</label>
                                        <input
                                            class="mail"
                                            type="表单类型"
                                            v-model="form.rotate"
                                            placeholder="请输入合作方式"
                                        />
                                    </div>
                                    <div class="relation-right-rotate">
                                        <label for="">*技术领域</label>
                                        <input
                                            class="mail"
                                            type="表单类型"
                                            v-model="form.technosphere"
                                            placeholder="请输入技术领域"
                                        />
                                    </div>

                                    <!-- 技术简介 -->
                                    <div class="detail-right-area">
                                        <label for="">*技术简介</label>
                                        <textarea
                                            rows="行数"
                                            cols="列数"
                                            v-model="form.area"
                                            class="area"
                                            placeholder="请输入技术简介"
                                        >
                                        </textarea>
                                    </div>
                                    <div class="detail-right-area">
                                        <label for="">*技术内容</label>
                                        <textarea
                                            rows="行数"
                                            cols="列数"
                                            v-model="form.content"
                                            class="area"
                                            placeholder="请输入技术内容"
                                        >
                                        </textarea>
                                    </div>

                                    <!-- 验证码 -->
                                    <div class="relation-right-code">
                                        <label for="">*验证码</label>
                                        <input
                                            class="mail"
                                            type="表单类型"
                                            v-model="form.code"
                                            placeholder="请输入验证码"
                                        />

                                        <div>
                                            <Code
                                                ref="ref_validateCode"
                                                @change="changeCode"
                                            ></Code>
                                        </div>
                                    </div>
                                    <div>
                                        <div @click="submit">提交</div>
                                        <div @click="rewrite">重写</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- 内容区域右侧 -->
                    <div class="survey-content-right">
                        <Right></Right>
                    </div>
                </div>
            </div>
            <!-- 内容区域 -->
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import Header from "@/components/Header.vue"; //头部
import Footer from "@/components/Footer.vue"; //尾部
import Right from "@/components/Right.vue"; //右侧
import Left3 from "@/components/Science/Left3.vue"; //左侧
import Code from "@/components/Code.vue"; //验证码
import Advertising from "@/components/Advertising.vue"; //广告图
import {
    needSetoutcome,
    outcomescate,
    setoutcome,
} from "../../../network/data";

export default {
    // 组件名称
    name: "demand",
    // 组件参数 接收来自父组件的数据
    props: {},
    // 组件状态值
    data() {
        return {
            // issueIndex==0需求成果页面发布，==1助企名家成果发布
            issueIndex: "",
            // 发布问题输入框数据
            form: {
                name: "",
                unit: "",
                author: "",
                phone: "",
                prove: "",
                result: "",
                domain: "",
                rotate: "",
                area: "",
                content: "",
                technosphere: "",
                catename: "",
            },
            identifyCode: "", // 生成的验证码
            // 成果转换标签
            setoutOptionDe: [],
            // 选中成果转换标签
            setoutId: "",
            // 验证码
            checkCode: "",
        };
    },
    // 计算属性
    computed: {
        navtl() {
            return this.issueIndex == 0
                ? "助企名家>发布推广"
                : "产业需求>发布推广";
        },
    },
    // 侦听器
    watch: {},
    // 组件方法
    created() {
        if (JSON.stringify(this.$route.query) != "{}") {
            this.issueIndex = this.$route.query.indexa;
            // console.log(this.$route.lable_id, "测试");
        }

        this.setoutOptionRe();
    },
    methods: {
        // 获取需求成果转换标签
        setoutOptionRe() {
            outcomescate().then((res) => {
                if (res.code == 1) {
                    // console.log(res.data);
                    this.setoutOptionDe = res.data;
                    this.setoutOptionDe.unshift({ id: 0, name: "请选择" });
                } else {
                    this.message(res.msg);
                }
            });
        },

        // 获取验证码
        getIdentifyCode(val) {
            this.identifyCode = val;
        },
        // 切换验证码(点击文字切换)
        refreshCode() {
            this.identifyCode = "";
            // 触发子组件刷新验证码事件
            this.$refs.code.$emit("bridge");
        },

        // 发布问题重写
        rewrite() {
            this.form.name = "";
            this.form.unit = "";
            this.form.author = "";
            this.form.phone = "";
            this.form.prove = "";
            this.form.result = "";
            this.form.domain = "";
            this.form.rotate = "";
            this.form.area = "";
            this.form.code = "";
            this.form.technosphere = "";
        },
        // 成果转换标签
        setoutChange(e) {
            this.setoutId = e.target.value;
        },
        // 问题发布验证码
        changeCode(value) {
            this.checkCode = value;
            // this.$refs["ref_validateCode"].draw();
            console.log(value, "验证码初始化");
        },

        // 发布问题提交
        submit() {
            let phoneDta = this.form.phone;
            let refPhone =
                /^[1](([3][0-9])|([4][0,1,4-9])|([5][0-3,5-9])|([6][2,5,6,7])|([7][0-8])|([8][0-9])|([9][0-3,5-9]))[0-9]{8}$/;
            if (this.form.name == "") {
                this.$message.warning({
                    message: "标题为空",
                    duration: 1000,
                    customClass: "myBox",
                });
                return;
            } else if (this.setoutId == "") {
                this.$message.warning({
                    message: "标签为空",
                    duration: 1000,
                    customClass: "myBox",
                });
                return;
            } else if (this.form.unit == "") {
                this.$message.warning({
                    message: "归属单位为空",
                    duration: 1000,
                    customClass: "myBox",
                });
                return;
            } else if (this.form.author == "") {
                this.$message.warning({
                    message: "完成作者为空",
                    duration: 1000,
                    customClass: "myBox",
                });
                return;
            } else if (!refPhone.test(phoneDta)) {
                this.$message.warning({
                    message: "联系电话错误",
                    duration: 1000,
                    customClass: "myBox",
                });
                return;
            } else if (this.form.prove == "") {
                this.$message.warning({
                    message: "评价单位为空",
                    duration: 1000,
                    customClass: "myBox",
                });
                return;
            } else if (this.form.result == "") {
                this.$message.warning({
                    message: "成果阶段为空",
                    duration: 1000,
                    customClass: "myBox",
                });
                return;
            } else if (this.form.domain == "") {
                this.$message.warning({
                    message: "引用领域为空",
                    duration: 1000,
                    customClass: "myBox",
                });
                return;
            } else if (this.form.rotate == "") {
                this.$message.warning({
                    message: "转让方式为空",
                    duration: 1000,
                    customClass: "myBox",
                });
                return;
            } else if (this.form.area == "") {
                this.$message.warning({
                    message: "技术描述为空",
                    duration: 1000,
                    customClass: "myBox",
                });
                return;
            } else if (this.form.content == "") {
                this.$message.warning({
                    message: "技术内容为空",
                    duration: 1000,
                    customClass: "myBox",
                });
                return;
            } else if (this.form.code != this.checkCode) {
                this.$message.warning({
                    message: "验证码错误",
                    duration: 1000,
                    customClass: "myBox",
                });
                return;
            } else {
                let data = {
                    id: sessionStorage.getItem("specialist_id"), //个人ID
                    title: this.form.name, //标题
                    lable_ids: this.setoutId, //标签
                    company: this.form.unit, //归属单位
                    author: this.form.author, //完成作者
                    authenticate: this.form.prove, //评价单位
                    stage: this.form.result, //成果阶段
                    usedomain: this.form.domain, //应用领域
                    makeway: this.form.rotate, //转让方式
                    description: this.form.area, //技术简介
                    content: this.form.content, //技术内容
                    technosphere: this.form.technosphere,
                    catename: this.form.catename,
                };
                if (this.issueIndex == 1) {
                    needSetoutcome(data).then((res) => {
                        if (res.code == 1) {
                            this.$message.success({
                                message: "提交成功",
                                duration: 1000,
                                customClass: "myBox",
                            });
                        } else {
                            this.$message.success({
                                message: res.msg,
                                duration: 1000,
                                customClass: "myBox",
                            });
                        }
                    });
                } else {
                    setoutcome(data).then((res) => {
                        if (res.code == 1) {
                            this.$message.success({
                                message: "提交成功",
                                duration: 1000,
                                customClass: "myBox",
                            });
                        } else {
                            this.$message.success({
                                message: res.msg,
                                duration: 1000,
                                customClass: "myBox",
                            });
                        }
                    });
                }
            }
        },
    },
    mounted() {},
    // 局部注册的组件
    components: {
        Header,
        Footer,
        Right,
        Left3,
        Code,
        Advertising,
    },
};
</script>

<style lang="scss">
// 修改message弹框样式
.myBox {
    min-width: 130px !important;
    height: 25px !important;
    padding: 3px !important;
    text-align: center !important;
    display: flex !important;
    justify-content: center !important;
    .el-message__icon {
        font-size: 10px;
    }
    .el-message__content {
        font-size: 8px;
    }
}

.demandResultIssue {
    width: 100%;

    .minkave-wrap {
        width: 70%;
        // height: 1000px;
        margin: 0 auto;
        margin-top: 35px;
        margin-bottom: 40px;
        // border: 1px solid red;

        /*顶部图片start*/
        .index-headImg {
            position: relative;
            z-index: 0;
            width: 100%;
            height: 50px;
            margin-top: 15px;
            display: flex;
            justify-content: space-between;

            // 左侧图片
            .index-headImg-left {
                width: 48%;
                height: 100%;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            // 右侧图片
            .index-headImg-right {
                width: 48%;
                height: 100%;

                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
        /*顶部图片end*/

        /*关于学会tap start*/
        .survey-nav {
            display: flex;
            align-items: center;
            width: 100%;
            padding: 10px 0;
            box-sizing: border-box;
            border-bottom: 0.5px solid #e4e4e4;

            .survey-nav-title {
                width: 20%;
                display: flex;
                align-items: center;
                // border: 1px solid red;

                .survey-title-cn {
                    font-size: 10px;
                    color: #0056b0;
                    margin-right: 15px;
                }

                .survey-title-sn {
                    height: 20px;
                    font-size: 6px;
                    color: #fff;
                    padding: 0 6px;
                    background-color: #0056b0;
                }
            }
        }
        /*关于学会tap end*/

        /*内容区域 start*/
        .survey-cont {
            width: 100%;

            .survey-content {
                display: flex;
                width: 100%;
                // height: 300px;
                // border: 1px solid red;

                // 内容区域左侧1(技术难题)
                .survey-content-left {
                    display: flex;
                    width: 68%;
                    height: 100%;
                    // border: 1px solid blue;

                    .detail-right {
                        width: 100%;
                        // font-size: 12px;
                        background-color: #f8f8f8;

                        .detail-right-place {
                            font-size: 10px;
                            padding: 5px;
                            box-sizing: border-box;
                            border-bottom: 0.5px solid #e6e6e6;
                        }

                        .detail-right-cont {
                            padding: 5px;
                            font-size: 8px;

                            //发布问题
                            > div:nth-child(1) {
                                // 成果名称
                                .detail-right-name {
                                    display: flex;
                                    align-items: center;
                                    margin-bottom: 10px;

                                    > label {
                                        width: 50px;
                                    }

                                    > input {
                                        width: 400px;
                                        height: 13px;
                                    }
                                }

                                .detail-right-select {
                                    display: flex;
                                    align-items: center;
                                    margin-bottom: 10px;

                                    > label {
                                        width: 54px;
                                    }

                                    > select {
                                        width: 100%;
                                        height: 15px;
                                    }
                                }

                                // 归属单位
                                .detail-right-unit {
                                    display: flex;
                                    align-items: center;
                                    margin-bottom: 10px;

                                    > label {
                                        width: 50px;
                                    }

                                    > input {
                                        width: 400px;
                                        height: 13px;
                                    }
                                }

                                // 完成作者
                                .relation-right-author {
                                    display: flex;
                                    align-items: center;
                                    margin-bottom: 10px;

                                    > label {
                                        width: 50px;
                                    }

                                    > input:nth-child(2) {
                                        width: 400px;
                                        height: 13px;
                                    }
                                }
                                // 联系电话
                                .relation-right-phone {
                                    display: flex;
                                    align-items: center;
                                    margin-bottom: 10px;

                                    > label {
                                        width: 50px;
                                    }

                                    > input {
                                        width: 400px;
                                        height: 13px;
                                    }
                                }
                                // 评价单位
                                .relation-right-prove {
                                    display: flex;
                                    align-items: center;
                                    margin-bottom: 10px;

                                    > label {
                                        width: 50px;
                                    }

                                    > input {
                                        width: 400px;
                                        height: 13px;
                                    }
                                }

                                // 成果阶段
                                .relation-right-result {
                                    display: flex;
                                    align-items: center;
                                    margin-bottom: 10px;

                                    > label {
                                        width: 50px;
                                    }

                                    > input {
                                        width: 400px;
                                        height: 13px;
                                    }
                                }

                                // 应用领域
                                .relation-right-domain {
                                    display: flex;
                                    align-items: center;
                                    margin-bottom: 10px;

                                    > label {
                                        width: 50px;
                                    }

                                    > input {
                                        width: 400px;
                                        height: 13px;
                                    }
                                }

                                // 应用领域
                                .relation-right-rotate {
                                    display: flex;
                                    align-items: center;
                                    margin-bottom: 10px;

                                    > label {
                                        width: 50px;
                                    }

                                    > input {
                                        width: 400px;
                                        height: 13px;
                                    }
                                }

                                // 技术简介
                                .detail-right-area {
                                    display: flex;
                                    margin-bottom: 10px;
                                    > label {
                                        width: 50px;
                                    }

                                    > textarea {
                                        width: 400px;
                                        height: 123px;
                                    }
                                }
                                // 验证码
                                .relation-right-code {
                                    display: flex;
                                    align-items: center;
                                    margin-bottom: 10px;

                                    > label {
                                        width: 50px;
                                    }

                                    > input {
                                        width: 100px;
                                        height: 13px;
                                    }

                                    > div {
                                        margin-left: 5px;
                                        width: 50px;
                                    }
                                }

                                // 提交、重写
                                > div:last-child {
                                    display: flex;
                                    margin-left: 50px;

                                    > div:nth-child(1) {
                                        cursor: pointer;
                                        margin-right: 5px;
                                        color: #fff;
                                        padding: 3px 7px;
                                        background-color: #0056b0;
                                    }

                                    > div:nth-child(2) {
                                        cursor: pointer;
                                        margin-right: 5px;
                                        color: #fff;
                                        padding: 3px 7px;
                                        background-color: #f77132;
                                    }
                                }
                            }
                        }
                    }
                }

                // 内容区域右侧
                .survey-content-right {
                    margin-left: auto;
                    width: 30%;
                    // border: 1px solid red;
                }
            }
        }

        /*内容区域 end*/
    }
}

//当屏幕最小宽度为992px
@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .demandResultIssue {
        width: 100%;

        .minkave-wrap {
            width: 70%;
            // height: 1000px;
            margin: 0 auto;
            margin-top: 35px;
            margin-bottom: 40px;
            // border: 1px solid red;

            /*顶部图片start*/
            .index-headImg {
                position: relative;
                z-index: 0;
                width: 100%;
                height: 50px;
                margin-top: 15px;
                display: flex;
                justify-content: space-between;

                // 左侧图片
                .index-headImg-left {
                    width: 48%;
                    height: 100%;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }

                // 右侧图片
                .index-headImg-right {
                    width: 48%;
                    height: 100%;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
            /*顶部图片end*/

            /*关于学会tap start*/
            .survey-nav {
                display: flex;
                align-items: center;
                width: 100%;
                padding: 10px 0;
                box-sizing: border-box;
                border-bottom: 0.5px solid #e4e4e4;

                .survey-nav-title {
                    width: 20%;
                    display: flex;
                    align-items: center;
                    // border: 1px solid red;

                    .survey-title-cn {
                        font-size: 10px;
                        color: #0056b0;
                        margin-right: 15px;
                    }

                    .survey-title-sn {
                        height: 20px;
                        font-size: 6px;
                        color: #fff;
                        padding: 0 6px;
                        background-color: #0056b0;
                    }
                }
            }
            /*关于学会tap end*/

            /*内容区域 start*/
            .survey-cont {
                width: 100%;

                .survey-content {
                    display: flex;
                    width: 100%;
                    // height: 300px;
                    // border: 1px solid red;

                    // 内容区域左侧1(技术难题)
                    .survey-content-left {
                        display: flex;
                        width: 68%;
                        height: 100%;
                        // border: 1px solid blue;

                        .detail-right {
                            width: 100%;
                            // font-size: 12px;
                            background-color: #f8f8f8;

                            .detail-right-place {
                                font-size: 10px;
                                padding: 5px;
                                box-sizing: border-box;
                                border-bottom: 0.5px solid #e6e6e6;
                            }

                            .detail-right-cont {
                                padding: 5px;
                                font-size: 8px;

                                //发布问题
                                > div:nth-child(1) {
                                    // 成果名称
                                    .detail-right-name {
                                        display: flex;
                                        align-items: center;
                                        margin-bottom: 10px;

                                        > label {
                                            width: 60px;
                                        }

                                        > input {
                                            width: 400px;
                                            height: 13px;
                                        }
                                    }

                                    .detail-right-select {
                                        display: flex;
                                        align-items: center;
                                        margin-bottom: 10px;

                                        > label {
                                            width: 50px;
                                        }

                                        > select {
                                            width: 100%;
                                            height: 15px;
                                        }
                                    }

                                    // 归属单位
                                    .detail-right-unit {
                                        display: flex;
                                        align-items: center;
                                        margin-bottom: 10px;

                                        > label {
                                            width: 60px;
                                        }

                                        > input {
                                            width: 400px;
                                            height: 13px;
                                        }
                                    }

                                    // 完成作者
                                    .relation-right-author {
                                        display: flex;
                                        align-items: center;
                                        margin-bottom: 10px;

                                        > label {
                                            width: 60px;
                                        }

                                        > input:nth-child(2) {
                                            width: 400px;
                                            height: 13px;
                                        }
                                    }
                                    // 联系电话
                                    .relation-right-phone {
                                        display: flex;
                                        align-items: center;
                                        margin-bottom: 10px;

                                        > label {
                                            width: 60px;
                                        }

                                        > input {
                                            width: 400px;
                                            height: 13px;
                                        }
                                    }
                                    // 评价单位
                                    .relation-right-prove {
                                        display: flex;
                                        align-items: center;
                                        margin-bottom: 10px;

                                        > label {
                                            width: 60px;
                                        }

                                        > input {
                                            width: 400px;
                                            height: 13px;
                                        }
                                    }

                                    // 成果阶段
                                    .relation-right-result {
                                        display: flex;
                                        align-items: center;
                                        margin-bottom: 10px;

                                        > label {
                                            width: 60px;
                                        }

                                        > input {
                                            width: 400px;
                                            height: 13px;
                                        }
                                    }

                                    // 应用领域
                                    .relation-right-domain {
                                        display: flex;
                                        align-items: center;
                                        margin-bottom: 10px;

                                        > label {
                                            width: 60px;
                                        }

                                        > input {
                                            width: 400px;
                                            height: 13px;
                                        }
                                    }

                                    // 应用领域
                                    .relation-right-rotate {
                                        display: flex;
                                        align-items: center;
                                        margin-bottom: 10px;

                                        > label {
                                            width: 60px;
                                        }

                                        > input {
                                            width: 400px;
                                            height: 13px;
                                        }
                                    }

                                    // 技术简介
                                    .detail-right-area {
                                        display: flex;
                                        margin-bottom: 10px;
                                        > label {
                                            width: 60px;
                                        }

                                        > textarea {
                                            width: 400px;
                                            height: 123px;
                                        }
                                    }
                                    // 验证码
                                    .relation-right-code {
                                        display: flex;
                                        align-items: center;
                                        margin-bottom: 10px;

                                        > label {
                                            width: 40px;
                                        }

                                        > input {
                                            width: 100px;
                                            height: 13px;
                                        }

                                        > div {
                                            margin-left: 5px;
                                            width: 50px;
                                        }
                                    }

                                    // 提交、重写
                                    > div:last-child {
                                        display: flex;
                                        margin-left: 50px;

                                        > div:nth-child(1) {
                                            cursor: pointer;
                                            margin-right: 5px;
                                            color: #fff;
                                            padding: 3px 7px;
                                            background-color: #0056b0;
                                        }

                                        > div:nth-child(2) {
                                            cursor: pointer;
                                            margin-right: 5px;
                                            color: #fff;
                                            padding: 3px 7px;
                                            background-color: #f77132;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    // 内容区域右侧
                    .survey-content-right {
                        margin-left: auto;
                        width: 30%;
                        // border: 1px solid red;
                    }
                }
            }

            /*内容区域 end*/
        }
    }
}

//当屏幕宽度 最小为768px 最大为991px时
@media screen and (min-width: 768px) and (max-width: 1200px) {
    // 修改message弹框样式
    .myBox {
        min-width: 130px !important;
        height: 25px !important;
        padding: 3px !important;
        text-align: center !important;
        display: flex !important;
        justify-content: center !important;
        .el-message__icon {
            font-size: 10px;
        }
        .el-message__content {
            font-size: 8px;
        }
    }

    .demandResultIssue {
        width: 100%;

        .minkave-wrap {
            width: 80%;
            // height: 1000px;
            margin: 0 auto;
            margin-top: 35px;
            margin-bottom: 40px;
            // border: 1px solid red;

            /*顶部图片start*/
            .index-headImg {
                position: relative;
                z-index: 0;
                width: 100%;
                height: 50px;
                margin-top: 15px;
                display: flex;
                justify-content: space-between;

                // 左侧图片
                .index-headImg-left {
                    width: 48%;
                    height: 100%;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }

                // 右侧图片
                .index-headImg-right {
                    width: 48%;
                    height: 100%;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
            /*顶部图片end*/

            /*关于学会tap start*/
            .survey-nav {
                display: flex;
                align-items: center;
                width: 100%;
                padding: 10px 0;
                box-sizing: border-box;
                border-bottom: 1px solid #e4e4e4;

                .survey-nav-title {
                    width: 20%;
                    display: flex;
                    align-items: center;
                    // border: 1px solid red;

                    .survey-title-cn {
                        font-size: 10px;
                        color: #0056b0;
                        margin-right: 15px;
                    }

                    .survey-title-sn {
                        height: 20px;
                        font-size: 6px;
                        color: #fff;
                        padding: 0 6px;
                        background-color: #0056b0;
                    }
                }
            }
            /*关于学会tap end*/

            /*内容区域 start*/
            .survey-cont {
                width: 100%;

                .survey-content {
                    display: flex;
                    width: 100%;
                    // height: 300px;
                    // border: 1px solid red;

                    // 内容区域左侧1(技术难题)
                    .survey-content-left {
                        display: flex;
                        width: 68%;
                        height: 100%;
                        // border: 1px solid blue;

                        .detail-right {
                            width: 100%;
                            // font-size: 12px;
                            background-color: #f8f8f8;

                            .detail-right-place {
                                font-size: 10px;
                                padding: 5px;
                                box-sizing: border-box;
                                border-bottom: 1px solid #e6e6e6;
                            }

                            .detail-right-cont {
                                padding: 5px;
                                font-size: 8px;

                                //发布问题
                                > div:nth-child(1) {
                                    // 成果名称
                                    .detail-right-name {
                                        display: flex;
                                        align-items: center;
                                        margin-bottom: 10px;

                                        > label {
                                            width: 80px;
                                            font-size: 8px;
                                        }

                                        > input {
                                            width: 400px;
                                            height: 13px;
                                        }
                                    }

                                    .detail-right-select {
                                        display: flex;
                                        align-items: center;
                                        margin-bottom: 10px;

                                        > label {
                                            width: 50px;
                                        }

                                        > select {
                                            width: 100%;
                                            height: 15px;
                                        }
                                    }

                                    // 归属单位
                                    .detail-right-unit {
                                        display: flex;
                                        align-items: center;
                                        margin-bottom: 10px;

                                        > label {
                                            width: 80px;
                                            font-size: 8px;
                                        }

                                        > input {
                                            width: 400px;
                                            height: 13px;
                                        }
                                    }

                                    // 完成作者
                                    .relation-right-author {
                                        display: flex;
                                        align-items: center;
                                        margin-bottom: 10px;

                                        > label {
                                            width: 80px;
                                            font-size: 8px;
                                        }

                                        > input:nth-child(2) {
                                            width: 400px;
                                            height: 13px;
                                        }
                                    }
                                    // 联系电话
                                    .relation-right-phone {
                                        display: flex;
                                        align-items: center;
                                        margin-bottom: 10px;

                                        > label {
                                            width: 80px;
                                            font-size: 8px;
                                        }

                                        > input {
                                            width: 400px;
                                            height: 13px;
                                        }
                                    }
                                    // 评价单位
                                    .relation-right-prove {
                                        display: flex;
                                        align-items: center;
                                        margin-bottom: 10px;

                                        > label {
                                            width: 80px;
                                            font-size: 8px;
                                        }

                                        > input {
                                            width: 400px;
                                            height: 13px;
                                        }
                                    }

                                    // 成果阶段
                                    .relation-right-result {
                                        display: flex;
                                        align-items: center;
                                        margin-bottom: 10px;

                                        > label {
                                            width: 80px;
                                            font-size: 8px;
                                        }

                                        > input {
                                            width: 400px;
                                            height: 13px;
                                        }
                                    }

                                    // 应用领域
                                    .relation-right-domain {
                                        display: flex;
                                        align-items: center;
                                        margin-bottom: 10px;

                                        > label {
                                            width: 80px;
                                            font-size: 8px;
                                        }

                                        > input {
                                            width: 400px;
                                            height: 13px;
                                        }
                                    }

                                    // 应用领域
                                    .relation-right-rotate {
                                        display: flex;
                                        align-items: center;
                                        margin-bottom: 10px;

                                        > label {
                                            width: 80px;
                                            font-size: 8px;
                                        }

                                        > input {
                                            width: 400px;
                                            height: 13px;
                                        }
                                    }

                                    // 技术简介
                                    .detail-right-area {
                                        display: flex;
                                        margin-bottom: 10px;
                                        > label {
                                            width: 80px;
                                            font-size: 8px;
                                        }

                                        > textarea {
                                            width: 400px;
                                            height: 123px;
                                        }
                                    }
                                    // 验证码
                                    .relation-right-code {
                                        display: flex;
                                        align-items: center;
                                        margin-bottom: 10px;

                                        > label {
                                            width: 43px;
                                            font-size: 8px;
                                        }

                                        > input {
                                            width: 100px;
                                            height: 13px;
                                        }

                                        > div {
                                            margin-left: 5px;
                                            width: 50px;
                                        }
                                    }

                                    // 提交、重写
                                    > div:last-child {
                                        display: flex;
                                        margin-left: 50px;

                                        > div:nth-child(1) {
                                            cursor: pointer;
                                            margin-right: 5px;
                                            color: #fff;
                                            padding: 3px 7px;
                                            background-color: #0056b0;
                                        }

                                        > div:nth-child(2) {
                                            cursor: pointer;
                                            margin-right: 5px;
                                            color: #fff;
                                            padding: 3px 7px;
                                            background-color: #f77132;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    // 内容区域右侧
                    .survey-content-right {
                        margin-left: auto;
                        width: 30%;
                        // border: 1px solid red;
                    }
                }
            }

            /*内容区域 end*/
        }
    }
}

//当屏幕最大宽度为767px
@media screen and (max-width: 767px) {
}
</style>
