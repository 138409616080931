<template>
    <!-- 首页顶部图片 -->
    <div class="headImg">
        <div class="index-headImg">
            <div class="index-headImg-left">
                <a :href="image[0].url"><img :src="image[0].image" alt="" /></a>
            </div>
            <div class="index-headImg-right">
                <a :href="image[1].url"><img :src="image[1].image" alt="" /></a>
            </div>
        </div>
    </div>
</template>

<script>
import { advimagenew } from "../network/data";
export default {
    name: "headImg",
    data() {
        return {
            image:''
        };
    },
    computed: {},
    watch: {},
    methods: {
        getImage() {
            let data = {
                type:1
            }
            advimagenew(data).then(res=>{
                if(res.code==1) {
                   this.image = res.data.indextop
                }
            })
        }
    },
    created() {
        this.getImage()
    },
    mounted() {},
    beforeCreate() {},
    beforeMount() {},
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
    activated() {},
    components: {},
};
</script>

<style lang="scss" scoped>
.headImg {
    /*顶部图片start*/
    .index-headImg {
        position: relative;
        z-index: 0;
        width: 100%;
        height: 50px;
        margin-top: 10px;
        display: flex;
        justify-content: space-between;

        // 左侧图片
        .index-headImg-left {
            width: 49%;
            height: 100%;

            img {
                width: 100%;
                height: 100%;
            }
        }

        // 右侧图片
        .index-headImg-right {
            width: 49%;
            height: 100%;

            img {
                width: 100%;
                height: 100%;
            }
        }
    }
}

//当屏幕最小宽度为992px
@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .headImg {
        /*顶部图片start*/
        .index-headImg {
            position: relative;
            z-index: 0;
            width: 100%;
            height: 45px;
            margin-top: 10px;
            display: flex;
            justify-content: space-between;

            // 左侧图片
            .index-headImg-left {
                width: 49%;
                height: 100%;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            // 右侧图片
            .index-headImg-right {
                width: 49%;
                height: 100%;

                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}

//当屏幕宽度 最小为768px 最大为991px时
@media screen and (min-width: 768px) and (max-width: 1200px) {
    .headImg {
        /*顶部图片start*/
        .index-headImg {
            position: relative;
            z-index: 0;
            width: 100%;
            height: 40px;
            margin-top: 10px;
            display: flex;
            justify-content: space-between;

            // 左侧图片
            .index-headImg-left {
                width: 49%;
                height: 100%;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            // 右侧图片
            .index-headImg-right {
                width: 49%;
                height: 100%;

                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
        /*顶部图片end*/
    }
}

//当屏幕最大宽度为767px
@media screen and (max-width: 767px) {
    .headImg {
        /*顶部图片start*/
        .index-headImg {
            height: 30px;
            line-height: 30px;
            margin-top: 10px;
        }
        /*顶部图片end*/
    }
}

/*顶部图片end*/
</style>
