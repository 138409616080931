<template>
    <!-- 企业会员首页 -->
    <div class="mIndex">
        <Mheader :currentIndex="0"></Mheader>

        <!-- 轮播图 -->
        <div class="mIndex-swiper">
            <MeSwiper></MeSwiper>
        </div>

        <!-- 公司简介背色 -->
        <div class="mIndex-introduce"></div>

        <!-- 版心 -->
        <div class="mIndex-wrap">
            <!-- 公司简介 -->
            <div class="mIndex-introduces">
                <!-- 标题 -->
                <div class="mIndex-introduces-title">
                    <div>公司简介</div>
                    <div>about us</div>
                </div>

                <!-- 内容 -->
                <div class="mIndex-introduces-cont" @click="goMIntroduce">
                    <div class="mIndex-introduces-img">
                        <img :src="memberAbout.image" alt="" />
                    </div>
                    <div class="mIndex-introduces-box">
                        <div>{{ memberAbout.title }}</div>
                        <div>{{ memberAbout.description }}</div>
                    </div>
                </div>
            </div>

            <!-- 产品展示 -->
            <div class="mIndex-show">
                <!-- 产品展示左侧 -->
                <div class="mIndex-show-left">
                    <div class="mIndex-left-title">产品展示</div>
                    <ul>
                        <li
                            :key="indexb"
                            @click="showEv(indexb)"
                            :class="showCurrent == indexb ? 'liactive' : ''"
                            v-for="(itemb, indexb) in showData"
                        >
                            {{ itemb.name }}
                        </li>
                    </ul>
                </div>

                <!-- 产品展示右侧 -->
                <div class="mIndex-show-right">
                    <ul>
                        <li
                            :key="indexc"
                            @click="goProductDetail(itemc.id)"
                            v-for="(itemc, indexc) in productList"
                        >
                            <div class="mIndex-item-img">
                                <img :src="itemc.image" alt="" />
                            </div>
                            <div class="mIndex-item-ms">
                                <div>{{ itemc.title }}</div>
                                <div>{{ itemc.description }}</div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>

            <!-- 新闻资讯 -->
            <div class="mIndex-news">
                <!-- 新闻资讯标题 -->
                <div class="mIndex-news-title">
                    <div>新闻资讯</div>
                    <div>NEWS</div>
                    <div></div>
                </div>

                <!-- 新闻中心内容 -->
                <div class="journalism-body">
                    <div class="journalism-body1">
                        <!-- 左侧 -->
                        <div
                            class="journalism-body1-left"
                            @click="goNewsDetail(newsleft)"
                            v-if="Object.keys(newsleft).length != 0"
                        >
                            <div class="journalism-left-img">
                                <img :src="newsleft.image" />
                            </div>
                            <h3 class="journalism-left-title">
                                {{ newsleft.title }}
                            </h3>
                            <div class="journalism-left-describe">
                                {{ newsleft.description }}<span>《更多》</span>
                            </div>
                        </div>
                        <!-- 右侧 -->
                        <div class="journalism-body1-right">
                            <!-- 行业新闻列表 -->
                            <div
                                :key="indexb"
                                class="describe-right-item"
                                @click="goNewsDetail(itemb)"
                                v-for="(itemb, indexb) in newsright"
                            >
                                <div class="journalism-right-time">
                                    <span>{{ itemb.year }}</span>
                                    <span>{{ itemb.day }}</span>
                                </div>
                                <div class="journalism-right-describe">
                                    <h3 class="journalism-right-title">
                                        {{ itemb.title }}
                                    </h3>
                                    <div class="journalism-right-text">
                                        {{ itemb.description }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- 联系我们 -->
            <div class="mIndex-contact">
                <div class="mIndex-contact-title">
                    <div>联系我们</div>
                    <div>CONTACT US</div>
                    <div></div>
                </div>
                <div class="mIndex-contact-cont">
                    <!-- 联系我们左侧 -->
                    <div class="mIndex-contact-left">
                        <div class="mIndex-contact-h1">
                            <span></span>
                            <span>联系我们</span>
                        </div>
                        <div class="mIndex-contact-h2">
                            {{ companyeDe.name }}
                        </div>
                        <ul>
                            <li>总部热线：{{ companyeDe.headline }}</li>
                            <li>招商电话：{{ companyeDe.hotline }}</li>
                            <!-- <li>业务邮箱：{{ companyeDe.email }}</li>
                            <li>QQ:{{ companyeDe.qq }}</li>
                            <li>传真：{{ companyeDe.faxnum }}</li> -->
                            <li>总部地址：{{ companyeDe.address }}</li>
                        </ul>
                    </div>
                    <!-- 联系我们右侧 -->
                    <div class="mIndex-contact-right">
                        <div>
                            <span></span>
                            <span>在线留言</span>
                        </div>
                        <!-- 意向品类 -->
                        <div class="mIndex-contact-name">
                            <label for="">
                                <span style="color: #00479d">*</span>
                                意向品类:</label
                            >
                            <select @change="intentionCh">
                                <option
                                    :key="index"
                                    :value="item.name"
                                    v-for="(item, index) in intention"
                                >
                                    {{ item.name }}
                                </option>
                            </select>
                        </div>
                        <!-- 意向区域 -->
                        <div class="mIndex-contact-dl">
                            <label for=""
                                ><span style="color: #00479d">*</span
                                >意向区域:</label
                            >
                            <input
                                type="text"
                                v-model="form.area"
                                placeholder="请输入您的意向区域"
                            />
                        </div>
                        <!-- 招商电话 -->
                        <div class="mIndex-contact-phone">
                            <label for=""
                                ><span style="color: #00479d">*</span
                                >联系方式:</label
                            >
                            <input
                                type="text"
                                v-model="form.phone"
                                placeholder="请输入您的手机号"
                            />
                        </div>

                        <!-- 留言内容 -->
                        <div class="mIndex-contact-ly">
                            <label for=""
                                ><span style="color: #00479d">*</span
                                >留言内容:</label
                            >
                            <div>
                                <select @change="areaCh">
                                    <option
                                        :key="index"
                                        :value="item.name"
                                        v-for="(item, index) in cities"
                                    >
                                        {{ item.name }}
                                    </option>
                                </select>
                            </div>
                        </div>

                        <!-- 立即留言 -->
                        <button @click="messageRe">立即留言</button>
                    </div>
                </div>
            </div>
        </div>

        <Footer></Footer>
    </div>
</template>

<script>
import Mheader from "../../../components/Mheader.vue";
import Footer from "../../../components/Footer";
import MeSwiper from "../../../components/Member/swiper.vue";
import {
    companyBanner,
    companyIndex,
    messagecause,
    companye,
    message,
    areasheng,
    areacity,
    companycate,
} from "../../../network/data";
export default {
    // 组件名称
    name: "mIndex",
    // 组件参数 接收来自父组件的数据
    props: {},
    // 组件状态值
    data() {
        return {
            // 当前导航栏索引,默认为1
            menu_id: 1,

            // 企业会员公司简介数据
            memberAbout: {},
            // 企业会员产品展示
            memberNews: {},
            // 产品展示左侧索引
            showCurrent: 0,
            // 产品展示左侧数据
            showData: [],
            // 产品展示右侧数据
            productList: [],
            // 新闻中心左侧数据
            newsleft: {},
            // 新闻中心右侧数据
            newsright: [],
            // 留言单选数据
            cities: [],
            // 留言选择数据
            message: "",
            // 联系我们数据
            companyeDe: {},
            // 留言数据
            form: {
                name: "",
                phone: "",
                
                area: "",
            },
            // 省数据
            areashengDe: [],
            // 选中的省数据id
            areasheng: "",
            // 市数据
            areacityDe: [],
            // 选中的市数据id
            areacity: "",
            // 意向品类
            intention: [],
            // 选中的意向品类
            intentionID: "",
        };
    },
    // 计算属性
    computed: {},
    // 侦听器
    watch: {},
    // 组件方法
    created() {
        // 存储当前网站id
        if (this.$route.query != "") {
            // 获取当前导航栏id
            // console.log(this.$route.query.menu_id);
            this.menu_id = this.$route.query.menu_id;
        }

        // 获取企业会员首页数据
        this.companyIndexRe();
        // 获取企业会员首页留言数据
        this.messagecauseRe();
        // 获取企业会员公司信息
        this.companyRe();
        // 获取省数据
        this.areashengRe();
        // 获取意向品类
        this.companycateRe();
    },
    methods: {
        // 获取意向品类
        companycateRe() {
            let data = { typeid: 1 };
            companycate(data).then((res) => {
                if (res.code == 1) {
                    // console.log(res.data);
                    this.intention = res.data;
                    this.intention.unshift({ id: "", name: "请选择" });
                } else {
                    this.message(res.msg);
                }
            });
        },
        // 获取省数据
        areashengRe() {
            areasheng().then((res) => {
                if (res.code == 1) {
                    // console.log(res.data, "省数据");
                    this.areashengDe = res.data;
                    this.areashengDe.unshift({ id: "", name: "请选择" });
                } else {
                    this.message(res.msg);
                }
            });
        },
        // 获取市数据
        areacitRe(ids) {
            let data = {
                pid: ids,
            };
            areacity(data).then((res) => {
                if (res.code == 1) {
                    // console.log(res.data);
                    this.areacityDe = res.data;
                    this.areacityDe.unshift({ id: "", name: "请选择" });
                } else {
                    this.message(res.msg);
                }
            });
        },
        // 监听点击导航栏
        changeNav(e) {
            this.menu_id = e;
            // console.log(e, "当前导航栏id");
        },
        // 留言单选事件
        checkbox(item, index) {
            this.message = item.name;
            this.cities.forEach((item) => {
                if (item.bOn !== this.cities[index].bOn) {
                    item.bOn = false;
                }
            });
            item.bOn = !item.bOn;
        },
        // 产品展示左侧切换事件
        showEv(indexb) {
            this.showCurrent = indexb;
            this.companyIndexRe();
        },
        //跳转到企业会员公司简介
        goMIntroduce() {
            this.$router.push({
                path: "/mIntroduce",
                query: {
                    menu_id: 2,
                },
            });
        },
        // 跳转到企业会员产品详情页
        goProductDetail(id) {
            this.$router.push({
                path: `/productDetail`,
                query: {
                    menu_id: id,
                },
            });
            window.scrollTo(0, 0);
        },
        // 跳转到新闻详情页
        goNewsDetail(item) {
            this.$router.push({
                path: "/mNewsDetail",
                query: {
                    id: item.id,
                    menu_id: this.menu_id,
                },
            });
            window.scrollTo(0, 0);
        },

        // 获取企业会员首页数据
        companyIndexRe() {
            let data = {
                id: sessionStorage.getItem("id"),
            };
            companyIndex(data).then((res) => {
                if (res.code == 1) {
                    // 获取企业会员公司简介数据
                    this.memberAbout = res.data.about.info;
                    // console.log(this.memberAbout, "公司简介数据");
                    // 获取企业会员新闻左侧数据
                    if (res.data.news.list[0]) {
                        this.newsleft = res.data.news.list[0];
                    }
                    // 获取企业会员新闻右侧数据
                    this.newsright = res.data.news.list;
                    // 获取企业会员产品展示左侧数据
                    this.showData = res.data.product.list;
                    // 获取企业会员产品展示右侧数据
                    this.productList =
                        res.data.product.list[this.showCurrent].list;
                    // console.log(res.data, "企业会员首页数据");
                } else {
                    this.message(res.msg);
                }
            });
        },
        // 获取企业会员首页留言数据
        messagecauseRe() {
            messagecause().then((res) => {
                if (res.code == 1) {
                    this.cities = res.data;
                    this.cities.unshift({ id: "", name: "请选择" });
                    // this.cities.forEach((item) => {
                    //     item.bOn = false;
                    // });
                    // 默认选择留言数据
                    // this.message = this.cities[0].name;
                    // console.log(this.cities, "企业会员留言数据");
                } else {
                    this.message(res.msg);
                }
            });
        },
        // 获取企业会员公司信息
        companyRe() {
            let data = {
                id: sessionStorage.getItem("id"),
            };
            companye(data).then((res) => {
                if (res.code == 1) {
                    this.companyeDe = res.data;
                    // console.log(res.data, "企业会员公司信息");
                } else {
                    this.message(res.msg);
                }
            });
        },
        // 发送留言请求
        messageRe() {
            let data = {
                id: 1,
                name: this.intentionID,
                phone: this.form.phone,
                area: this.form.area,
                // wxnum: "",
                // province: this.areasheng,
                // city: this.areacity,
                content: this.message,
            };
            message(data).then((res) => {
                if (res.code == 1) {
                    this.$message.warning({
                        message: res.msg,
                        duration: 1000,
                        customClass: "myBox",
                    });
                } else {
                    this.$message.warning({
                        message: res.msg,
                        duration: 1000,
                        customClass: "myBox",
                    });
                }
            });
        },
        // 监听省选择数据
        areashengCh(e) {
            this.areasheng = e.target.value;
            this.areacitRe(this.areasheng);
        },
        // 监听市选择数据
        areacityCh(e) {
            this.areacity = e.target.value;
        },
        // 监听留言
        areaCh(e) {
            this.message = e.target.value;
        },
        // 监听意向品类
        intentionCh(e) {
            this.intentionID = e.target.value;
        },
    },
    mounted() {},
    // 局部注册的组件
    components: {
        Mheader,
        Footer,
        MeSwiper,
    },
};
</script>

<style lang="scss">
// 修改message弹框样式
.myBox {
    min-width: 130px !important;
    height: 25px !important;
    padding: 3px !important;
    text-align: center !important;
    display: flex !important;
    justify-content: center !important;
    .el-message__icon {
        font-size: 10px;
    }
    .el-message__content {
        font-size: 8px;
    }
}

.mIndex {
    img {
        display: block;
        height: 100%;
        width: 100%;
    }

    // 公司简介背景色
    .mIndex-introduce {
        width: 100%;
        height: 130px;
        background-color: #e6f2ff;
    }

    // 版心
    .mIndex-wrap {
        width: 70%;
        margin: 0 auto;
        position: relative;
        top: -100px;
        // border: 1px solid red;

        /*公司简介 start*/
        .mIndex-introduces {
            // 公司简介标题
            .mIndex-introduces-title {
                text-align: center;

                > div:nth-child(1) {
                    font-size: 15px;
                }

                > div:nth-child(2) {
                    font-size: 10px;
                    color: #999999;
                }
            }

            // 公司简介内容
            .mIndex-introduces-cont {
                width: 100%;
                display: flex;
                align-items: center;
                cursor: pointer;
                // border: 1px solid red;

                .mIndex-introduces-img {
                    width: 40%;
                    height: 200px;
                    // border: 1px solid red;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }

                .mIndex-introduces-box {
                    width: 60%;
                    height: 70%;
                    padding: 8px;
                    box-sizing: border-box !important;
                    background-color: #fff;
                    box-shadow: 5px 5px 10px -4px #d8d7d7;
                    // border: 1px solid red;

                    > div:nth-child(1) {
                        padding: 10px 0;
                        font-size: 12px;
                    }

                    > div:nth-child(2) {
                        font-size: 10px;
                        line-height: 15px;
                        color: #666666;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 5; //行数
                        -webkit-box-orient: vertical;
                    }
                }
            }
        }
        /*公司简介 end*/

        /*产品展示 start*/
        .mIndex-show {
            display: flex;
            width: 100%;
            /* height: 300px; */
            margin-top: 30px;
            // border: 1px solid red;

            // 产品展示左侧
            .mIndex-show-left {
                width: 20%;
                height: 300px;
                overflow: scroll;

                border: 1px solid #ebebeb;

                .mIndex-left-title {
                    padding: 6px 10px;
                    font-size: 10px;
                    color: #fff;
                    background-color: #00479d;
                }

                > ul {
                    list-style: none;
                    padding: 0 10px;
                    font-size: 10px;

                    .liactive {
                        color: #00479d;
                    }

                    .liactive::before {
                        background-color: #00479d;
                    }

                    li::before {
                        content: "";
                        width: 5px;
                        height: 5px;
                        // border-radius: 50%;
                        margin-right: 5px;
                        background-color: #00479d;
                        display: block;
                    }
                    li {
                        cursor: pointer;
                        display: flex;
                        align-items: center;
                        margin-top: 20px;
                        color: #a9a9a9;
                    }
                }
            }

            // 产品展示右侧
            .mIndex-show-right {
                width: 78%;
                height: 100%;
                margin-left: auto;
                // border: 1px solid blue;

                > ul {
                    list-style: none;
                    display: flex;
                    justify-content: space-between;
                    flex-wrap: wrap;

                    li {
                        width: 32%;
                        height: 145px;
                        cursor: pointer;
                        margin-bottom: 5px;
                        // border: 1px solid red;

                        .mIndex-item-img {
                            width: 100%;
                            height: 70%;

                            img {
                                width: 100%;
                                height: 100%;
                            }
                        }

                        .mIndex-item-ms {
                            width: 100%;
                            height: 30%;
                            padding: 5px;
                            text-align: center;

                            > div:nth-child(1) {
                                font-size: 10px;
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            }

                            > div:nth-child(2) {
                                font-size: 8px;
                                color: #a2a2a2;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                display: -webkit-box;
                                -webkit-line-clamp: 2; //行数
                                -webkit-box-orient: vertical;
                            }
                        }
                    }
                }
            }
        }
        /*产品展示 end*/

        /*新闻资讯 start*/
        .mIndex-news {
            width: 100%;
            margin-top: 40px;
            // border: 1px solid red;

            // 新闻资讯标题
            .mIndex-news-title {
                text-align: center;

                > div:nth-child(1) {
                    font-size: 15px;
                }

                > div:nth-child(2) {
                    font-size: 8px;
                    margin-bottom: 6px;
                    margin-top: 3px;
                    color: #a2a2a2;
                }
                > div:nth-child(3) {
                    margin: 0 auto;
                    width: 15px;
                    height: 2px;
                    background-color: #00479d;
                }
            }

            /*新闻中心内容 start*/
            .journalism-body {
                width: 100%;
                height: 246px;
                margin-top: 10px;

                .journalism-body1 {
                    display: flex;

                    // 左侧
                    .journalism-body1-left {
                        cursor: pointer;
                        overflow: hidden;
                        width: 40%;

                        // 图片
                        .journalism-left-img {
                            width: 100%;
                            height: 180px;

                            img {
                                width: 100%;
                                height: 100%;
                            }
                        }
                        // 标题
                        .journalism-left-title {
                            padding: 10px 0 8px 0;
                            font-size: 10px;
                            border-bottom: 1px solid #e1e1e1;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }

                        // 描述
                        .journalism-left-describe {
                            margin-top: 3px;
                            font-size: 8px;
                            color: #999999;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 2; //行数
                            -webkit-box-orient: vertical;

                            > span {
                                cursor: pointer;
                                color: #337136;
                            }
                        }
                    }

                    // 右侧
                    .journalism-body1-right {
                        margin-left: auto;
                        width: 57%;
                        // border: 1px solid red;

                        .tapActive {
                            color: #00479d;
                        }

                        .describe-right-item:hover {
                            color: #00479d;
                        }

                        // 列表
                        .describe-right-item {
                            display: flex;
                            margin-bottom: 8px;
                            cursor: pointer;

                            // 日期时间
                            .journalism-right-time {
                                display: flex;
                                flex-direction: column;
                                justify-content: flex-end;
                                padding: 10px 10px;
                                border-right: 1px solid #e1e1e1;

                                span:nth-child(1) {
                                    display: block;
                                    font-size: 10px;
                                    color: #00479d;
                                }

                                span:nth-child(2) {
                                    font-size: 10px;
                                    color: #00479d;
                                    margin-left: auto;
                                }
                            }

                            // 描述
                            .journalism-right-describe {
                                padding: 0 10px;

                                .journalism-right-title {
                                    width: 200px;
                                    margin-bottom: 5px;
                                    font-size: 10px;
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                }

                                .journalism-right-text {
                                    font-size: 8px;
                                    color: #999999;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    display: -webkit-box;
                                    -webkit-line-clamp: 2; //行数
                                    -webkit-box-orient: vertical;
                                }
                            }
                        }
                    }
                }
            }
            /*新闻中心内容 end*/
        }
        /*新闻资讯 end*/

        /*联系我们 start*/
        .mIndex-contact {
            width: 100%;
            margin-top: 80px;
            // border: 1px solid red;

            .mIndex-contact-title {
                text-align: center;

                > div:nth-child(1) {
                    font-size: 15px;
                }

                > div:nth-child(2) {
                    font-size: 8px;
                    margin-bottom: 6px;
                    margin-top: 3px;
                    color: #a2a2a2;
                }

                > div:nth-child(3) {
                    margin: 0 auto;
                    width: 15px;
                    height: 2px;
                    margin-bottom: 20px;
                    background-color: #00479d;
                }
            }

            // 联系我们内容区域
            .mIndex-contact-cont {
                width: 100%;
                display: flex;

                // 联系我们内容区域左侧
                .mIndex-contact-left {
                    width: 46%;

                    .mIndex-contact-h1 {
                        display: flex;
                        align-items: center;
                        font-size: 10px;

                        > span:nth-child(1) {
                            width: 2px;
                            height: 10px;
                            margin-right: 3px;
                            background-color: red;
                        }
                    }

                    .mIndex-contact-h2 {
                        font-size: 12px;
                        margin: 5px 0;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }

                    > ul {
                        list-style: none;
                        font-size: 10px;
                        color: #666666;

                        li {
                            margin-top: 8px;
                        }
                    }
                }

                // 联系我们内容区右侧
                .mIndex-contact-right {
                    width: 54%;

                    > div:nth-child(1) {
                        font-size: 10px;

                        // > span:nth-child(1) {
                        //     width: 20px;
                        //     height: 10px;
                        //     margin-right: 3px;
                        //     background-color: red;
                        // }
                    }

                    // 意向品类
                    .mIndex-contact-name {
                        display: flex;
                        width: 100%;
                        margin-top: 10px;
                        font-size: 10px;

                        > label {
                            display: block;
                            width: 20%;
                            text-align: end;
                            box-sizing: border-box;
                            padding-right: 10px;
                            // border: 1px solid red;
                        }

                        > select {
                            width: 80%;
                            height: 15px;
                        }
                    }

                    // 联系方式
                    .mIndex-contact-phone {
                        display: flex;
                        width: 100%;
                        margin-top: 10px;
                        font-size: 10px;

                        > label {
                            display: block;
                            width: 20%;
                            text-align: end;
                            box-sizing: border-box;
                            padding-right: 10px;
                            // border: 1px solid red;
                        }

                        > input {
                            width: 80%;
                            height: 15px;
                        }
                    }

                 

                    // 意向区域
                    .mIndex-contact-dl {
                        display: flex;
                        width: 100%;
                        margin-top: 10px;
                        font-size: 10px;

                        > label {
                            display: block;
                            width: 20%;
                            text-align: end;
                            box-sizing: border-box;
                            padding-right: 10px;
                            // border: 1px solid red;
                        }

                        > input {
                            width: 80%;
                            height: 15px;
                        }
                    }

                    // 留言内容
                    .mIndex-contact-ly {
                        display: flex;
                        width: 100%;
                        margin-top: 10px;
                        font-size: 10px;
                        // border: 1px solid red;

                        > label {
                            display: block;
                            width: 20%;
                            text-align: end;
                            box-sizing: border-box;
                            padding-right: 10px;
                            // border: 1px solid red;
                        }

                        // 留言选择
                        > div {
                            width: 80%;
                            // border: 1px solid red;
                            > select {
                                width: 100%;
                                height: 15px;
                            }

                         
                        }

                       
                    }

                    // 立即留言
                    > button {
                        margin-left: 40%;
                        width: 100px;
                        height: 25px;
                        font-size: 11px;
                        border: 0;
                        color: #fff;
                        border-radius: 4px;
                        background-color: #00479d;
                    }
                }
            }
        }
        /*联系我们 end*/
    }
}

//当屏幕最小宽度为992px
@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .mIndex {
        img {
            display: block;
            height: 100%;
            width: 100%;
        }

        // 公司简介背景色
        .mIndex-introduce {
            width: 100%;
            height: 130px;
            background-color: #e6f2ff;
        }

        // 版心
        .mIndex-wrap {
            width: 70%;
            margin: 0 auto;
            position: relative;
            top: -100px;
            // border: 1px solid red;

            /*公司简介 start*/
            .mIndex-introduces {
                // 公司简介标题
                .mIndex-introduces-title {
                    text-align: center;

                    > div:nth-child(1) {
                        font-size: 15px;
                    }

                    > div:nth-child(2) {
                        font-size: 10px;
                    }
                }

                // 公司简介内容
                .mIndex-introduces-cont {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    // border: 1px solid red;

                    .mIndex-introduces-img {
                        width: 40%;
                        height: 160px;
                        // border: 1px solid red;

                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }

                    .mIndex-introduces-box {
                        width: 60%;
                        height: 45%;
                        padding: 8px;
                        box-sizing: border-box !important;
                        background-color: #fff;
                        box-shadow: 5px 5px 10px -4px #d8d7d7;
                        // border: 1px solid red;

                        > div:nth-child(1) {
                            padding: 5px 0;
                            font-size: 10px;
                        }

                        > div:nth-child(2) {
                            font-size: 8px;
                            line-height: 15px;
                            color: #666666;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 5; //行数
                            -webkit-box-orient: vertical;
                        }
                    }
                }
            }
            /*公司简介 end*/

            /*产品展示 start*/
            .mIndex-show {
                display: flex;
                width: 100%;
                height: 260px;
                margin-top: 30px;
                // border: 1px solid red;

                // 产品展示左侧
                .mIndex-show-left {
                    width: 20%;
                    height: 90%;

                    border: 1px solid #ebebeb;

                    .mIndex-left-title {
                        padding: 6px 10px;
                        font-size: 10px;
                        color: #fff;
                        background-color: #00479d;
                    }

                    > ul {
                        list-style: none;
                        padding: 0 10px;
                        font-size: 8px;

                        .liactive {
                            color: #00479d;
                        }

                        .liactive::before {
                            background-color: #00479d;
                        }

                        li::before {
                            content: "";
                            width: 5px;
                            height: 5px;
                            // border-radius: 50%;
                            margin-right: 5px;
                            background-color: #333333;
                            display: block;
                        }
                        li {
                            cursor: pointer;
                            display: flex;
                            align-items: center;
                            margin-top: 15px;
                            color: #a9a9a9;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                    }
                }

                // 产品展示右侧
                .mIndex-show-right {
                    width: 78%;
                    height: 100%;
                    margin-left: auto;
                    // border: 1px solid blue;

                    > ul {
                        list-style: none;
                        display: flex;
                        justify-content: space-between;
                        flex-wrap: wrap;

                        li {
                            width: 32%;
                            height: 115px;
                            cursor: pointer;
                            margin-bottom: 5px;
                            // border: 1px solid red;

                            .mIndex-item-img {
                                width: 100%;
                                height: 70%;

                                img {
                                    width: 100%;
                                    height: 100%;
                                }
                            }

                            .mIndex-item-ms {
                                width: 100%;
                                height: 30%;
                                padding: 5px;
                                text-align: center;

                                > div:nth-child(1) {
                                    font-size: 9px;
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                }

                                > div:nth-child(2) {
                                    font-size: 8px;
                                    color: #a2a2a2;
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                }
                            }
                        }
                    }
                }
            }
            /*产品展示 end*/

            /*新闻资讯 start*/
            .mIndex-news {
                width: 100%;
                margin-top: 40px;
                // border: 1px solid red;

                // 新闻资讯标题
                .mIndex-news-title {
                    text-align: center;

                    > div:nth-child(1) {
                        font-size: 15px;
                    }

                    > div:nth-child(2) {
                        font-size: 8px;
                        margin-bottom: 6px;
                        margin-top: 3px;
                        color: #a2a2a2;
                    }
                    > div:nth-child(3) {
                        margin: 0 auto;
                        width: 15px;
                        height: 2px;
                        background-color: #00479d;
                    }
                }

                /*新闻中心内容 start*/
                .journalism-body {
                    width: 100%;
                    height: 226px;
                    margin-top: 10px;
                    // border: 1px solid red;

                    .journalism-body1 {
                        display: flex;

                        // 左侧
                        .journalism-body1-left {
                            overflow: hidden;
                            width: 40%;

                            // 图片
                            .journalism-left-img {
                                width: 100%;
                                height: 140px;

                                img {
                                    width: 100%;
                                    height: 100%;
                                }
                            }
                            // 标题
                            .journalism-left-title {
                                padding: 10px 0 8px 0;
                                font-size: 10px;
                                border-bottom: 1px solid #e1e1e1;
                            }

                            // 描述
                            .journalism-left-describe {
                                margin-top: 3px;
                                font-size: 8px;
                                color: #999999;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                display: -webkit-box;
                                -webkit-line-clamp: 2; //行数
                                -webkit-box-orient: vertical;

                                > span {
                                    cursor: pointer;
                                    color: #337136;
                                }
                            }
                        }

                        // 右侧
                        .journalism-body1-right {
                            margin-left: auto;
                            width: 57%;
                            // border: 1px solid red;

                            .tapActive {
                                color: #00479d;
                            }

                            .describe-right-item:hover {
                                color: #00479d;
                            }

                            // 列表
                            .describe-right-item {
                                display: flex;
                                margin-bottom: 3x;
                                cursor: pointer;
                                // border: 1px solid red;
                                // 日期时间
                                .journalism-right-time {
                                    display: flex;
                                    flex-direction: column;
                                    justify-content: flex-end;
                                    padding: 5px 8px;
                                    border-right: 1px solid #e1e1e1;

                                    span:nth-child(1) {
                                        display: block;
                                        font-size: 8px;
                                        color: #00479d;
                                    }

                                    span:nth-child(2) {
                                        font-size: 8px;
                                        color: #00479d;
                                        margin-left: auto;
                                    }
                                }

                                // 描述
                                .journalism-right-describe {
                                    padding: 0 8px;

                                    .journalism-right-title {
                                        margin-bottom: 5px;
                                        font-size: 8px;
                                    }

                                    .journalism-right-text {
                                        font-size: 7px;
                                        color: #999999;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        display: -webkit-box;
                                        -webkit-line-clamp: 2; //行数
                                        -webkit-box-orient: vertical;
                                    }
                                }
                            }
                        }
                    }
                }
                /*新闻中心内容 end*/
            }
            /*新闻资讯 end*/

            /*联系我们 start*/
            .mIndex-contact {
                width: 100%;
                margin-top: 30px;
                // border: 1px solid red;

                .mIndex-contact-title {
                    text-align: center;

                    > div:nth-child(1) {
                        font-size: 15px;
                    }

                    > div:nth-child(2) {
                        font-size: 8px;
                        margin-bottom: 6px;
                        margin-top: 3px;
                        color: #a2a2a2;
                    }

                    > div:nth-child(3) {
                        margin: 0 auto;
                        width: 15px;
                        height: 2px;
                        margin-bottom: 20px;
                        background-color: #00479d;
                    }
                }

                // 联系我们内容区域
                .mIndex-contact-cont {
                    width: 100%;
                    display: flex;

                    // 联系我们内容区域左侧
                    .mIndex-contact-left {
                        width: 50%;

                        .mIndex-contact-h1 {
                            display: flex;
                            align-items: center;
                            font-size: 10px;

                            > span:nth-child(1) {
                                width: 2px;
                                height: 10px;
                                margin-right: 3px;
                                background-color: #00479d;
                            }
                        }

                        .mIndex-contact-h2 {
                            font-size: 12px;
                            margin: 5px 0;
                        }

                        > ul {
                            list-style: none;
                            font-size: 10px;
                            color: #666666;

                            li {
                                margin-top: 8px;
                            }
                        }
                    }

                    // 联系我们内容区右侧
                    .mIndex-contact-right {
                        width: 50%;
                        // border: 1px solid red;

                        > div:nth-child(1) {
                            display: flex;
                            align-items: center;
                            font-size: 10px;
                            margin-left: 30px;
                            // border: 1px solid red;

                            > span:nth-child(1) {
                                display: block;
                                width: 2px;
                                height: 10px;
                                margin-right: 3px;
                                background-color: #00479d;
                            }
                        }

                        // 意向品类
                        .mIndex-contact-name {
                            display: flex;
                            width: 100%;
                            margin-top: 10px;
                            font-size: 10px;

                            > label {
                                display: block;
                                width: 30%;
                                text-align: end;
                                box-sizing: border-box;
                                padding-right: 10px;
                                // border: 1px solid red;
                            }

                            > select {
                                width: 80%;
                                height: 15px;
                            }
                        }

                        // 联系方式
                        .mIndex-contact-phone {
                            display: flex;
                            width: 100%;
                            margin-top: 10px;
                            font-size: 10px;

                            > label {
                                display: block;
                                width: 30%;
                                text-align: end;
                                box-sizing: border-box;
                                padding-right: 10px;
                                // border: 1px solid red;
                            }

                            > input {
                                width: 70%;
                                height: 15px;
                            }
                        }

                        // 微信号
                        // .mIndex-contact-wx {
                        //     display: flex;
                        //     width: 100%;
                        //     margin-top: 10px;
                        //     font-size: 10px;

                        //     > label {
                        //         display: block;
                        //         width: 30%;
                        //         text-align: end;
                        //         box-sizing: border-box;
                        //         padding-right: 10px;
                        //         // border: 1px solid red;
                        //     }

                        //     > input {
                        //         width: 70%;
                        //         height: 15px;
                        //     }
                        // }

                        // 意向区域
                        .mIndex-contact-dl {
                            display: flex;
                            width: 100%;
                            margin-top: 10px;
                            font-size: 10px;

                            > label {
                                display: block;
                                width: 30%;
                                text-align: end;
                                box-sizing: border-box;
                                padding-right: 10px;
                                // border: 1px solid red;
                            }

                            > input {
                                width: 80%;
                                height: 15px;
                            }
                        }

                        .mIndex-contact-ly {
                            display: flex;
                            width: 100%;
                            margin-top: 10px;
                            font-size: 10px;
                            // border: 1px solid red;

                            > label {
                                display: block;
                                width: 20%;
                                text-align: end;
                                box-sizing: border-box;
                                padding-right: 10px;
                                // border: 1px solid red;
                            }

                            // 留言选择
                            > div {
                                width: 80%;
                                // border: 1px solid red;
                                > select {
                                    width: 100%;
                                    height: 15px;
                                }

                                // ul {
                                //     width: 50%;
                                //     padding: 0;
                                //     margin: 0;
                                //     list-style: none;

                                //     li {
                                //         width: 100%;
                                //         height: 15px;
                                //         display: inline-block;
                                //         text-align: start;
                                //         line-height: 15px;
                                //         cursor: pointer;
                                //         font-size: 6px;
                                //         white-space: nowrap;
                                //         overflow: hidden;
                                //         text-overflow: ellipsis;
                                //         // border: 1px solid red;
                                //     }
                                //     li:before {
                                //         content: "";
                                //         display: inline-block;
                                //         width: 10px;
                                //         height: 10px;
                                //         line-height: 10px;
                                //         border: 1px #000 solid;
                                //         margin-right: 2px;
                                //         margin-bottom: -3px;
                                //         transition: all 0.3s linear;
                                //     }
                                //     li.checked:before {
                                //         background-color: #00479d;
                                //         border: 1px #fff solid;
                                //     }
                                //     li.checked {
                                //         color: #00479d;
                                //     }
                                // }
                            }

                            // > textarea {
                            //     width: 80%;
                            //     height: 60px;
                            // }
                        }

                        // 立即留言
                        > button {
                            margin-left: 40%;
                            width: 100px;
                            height: 25px;
                            font-size: 11px;
                            border: 0;
                            color: #fff;
                            border-radius: 4px;
                            background-color: #00479d;
                        }
                    }
                }
            }
            /*联系我们 end*/
        }
    }
}

//当屏幕宽度 最小为768px 最大为991px时
@media screen and (min-width: 768px) and (max-width: 1200px) {
    .mIndex {
        img {
            display: block;
            height: 100%;
            width: 100%;
        }

        // 公司简介背景色
        .mIndex-introduce {
            width: 100%;
            height: 110px;
            background-color: #e6f2ff;
        }

        // 版心
        .mIndex-wrap {
            width: 80%;
            margin: 0 auto;
            position: relative;
            top: -100px;
            // border: 1px solid red;

            /*公司简介 start*/
            .mIndex-introduces {
                // 公司简介标题
                .mIndex-introduces-title {
                    text-align: center;

                    > div:nth-child(1) {
                        font-size: 15px;
                    }

                    > div:nth-child(2) {
                        font-size: 10px;
                    }
                }

                // 公司简介内容
                .mIndex-introduces-cont {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    // border: 1px solid red;

                    .mIndex-introduces-img {
                        width: 40%;
                        height: 120px;
                        // border: 1px solid red;

                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }

                    .mIndex-introduces-box {
                        width: 60%;
                        height: 70%;
                        padding: 10px 0px 10px 10px;
                        background-color: #fff;
                        box-shadow: 5px 5px 10px -4px #d8d7d7;
                        // border: 1px solid red;

                        > div:nth-child(1) {
                            padding: 7px 0;
                            font-size: 12px;
                        }

                        > div:nth-child(2) {
                            font-size: 10px;
                            line-height: 15px;
                            color: #666666;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 3; //行数
                            -webkit-box-orient: vertical;
                        }
                    }
                }
            }
            /*公司简介 end*/

            /*产品展示 start*/
            .mIndex-show {
                display: flex;
                width: 100%;
                height: 220px;
                margin-top: 30px;
                // border: 1px solid red;

                // 产品展示左侧
                .mIndex-show-left {
                    width: 20%;
                    height: 90%;
                    border: 1px solid #ebebeb;

                    .mIndex-left-title {
                        padding: 6px 10px;
                        font-size: 10px;
                        color: #fff;
                        background-color: #00479d;
                    }

                    > ul {
                        list-style: none;
                        padding: 0 10px;
                        font-size: 10px;

                        .liactive {
                            color: #00479d;
                        }

                        .liactive::before {
                            background-color: #00479d;
                        }

                        li::before {
                            content: "";
                            width: 5px;
                            height: 5px;
                            margin-right: 5px;
                            background-color: #333333;
                            display: block;
                        }
                        li {
                            cursor: pointer;
                            display: flex;
                            align-items: center;
                            margin-top: 10px;
                            font-size: 8px;
                            color: #a9a9a9;
                            // border: 1px solid red;
                        }
                    }
                }

                // 产品展示右侧
                .mIndex-show-right {
                    width: 78%;
                    height: 100%;
                    margin-left: auto;
                    // border: 1px solid blue;

                    > ul {
                        list-style: none;
                        display: flex;
                        justify-content: space-between;
                        flex-wrap: wrap;

                        li {
                            width: 32%;
                            height: 95px;
                            cursor: pointer;
                            margin-bottom: 5px;
                            // border: 1px solid red;

                            .mIndex-item-img {
                                width: 100%;
                                height: 70%;

                                img {
                                    width: 100%;
                                    height: 100%;
                                }
                            }

                            .mIndex-item-ms {
                                width: 100%;
                                height: 30%;
                                padding: 5px;
                                box-sizing: border-box;
                                text-align: center;

                                > div:nth-child(1) {
                                    font-size: 10px;
                                }

                                > div:nth-child(2) {
                                    width: 100%;
                                    font-size: 8px;
                                    color: #a2a2a2;
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                }
                            }
                        }
                    }
                }
            }
            /*产品展示 end*/

            /*新闻资讯 start*/
            .mIndex-news {
                width: 100%;
                margin-top: 30px;
                // border: 1px solid red;

                // 新闻资讯标题
                .mIndex-news-title {
                    text-align: center;

                    > div:nth-child(1) {
                        font-size: 15px;
                    }

                    > div:nth-child(2) {
                        font-size: 10px;
                        color: #a2a2a2;
                    }
                }

                /*新闻中心内容 start*/
                .journalism-body {
                    width: 100%;
                    height: 166px;
                    margin-top: 5px;

                    .journalism-body1 {
                        display: flex;

                        // 左侧
                        .journalism-body1-left {
                            overflow: hidden;
                            width: 40%;

                            // 图片
                            .journalism-left-img {
                                width: 100%;
                                height: 100px;

                                img {
                                    width: 100%;
                                    height: 100%;
                                }
                            }
                            // 标题
                            .journalism-left-title {
                                padding: 8px 0 6px 0;
                                font-size: 8px;
                                border-bottom: 1px solid #e1e1e1;
                            }

                            // 描述
                            .journalism-left-describe {
                                margin-top: 3px;
                                font-size: 8px;
                                color: #999999;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                display: -webkit-box;
                                -webkit-line-clamp: 2; //行数
                                -webkit-box-orient: vertical;

                                > span {
                                    cursor: pointer;
                                    color: #00479d;
                                }
                            }
                        }

                        // 右侧
                        .journalism-body1-right {
                            margin-left: auto;
                            width: 57%;
                            // border: 1px solid red;

                            .tapActive {
                                color: #00479d;
                            }

                            .describe-right-item:hover {
                                color: #00479d;
                            }

                            // 列表
                            .describe-right-item {
                                display: flex;
                                margin-bottom: 2px;

                                // 日期时间
                                .journalism-right-time {
                                    display: flex;
                                    flex-direction: column;
                                    justify-content: flex-end;
                                    padding: 4px 6px;
                                    border-right: 1px solid #e1e1e1;

                                    span:nth-child(1) {
                                        display: block;
                                        font-size: 10px;
                                        color: #00479d;
                                    }

                                    span:nth-child(2) {
                                        font-size: 10px;
                                        color: #00479d;
                                        margin-left: auto;
                                    }
                                }

                                // 描述
                                .journalism-right-describe {
                                    padding: 0 8px;

                                    .journalism-right-title {
                                        margin-bottom: 5px;
                                        font-size: 8px;
                                    }

                                    .journalism-right-text {
                                        font-size: 6px;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        display: -webkit-box;
                                        -webkit-line-clamp: 2; //行数
                                        -webkit-box-orient: vertical;
                                    }
                                }
                            }
                        }
                    }
                }
                /*新闻中心内容 end*/
            }
            /*新闻资讯 end*/

            /*联系我们 start*/
            .mIndex-contact {
                width: 100%;
                margin-top: 30px;
                // border: 1px solid red;

                .mIndex-contact-title {
                    text-align: center;

                    > div:nth-child(1) {
                        font-size: 15px;
                    }

                    > div:nth-child(2) {
                        font-size: 10px;
                    }
                }

                // 联系我们内容区域
                .mIndex-contact-cont {
                    width: 100%;
                    display: flex;

                    // 联系我们内容区域左侧
                    .mIndex-contact-left {
                        width: 50%;

                        .mIndex-contact-h1 {
                            display: flex;
                            align-items: center;
                            font-size: 10px;

                            > span:nth-child(1) {
                                width: 2px;
                                height: 10px;
                                margin-right: 3px;
                                background-color: #00479d;
                            }
                        }

                        .mIndex-contact-h2 {
                            font-size: 12px;
                            margin: 5px 0;
                        }

                        > ul {
                            list-style: none;
                            font-size: 10px;
                            color: #666666;

                            li {
                                margin-top: 2px;
                            }
                        }
                    }

                    // 联系我们内容区右侧
                    .mIndex-contact-right {
                        width: 50%;

                        > div:nth-child(1) {
                            display: flex;
                            align-items: center;
                            font-size: 10px;
                            margin-left: 20px;
                            // border: 1px solid red;

                            > span:nth-child(1) {
                                display: block;
                                width: 2px;
                                height: 10px;
                                margin-right: 3px;
                                background-color: #00479d;
                            }
                        }

                        // 姓名
                        .mIndex-contact-name {
                            display: flex;
                            width: 100%;
                            margin-top: 6px;
                            font-size: 10px;

                            > label {
                                display: block;
                                width: 40%;
                                text-align: end;
                                box-sizing: border-box;
                                padding-right: 10px;
                                // border: 1px solid #cbcbcb;
                            }

                            > select {
                                width: 80%;
                                height: 15px;
                            }
                        }

                        // 手机号
                        .mIndex-contact-phone {
                            display: flex;
                            width: 100%;
                            margin-top: 6px;
                            font-size: 10px;

                            > label {
                                display: block;
                                width: 40%;
                                text-align: end;
                                box-sizing: border-box;
                                padding-right: 10px;
                                // border: 1px solid #cbcbcb;
                            }

                            > input {
                                width: 80%;
                                height: 15px;
                                padding-left: 3px;
                            }
                        }

                        // 微信号
                        // .mIndex-contact-wx {
                        //     display: flex;
                        //     width: 100%;
                        //     margin-top: 6px;
                        //     font-size: 10px;

                        //     > label {
                        //         display: block;
                        //         width: 30%;
                        //         text-align: end;
                        //         box-sizing: border-box;
                        //         padding-right: 10px;
                        //         // border: 1px solid #cbcbcb;
                        //     }

                        //     > input {
                        //         width: 80%;
                        //         height: 15px;
                        //         padding-left: 3px;
                        //     }
                        // }

                        // 代理区域
                        .mIndex-contact-dl {
                            display: flex;
                            width: 100%;
                            margin-top: 6px;
                            font-size: 10px;

                            > label {
                                display: block;
                                width: 40%;
                                text-align: end;
                                box-sizing: border-box;
                                padding-right: 10px;
                                // border: 1px solid #cbcbcb;
                            }

                            > select {
                                width: 40%;
                                height: 15px;
                                padding-left: 3px;
                            }
                        }

                        .mIndex-contact-ly {
                            display: flex;
                            width: 100%;
                            margin-top: 10px;
                            font-size: 10px;
                            // border: 1px solid red;

                            > label {
                                display: block;
                                width: 40%;
                                text-align: end;
                                box-sizing: border-box;
                                padding-right: 10px;
                                // border: 1px solid red;
                            }

                            // 留言选择
                            > div {
                                width: 80%;
                                // border: 1px solid red;
                                > select {
                                    width: 100%;
                                    height: 15px;
                                }

                                // ul {
                                //     width: 50%;
                                //     padding: 0;
                                //     margin: 0;
                                //     list-style: none;

                                //     li {
                                //         width: 100%;
                                //         height: 15px;
                                //         display: inline-block;
                                //         text-align: start;
                                //         line-height: 15px;
                                //         cursor: pointer;
                                //         font-size: 6px;
                                //         white-space: nowrap;
                                //         overflow: hidden;
                                //         text-overflow: ellipsis;
                                //         // border: 1px solid red;
                                //     }
                                //     li:before {
                                //         content: "";
                                //         display: inline-block;
                                //         width: 10px;
                                //         height: 10px;
                                //         line-height: 10px;
                                //         border: 1px #000 solid;
                                //         margin-right: 2px;
                                //         margin-bottom: -3px;
                                //         transition: all 0.3s linear;
                                //     }
                                //     li.checked:before {
                                //         background-color: #00479d;
                                //         border: 1px #fff solid;
                                //     }
                                //     li.checked {
                                //         color: #00479d;
                                //     }
                                // }
                            }

                            // > textarea {
                            //     width: 80%;
                            //     height: 60px;
                            // }
                        }

                        // 立即留言
                        > button {
                            margin-left: 40%;
                            width: 100px;
                            height: 25px;
                            font-size: 11px;
                            border: 0;
                            color: #fff;
                            border-radius: 4px;
                            background-color: #00479d;
                        }
                    }
                }
            }
            /*联系我们 end*/
        }
    }
}

//当屏幕最大宽度为767px
@media screen and (max-width: 767px) {
}
</style>
