<template>
    <!-- 通告左侧内容 -->
    <div class="annunciateLeft">
        <div class="survey-content-left">
            <ul>
                <!-- 左侧内容区域列表 -->
                <template v-if="JSON.stringify(noticesList) != ''">
                    <li
                        :key="indexd"
                        v-for="(itemd, indexd) in noticesList.data"
                        @click="goAnnunciateDetail(indexd, itemd.id)"
                    >
                        <div class="survey-item-img">
                            <img :src="itemd.image" alt="" />
                        </div>
                        <div class="survey-item-text">
                            <div class="survey-item-text1">
                                {{ itemd.title }}
                            </div>
                            <div class="survey-item-text2">
                                {{ itemd.description }}
                            </div>
                            <div class="survey-item-text3">
                                时间：{{ itemd.createtime }}
                            </div>
                        </div>
                    </li>
                </template>

                <!-- 左侧内容区域分页 -->
                <div class="pageDown">
                    <Pagination
                        v-if="JSON.stringify(noticesList) != ''"
                        :pageNo="searchParams.page"
                        :pageSize="searchParams.pageSize"
                        :total="total"
                        :continues="5"
                        @getPageNo="getPageEv"
                    />
                </div>
            </ul>
        </div>
    </div>
</template>

<script>
import Pagination from "@/components/Pagination";
export default {
    // 组件名称
    name: "annunciateLeft",
    // 组件参数 接收来自父组件的数据
    props: {
        noticesList: {
            type: Object,
            default: {},
        },
    },
    // 组件状态值
    data() {
        return {
            searchParams: {
                page: 1,
                pageSize: 5,
            },
            total: 10,
        };
    },
    // 计算属性
    computed: {},
    // 侦听器
    watch: {
        // 定义 total 侦听器，指向一个配置对象
        noticesList: {
            // handler 属性用来定义侦听器的 function 处理函数
            handler(newVal) {
                this.searchParams.page = newVal.current_page;
                if (newVal.per_page) {
                    this.searchParams.pageSize = newVal.per_page - 0;
                }
                if (newVal.total) {
                    this.total = newVal.total;
                }
            },
            // immediate 属性用来声明此侦听器，是否在页面初次加载完毕后立即调用
            immediate: true,
        },
    },
    // 组件方法
    created() {},
    methods: {
        // 分页触发事件
        getPageEv(index) {
            this.searchParams.page = index;
            this.$emit("page", index);
        },
        // 跳转通知通告详情页
        goAnnunciateDetail(index, id) {
            console.log(id);
            this.$router.push({
                path: `/about/annunciateDetail/${index}`,
                query: {
                    ids: id,
                },
            });
        },
    },
    mounted() {
        console.log(this.noticesList, "222222222222222");
    },
    // 局部注册的组件
    components: {
        Pagination,
    },
};
</script>

<style lang="scss" scoped>
.annunciateLeft {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    // border: 1px solid red;

    // 内容区域左侧
    .survey-content-left {
        width: 100% !important;
        // height: 1000px;
        // border: 1px solid red;
        > ul {
            list-style: none;

            // 左侧内容区域列表
            li {
                width: 100%;
                height: 80px;
                display: flex;
                padding: 12px 0;
                cursor: pointer;
                border-bottom: 0.5px solid #cacaca;

                .survey-item-img {
                    width: 27%;
                    height: 100%;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }

                .survey-item-text {
                    width: 70%;
                    display: flex;
                    flex-direction: column;
                    margin-left: auto;
                    font-size: 10px;
                    .survey-item-text1 {
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }

                    .survey-item-text2 {
                        margin-top: 20px;
                        font-size: 8px;
                        line-height: 12px;
                        color: #999999;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2; //行数
                        -webkit-box-orient: vertical;
                    }

                    .survey-item-text3 {
                        margin-top: auto;
                        font-size: 8px;
                        color: #999999;
                    }
                }
            }
        }
    }
}

//当屏幕最小宽度为992px
@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .annunciateLeft {
        width: 100%;
        height: 100%;
        margin: 0 auto;

        // 内容区域左侧
        .survey-content-left {
            width: 100%;
            width: 100%;
            // height: 1000px;
            // border: 1px solid red;
            > ul {
                width: 100%;
                list-style: none;

                // 左侧内容区域列表
                li {
                    width: 100%;
                    height: 60px;
                    display: flex;
                    padding: 13px 0 10px 0;
                    cursor: pointer;
                    border-bottom: 0.5px solid #cacaca;

                    .survey-item-img {
                        width: 27%;
                        height: 100%;

                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }

                    .survey-item-text {
                        width: 70%;
                        display: flex;
                        flex-direction: column;
                        margin-left: auto;
                        font-size: 10px;
                        .survey-item-text1 {
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }

                        .survey-item-text2 {
                            margin-top: 10px;
                            font-size: 8px;
                            line-height: 12px;
                            color: #999999;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 2; //行数
                            -webkit-box-orient: vertical;
                        }

                        .survey-item-text3 {
                            margin-top: auto;
                            font-size: 8px;
                            color: #999999;
                        }
                    }
                }
            }
        }
    }
}

//当屏幕宽度 最小为768px 最大为991px时
@media screen and (min-width: 768px) and (max-width: 1200px) {
    .annunciateLeft {
        width: 100%;
        height: 100%;
        margin: 0 auto;

        // 内容区域左侧
        .survey-content-left {
            width: 100%;
            // height: 1000px;
            // border: 1px solid red;
            > ul {
                list-style: none;

                // 左侧内容区域列表
                li {
                    width: 100%;
                    height: 55px;
                    display: flex;
                    padding: 8px 0;
                    border-bottom: 0.5px solid #cacaca;

                    .survey-item-img {
                        width: 27%;
                        height: 100%;

                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }

                    .survey-item-text {
                        width: 70%;
                        display: flex;
                        flex-direction: column;
                        margin-left: auto;
                        font-size: 9px;
                        .survey-item-text1 {
                        }

                        .survey-item-text2 {
                            margin-top: 10px;
                            font-size: 7px;
                            color: #999999;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 2; //行数
                            -webkit-box-orient: vertical;
                        }

                        .survey-item-text3 {
                            margin-top: auto;
                            font-size: 8px;
                            color: #999999;
                        }
                    }
                }
            }
        }
    }
}

//当屏幕最大宽度为767px
@media screen and (max-width: 767px) {
}
</style>
