<template>
    <div class="mHonor">
        <Mheader :currentIndex="2"></Mheader>

        <!-- 轮播图 -->
        <div class="mIndex-swiper">
            <MeSwiper></MeSwiper>
        </div>

        <div class="introduces-wrap">
            <div class="introduces-cont">
                <div class="introduces-cont-title">
                    <div>{{ honorsTitle.name }}</div>
                    <div>{{ honorsTitle.enname }}</div>
                </div>
                <div class="introduces-cont-tp">
                    <ul>
                        <li :key="indexb" v-for="(itemb, indexb) in honorsList">
                            <img :src="itemb.image" />
                        </li>
                    </ul>
                </div>
            </div>
            <div class="pageDown">
                <Pagination
                    :pageNo="searchParams.page"
                    :pageSize="searchParams.pageSize"
                    :total="total"
                    :continues="6"
                    @getPageNo="getPageEv"
                />
            </div>  
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import Mheader from "../../../components/Mheader.vue";
import Footer from "../../../components/Footer";
import Pagination from "@/components/Pagination";
import MeSwiper from "../../../components/Member/swiper.vue";
import { companyHonors } from "../../../network/data"; //
export default {
    // 组件名称
    name: "introduces",
    // 组件参数 接收来自父组件的数据
    props: {},
    // 组件状态值
    data() {
        return {
            // 获取当前导航栏id
            menu_id: "",
            // 公司荣誉标题
            honorsTitle: {},
            // 公司荣誉照片
            honorsList: [],
            searchParams: {
                page: 1,
                pageSize: 6,
            },
            total: 10,
        };
    },
    // 计算属性
    computed: {},
    // 侦听器
    watch: {},
    // 组件方法
    created() {
        if (this.$route.query != "") {
            this.menu_id = this.$route.query.menu_id;

            // 获取公司荣誉数据
            this.companyHonorsRe();
        }
    },
    methods: {
        // 获取公司荣誉数据
        companyHonorsRe() {
            let data = {
                id: sessionStorage.getItem("id"),
                menuid: this.menu_id,
                page: this.searchParams.page,
                pagesize: this.searchParams.pageSize,
            };
            companyHonors(data).then((res) => {
                if (res.code == 1) {
                    console.log(res.data);
                    this.honorsTitle = res.data.menu;
                    this.honorsList = res.data.list.data;
                    this.total = res.data.list.total;
                } else {
                    this.message(res.msg);
                }
            });
        },
        getPageEv(e) {
            this.searchParams.page = e;
            this.companyHonorsRe();
        },
    },
    mounted() {},
    // 局部注册的组件
    components: {
        Mheader,
        Footer,
        MeSwiper,
        Pagination,
    },
};
</script>

<style lang="scss">
.mHonor {
    width: 100%;

    img {
        display: block;
        height: 100%;
        width: 100%;
    }

    // 版心
    .introduces-wrap {
        width: 70%;
        margin: 0 auto;

        // 内容区域
        .introduces-cont {
            width: 100%;
            height: 300px;
            margin-top: 20px;
            // border: 1px solid red;

            // 公司荣誉标题
            .introduces-cont-title {
                text-align: center;
                font-size: 12px;

                > div:nth-child(1) {
                    font-size: 12px;
                }
                > div:nth-child(2) {
                    font-size: 10px;
                    color: #999999;
                }
            }

            // 公司荣誉图片
            .introduces-cont-tp {
                width: 100%;
                height: 100%;
                margin-top: 20px;
                // border: 1px solid red;

                > ul {
                    width: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    list-style: none;

                    li {
                        width: 31%;
                        height: 110px;
                        margin-bottom: 30px;
                        box-shadow: 2px 2px 10px #e1e0e0;
                        // border: 1px solid red;
                    }
                }
            }
        }
        .pageDown {
        display: flex;
        justify-content: center;
        margin: 20px;
        text-align: center;
        // margin-top: -200px;
    }
    }
    
}

//当屏幕最小宽度为992px
@media screen and (min-width: 1200px) {
}

//当屏幕宽度 最小为768px 最大为991px时
@media screen and (min-width: 768px) and (max-width: 1200px) {
    .mHonor {
        width: 100%;

        img {
            display: block;
            height: 100%;
            width: 100%;
        }

        // 版心
        .introduces-wrap {
            width: 80%;
            margin: 0 auto;

            // 内容区域
            .introduces-cont {
                width: 100%;
                height: 280px;
                margin-top: 20px;
                // border: 1px solid red;

                // 公司荣誉标题
                .introduces-cont-title {
                    text-align: center;
                    font-size: 12px;

                    > div:nth-child(1) {
                        font-size: 12px;
                    }
                    > div:nth-child(2) {
                        font-size: 10px;
                    }
                }

                // 公司荣誉图片
                .introduces-cont-tp {
                    width: 100%;
                    height: 100%;
                    margin-top: 20px;
                    // border: 1px solid red;

                    > ul {
                        width: 100%;
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: space-between;
                        list-style: none;

                        li {
                            width: 31%;
                            height: 80px;
                            margin-bottom: 15px;
                            box-shadow: 2px 2px 10px #e1e0e0;
                            // border: 1px solid red;
                        }
                    }
                }
            }
        }
    }
}

//当屏幕最大宽度为767px
@media screen and (max-width: 767px) {
}
</style>
