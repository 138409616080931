<template>
    <div>
        <!-- 关于学会tap -->
        <div class="survey-nav">
            <div class="survey-nav-title">
                <div class="survey-title-cn">关于学会</div>
                <div class="survey-title-sn">About the Institute</div>
            </div>
            <ul>
                <li
                    :key="index"
                    v-for="(item, index) in surveyTap"
                    @click="surveyTapEv(index)"
                    :class="currentIndex == index ? 'active' : ''"
                >
                    {{ item.name }}
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    // 组件名称
    name: "nav",
    // 组件参数 接收来自父组件的数据
    props: {},
    // 组件状态值
    data() {
        return {
            // 当前tap索引
            currentIndex: 0,
            // 关于学会tap数据
            surveyTap: [
                {
                    name: "学会概况",
                },
                {
                    name: "学会章程",
                },
                {
                    name: "学会工作",
                },
                {
                    name: "分支机构",
                },
                {
                    name: "入会申请",
                },
                {
                    name: "通知通告",
                },
                {
                    name: "学会要闻",
                },
                {
                    name: "企业会员",
                },
            ],
        };
    },
    // 计算属性
    computed: {},
    // 侦听器
    watch: {},
    // 组件方法
    created() {},
    methods: {
        surveyTapEv(index) {
            // this.surveyData = [];
            this.currentIndex = index;
        },
    },
    mounted() {},
    // 局部注册的组件
    components: {},
};
</script>

<style scoped lang="scss">
/*关于学会tap start*/
.survey-nav {
    display: flex;
    align-items: center;
    width: 1200px;
    margin: 0 auto;
    padding: 15px 0;
    box-sizing: border-box;
    border-bottom: 1px solid #e4e4e4;

    .survey-nav-title {
        display: flex;
        align-items: center;

        .survey-title-cn {
            font-size: 24px;
            color: #0056b0;
            margin-right: 15px;
        }

        .survey-title-sn {
            height: 20px;
            font-size: 12px;
            color: #fff;
            padding: 0 6px;
            background-color: #0056b0;
        }
    }

    > ul {
        list-style: none;
        display: flex;

        .active {
            color: #0068d5;
        }

        li {
            cursor: pointer;
            margin-left: 15px;
            padding-left: 15px;
            border-left: 1px solid #e9e9e9;
        }
    }
}
/*关于学会tap end*/
</style>
