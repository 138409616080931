<template>
    <div class="invite">
        <Mheader :currentIndex="5"></Mheader>

        <!-- 轮播图 -->
        <div class="mIndex-swiper">
            <MeSwiper></MeSwiper>
        </div>

        <!-- 版心 -->
        <div class="introduces-wrap">
            <!-- 公司招聘 -->
            <div class="introduces-cont">
                <!-- 公司招聘标题 -->
                <div class="introduces-cont-title">
                    <div>{{ jobsTitle.name }}</div>
                    <div>{{ jobsTitle.enname }}</div>
                </div>
                <!-- 公司招聘内容区域 -->
                <div class="introduces-cont-box">
                    <!-- 招聘信息文字 -->
                    <div class="introduces-box-h">招聘信息</div>
                    <!-- 招聘信息内容区域副标题 -->
                    <ul>
                        <li>招聘岗位</li>
                        <li>招聘名额</li>
                        <li>就业城市</li>
                        <li>学历要求</li>
                        <li>工作经验</li>
                        <li>职位状态</li>
                        <li>发布时间</li>
                    </ul>
                    <!-- 招聘信息展开列表 -->
                    <ul>
                        <li
                            :key="index"
                            @click="isUnfold(item, index)"
                            v-for="(item, index) in inviteData"
                        >
                            <div class="invite-item-icon" v-if="item.current">
                                <i class="el-icon-close"></i>
                            </div>
                            <div class="invite-item-icon" v-else>
                                <i class="el-icon-plus"></i>
                            </div>

                            <!-- 展开区域 -->
                            <div
                                class="incite-item-text"
                                :class="item.current == true ? 'activess' : ''"
                            >
                                <!-- 展开列表头部标题 -->
                                <div
                                    class="invite-item-h"
                                    :class="
                                        item.current == true ? 'active' : ''
                                    "
                                >
                                    <span>{{ item.title }}</span>
                                    <span>{{ item.jobnum }}</span>
                                    <span>{{ item.address }}</span>
                                    <span>{{ item.education }}</span>
                                    <span>{{ item.workyear }}</span>
                                    <span>{{
                                        item.status == 0
                                            ? "结束招聘"
                                            : "开始招聘"
                                    }}</span>
                                    <span>{{ item.createtime }}</span>
                                </div>

                                <!-- 展开列表隐藏内容 -->
                                <div
                                    class="invite-list-content"
                                    v-show="item.current"
                                >
                                    <div v-html="item.content"></div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <!-- 左侧内容区域分页 -->
            <div class="pageDown">
                <Pagination
                    :pageNo="searchParams.page"
                    :pageSize="searchParams.pageSize"
                    :total="total"
                    :continues="5"
                    @getPageNo="getPageEv"
                />
            </div>
        </div>

        <Footer></Footer>
    </div>
</template>

<script>
import Mheader from "../../../components/Mheader.vue";
import Footer from "../../../components/Footer";
import Pagination from "@/components/Pagination";
import MeSwiper from "../../../components/Member/swiper.vue";
import { companyJobs } from "../../../network/data";
export default {
    // 组件名称
    name: "introduces",
    // 组件参数 接收来自父组件的数据
    props: {},
    // 组件状态值
    data() {
        return {
            // 展开列表数据
            inviteData: [],
            // 分页
            searchParams: {
                page: 1,
                pageSize: 6,
            },
            total: 10,
            // 当前导航栏id
            menu_id: "",
            // 招聘数据
            jobsList: [],
            // 招聘标题
            jobsTitle: {},
        };
    },
    // 计算属性
    computed: {},
    // 侦听器
    watch: {},
    // 组件方法
    created() {
        if (this.$route.query != "") {
            this.menu_id = this.$route.query.menu_id;

            // 获取招聘数据
            this.companyJobsRe();
        }
    },
    methods: {
        // 点击展开事件
        isUnfold(item, index) {
            this.$nextTick(function () {
                this.inviteData.forEach((item) => {
                    // 判断所有list中的item与当前item状态是否一样，如果不一样的所有list改为关闭状态;
                    if (item.current !== this.inviteData[index].current) {
                        item.current = false;
                    }
                });
                item.current = !item.current;
            });
            this.$forceUpdate();
        },

        // 获取企业会员招聘数据
        companyJobsRe() {
            let data = {
                id: sessionStorage.getItem("id"),
                menuid: this.menu_id,
                page: this.searchParams.page,
                pagesize: this.searchParams.pageSize,
            };
            companyJobs(data).then((res) => {
                if (res.code == 1) {
                    // console.log(res.data);
                    this.searchParams.page = res.data.list.current_page;
                    this.searchParams.pageSize = res.data.list.per_page;
                    this.total = res.data.list.total;
                    this.inviteData = res.data.list.data;
                    this.jobsTitle = res.data.menu;
                    this.inviteData.forEach((item) => {
                        item.current = false;
                    });
                } else {
                    this.message(res.msg);
                }
            });
        },
        // 分页点击事件
        getPageEv(e) {
            this.searchParams.page = e;
            this.companyJobsRe();
        },
    },
    mounted() {},
    // 局部注册的组件
    components: {
        Mheader,
        Footer,
        Pagination,
        MeSwiper,
    },
};
</script>

<style lang="scss">
.invite {
    width: 100%;

    img {
        display: block;
        height: 100%;
        width: 100%;
    }

    // 版心
    .introduces-wrap {
        width: 70%;
        margin: 0 auto;

        // 公司招聘
        .introduces-cont {
            width: 100%;
            // height: 450px;
            margin-top: 20px;
            margin-bottom: 40px;
            // border: 1px solid red;

            // 公司招聘标题
            .introduces-cont-title {
                text-align: center;

                > div:nth-child(1) {
                    font-size: 12px;
                }

                > div:nth-child(2) {
                    font-size: 10px;
                    color: #999999;
                }
            }

            // 公司招聘内容区域
            .introduces-cont-box {
                margin: 20px 0;

                // 招聘信息文字
                .introduces-box-h {
                    padding-bottom: 5px;
                    font-size: 12px;
                    color: #00479d;
                    border-bottom: 1px solid #efefef;
                }
                // 招新信息内容区域副标题
                > ul:nth-child(2) {
                    width: 85%;
                    list-style: none;
                    display: flex;
                    margin-left: auto;
                    padding: 15px 0px;
                    font-size: 12px;
                    // border: 1px solid red;

                    li {
                        width: 20%;
                        // border: 1px solid red;
                    }
                }

                // 招新信息展开列表
                > ul:nth-child(3) {
                    list-style: none;
                    width: 100%;
                    font-size: 12px;
                    // border: 1px solid red;

                    li {
                        display: flex;
                        // height: 30px;
                        margin-bottom: 10px;

                        // 图标
                        .invite-item-icon {
                            width: 7%;
                            line-height: 30px;
                            text-align: center;
                            font-size: 14px;
                            // border: 1px solid red;
                        }

                        // 展开区域
                        .activess {
                            box-sizing: border-box;
                            border: 2px solid blue;
                        }

                        .incite-item-text {
                            display: flex;
                            flex-direction: column;
                            width: 100%;
                            background-color: #b3b3b3;

                            // 展开列表头部标题
                            .active {
                                color: #fff;
                                background-color: #00479d;
                            }

                            .invite-item-h {
                                display: flex;
                                align-items: center;
                                height: 30px;
                                width: 92%;
                                margin-top: -1px;
                                padding-left: 8%;
                                // border: 1px solid red;

                                span {
                                    display: block;
                                    text-align: center;
                                    width: 80px;
                                    // border: 1px solid red;
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    // border: 1px solid red;
                                }
                            }

                            // 展开列表隐藏内容
                            .invite-list-content {
                                width: 92%;
                                font-size: 8px;
                                line-height: 20px;
                                padding-left: 8%;
                                background-color: #fff;
                            }
                        }
                    }
                }
            }
        }

        .pageDown {
            display: flex;
            justify-content: center;
            margin: 20px;
            text-align: center;
        }
    }
}

//当屏幕最小宽度为992px
@media screen and (min-width: 1200px) {
}

//当屏幕宽度 最小为768px 最大为991px时
@media screen and (min-width: 768px) and (max-width: 1200px) {
    .invite {
        width: 100%;

        img {
            display: block;
            height: 100%;
            width: 100%;
        }

        // 版心
        .introduces-wrap {
            width: 70%;
            margin: 0 auto;

            // 公司招聘
            .introduces-cont {
                width: 100%;
                // height: 450px;
                margin-top: 20px;
                margin-bottom: 40px;
                // border: 1px solid red;

                // 公司招聘标题
                .introduces-cont-title {
                    text-align: center;

                    > div:nth-child(1) {
                        font-size: 12px;
                    }

                    > div:nth-child(2) {
                        font-size: 10px;
                    }
                }

                // 公司招聘内容区域
                .introduces-cont-box {
                    // 招聘信息文字
                    .introduces-box-h {
                        padding-bottom: 5px;
                        font-size: 12px;
                        color: #00479d;
                        border-bottom: 1px solid #cbcbcb;
                    }
                    // 招新信息内容区域副标题
                    > ul:nth-child(2) {
                        width: 85%;
                        list-style: none;
                        display: flex;
                        margin-left: auto;
                        padding: 0px 0px;
                        font-size: 9px;
                        // border: 1px solid red;

                        li {
                            width: 20%;
                            // border: 1px solid red;
                        }
                    }

                    // 招新信息展开列表
                    > ul:nth-child(3) {
                        list-style: none;
                        width: 100%;
                        font-size: 12px;
                        // border: 1px solid red;

                        li {
                            display: flex;
                            // height: 30px;
                            margin-bottom: 10px;

                            // 图标
                            .invite-item-icon {
                                width: 7%;
                                line-height: 20px;
                                text-align: center;
                                font-size: 14px;
                                // border: 1px solid red;
                            }

                            // 展开区域
                            .activess {
                                border: 2px solid blue;
                            }

                            .incite-item-text {
                                display: flex;
                                flex-direction: column;
                                width: 100%;
                                background-color: #b3b3b3;

                                // 展开列表头部标题
                                .active {
                                    color: #fff;
                                    background-color: #00479d;
                                }

                                .invite-item-h {
                                    display: flex;
                                    align-items: center;
                                    height: 20px;
                                    width: 92%;
                                    padding-left: 8%;

                                    span {
                                        display: block;
                                        width: 80px;
                                        font-size: 10px;
                                        // border: 1px solid red;
                                        white-space: nowrap;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        // border: 1px solid red;
                                    }
                                }

                                // 展开列表隐藏内容
                                .invite-list-content {
                                    width: 92%;
                                    padding-left: 8%;
                                    font-size: 8px !important;
                                    background-color: #fff;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

//当屏幕最大宽度为767px
@media screen and (max-width: 767px) {
}
</style>
