<template>
    <!-- 助企活动---下一级 -->
    <div class="activitysub">
        <Header></Header>
        <Nav></Nav>
        <div class="activityDetails-wrap">
            <!-- 顶部图片 -->
            <Advertising></Advertising>
            <!-- <div class="index-headImg">
                <div class="index-headImg-left">
                    <img src="../../../assets/pc/Index/headL.png" alt="" />
                </div>
                <div class="index-headImg-right">
                    <img src="../../../assets/pc/Index/headR.png" alt="" />
                </div>
            </div> -->

            <!-- 关于学会tap -->
            <div class="survey-nav">
                <!-- <Nav></Nav> -->
                <div class="survey-nav-title">
                    <div class="survey-title-cn">助企名家</div>
                    <div class="survey-title-sn">About the Institute</div>
                </div>
                <ul>
                    <li
                        :key="index"
                        v-for="(item, index) in machiningTap"
                        @click="machiningTapEv(item, index)"
                        :class="currentIndex == index ? 'active' : ''"
                    >
                        {{ item.name }}
                    </li>
                </ul>
            </div>

            <!-- 内容区域 -->
            <div class="survey-cont">
                <div class="survey-content">
                    <div class="survey-content-left">
                        <div class="left">
                            <div class="left-card">
                                <ul class="ul2">
                                    <li
                                        v-for="(itemb, indexb) in classify"
                                        :key="indexb"
                                        @click="detailsDetail(itemb.id)"
                                    >
                                        <div class="science-card">
                                            <!-- 助企活动图片 -->
                                            <img
                                                src="../../../assets/pc/Index/img1.png"
                                                alt=""
                                            />
                                            <!-- 助企活动标题 -->
                                            <div class="science-card3-title">
                                                {{
                                                    itemb.title
                                                }}你好明天你好明天你好明天你好明天你好明天你好明天你好明天
                                            </div>
                                            <!-- 助企活动描述 -->
                                            <div class="science-card3-ms">
                                                你好明天你好明天你好明天你好明天你好明天你好明天你好明天你好明天你好明天你好明天你好明天你好明天你好明天你好明天你好明天你好明天你好明天你好明天你好明天你好明天你好明天
                                            </div>
                                            <!-- 助企活动底部样式 -->
                                            <div class="science-card3-footer">
                                                <div
                                                    class="science-card3-footer1"
                                                >
                                                    {{ itemb.flagname }}
                                                </div>
                                                <div
                                                    class="science-card3-footer2"
                                                >
                                                    <img
                                                        src="../../../assets/pc/Index/clock.png"
                                                        alt=""
                                                    />
                                                    <div>
                                                        {{ itemb.createtime }}
                                                    </div>
                                                </div>
                                                <div
                                                    class="science-card3-footer3"
                                                >
                                                    <img
                                                        src="../../../assets/pc/Index/location.png"
                                                        alt=""
                                                    />
                                                    <div>*****</div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>

                                    <!-- 左侧内容区域分页 -->
                                    <div class="pageDowns">
                                        <Pagination
                                            v-if="JSON.stringify() != ''"
                                            :pageNo="searchParams.page"
                                            :pageSize="searchParams.pageSize"
                                            :total="total"
                                            :continues="5"
                                            @getPageNo="getPageEv"
                                        />
                                    </div>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="survey-content-right">
                        <Right></Right>
                    </div>
                </div>
            </div>
            <!-- 内容区域 -->
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import Header from "@/components/Header.vue"; //头部
import Footer from "@/components/Footer.vue"; //尾部
import Right from "@/components/Right.vue";
import Pagination from "@/components/Pagination";
import Nav from "@/components/Nav.vue"; //导航
import { catelist, detailactivitylist } from "../../../network/data";
import Advertising from "@/components/Advertising.vue"; //广告图
// import Left2 from "@/components/Science/Left2.vue"; //左侧
export default {
    // 组件名称
    name: "activityDetails",
    // 组件参数 接收来自父组件的数据
    props: {},
    // 组件状态值
    data() {
        return {
            searchParams: {
                page: 1,
                pageSize: 5,
            },
            total: 10,
            // 当前tap索引
            currentIndex: 0,
            // 当前导航栏id
            cateid: "1",
            // 助企活动分类列表
            classify: [],
            // 关于学会tap数据
            machiningTap: [],
        };
    },
    // 计算属性
    computed: {},
    // 侦听器
    watch: {},
    // 组件方法
    created() {
        // 获取助企活动详情页分类数据
        this.navlistifyRe();
        // 获取助企活动分类列表
        this.detailactivitylistRe();
    },
    methods: {
        machiningTapEv(item, index) {
            this.currentIndex = index;
            this.cateid = item.id;
            this.detailactivitylistRe();
        },
        // 分页触发事件
        getPageEv(index) {
            this.searchParams.page = index;
            this.detailactivitylistRe();
        },
        // 跳转到助企下级界面
        detailsDetail(id) {
            this.$router.push({
                path: "/science/ActivityDetails",
                query: {
                    id: id,
                },
            });
        },
        // 获取助企活动详情页分类
        navlistifyRe() {
            catelist().then((res) => {
                if (res.code == 1) {
                    // console.log(res.data);
                    this.machiningTap = res.data;
                } else {
                    this.message(res.msg);
                }
            });
        },
        // 获取助企活动详情分类列表
        detailactivitylistRe() {
            let data = {
                cateid: this.cateid,
                page: this.searchParams.page,
                pagesize: this.searchParams.pageSize,
            };
            detailactivitylist(data).then((res) => {
                if (res.code == 1) {
                    // console.log(res.data);
                    this.classify = res.data.list.data;
                    this.searchParams.page = res.data.list.current_page;
                    this.searchParams.pageSize = res.data.list.per_page;
                    this.total = res.data.list.total;
                } else {
                    this.message(res.msg);
                }
            });
        },
    },
    mounted() {},
    // 局部注册的组件
    components: {
        Header,
        Footer,
        Right,
        Nav,
        Pagination,
        Advertising,
        // Left2,
    },
};
</script>

<style scoped lang="scss">
.activitysub {
    width: 100%;

    .activityDetails-wrap {
        width: 70%;
        margin: 0 auto;
        margin-bottom: 40px;

        /*顶部图片start*/
        .index-headImg {
            position: relative;
            z-index: 0;
            width: 100%;
            height: 50px;
            margin-top: 15px;
            display: flex;
            justify-content: space-between;

            // 左侧图片
            .index-headImg-left {
                width: 48%;
                height: 100%;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            // 右侧图片
            .index-headImg-right {
                width: 48%;
                height: 100%;

                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
        /*顶部图片end*/

        /*关于学会tap start*/
        .survey-nav {
            display: flex;
            align-items: center;
            width: 100%;
            padding: 10px 0;
            box-sizing: border-box;
            border-bottom: 1px solid #e4e4e4;
            // border: 1px solid red;

            .survey-nav-title {
                width: 30%;
                display: flex;
                align-items: center;
                // border: 1px solid red;

                .survey-title-cn {
                    text-align: center;
                    width: 40px;
                    font-size: 10px;
                    color: #0056b0;
                    margin-right: 15px;
                }

                .survey-title-sn {
                    height: 10px;
                    line-height: 10px;
                    font-size: 6px;
                    color: #fff;
                    padding: 0 6px;
                    background-color: #0056b0;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }

            > ul {
                width: 70%;
                list-style: none;
                display: flex;
                // border: 1px solid red;

                .active {
                    color: #0068d5;
                }

                li {
                    cursor: pointer;
                    margin-left: 10px;
                    padding-left: 10px;
                    font-size: 10px;
                    border-left: 1px solid #e9e9e9;
                }
            }
        }
        /*关于学会tap end*/

        /*内容区域 start*/
        .survey-cont {
            width: 100%;

            .survey-content {
                display: flex;
                width: 100%;
                // height: 300px;
                // border: 1px solid red;

                // 内容区域左侧
                .survey-content-left {
                    width: 68%;
                    height: 100%;
                    // border: 1px solid blueviolet;

                    .left {
                        width: 100%;

                        .left-card {
                            width: 100%;
                            // 助企名家start
                            .ul2 {
                                display: flex;
                                justify-content: space-between;
                                flex-wrap: wrap;
                                margin-top: 10px;
                                list-style: none;

                                li {
                                    width: 48%;
                                    padding: 0;
                                    box-sizing: border-box;
                                    margin-bottom: 10px;
                                    border: 0.5px solid #e6e6e6;
                                    // border: 1px solid red;

                                    .science-card {
                                        width: 100%;
                                        display: flex;
                                        flex-direction: column;

                                        // 助企活动图片
                                        > img {
                                            width: 100%;
                                            height: 90px;
                                        }

                                        // 助企活动标题
                                        .science-card3-title {
                                            padding: 3px;
                                            font-size: 9px;
                                            white-space: nowrap;
                                            overflow: hidden;
                                            text-overflow: ellipsis;
                                        }

                                        // 助企活动描述
                                        .science-card3-ms {
                                            height: 15px;
                                            padding: 3px;
                                            padding-bottom: 5px;
                                            font-size: 7px;
                                            color: #999999;
                                            overflow: hidden;
                                            text-overflow: ellipsis;
                                            display: -webkit-box;
                                            -webkit-line-clamp: 2; //行数
                                            -webkit-box-orient: vertical;
                                            border-bottom: 0.5px dashed #e6e6e6;
                                        }

                                        // 助企活动底部样式
                                        .science-card3-footer {
                                            display: flex;
                                            margin-top: 5px;
                                            // justify-content: space-between;
                                            padding: 4px;
                                            .science-card3-footer1 {
                                                text-align: center;
                                                width: 30px;
                                                height: 13px;
                                                line-height: 13px;
                                                margin-right: auto;
                                                color: #fff;
                                                font-size: 6px;
                                                background-color: #333333;
                                            }

                                            .science-card3-footer2 {
                                                display: flex;
                                                align-items: center;

                                                > img {
                                                    width: 8px;
                                                    height: 8px;
                                                }

                                                > div {
                                                    margin-left: 3px;
                                                    font-size: 7.5px;
                                                    color: #999999;
                                                }
                                            }

                                            .science-card3-footer3 {
                                                display: flex;
                                                align-items: center;
                                                margin-left: 5px;

                                                > img {
                                                    width: 8px;
                                                    height: 8px;
                                                }

                                                > div {
                                                    margin-left: 3px;
                                                    font-size: 7.5px;
                                                    color: #999999;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            // 助企名家end

                            // 左侧内容区域分页
                            .pageDowns {
                                width: 100%;
                                margin: 20px auto;
                                text-align: start;
                                // border: 10px solid blue;
                            }
                        }
                    }
                }

                // 内容区域右侧
                .survey-content-right {
                    margin-left: auto;
                    width: 30%;
                    // border: 1px solid red;
                }
            }
        }

        /*内容区域 end*/
    }
}

//当屏幕最小宽度为992px
@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .activitysub {
        width: 100%;

        .activityDetails-wrap {
            width: 70%;
            margin: 0 auto;
            margin-bottom: 40px;
            // border: 1px solid red;

            /*顶部图片start*/
            .index-headImg {
                position: relative;
                z-index: 0;
                width: 100%;
                height: 50px;
                margin-top: 15px;
                display: flex;
                justify-content: space-between;

                // 左侧图片
                .index-headImg-left {
                    width: 48%;
                    height: 100%;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }

                // 右侧图片
                .index-headImg-right {
                    width: 48%;
                    height: 100%;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
            /*顶部图片end*/

            /*关于学会tap start*/
            .survey-nav {
                display: flex;
                align-items: center;
                width: 100%;
                padding: 10px 0;
                box-sizing: border-box;
                border-bottom: 1px solid #e4e4e4;

                .survey-nav-title {
                    width: 30%;
                    display: flex;
                    align-items: center;
                    // border: 1px solid red;

                    .survey-title-cn {
                        text-align: center;
                        width: 40px;
                        font-size: 10px;
                        color: #0056b0;
                        margin-right: 15px;
                    }

                    .survey-title-sn {
                        height: 10px;
                        line-height: 10px;
                        font-size: 6px;
                        color: #fff;
                        padding: 0 6px;
                        background-color: #0056b0;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }

                > ul {
                    width: 70%;
                    list-style: none;
                    display: flex;
                    // border: 1px solid red;

                    .active {
                        color: #0068d5;
                    }

                    li {
                        cursor: pointer;
                        text-align: center;
                        width: 20px;
                        margin-left: 10px;
                        padding-left: 10px;
                        font-size: 7.5px;
                        border-left: 1px solid #e9e9e9;
                    }
                }
            }
            /*关于学会tap end*/

            /*内容区域 start*/
            .survey-cont {
                width: 100%;

                .survey-content {
                    display: flex;
                    width: 100%;
                    // height: 300px;
                    // border: 1px solid red;

                    // 内容区域左侧
                    .survey-content-left {
                        width: 68%;
                        height: 100%;
                        // border: 1px solid blueviolet;

                        .left {
                            width: 100%;

                            .left-card {
                                width: 100%;
                                // 助企名家start
                                .ul2 {
                                    display: flex;
                                    justify-content: space-between;
                                    flex-wrap: wrap;
                                    margin-top: 10px;
                                    list-style: none;

                                    li {
                                        width: 46%;
                                        padding: 0;
                                        box-sizing: border-box;
                                        margin-bottom: 10px;
                                        border: 0.5px solid #e6e6e6;
                                        // border: 1px solid red;

                                        .science-card {
                                            width: 100%;
                                            display: flex;
                                            flex-direction: column;

                                            // 助企活动图片
                                            > img {
                                                width: 100%;
                                                height: 60px;
                                            }

                                            // 助企活动标题
                                            .science-card3-title {
                                                padding: 3px;
                                                font-size: 9px;
                                            }

                                            // 助企活动描述
                                            .science-card3-ms {
                                                height: 15px;
                                                padding: 3px;
                                                padding-bottom: 5px;
                                                font-size: 7px;
                                                color: #999999;
                                                overflow: hidden;
                                                text-overflow: ellipsis;
                                                display: -webkit-box;
                                                -webkit-line-clamp: 2; //行数
                                                -webkit-box-orient: vertical;
                                                border-bottom: 0.5px dashed
                                                    #e6e6e6;
                                            }

                                            // 助企活动底部样式
                                            .science-card3-footer {
                                                display: flex;
                                                margin-top: 2px;
                                                // justify-content: space-between;
                                                padding: 4px;
                                                .science-card3-footer1 {
                                                    text-align: center;
                                                    width: 30px;
                                                    height: 13px;
                                                    line-height: 13px;
                                                    margin-right: auto;
                                                    color: #fff;
                                                    font-size: 6px;
                                                    background-color: #333333;
                                                }

                                                .science-card3-footer2 {
                                                    display: none;
                                                    align-items: center;

                                                    > img {
                                                        width: 8px;
                                                        height: 8px;
                                                    }

                                                    > div {
                                                        margin-left: 3px;
                                                        font-size: 7.5px;
                                                        color: #999999;
                                                    }
                                                }

                                                .science-card3-footer3 {
                                                    display: flex;
                                                    align-items: center;
                                                    margin-left: 5px;

                                                    > img {
                                                        width: 8px;
                                                        height: 8px;
                                                    }

                                                    > div {
                                                        margin-left: 3px;
                                                        font-size: 7.5px;
                                                        color: #999999;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                // 助企名家end

                                // 左侧内容区域分页
                                .pageDowns {
                                    width: 100%;
                                    margin: 20px auto;
                                    text-align: start;
                                    // border: 10px solid blue;
                                }
                            }
                        }
                    }

                    // 内容区域右侧
                    .survey-content-right {
                        margin-left: auto;
                        width: 30%;
                        // border: 1px solid red;
                    }
                }
            }

            /*内容区域 end*/
        }
    }
}

//当屏幕宽度 最小为768px 最大为991px时
@media screen and (min-width: 768px) and (max-width: 1200px) {
    .activitysub {
        width: 100%;

        .activityDetails-wrap {
            width: 70%;
            margin: 0 auto;
            margin-bottom: 40px;

            /*顶部图片start*/
            .index-headImg {
                position: relative;
                z-index: 0;
                width: 100%;
                height: 50px;
                margin-top: 15px;
                display: flex;
                justify-content: space-between;

                // 左侧图片
                .index-headImg-left {
                    width: 48%;
                    height: 100%;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }

                // 右侧图片
                .index-headImg-right {
                    width: 48%;
                    height: 100%;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
            /*顶部图片end*/

            /*关于学会tap start*/
            .survey-nav {
                display: flex;
                align-items: center;
                width: 100%;
                padding: 10px 0;
                box-sizing: border-box;
                border-bottom: 1px solid #e4e4e4;

                .survey-nav-title {
                    width: 30%;
                    display: flex;
                    align-items: center;
                    // border: 1px solid red;

                    .survey-title-cn {
                        width: 70px;
                        font-size: 10px;
                        color: #0056b0;
                        margin-right: 15px;
                    }

                    .survey-title-sn {
                        height: 10px;
                        line-height: 10px;
                        font-size: 6px;
                        color: #fff;
                        padding: 0 6px;
                        background-color: #0056b0;
                    }
                }

                > ul {
                    width: 70%;
                    list-style: none;
                    display: flex;
                    // border: 1px solid red;

                    .active {
                        color: #0068d5;
                    }

                    li {
                        cursor: pointer;
                        margin-left: 10px;
                        padding-left: 10px;
                        font-size: 10px;
                        border-left: 1px solid #e9e9e9;
                    }
                }
            }
            /*关于学会tap end*/

            /*内容区域 start*/
            .survey-cont {
                width: 100%;

                .survey-content {
                    display: flex;
                    width: 100%;
                    // height: 300px;
                    // border: 1px solid red;

                    // 内容区域左侧
                    .survey-content-left {
                        width: 68%;
                        height: 100%;
                        // border: 1px solid blueviolet;

                        .left {
                            width: 100%;

                            .left-card {
                                width: 100%;
                                // 助企名家start
                                .ul2 {
                                    display: flex;
                                    justify-content: space-between;
                                    flex-wrap: wrap;
                                    margin-top: 10px;
                                    list-style: none;

                                    li {
                                        width: 48%;
                                        // padding: 4px 4px;
                                        box-sizing: border-box;
                                        margin-bottom: 10px;
                                        border: 0.5px solid #e6e6e6;

                                        .science-card {
                                            width: 100%;
                                            display: flex;
                                            flex-direction: column;

                                            // 助企活动图片
                                            > img {
                                                width: 100%;
                                                height: 50px;
                                            }

                                            // 助企活动标题
                                            .science-card3-title {
                                                padding: 0px 3px;
                                                padding-top: 5px;
                                                font-size: 9px;
                                                overflow: hidden;
                                                text-overflow: ellipsis;
                                                display: -webkit-box;
                                                -webkit-line-clamp: 2; //行数
                                                -webkit-box-orient: vertical;
                                            }

                                            // 助企活动描述
                                            .science-card3-ms {
                                                height: 12px;
                                                line-height: 10px;
                                                margin-top: 3px;
                                                padding: 0 3px;
                                                padding-bottom: 10px;
                                                font-size: 7px;
                                                color: #999999;
                                                overflow: hidden;
                                                text-overflow: ellipsis;
                                                display: -webkit-box;
                                                -webkit-line-clamp: 2; //行数
                                                -webkit-box-orient: vertical;
                                                border-bottom: 0.5px dashed
                                                    #e6e6e6;
                                            }

                                            // 助企活动底部样式
                                            .science-card3-footer {
                                                display: flex;
                                                margin-top: 3px;
                                                // justify-content: space-between;
                                                padding: 4px;
                                                .science-card3-footer1 {
                                                    text-align: center;
                                                    width: 30px;
                                                    height: 13px;
                                                    line-height: 13px;
                                                    margin-right: auto;
                                                    color: #fff;
                                                    font-size: 6px;
                                                    background-color: #333333;
                                                }

                                                .science-card3-footer2 {
                                                    display: none !important;
                                                    display: flex;
                                                    align-items: center;

                                                    > img {
                                                        width: 8px;
                                                        height: 8px;
                                                    }

                                                    > div {
                                                        margin-left: 3px;
                                                        font-size: 7.5px;
                                                        color: #999999;
                                                        white-space: nowrap;
                                                        overflow: hidden;
                                                        text-overflow: ellipsis;
                                                    }
                                                }

                                                .science-card3-footer3 {
                                                    display: flex;
                                                    align-items: center;
                                                    margin-left: 5px;

                                                    > img {
                                                        width: 8px;
                                                        height: 8px;
                                                    }

                                                    > div {
                                                        margin-left: 3px;
                                                        font-size: 7.5px;
                                                        color: #999999;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                // 助企名家end

                                // 左侧内容区域分页
                                .pageDown {
                                    width: 100%;
                                    margin: 20px auto;
                                    text-align: start;
                                    // border: 10px solid blue;
                                }
                            }
                        }
                    }

                    // 内容区域右侧
                    .survey-content-right {
                        margin-left: auto;
                        width: 30%;
                        // border: 1px solid red;
                    }
                }
            }

            /*内容区域 end*/
        }
    }
}

//当屏幕最大宽度为767px
@media screen and (max-width: 767px) {
}
</style>
