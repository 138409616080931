<template>
    <!-- 首页搜素组件 -->
    <div class="search">
        <div class="search-box">
            <!-- 搜索左侧 -->
            <div class="search-box-left">
                <button>快讯</button>
                <div class="bs-sysMsg">
                    <div class="msg__content">
                        <el-carousel
                            height="20px"
                            direction="vertical"
                            indicator-position="none"
                            :autoplay="true"
                        >
                            <el-carousel-item
                                v-for="item in systemMsg"
                                :key="item.id"
                            >
                                <a :href="item.url" class="item">
                                    {{ item.title }}
                                </a>
                            </el-carousel-item>
                        </el-carousel>
                    </div>
                </div>
            </div>

            <!-- 搜索右侧 -->
            <div class="search-box-right">
                <div class="search-right-map">
                    <img src="../assets/pc/Index/map.png" alt="" />
                </div>
                <div class="search-right-input">
                    <input
                        type="text"
                        placeholder="搜索文章等关键字"
                        v-model="keyword"
                    />
                    <button @click="searchEv">搜索</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { alerts } from "../network/data";
export default {
    name: "search",
    data() {
        return {
            // 搜索数据
            systemMsg: [
                // { uuid: 1, title: 111111111 },
                // { uuid: 2, title: 2222222222 },
                // { uuid: 3, title: 333333333 },
            ],
            // 搜索字段
            keyword: "",
        };
    },
    computed: {},
    watch: {},
    created() {
        // 获取快讯数据
        this.alertsRe();
    },
    methods: {
        // 获取快讯数据
        alertsRe() {
            alerts().then((res) => {
                if (res.code == 1) {
                    this.systemMsg = res.data;
                } else {
                    this.$message(res.msg);
                }
            });
        },
        // 搜索事件
        searchEv() {
            if (this.keyword == "") {
                this.$message.warning({
                    message: "关键字为空",
                    duration: 1000,
                    customClass: "myBox",
                });
            } else {
                this.$router.push({
                    path: "/consult/cousultHot",
                    query: {
                        keyword: this.keyword,
                        type: -1,
                    },
                });
                window.scrollTo(0, 0);
            }
        },
    },
    mounted() {},
    beforeCreate() {},
    beforeMount() {},
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
    activated() {},
    components: {},
};
</script>

<style lang="scss">
// 修改message弹框样式
.myBox {
    min-width: 130px !important;
    height: 25px !important;
    padding: 3px !important;
    text-align: center !important;
    display: flex !important;
    justify-content: center !important;
    .el-message__icon {
        font-size: 10px;
    }
    .el-message__content {
        font-size: 8px;
    }
}

.search {
    margin-top: 6px;
    height: 26px;
    width: 100%;

    .search-box {
        z-index: -1;
        display: flex;
        align-items: center;
        width: 70%;
        height: 100%;
        margin: 0 auto;
        padding: 0 10px;
        box-sizing: border-box;
        // background-color: rgba(219, 214, 214, 0.3);
        // border: 1px solid rgb(149, 146, 146);
        box-shadow: 0px 0px 1px 1px rgba(103, 101, 101, 0.25);

        // 搜索左侧
        .search-box-left {
            width: 65%;
            display: flex;
            align-items: center;

            // 快讯按钮
            button {
                height: 13px;
                width: 30px;
                color: #fff;
                border: 0;
                border-radius: 3px;
                background-color: #0056b0;
            }

            .bs-sysMsg {
                // z-index: -1;
                margin-left: 6%;
                position: relative;
                display: flex;
                width: 100%;
                color: #017dff;
                opacity: 1;
                align-items: center;
                transition: opacity 0.2s;
                overflow: hidden;
            }

            .el-carousel__container {
                padding-bottom: 6px;
                height: 30px !important;
            }

            .el-carousel__item {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .bs-sysMsg .msg__content {
                width: 100%;
                height: 15px;
                line-height: 0px;
            }
            .bs-sysMsg .msg__content a.item {
                color: #000;
                font-size: 10px;
                padding: 0 0px;
                opacity: 0.75;
                text-decoration: none;
                z-index: -1;
                // background-color: rgba(219, 214, 214, 0.3);
            }
        }

        // 搜索右侧
        .search-box-right {
            height: 100%;
            width: 30%;
            display: flex;
            align-items: center;
            margin-left: auto;

            .search-right-map {
                width: 26px;
                height: 80%;
                line-height: 0;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            .search-right-input {
                display: flex;
                width: 130px;
                height: 80%;
                margin-left: auto;

                > input {
                    height: 80%;
                    padding-left: 5px;
                }

                button {
                    width: 30px;
                    height: 90%;
                    margin-left: 3px;
                    color: #fff;
                    border: 0;
                    border-radius: 3px;
                    background-color: #0056b0;
                }
            }
        }
    }
}

//当屏幕最小宽度为992px
@media screen and (min-width: 1200px) {
}

//当屏幕宽度 最小为768px 最大为991px时
@media screen and (min-width: 768px) and (max-width: 1200px) {
    .search {
        margin-top: 12px;
        height: 26px;
        width: 100%;

        .search-box {
            display: flex;
            align-items: center;
            width: 80%;
            height: 100%;
            margin: 0 auto;
            padding: 0 10px;
            box-sizing: border-box;

            // 搜索左侧
            .search-box-left {
                width: 60%;
                display: flex;
                align-items: center;

                // 快讯按钮
                button {
                    height: 13px;
                    width: 30px;
                    color: #fff;
                    border: 0;
                    border-radius: 3px;
                    background-color: #0056b0;
                }

                .bs-sysMsg {
                    // z-index: -1;
                    margin-left: 6%;
                    position: relative;
                    display: flex;
                    width: 100%;
                    color: #017dff;
                    // background-color: #fff;
                    opacity: 1;
                    align-items: center;
                    transition: opacity 0.2s;
                }

                .el-carousel__container {
                    padding-bottom: 6px;
                }

                .el-carousel__item {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                .bs-sysMsg .msg__content {
                    width: 100%;
                    height: 15px;
                    line-height: 0px;
                }
                .bs-sysMsg .msg__content a.item {
                    z-index: -1;
                    color: #000;
                    font-size: 10px;
                    padding: 0 0px;
                    opacity: 0.75;
                    text-decoration: none;
                }
            }

            // 搜索右侧
            .search-box-right {
                height: 100%;
                width: 33%;
                display: flex;
                align-items: center;
                margin-left: auto;

                .search-right-map {
                    width: 26px;
                    height: 80%;
                    line-height: 0;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }

                .search-right-input {
                    display: flex;
                    width: 130px;
                    height: 80%;
                    margin-left: auto;

                    > input {
                        height: 80%;
                        padding-left: 10px;
                    }

                    button {
                        width: 30px;
                        height: 90%;
                        margin-left: 3px;
                        color: #fff;
                        border: 0;
                        border-radius: 3px;
                        background-color: #0056b0;
                    }
                }
            }
        }
    }
}

//当屏幕最大宽度为767px
@media screen and (max-width: 767px) {
}
</style>
