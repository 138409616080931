<template>
    <!-- 行业标准---详情页 -->
    <div class="standardDetail">
        <Header></Header>
        <Nav></Nav>
        <div class="prefabricate-wrap">
            <!-- 顶部图片 -->
            <Advertising></Advertising>

            <!-- 关于学会tap -->
            <div class="survey-nav">
                <div class="survey-nav-title">
                    <div class="survey-title-cn">行业标准--详情页</div>
                    <!-- <div class="survey-title-sn">About the Institute</div> -->
                </div>
                <ul style="font-size: 16px">
                    <!-- 行业标准详情页--暂未写tap -->
                    <!-- <li
                        :key="index"
                        v-for="(item, index) in surveyTap"
                        @click="surveyTapEv(index)"
                        :class="currentIndex == index ? 'active' : ''"
                    >
                        {{ item.name }}
                    </li> -->
                </ul>
            </div>

            <!-- 内容区域,包含学会概况、学会章程、入会申请、通知通告、学会要闻、企业会员 -->
            <div class="survey-cont">
                <div class="survey-content">
                    <div class="survey-content-left">
                        <div class="survey-left-top">
                            <div>
                                {{ normdetailDe.title }}
                            </div>
                            <!-- <div>
                                {{ normdetailDe.entitle }}
                            </div> -->
                            <ul>
                                <li>{{ normdetailDe.entitle }}</li>
                                <!-- <li>国家标准</li>
                                <li>推荐性</li>
                                <li>现行</li> -->
                            </ul>
                        </div>
                        <!-- <div class="survey-left-center">
                            {{ normdetailDe.description }}
                        </div> -->
                        <div class="survey-left-bottom">
                            <!-- <div>中国行业化标准</div> -->
                            <div v-html="normdetailDe.content"></div>
                        </div>
                    </div>
                    <div class="survey-content-right">
                        <Right></Right>
                    </div>
                </div>
            </div>
            <!-- 内容区域 -->
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import Header from "@/components/Header.vue"; //头部
import Footer from "@/components/Footer.vue"; //尾部
import Nav from "@/components/Nav.vue"; //导航
import Right from "@/components/Right.vue"; //关于右侧组件
import Advertising from "@/components/Advertising.vue"; //广告图
import { normdetail } from "../../../network/data";
export default {
    name: "prefabricate",
    data() {
        return {
            // 当前tap索引
            currentIndex: 0,
            // 当前的内容id和当前面包屑id
            id: "",
            menu_id: "",
            // 行业标准详情页数据
            normdetailDe: {},
        };
    },
    computed: {},
    watch: {
        // $route(to, from) {
        //     window.location.reload(); //监测到路由发生跳转时刷新一次页面
        // },
    },
    created() {
        if (JSON.stringify(this.$route.query) != "{}") {
            this.id = this.$route.query.ids;
            this.menu_id = this.$route.query.menu_id;
        }

        // 获取行业标准详情页
        this.normdetailRe();
    },
    methods: {
        // 获取行业标准详情页
        normdetailRe() {
            let data = {
                menuid: this.menu_id,
                id: this.id,
            };
            normdetail(data).then((res) => {
                if (res.code == 1) {
                    // console.log(res.data);
                    this.normdetailDe = res.data.work;
                } else {
                    this.message(res.msg);
                }
            });
        },
    },

    mounted() {},
    beforeCreate() {},
    beforeMount() {},
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
    activated() {},
    components: {
        Header,
        Footer,
        Right,
        Nav,
        Advertising,
    },
};
</script>

<style lang="scss">
.standardDetail {
    width: 100%;
    .prefabricate-wrap {
        width: 70%;
        // height: 1000px;
        margin: 0 auto;
        margin-bottom: 40px;
        // border: 1px solid red;

        /*关于学会tap start*/
        .survey-nav {
            display: flex;
            align-items: center;
            width: 100%;
            padding: 10px 0;
            box-sizing: border-box;
            border-bottom: 1px solid #e4e4e4;

            .survey-nav-title {
                width: 20%;
                display: flex;
                align-items: center;
                // border: 1px solid red;

                .survey-title-cn {
                    font-size: 13px;
                    color: #0056b0;
                    margin-right: 10px;
                }

                .survey-title-sn {
                    height: 20px;
                    font-size: 6px;
                    color: #fff;
                    padding: 0 6px;
                    background-color: #0056b0;
                }
            }

            > ul {
                width: 80%;
                list-style: none;
                display: flex;
                // border: 1px solid red;

                .active {
                    color: #0068d5;
                }

                li {
                    cursor: pointer;
                    margin-left: 10px;
                    padding-left: 10px;
                    font-size: 10px;
                    border-left: 1px solid #e9e9e9;
                }
            }
        }
        /*关于学会tap end*/

        /*内容区域 start*/
        .survey-cont {
            width: 100%;
            height: 100%;

            .survey-content {
                display: flex;
                width: 100%;
                margin: 0 auto;
                // border: 1px solid red;

                // 内容区域左侧
                .survey-content-left {
                    width: 68%;
                    height: 100%;
                    // border: 1px solid blue;

                    .survey-left-top {
                        font-size: 10px;
                        padding: 10px 0 20px 0;
                        border-bottom: 1px solid #e4e4e4;

                        > div:nth-child(1) {
                        }

                        > div:nth-child(2) {
                            font-size: 8px;
                            margin-top: 2px;
                        }

                        > ul {
                            list-style: none;
                            display: flex;
                            color: #fff;
                            > li:nth-child(1) {
                                font-size: 7px;
                                margin-right: 10px;
                                padding: 0 2px;
                                border-radius: 2px;
                                background-color: #5bc0de;
                                // border: 1px solid red;
                            }
                            > li:nth-child(2) {
                                font-size: 7px;
                                margin-right: 10px;
                                padding: 0 2px;
                                border-radius: 2px;
                                background-color: #57b85c;
                                // border: 1px solid red;
                            }
                            > li:nth-child(3) {
                                font-size: 7px;
                                margin-right: 10px;
                                padding: 0 2px;
                                border-radius: 2px;
                                background-color: #5499e9;
                                // border: 1px solid red;
                            }
                        }
                    }

                    .survey-left-center {
                        font-size: 10px;
                        padding: 15px 0;
                        border-bottom: 1px solid #e4e4e4;
                    }

                    .survey-left-bottom {
                        font-size: 10px;
                        padding: 15px 0;
                        height: 660px;
                        overflow-y: auto; //超出显示滚动条

                        &::-webkit-scrollbar {
                            width: 3px;
                            height: 6px;
                        }
                        // 滚动条样式 start
                        // 滚动条的轨道的两端按钮，允许通过点击微调小方块的位置。
                        &::-webkit-scrollbar-button {
                            display: none;
                        }
                        // 滚动条里面的小方块，能向上向下移动（或往左往右移动，取决于是垂直滚动条还是水平滚动条）
                        &::-webkit-scrollbar-thumb {
                            background: rgba(144, 147, 153, 0.3);
                            cursor: pointer;
                            border-radius: 4px;
                        }
                        // 边角，即两个滚动条的交汇处
                        &::-webkit-scrollbar-corner {
                            display: none;
                        }
                        // 两个滚动条的交汇处上用于通过拖动调整元素大小的小控件
                        &::-webkit-resizer {
                            display: none;
                        }
                        // 滚动条样式 end

                        > div:nth-child(1) {
                            text-align: center;
                            font-size: 12px;
                            margin-bottom: 10px;
                        }
                    }
                }

                // 内容区域右侧
                .survey-content-right {
                    margin-left: auto;
                    width: 30%;
                    // border: 1px solid red;
                }
            }
        }

        /*内容区域 end*/
    }
}

//当屏幕最小宽度为992px
@media screen and (min-width: 1200px) {
}

//当屏幕宽度 最小为768px 最大为991px时
@media screen and (min-width: 768px) and (max-width: 1200px) {
    .standardDetail {
        width: 100%;
        .prefabricate-wrap {
            width: 80%;
            // height: 1000px;
            margin: 0 auto;
            margin-bottom: 40px;
            // border: 1px solid red;

            /*关于学会tap start*/
            .survey-nav {
                display: flex;
                align-items: center;
                width: 100%;
                padding: 10px 0;
                box-sizing: border-box;
                border-bottom: 1px solid #e4e4e4;

                .survey-nav-title {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    // border: 1px solid red;

                    .survey-title-cn {
                        font-size: 10px;
                        color: #0056b0;
                        margin-right: 15px;
                    }

                    // .survey-title-sn {
                    //     height: 20px;
                    //     font-size: 6px;
                    //     color: #fff;
                    //     padding: 0 6px;
                    //     background-color: #0056b0;
                    // }
                }

                // > ul {
                //     width: 80%;
                //     list-style: none;
                //     display: flex;
                //     // border: 1px solid red;

                //     .active {
                //         color: #0068d5;
                //     }

                //     li {
                //         cursor: pointer;
                //         margin-left: 10px;
                //         padding-left: 10px;
                //         font-size: 10px;
                //         border-left: 1px solid #e9e9e9;
                //     }
                // }
            }
            /*关于学会tap end*/

            /*内容区域 start*/
            .survey-cont {
                width: 100%;
                height: 100%;

                .survey-content {
                    display: flex;
                    width: 100%;
                    margin: 0 auto;
                    // border: 1px solid red;

                    // 内容区域左侧
                    .survey-content-left {
                        width: 68%;
                        height: 100%;
                        // border: 1px solid blue;

                        .survey-left-top {
                            font-size: 10px;
                            padding: 10px 0 10px 0;
                            border-bottom: 1px solid #e4e4e4;

                            > div:nth-child(1) {
                            }

                            > div:nth-child(2) {
                                font-size: 8px;
                                margin-top: 2px;
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            }

                            > ul {
                                list-style: none;
                                display: flex;
                                > li:nth-child(1) {
                                    font-size: 7px;
                                    margin-right: 10px;
                                    padding: 0 2px;
                                    border-radius: 2px;
                                    background-color: #5bc0de;
                                    // border: 1px solid red;
                                }
                                > li:nth-child(2) {
                                    font-size: 7px;
                                    margin-right: 10px;
                                    padding: 0 2px;
                                    border-radius: 2px;
                                    background-color: #57b85c;
                                    // border: 1px solid red;
                                }
                                > li:nth-child(3) {
                                    font-size: 7px;
                                    margin-right: 10px;
                                    padding: 0 2px;
                                    border-radius: 2px;
                                    background-color: #5499e9;
                                    // border: 1px solid red;
                                }
                            }
                        }

                        .survey-left-center {
                            font-size: 10px;
                            padding: 10px 0;
                            border-bottom: 1px solid #e4e4e4;
                        }

                        .survey-left-bottom {
                            font-size: 10px;
                            padding: 15px 0;
                            height: 460px;
                            overflow-y: auto; //超出显示滚动条

                            &::-webkit-scrollbar {
                                width: 3px;
                                height: 6px;
                            }
                            // 滚动条样式 start
                            // 滚动条的轨道的两端按钮，允许通过点击微调小方块的位置。
                            &::-webkit-scrollbar-button {
                                display: none;
                            }
                            // 滚动条里面的小方块，能向上向下移动（或往左往右移动，取决于是垂直滚动条还是水平滚动条）
                            &::-webkit-scrollbar-thumb {
                                background: rgba(144, 147, 153, 0.3);
                                cursor: pointer;
                                border-radius: 4px;
                            }
                            // 边角，即两个滚动条的交汇处
                            &::-webkit-scrollbar-corner {
                                display: none;
                            }
                            // 两个滚动条的交汇处上用于通过拖动调整元素大小的小控件
                            &::-webkit-resizer {
                                display: none;
                            }
                            // 滚动条样式 end

                            > div:nth-child(1) {
                                text-align: center;
                                font-size: 12px;
                                margin-bottom: 10px;
                            }
                        }
                    }

                    // 内容区域右侧
                    .survey-content-right {
                        margin-left: auto;
                        width: 30%;
                        // border: 1px solid red;
                    }
                }
            }

            /*内容区域 end*/
        }
    }
}

//当屏幕最大宽度为767px
@media screen and (max-width: 767px) {
}
</style>
