<template>
    <div class="mContact">
        <Mheader :currentIndex="6"></Mheader>

        <!-- 轮播图 -->
        <div class="mIndex-swiper">
            <MeSwiper></MeSwiper>
        </div>

        <!-- 版心 -->
        <div class="introduces-wrap">
            <div class="introduces-cont">
                <div class="introduces-cont-title">
                    <div>联系我们</div>
                    <div>CONTACT US</div>
                    <div></div>
                </div>

                <div class="introduces-cont-box">
                    <div>{{ companyeDe.name }}</div>
                    <div>总部热线：{{ companyeDe.headline }}</div>
                    <div>招商电话：{{ companyeDe.askline }}</div>
                    <!-- <div>业务邮箱：{{ companyeDe.email }}</div>
                    <div>QQ:{{ companyeDe.qq }}</div>
                    <div>传真：{{ companyeDe.faxnum }}</div> -->
                    <div>总部地址：{{ companyeDe.address }}</div>
                    <div>
                        <map-container
                            :longitude="companyeDe.longitude"
                            :latitude="companyeDe.latitude"
                        ></map-container>
                    </div>
                </div>
            </div>
        </div>

        <Footer></Footer>
    </div>
</template>

<script>
import Mheader from "../../../components/Mheader.vue";
import Footer from "../../../components/Footer.vue";
import MapContainer from "../../../components/MapContainer.vue";
import MeSwiper from "../../../components/Member/swiper.vue";
import { companye } from "../../../network/data";

export default {
    // 组件名称
    name: "introduces",
    // 组件参数 接收来自父组件的数据
    props: {},
    // 组件状态值
    data() {
        return {
            companyeDe: {},
        };
    },
    // 计算属性
    computed: {},
    // 侦听器
    watch: {},
    // 组件方法
    created() {
        // 获取公司信息
        this.companyeRe();
    },
    methods: {
        // 获取公司信息
        companyeRe() {
            let data = {
                id: sessionStorage.getItem("id"),
            };
            companye(data).then((res) => {
                if (res.code == 1) {
                    // console.log(res.data);
                    this.companyeDe = res.data;
                } else {
                    this.message(res.msg);
                }
            });
        },
    },
    mounted() {},
    // 局部注册的组件
    components: {
        Mheader,
        Footer,
        MapContainer,
        MeSwiper,
    },
};
</script>

<style lang="scss">
.mContact {
    width: 100%;

    img {
        display: block;
        height: 100%;
        width: 100%;
    }

    // 版心
    .introduces-wrap {
        width: 70%;
        margin: 0 auto;

        .introduces-cont {
            width: 100%;
            height: 500px;
            margin-top: 20px;
            // border: 1px solid red;

            .introduces-cont-title {
                text-align: center;
                // border: 1px solid red;

                > div:nth-child(1) {
                    font-size: 12px;
                }

                > div:nth-child(2) {
                    margin-top: 2px;
                    font-size: 8px;
                    color: #d1d1d1;
                }

                > div:nth-child(3) {
                    margin: 0 auto;
                    margin-top: 5px;
                    // text-align: center;
                    width: 15px;
                    height: 2px;
                    background-color: #00479d;
                }
            }

            .introduces-cont-box {
                text-align: center;
                > div:nth-child(1) {
                    font-size: 13px;
                    margin-top: 20px;
                    color: black;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                > div {
                    margin-top: 10px;
                    font-size: 10px;
                    color: #666666;
                }
                > div:last-child {
                    width: 300px;
                    height: 200px;
                    margin: 20px auto;
                    // border: 1px solid red;
                }
            }
        }
    }
}

//当屏幕最小宽度为992px
@media screen and (min-width: 1200px) {
}

//当屏幕宽度 最小为768px 最大为991px时
@media screen and (min-width: 768px) and (max-width: 1200px) {
    .mContact {
        width: 100%;

        img {
            display: block;
            height: 100%;
            width: 100%;
        }

        // 版心
        .introduces-wrap {
            width: 80%;
            margin: 0 auto;

            .introduces-cont {
                width: 100%;
                height: 500px;
                margin-top: 20px;
                // border: 1px solid red;

                .introduces-cont-title {
                    text-align: center;

                    > div:nth-child(1) {
                        font-size: 12px;
                    }

                    > div:nth-child(2) {
                        font-size: 10px;
                    }
                }

                .introduces-cont-box {
                    text-align: center;
                    > div:nth-child(1) {
                        font-size: 13px;
                        margin-top: 20px;
                        color: black;
                    }
                    > div {
                        margin-top: 10px;
                        font-size: 10px;
                        color: #666666;
                    }
                    > div:last-child {
                        width: 300px;
                        height: 150px;
                        margin: 20px auto;
                        // border: 1px solid red;
                    }
                }
            }
        }
    }
}

//当屏幕最大宽度为767px
@media screen and (max-width: 767px) {
}
</style>
