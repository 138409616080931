<template>
    <div class="machining">
        <Header></Header>
        <HeadNav></HeadNav>
        <div class="machining-wrap">
            <!-- 顶部图片 -->
            <Advertising></Advertising>

            <!-- 关于学会tap -->
            <div class="survey-nav">
                <!-- <Nav></Nav> -->
                <div class="survey-nav-title">
                    <div class="survey-title-cn">{{ machiningTap.name }}</div>
                    <div class="survey-title-sn">{{ machiningTap.enname }}</div>
                </div>
                <ul>
                    <li
                        :key="index"
                        v-for="(item, index) in machiningTap.list"
                        @click="machiningTapEv(item, index)"
                        :class="currentIndex == index ? 'active' : ''"
                    >
                        {{ item.name }}
                    </li>
                </ul>
            </div>

            <!-- 内容区域,包含学会工作、分支机构 -->
            <div class="machining-cont">
                <div class="machining-content">
                    <!-- 左侧内容区 -->
                    <div class="machining-content-left">
                        <Left
                            v-if="JSON.stringify(makelistDe) != ''"
                            :makelist="makelistDe"
                            @page="newsPage"
                        ></Left>
                    </div>

                    <!-- 右侧内容区 -->
                    <div class="machining-content-right">
                        <Right></Right>
                    </div>
                </div>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import Header from "@/components/Header.vue"; //头部
import Footer from "@/components/Footer.vue"; //尾部
import Left from "@/components/Made/Left.vue"; //智造左侧组件
import Right from "@/components/Right.vue"; //关于右侧组件
import Advertising from "@/components/Advertising.vue"; //广告图
import HeadNav from "@/components/Nav.vue"; //导航
import { navlist, makelist } from "../../../../network/data";
export default {
    // 组件名称
    name: "machining",
    // 组件参数 接收来自父组件的数据
    props: {},
    // 组件状态值
    data() {
        return {
            // 当前tap索引
            currentIndex: 0,
            // 切换tap栏id
            id: 11,
            // 产业制造tap数据
            machiningTap: [],
            // 产业智造列表数据
            makelistDe: {},
            // 产业智造分页
            makeParams: {
                page: 1,
                pageSize: 3,
            },
        };
    },
    // 计算属性
    computed: {},
    // 侦听器
    watch: {
        $route(to, from) {
            window.location.reload(); //监测到路由发生跳转时刷新一次页面
        },
    },
    // 组件方法
    created() {
        if (JSON.stringify(this.$route.query) != "{}") {
            this.currentIndex = this.$route.query.type;
            this.id = this.$route.query.menuid;
        }

        // 获取产业智造tap栏数据
        this.navlistRe();
        // 获取产业智造列表数据
        this.makelistRe();
    },
    methods: {
        // 面包屑切换
        machiningTapEv(item, index) {
            this.id = item.id;
            // this.surveyData = [];
            this.currentIndex = index;
            this.makeParams.page = 1;
            this.makelistRe();
        },
        // 获取产业智造tap栏数据
        navlistRe() {
            navlist(11).then((res) => {
                if (res.code == 1) {
                    this.machiningTap = res.data;
                } else {
                    this.message(res.msg);
                }
            });
        },
        // 获取产业制造列表数据
        makelistRe() {
            let data = {
                menuid: this.id,
                page: this.makeParams.page,
                pagesize: this.makeParams.pageSize,
            };
            makelist(data).then((res) => {
                if (res.code == 1) {
                    this.makelistDe = res.data.list;
                } else {
                    this.message(res.msg);
                }
            });
        },
        // 产业智造分页事件
        newsPage(page) {
            this.makeParams.page = page;
            this.makelistRe();
        },
    },
    mounted() {},
    // 局部注册的组件
    components: {
        Header,
        Footer,
        Left,
        Right,
        Advertising,
        HeadNav,
    },
};
</script>

<style scoped lang="scss">
.machining {
    width: 100%;

    .machining-wrap {
        width: 70%;
        margin: 0 auto;
        margin-bottom: 40px;

        /*产业智造tap start*/
        .survey-nav {
            display: flex;
            align-items: center;
            width: 100%;
            padding: 10px 0;
            box-sizing: border-box;
            border-bottom: 1px solid #e4e4e4;

            .survey-nav-title {
                width: 35%;
                display: flex;
                align-items: center;
                // border: 1px solid red;

                .survey-title-cn {
                    width: 30%;
                    font-size: 13px;
                    color: #0056b0;
                    margin-right: 10px;
                    // border: 1px solid red;
                }
                .survey-title-sn {
                    // width: 70%;
                    height: 10px;
                    font-size: 6px;
                    color: #fff;
                    padding: 0 6px;
                    background-color: #0056b0;
                }
            }

            > ul {
                width: 65%;
                list-style: none;
                display: flex;
                // border: 1px solid red;

                .active {
                    color: #0068d5;
                }

                li {
                    cursor: pointer;
                    margin-left: 10px;
                    padding-left: 10px;
                    font-size: 10px;
                    border-left: 1px solid #e9e9e9;
                }
            }
        }
        /*关于学会tap end*/

        /*内容区域 start*/
        .machining-cont {
            width: 100%;

            .machining-content {
                display: flex;
                width: 100%;
                // border: 1px solid red;

                // 内容区域左侧
                .machining-content-left {
                    width: 72%;
                    // height: 1600px;
                    // border: 1px solid red;
                }

                // 内容区域右侧
                .machining-content-right {
                    width: 25%;
                    margin-left: auto;
                    // width: 320px;
                    // border: 1px solid red;
                }
            }
        }

        /*内容区域 end*/
    }
}

//当屏幕最小宽度为992px
@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .machining {
        width: 100%;

        .machining-wrap {
            width: 70%;
            margin: 0 auto;
            margin-bottom: 40px;

            /*产业智造tap start*/
            .survey-nav {
                display: flex;
                align-items: center;
                width: 100%;
                padding: 10px 0;
                box-sizing: border-box;
                border-bottom: 1px solid #e4e4e4;

                .survey-nav-title {
                    width: 30%;
                    display: flex;
                    align-items: center;
                    // border: 1px solid red;

                    .survey-title-cn {
                        text-align: center;
                        width: 70px;
                        font-size: 12px;
                        color: #0056b0;
                        margin-right: 6px;
                        // border: 1px solid red;
                    }
                    .survey-title-sn {
                        width: 80px;
                        height: 10px;
                        font-size: 6px;
                        color: #fff;
                        padding: 0 6px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        background-color: #0056b0;
                        // border: 1px solid red;
                    }
                }

                > ul {
                    width: 70%;
                    list-style: none;
                    display: flex;
                    // border: 1px solid red;

                    .active {
                        color: #0068d5;
                    }

                    li {
                        cursor: pointer;
                        text-align: center;
                        width: 40px;
                        margin-left: 5px;
                        padding-left: 3px;
                        font-size: 7.5px;
                        border-left: 1px solid #e9e9e9;
                        // border: 1px solid red;
                    }
                }
            }
            /*关于学会tap end*/
        }
    }
}

//当屏幕宽度 最小为768px 最大为991px时
@media screen and (min-width: 768px) and (max-width: 1200px) {
    .machining {
        width: 100%;

        .machining-wrap {
            width: 80%;
            margin: 0 auto;
            margin-bottom: 40px;

            /*关于学会tap start*/
            .survey-nav {
                display: flex;
                align-items: center;
                width: 100%;
                padding: 10px 0;
                box-sizing: border-box;
                border-bottom: 1px solid #e4e4e4;

                .survey-nav-title {
                    width: 30%;
                    display: flex;
                    align-items: center;
                    // border: 1px solid red;

                    .survey-title-cn {
                        width: 50px;
                        font-size: 10px;
                        color: #0056b0;
                        margin-right: 6px;
                        // border: 1px solid red;
                    }
                    .survey-title-sn {
                        width: 60px;
                        height: 10px;
                        font-size: 6px;
                        color: #fff;
                        padding: 0 6px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        background-color: #0056b0;
                    }
                }

                > ul {
                    width: 70%;
                    list-style: none;
                    display: flex;
                    // border: 1px solid red;

                    .active {
                        color: #0068d5;
                    }

                    li {
                        cursor: pointer;
                        text-align: center;
                        width: 40px;
                        margin-left: 5px;
                        padding-left: 3px;
                        font-size: 8px;
                        border-left: 1px solid #e9e9e9;
                        // border: 1px solid red;
                    }
                }
            }
            /*关于学会tap end*/

            /*内容区域 start*/
            .machining-cont {
                width: 100%;

                .machining-content {
                    display: flex;
                    width: 100%;
                    // border: 1px solid red;

                    // 内容区域左侧
                    .machining-content-left {
                        width: 72%;
                        // height: 1600px;
                        // border: 1px solid red;
                    }

                    // 内容区域右侧
                    .machining-content-right {
                        width: 25%;
                        margin-left: auto;
                        // width: 320px;
                        // border: 1px solid red;
                    }
                }
            }

            /*内容区域 end*/
        }
    }
}

//当屏幕最大宽度为767px
@media screen and (max-width: 767px) {
}
</style>
