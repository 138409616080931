<template>
    <!-- 企业公司头部组件 -->
    <div class="mHeader">
        <!-- 头部 -->
        <div class="mHeader-welcome">
            <div>
                <div>您好,欢迎来到河南省食品科学技术会</div>
                <ul>
                    <li
                        :key="index"
                        @click="skipIndex(item.path)"
                        v-for="(item, index) in mHeaderData"
                    >
                        {{ item.name }}
                    </li>
                </ul>
            </div>
        </div> 
        <div class="gao"></div>
        <div class="mHeader-wrap">
            <!-- logo -->
            <div class="mHeader-logo">
                <div>
                    <img :src="logo.logo" alt="" />
                </div>
                <div class="title">{{ name }}</div>
                <div class="phone">
                    <img src="../assets/pc/Mindex/phone.png" alt="" />
                    <div>服务热线:{{ logo.headline }}</div>
                </div>
            </div>
        </div>

        <!-- 导航栏 -->
        <div class="mHeader-nav">
            <ul>
                <li
                    :key="indexb"
                    @click="nav(itemb)"
                    v-for="(itemb, indexb) in memberNavObj"
                    :class="currentIndex == indexb ? 'active' : ''"
                >
                    {{ itemb.name }}
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import { companyMneu, companye } from "../network/data";
export default {
    // 组件名称
    name: "mHeader",
    props: {
        currentIndex: {
            type: Number,
            default: 0,
        },
    },
    // 组件状态值
    data() {
        return {
            name: "",
            // currentIndex: 0,
            // 头部数据
            mHeaderData: [
                { path: "/index", name: "首页" },
                { path: "/about/survey", name: "关于学会" },
                { path: "/made/machining", name: "产业智造" },
                { path: "/science/minkave", name: "科技赋能" },
                { path: "/Special/prefabricate", name: "产业专题" },
                { path: "/observe/flesh", name: "产业观察" },
                { path: "/science/demand", name: "产业需求" },
                { path: "/norm/standard", name: "行业规范" },
                { path: "/consult", name: "食品咨询" },
            ],
            // 企业会员导航栏数据
            memberNavObj: {},
            // logo
            logo: {},
        };
    },
    // 计算属性
    computed: {},
    // 侦听器
    watch: {},
    // 组件方法
    created() {
        // 获取企业会员导航栏数据
        this.companyMneuRe();
        // 获取企业会员公司信息
        this.companyeRe();
    },
    methods: {
        // 企业会员首页导航跳转
        nav(item) {
            // console.log(item);
            this.$router.push({
                path: `${item.path}`,
                query: {
                    menu_id: item.id,
                },
            });
        },
        // 跳转到首页
        skipIndex(paths) {
            console.log(paths);
            if (paths) {
                this.$router.push(`${paths}`);
            }
        },
        // 获取企业会员首页导航栏数据
        companyMneuRe() {
            companyMneu().then((res) => {
                if (res.code == 1) {
                    this.memberNavObj = res.data;
                    this.memberNavObj[0].path = "/mIndex";
                    this.memberNavObj[1].path = "/mIntroduce";
                    this.memberNavObj[2].path = "/mHonor";
                    this.memberNavObj[3].path = "/mShow";
                    this.memberNavObj[4].path = "/mNews";
                    this.memberNavObj[5].path = "/mInvite";
                    this.memberNavObj[6].path = "/mContact";
                    // console.log(this.memberNavObj, "企业会员导航栏数据");
                } else {
                    this.message(res.msg);
                }
            });
        },
        // 获取企业会员公司信息
        companyeRe() {
            let data = {
                id: sessionStorage.getItem("id"),
            };
            companye(data).then((res) => {
                if (res.code == 1) {
                    console.log(res.data, "企业会员公司信息");
                    this.logo = res.data;
                    this.name = res.data.name;
                } else {
                    this.message(res.msg);
                }
            });
        },
    },
    mounted() {},
    // 局部注册的组件
    components: {},
};
</script>

<style scoped lang="scss">
.mHeader {
    width: 100%;
    // border: 1px solid red;

    // 头部
    .mHeader-welcome {
        z-index: 99999;
        background-color: #f1f1f1;
        position: fixed;
        top: 0;
        width: 100%;
        > div {
            width: 70%;
            margin: 0 auto;
            display: flex;
            height: 20px;
            line-height: 20px;

            > div:nth-child(1) {
                width: 30%;
                font-size: 8px;
            }

            > ul {
                width: 70%;
                display: flex;
                margin-left: auto;
                list-style: none;
                font-size: 8px;

                li {
                    margin-left: 13px;
                    color: #666666;
                    cursor: pointer;
                }
            }
        }
    }
    .gao{
        height: 20px;
    }
    .mHeader-wrap {
        width: 70%;
        margin: 0 auto;
        height: 60px;
        margin-top: 20rpx;
        // border: 1px solid red;

        //logo
        .mHeader-logo {
            margin-top: 20px;
            display: flex;
            align-items: center;
            width: 100%;
            height: 30px;
            padding: 6px 0px;
            // border: 1px solid red;

            > div:nth-child(1) {
                width: 70px;
                height: 70px;
                // border: 1px solid red;

                img {
                    width: 100%;
                    height: 100%;
                }
            }
            .title {
                font-size: 18px;
                margin-left: 20px;
            }
            .phone {
                margin-left: auto;
                margin-right: 20px;
                display: flex;
                align-items: center;
                // width: 300px;

                > img {
                    width: 20px;
                    height: 20px;
                }

                > div {
                    margin-left: 10px;
                    font-size: 10px;
                }
            }
        }
    }

    // 导航栏
    .mHeader-nav {
        height: 30px;
        width: 100%;
        background-color: #00479d;

        > ul {
            list-style: none;
            display: flex;
            align-items: center;
            height: 100%;
            width: 70%;
            margin: auto;

            .active {
                // height: 100%;
                color: #074ca0;
                background-color: #fff;
            }

            li {
                text-align: center;
                line-height: 30px;
                width: 14%;
                height: 100%;
                font-size: 10px;
                color: #fff;
                cursor: pointer;
            }
        }
    }
}

//当屏幕最小宽度为992px
@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .mHeader {
        width: 100%;
        // border: 1px solid red;
        // 头部
        .mHeader-welcome {
            z-index: 99999;
            background-color: #f1f1f1;
            position: fixed;
            top: 0;
            width: 100%;
            > div {
                width: 80%;
                margin: 0 auto;
                display: flex;
                height: 20px;
                line-height: 20px;

                > div:nth-child(1) {
                    width: 30%;
                    font-size: 8px;
                }

                > ul {
                    width: 70%;

                    display: flex;
                    margin-left: auto;
                    list-style: none;
                    font-size: 8px;

                    li {
                        margin-left: 8px;
                        color: #666666;
                        cursor: pointer;
                    }
                }
            }
        }

        .mHeader-wrap {
            width: 70%;
            margin: 0 auto;
            // border: 1px solid red;

            //logo
            .mHeader-logo {
                margin-top: 20px;
                display: flex;
                width: 100%;
                height: 30px;
                padding: 6px 0px;
                // border: 1px solid red;

                > div:nth-child(1) {
                    width: 80px;
                    height: 21px;
                    // border: 1px solid red;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
                .title {
                    font-size: 10px;
                }
                .phone {
                    margin-left: auto;
                    margin-right: 20px;
                    display: flex;
                    align-items: center;
                    // width: 300px;

                    > img {
                        width: 20px;
                        height: 20px;
                    }

                    > div {
                        margin-left: 10px;
                        font-size: 10px;
                    }
                }
            }
        }

        // 导航栏
        .mHeader-nav {
            height: 30px;
            width: 100%;
            background-color: #00479d;

            > ul {
                list-style: none;
                display: flex;
                align-items: center;
                height: 100%;
                width: 70%;
                margin: auto;

                .active {
                    // height: 100%;
                    color: #074ca0;
                    background-color: #fff;
                }

                li {
                    text-align: center;
                    line-height: 30px;
                    width: 14%;
                    height: 100%;
                    font-size: 10px;
                    color: #fff;
                    cursor: pointer;
                }
            }
        }
    }
}

//当屏幕宽度 最小为768px 最大为991px时
@media screen and (min-width: 768px) and (max-width: 1200px) {
    .mHeader {
        width: 100%;

        // border: 1px solid red;

        // 头部
        .mHeader-welcome {
            z-index: 99999;
            background-color: #f1f1f1;
            position: fixed;
            top: 0;
            width: 100%;
            > div {
                width: 70%;
                margin: 0 auto;
                display: flex;
                height: 20px;
                line-height: 20px;

                > div:nth-child(1) {
                    width: 40%;
                    font-size: 7px;
                }

                > ul {
                    width: 80%;
                    // background: #000;
                    display: flex;
                    margin-left: auto;
                    list-style: none;
                    font-size: 7px;

                    li {
                        margin-left: 2px;
                        color: #666666;
                        cursor: pointer;
                    }
                }
            }
        }

        .mHeader-wrap {
            width: 80%;
            margin: 0 auto;
            // border: 1px solid red;

            //logo
            .mHeader-logo {
                margin-top: 20px;
                display: flex;
                width: 100%;
                height: 30px;
                padding: 6px 0px;
                // border: 1px solid red;

                > div:nth-child(1) {
                    width: 80px;
                    height: 25px;
                    // border: 1px solid red;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }

                .title {
                    font-size: 10px;
                }
                .phone {
                    margin-left: auto;
                    margin-right: 20px;
                    display: flex;
                    align-items: center;
                    // width: 300px;

                    > img {
                        width: 20px;
                        height: 20px;
                    }

                    > div {
                        margin-left: 10px;
                        font-size: 10px;
                    }
                }
            }
        }

        // 导航栏
        .mHeader-nav {
            height: 30px;
            width: 100%;
            background-color: #00479d;

            > ul {
                list-style: none;
                display: flex;
                align-items: center;
                height: 100%;
                width: 80%;
                margin: auto;

                .active {
                    // height: 100%;
                    color: #074ca0;
                    background-color: #fff;
                }

                li {
                    text-align: center;
                    line-height: 30px;
                    width: 14%;
                    height: 100%;
                    font-size: 10px;
                    color: #fff;
                }
            }
        }
    }
}

//当屏幕最大宽度为767px
@media screen and (max-width: 767px) {
}
</style>
