<template>
    <!-- 产品需求 -->
    <div class="demand">
        <Header></Header>
        <HeadNav></HeadNav>

        <div class="minkave-wrap">
            <!-- 顶部图片 -->
            <Advertising></Advertising>
            <!-- <div class="index-headImg">
                <div class="index-headImg-left">
                    <img src="../../../assets/pc/Index/headL.png" alt="" />
                </div>
                <div class="index-headImg-right">
                    <img src="../../../assets/pc/Index/headR.png" alt="" />
                </div>
            </div> -->

            <!-- 关于学会tap -->
            <div class="survey-nav">
                <!-- <Nav></Nav> -->
                <div class="survey-nav-title">
                    <div class="survey-title-cn">技术需求</div>
                    <div class="survey-title-sn">About the Institute</div>
                </div>
                <ul>
                    <li
                        :key="index"
                        v-for="(item, index) in machiningTap"
                        @click="machiningTapEv(item, index)"
                        :class="currentIndex == index ? 'active' : ''"
                    >
                        {{ item.name }}
                    </li>
                </ul>
            </div>

            <!-- 内容区域 -->
            <div class="survey-cont">
                <div class="survey-content">
                    <!-- 内容区域左侧 -->
                    <!-- 技术难题 -->
                    <div
                        class="survey-content-left1"
                        v-show="currentIndex == 0"
                    >
                        <!-- <Left3></Left3> -->
                        <ul>
                            <li
                                v-for="(itemb, indexb) in needList"
                                :key="indexb"
                            >
                                <div class="science-card">
                                    <img
                                        src="../../../assets/pc/Index/qf.png"
                                    />
                                    <div class="science-card4-start">
                                        <!-- <div>未解决</div> -->
                                        <div>{{ itemb.usename }}</div>
                                    </div>
                                    <!-- <div class="science-card4-tag0">
                                        <span>发布单位：</span>
                                        <span>{{ itemb.company }}</span>
                                    </div> -->
                                    <div class="science-card4-tag1">
                                        <div>合作方式：{{ itemb.lable }}</div>
                                        <div>应用领域：{{ itemb.usename }}</div>
                                    </div>
                                    <div class="science-card4-tag1">
                                        <!-- <div>预算：{{ itemb.money }}</div> -->
                                        <div>预算：*万</div>
                                        <div>问题标签：{{ itemb.lable }}</div>
                                    </div>
                                    <div class="science-card4-tag5">
                                        <div>
                                            问题标签：{{ itemb.lablename }}
                                        </div>
                                    </div>
                                    <div class="science-card4-tag4">
                                        <div>
                                            需求描述：
                                            <div v-html="itemb.content"></div>
                                        </div>
                                    </div>

                                    <div class="science-card4-tag2">
                                        <div>{{ itemb.answernum }}回答</div>
                                        <div class="science-card4-tag3">
                                            <div @click="tankuang">
                                                <img
                                                    src="../../../assets/pc/Index/fj.png"
                                                    alt=""
                                                />
                                                <div>需求发布</div>
                                            </div>
                                            <div @click.stop="ulEv1(itemb)">
                                                <img
                                                    src="../../../assets/pc/Index/bj.png"
                                                    alt=""
                                                />
                                                <div>我来答</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>

                            <!-- 左侧内容区域分页 -->
                            <div class="pageDown">
                                <Pagination
                                    v-if="JSON.stringify() != ''"
                                    :pageNo="searchParams.page"
                                    :pageSize="searchParams.pageSize"
                                    :total="total"
                                    :continues="5"
                                    @getPageNo="getPageEv"
                                />
                            </div>

                            <!-- <button class="issue" @click="goCompile">
                                去发布
                            </button> -->
                        </ul>
                    </div>

                    <!-- 成果转化 -->
                    <div
                        class="survey-content-left2"
                        v-show="currentIndex == 1"
                    >
                        <ul>
                            <li
                                v-for="(itemb, indexb) in comeListDe"
                                :key="indexb"
                            >
                                <div class="survey-left2-title1">
                                    {{ itemb.title }}
                                </div>
                                <div class="survey-left2-title2">
                                    <div>成果类型：{{ itemb.lable }}</div>
                                    <div>完成单位：{{ itemb.company }}</div>
                                </div>
                                <div class="survey-left2-title2">
                                    <div>合作方式：{{ itemb.makeway }}</div>
                                    <div>应用领域：{{ itemb.usedomain }}</div>
                                </div>
                                <div class="survey-left2-title2">
                                    <div>成果描述：{{ itemb.description }}</div>
                                </div>
                                <div class="survey-left2-footer">
                                    <div>
                                        {{ itemb.looknum }}次关注|{{
                                            itemb.createtime
                                        }}
                                    </div>
                                    <div @click="resultDetil(itemb.id)">
                                        <img
                                            src="../../../assets/pc/Index/eye.png"
                                            alt=""
                                        />
                                        <span>查看详情</span>
                                    </div>
                                    <div
                                        @click="resultIssue"
                                        v-if="
                                            memberId == itemb.company_id &&
                                            companyType == 1
                                        "
                                    >
                                        <!-- <img
                                            src="../../../assets/pc/Index/bj.png"
                                            alt=""
                                        /> -->
                                        <span
                                            >发布推广{{
                                                itemb.company_id
                                            }}</span
                                        >
                                    </div>
                                </div>
                            </li>

                            <!-- 左侧内容区域分页 -->
                            <div class="pageDown">
                                <Pagination
                                    v-if="JSON.stringify() != ''"
                                    :pageNo="setoutcomeParams.page"
                                    :pageSize="setoutcomeParams.pageSize"
                                    :total="setoutcomeTotle"
                                    :continues="5"
                                    @getPageNo="setoutcome"
                                />
                            </div>
                            <!-- <button
                                class="issue"
                                v-if="companyType == 1 || companyType == null"
                                @click="goCompile"
                            >
                                去发布
                            </button> -->
                        </ul>
                    </div>

                    <!-- 就业招聘 -->
                    <div
                        class="survey-content-left3"
                        v-show="currentIndex == 2"
                    >
                        <ul class="ul2">
                            <li
                                v-for="(itemb, indexb) in joblist"
                                :key="indexb"
                                @click="inviteDetail(itemb)"
                            >
                                <div
                                    class="science-card"
                                    v-if="itemb.type == 2"
                                >
                                    <div class="science-card4-start">
                                        <div>{{ itemb.typetext }}</div>
                                        <div>在寻找</div>
                                    </div>
                                    <div class="science-card4-tag1">
                                        <div>姓名：{{ itemb.title }}</div>
                                        <div>年龄：{{ itemb.oldyear }}</div>
                                    </div>
                                    <div class="science-card4-tag1">
                                        <div>
                                            就业方向：{{ itemb.catename }}
                                        </div>
                                        <div>
                                            专业学历：{{ itemb.eduction }}
                                        </div>
                                    </div>
                                    <div class="science-card4-tag1">
                                        <div>联系方式：{{ itemb.phone }}</div>
                                        <div>
                                            工作经验：{{ itemb.workyear }}
                                        </div>
                                    </div>

                                    <div class="science-card4-tag3">
                                        <div class="science-card4-data">
                                            {{ itemb.jobnum }}回答 |
                                            <span>{{ itemb.time }}</span>
                                        </div>
                                        <div @click.stop="goCompile">
                                            <img
                                                src="../../../assets/pc/Index/fj.png"
                                                alt=""
                                            />
                                            <div>发布就业</div>
                                        </div>
                                        <!-- <div
                                            v-if="
                                                companyType == 2 &&
                                                memberId == itemb.company_id
                                            "
                                        >
                                            <div>招聘发布</div>
                                        </div> -->
                                    </div>
                                </div>
                                <div
                                    class="science-card"
                                    v-if="itemb.type == 1"
                                >
                                    <div class="science-card4-start">
                                        <div>{{ itemb.typetext }}</div>
                                        <div>在寻找</div>
                                    </div>
                                    <div class="science-card4-tag1">
                                        <div>
                                            招聘部门：{{ itemb.catename }}
                                        </div>
                                        <div>招聘岗位：{{ itemb.title }}</div>
                                    </div>
                                    <div class="science-card4-tag1">
                                        <div>
                                            经验要求：{{ itemb.workyear }}
                                        </div>
                                        <div>
                                            学历要求：{{ itemb.education }}
                                        </div>
                                    </div>
                                    <div class="science-card4-tag1">
                                        <div>
                                            薪资范围：{{ itemb.salarymoney }}
                                        </div>
                                        <div>工作地点：{{ itemb.address }}</div>
                                    </div>

                                    <div class="science-card4-tag3">
                                        <div class="science-card4-data">
                                            {{ itemb.jobnum }}关注 |
                                            <span>{{ itemb.time }}</span>
                                        </div>
                                        <div @click.stop="goCompile">
                                            <img
                                                src="../../../assets/pc/Index/fj.png"
                                                alt=""
                                            />
                                            <div>发布招聘</div>
                                        </div>
                                        <el-button
                                            type="text"
                                            @click="open"
                                        ></el-button>
                                        <div>
                                            <div>查看详情</div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>

                        <!-- 就业招聘分页 -->
                        <div class="pageDown">
                            <Pagination
                                v-if="JSON.stringify() != ''"
                                :pageNo="joblistParams.page"
                                :pageSize="joblistParams.pageSize"
                                :total="joblistTotal"
                                :continues="5"
                                @getPageNo="getPageJoblistEv"
                            />
                        </div>
                        <!-- <button class="issue" @click.stop="goCompile" v-if="companyType == 1 || companyType == null">
                            去发布
                        </button> -->
                    </div>

                    <!-- 招商引资 -->
                    <div
                        class="survey-content-left4"
                        v-show="currentIndex == 3"
                    >
                        <ul>
                            <!-- 左侧内容区域列表 -->
                            <li
                                v-for="(itemd, indexd) in attractListDe"
                                :key="indexd"
                                @click="goAttractDetail(itemd)"
                            >
                                <div class="survey-item-img">
                                    <img :src="itemd.image" alt="" />
                                </div>
                                <div class="survey-item-text">
                                    <div class="survey-item-text1">
                                        {{ itemd.title }}
                                    </div>
                                    <div class="survey-item-text2">
                                        {{ itemd.description }}
                                    </div>
                                    <div class="survey-item-text3">
                                        时间：{{ itemd.createtime }}
                                    </div>
                                </div>
                            </li>

                            <!-- 左侧内容区域分页 -->
                            <div class="pageDown">
                                <Pagination
                                    v-if="JSON.stringify() != ''"
                                    :pageNo="attrParams.page"
                                    :pageSize="attrParams.pageSize"
                                    :total="attrTotal"
                                    :continues="5"
                                    @getPageNo="getPageattrEv"
                                />
                            </div>
                        </ul>
                    </div>

                    <!-- 产学研合作 -->
                    <div
                        class="survey-content-left4"
                        v-show="currentIndex == 4"
                    >
                        <ul>
                            <!-- 左侧内容区域列表 -->
                            <li
                                v-for="(itemd, indexd) in teamworkDe"
                                :key="indexd"
                                @click="goTeamworkDetail(itemd)"
                            >
                                <div class="survey-item-img">
                                    <img :src="itemd.image" alt="" />
                                </div>
                                <div class="survey-item-text">
                                    <div class="survey-item-text1">
                                        {{ itemd.title }}
                                    </div>
                                    <div class="survey-item-text2">
                                        {{ itemd.description }}
                                    </div>
                                    <div class="survey-item-text3">
                                        时间：{{ itemd.createtime }}
                                    </div>
                                </div>
                            </li>

                            <!-- 左侧内容区域分页 -->
                            <div class="pageDown">
                                <Pagination
                                    v-if="JSON.stringify() != ''"
                                    :pageNo="teamworkParams.page"
                                    :pageSize="teamworkParams.pageSize"
                                    :total="teamworkTotal"
                                    :continues="5"
                                    @getPageNo="getPageteamwork"
                                />
                            </div>
                        </ul>
                    </div>

                    <!-- 内容区域右侧 -->
                    <div class="survey-content-right">
                        <Right></Right>
                    </div>
                </div>
            </div>
            <!-- 内容区域 -->
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import Header from "@/components/Header.vue"; //头部
import Footer from "@/components/Footer.vue"; //尾部
import Right from "@/components/Right.vue"; //右侧
import Left3 from "@/components/Science/Left3.vue"; //左侧
import HeadNav from "@/components/Nav.vue"; //导航
import Pagination from "@/components/Pagination";
import Advertising from "@/components/Advertising.vue"; //广告图
import {
    needNavlist,
    needProblemlist,
    needOutcomelist,
    joblist,
    attractlist,
    teamworklist,
} from "../../../network/data";

export default {
    // 组件名称
    name: "demand",
    // 组件参数 接收来自父组件的数据
    props: {},
    // 组件状态值
    data() {
        return {
            // 存储企业会员登录返回的id
            memberId: "",
            // 判断显示发布问题还是解答问题、发布就业还是发布招聘，，1==解答、发布就业,2==发布难题、发布招聘
            companyType: 0,
            // 当前tap索引
            currentIndex: 0,
            // 需求导航栏id
            menuid: "1",
            // 关于学会tap数据
            machiningTap: [],
            // 技术难题分页
            searchParams: {
                page: 1,
                pageSize: 6,
            },
            total: 10,
            // 技术难题列表
            needList: [],
            // 成果转化分页
            setoutcomeParams: {
                page: 1,
                pageSize: 6,
            },
            setoutcomeTotle: 10,
            // 成果转换列表
            comeListDe: [],

            // 就业招聘
            joblistParams: {
                page: 1,
                pageSize: 6,
            },
            joblistTotal: 10,
            // 就业招聘列表
            joblist: [],

            // 招商引资分页
            attrParams: {
                page: 1,
                pageSize: 5,
            },
            attrTotal: 10,
            // 招商引资列表
            attractListDe: [],

            // 产学研合作分页
            teamworkParams: {
                page: 1,
                pageSize: 5,
            },
            teamworkTotal: 10,
            // 产学研合作列表
            teamworkDe: [],
        };
    },
    // 计算属性
    computed: {},
    // 侦听器
    watch: {
        $route(to, from) {
            window.location.reload(); //监测到路由发生跳转时刷新一次页面
        },
    },
    // 组件方法
    created() {
        // 判断显示发布问题还是解答问题、发布就业还是发布招聘，，1==解答、发布就业,2==发布难题、发布招聘
        this.companyType = sessionStorage.getItem("companyType");

        // console.log(JSON.stringify(this.$route.query.type), "123456");
        if (JSON.stringify(this.$route.query) != "{}") {
            this.currentIndex = this.$route.query.type;
        }

        // 存储企业会员登录返回的id
        this.memberId = sessionStorage.getItem("companyId");

        // 获取产业需求导航栏
        this.needNavlistRe();

        // 获取产业需求-技术难题列表
        this.needProblemlistRe();

        this.machiningTapEv();
    },
    methods: {
        // 获取产业需求导航栏
        needNavlistRe() {
            let data = {
                menuid: 20,
            };
            needNavlist(data).then((res) => {
                if (res.code == 1) {
                    // console.log(res.data, "需求导航栏");
                    this.machiningTap = res.data;
                } else {
                    this.message(res.msg);
                }
            });
        },
        // 产业需求-技术难题列表
        needProblemlistRe() {
            let data = {
                menuid: this.menuid,
                page: this.searchParams.page,
                pagesize: this.searchParams.pageSize,
            };
            needProblemlist(data).then((res) => {
                if (res.code == 1) {
                    // console.log(res.data);
                    this.needList = res.data.list.data;
                    console.log(this.needList, "666666");
                    this.searchParams.page = res.data.list.current_page;
                    this.searchParams.pageSize = res.data.list.per_page;
                    this.total = res.data.list.total;
                } else {
                    this.message(res.msg);
                }
            });
        },
        // 获取成果转换列表
        needOutcomelistRe() {
            let data = {
                menuid: this.menuid,
                page: this.setoutcomeParams.page,
                pagesize: this.setoutcomeParams.pageSize,
            };
            needOutcomelist(data).then((res) => {
                if (res.code == 1) {
                    // console.log(res.data, "成果转换列表");
                    this.comeListDe = res.data.list.data;
                    console.log(this.comeListDe, "8888888888888");
                    this.setoutcomeParams.page = res.data.list.current_page;
                    this.setoutcomeParams.pageSize = res.data.list.per_page;
                    this.setoutcomeTotle = res.data.list.total;
                } else {
                    this.message(res.msg);
                }
            });
        },
        // 获取就业招聘列表
        joblistRe() {
            let data = {
                menuid: this.menuid,
                page: this.joblistParams.page,
                pagesize: this.joblistParams.pageSize,
            };
            joblist(data).then((res) => {
                if (res.code == 1) {
                    // console.log(res.data, "就业招聘列表");
                    this.joblist = res.data.list.data;
                    this.joblistParams.page = res.data.list.current_page;
                    this.joblistParams.pageSize = res.data.list.per_page;
                    this.joblistTotal = res.data.list.total;
                } else {
                    this.message(res.msg);
                }
            });
        },
        // 招商引资列表数据
        attractlistRe() {
            let data = {
                menuid: this.menuid,
                page: this.attrParams.page,
                pagesize: this.attrParams.pageSize,
            };
            attractlist(data).then((res) => {
                if (res.code == 1) {
                    // console.log(res.data, "招商引资列表");
                    this.attractListDe = res.data.list.data;
                    this.attrParams.page = res.data.list.current_page;
                    this.attrParams.pageSize = res.data.list.per_page;
                    this.attrTotal = res.data.list.total;
                } else {
                    this.message(res.msg);
                }
            });
        },
        // 获取产学研合作列表数据
        teamworklistRe() {
            let data = {
                menuid: this.menuid,
                page: this.teamworkParams.page,
                pagesize: this.teamworkParams.pageSize,
            };
            teamworklist(data).then((res) => {
                if (res.code == 1) {
                    // console.log(res.data, "产学研合作列表");
                    this.teamworkDe = res.data.list.data;
                    this.teamworkParams.page = res.data.list.current_page;
                    this.teamworkParams.pageSize = res.data.list.per_page;
                    this.teamworkTotal = res.data.list.total;
                } else {
                    this.message(res.msg);
                }
            });
        },
        machiningTapEv(item, index) {
            // this.surveyData = [];
            this.currentIndex = index ? index : 0;
            this.menuid = item.id ? item.id : this.machiningTap[0].id;
            if (this.currentIndex == 0) {
                this.needProblemlistRe();
            } else if (this.currentIndex == 1) {
                this.needOutcomelistRe();
            } else if (this.currentIndex == 2) {
                this.joblistRe();
            } else if (this.currentIndex == 3) {
                this.attractlistRe();
            } else if (this.currentIndex == 4) {
                this.teamworklistRe();
            }
        },

        // 分页触发事件
        getPageEv(e) {
            this.searchParams.page = e;
            this.needProblemlistRe();
        },

        // 成果转化分页点击事件
        setoutcome(e) {
            this.setoutcomeParams.page = e;
            this.needOutcomelistRe();
        },

        // 就业招聘分页点击事件
        getPageJoblistEv(e) {
            // conosle.log("暂未设置");
            this.joblistParams.page = e;
            this.joblistRe();
        },

        // 招商引资分页点击事件
        getPageattrEv(e) {
            this.joblistParams.page = e;
            this.attractlistRe();
        },

        // 产学研合作分页点击事件
        getPageteamwork(e) {
            this.teamworkParams.page = e;
            this.teamworklistRe();
        },

        // 跳转到技术难题详情页
        ulEv1(item) {
            if (!sessionStorage.getItem("token")) {
                this.$router.push({
                    path: "/login",
                });
            } else {
                this.$router.push({
                    path: "/science/demandDetails",
                    query: {
                        id: item.id,
                    },
                });
            }
        },
        // 跳转到发布页面
        // issueEv(item, index) {
        //     if (!sessionStorage.getItem("token")) {
        //         this.$router.push({
        //             path: "/login",
        //         });
        //     } else {
        //         this.$router.push({
        //             path: `/science/DemandIssue/`,
        //             query: {
        //                 indexa: index,
        //                 lable_id: item.lable_id,
        //             },
        //         });
        //     }
        // },
        // 跳转到成果转化详情页
        resultDetil(id) {
            // cole.log(id, "成果id");
            this.$router.push({
                path: `/science/demandResultDetail`,
                query: {
                    id: id,
                    type: 2,
                },
            });
        },
        // 跳转到成果转化发布推广
        resultIssue() {
            if (!sessionStorage.getItem("token")) {
                this.$router.push({
                    path: "/login",
                });
            } else {
                this.$router.push({
                    path: "/science/demandResultIssue/",
                    query: {
                        // indexa: index,
                    },
                });
            }
        },
        // 跳转到招聘详情页
        inviteDetail(item) {
            // 跳转到招聘详情
            if (item.type == 1) {
                this.$router.push({
                    path: "/science/demandInviteDetail",
                    query: {
                        id: item.id,
                    },
                });
            } else {
                this.$router.push({
                    path: "/science/obtain",
                    query: {
                        id: item.id,
                    },
                });
            }
        },
        // 跳转到招商引资详情页
        goAttractDetail(item) {
            this.$router.push({
                path: `/consult/cousultDetail`,
                query: {
                    id: item.id,
                    type: 2,
                },
            });
        },
        // 跳转到产学研合作详情页
        goTeamworkDetail(item) {
            this.$router.push({
                path: `/consult/cousultDetail`,
                query: {
                    id: item.id,
                    type: 3,
                },
            });
        },
        tankuang() {
            this.$alert("请注册登录！", {
                confirmButtonText: "确定",
            });
        },
        // 跳转到去发布编辑页面
        goCompile(item) {
            // 判断是否登录
            if (!sessionStorage.getItem("token")) {
                // this.$router.push({
                //     path: "/login",
                // });
                this.$alert("请注册登录！", {
                    confirmButtonText: "确定",
                });
            } else {
                if (this.currentIndex == 0) {
                    // this.$router.push({
                    //     path: "/science/compile",
                    //     query: {
                    //         type: 10,
                    //     },
                    // });
                    if (this.companyType == 1) {
                        this.$router.push({
                            path: `/science/DemandIssue/`,
                            query: {
                                indexa: 1,
                            },
                        });
                    } else {
                        this.$router.push({
                            path: `/science/DemandIssue/`,
                            query: {
                                indexa: 0,
                            },
                        });
                    }
                } else if (this.currentIndex == 1) {
                    // this.$router.push({
                    //     path: "/science/compile",
                    //     query: {
                    //         type: 11,
                    //     },
                    // });
                    this.$router.push({
                        path: "/science/demandResultIssue/",
                        query: {
                            indexa: 1,
                        },
                    });
                } else if (this.currentIndex == 2) {
                    if (this.companyType == 1) {
                        this.$router.push({
                            path: "/science/recruit",
                            query: {
                                indexa: 0,
                            },
                        });
                    } else {
                        this.$router.push({
                            path: "/science/recruit",
                            query: {
                                indexa: 1,
                            },
                        });
                    }
                }
            }
        },
    },
    mounted() {},
    // 局部注册的组件
    components: {
        Header,
        Footer,
        Right,
        Left3,
        Pagination,
        HeadNav,
        Advertising,
    },
};
</script>

<style lang="scss">
.demand {
    width: 100%;

    .minkave-wrap {
        width: 70%;
        // height: 1000px;
        margin: 0 auto;
        margin-top: 10px;
        margin-bottom: 40px;
        // border: 1px solid red;

        /*顶部图片start*/
        .index-headImg {
            position: relative;
            z-index: 0;
            width: 100%;
            height: 50px;
            margin-top: 10px;
            display: flex;
            justify-content: space-between;

            // 左侧图片
            .index-headImg-left {
                width: 48%;
                height: 100%;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            // 右侧图片
            .index-headImg-right {
                width: 48%;
                height: 100%;

                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
        /*顶部图片end*/

        /*关于学会tap start*/
        .survey-nav {
            display: flex;
            align-items: center;
            width: 100%;
            padding: 10px 0;
            box-sizing: border-box;
            border-bottom: 0.5px solid #e4e4e4;

            .survey-nav-title {
                width: 30%;
                display: flex;
                align-items: center;
                // border: 1px solid red;

                .survey-title-cn {
                    font-size: 12px;
                    color: #0056b0;
                    margin-right: 15px;
                    // border: 1px solid red;
                }

                .survey-title-sn {
                    height: 10px;
                    line-height: 10px;
                    font-size: 6px;
                    color: #fff;
                    padding: 0 6px;
                    background-color: #0056b0;
                }
            }

            > ul {
                width: 70%;
                list-style: none;
                display: flex;
                // border: 1px solid red;

                .active {
                    color: #0068d5;
                }

                li {
                    cursor: pointer;
                    margin-left: 10px;
                    padding-left: 10px;
                    font-size: 10px;
                    border-left: 1px solid #e9e9e9;
                }
            }
        }
        /*关于学会tap end*/

        /*内容区域 start*/
        .survey-cont {
            width: 100%;

            .survey-content {
                display: flex;
                width: 100%;
                // height: 300px;
                // border: 1px solid red;

                // 内容区域左侧1(技术难题)
                .survey-content-left1 {
                    width: 68%;
                    height: 100%;

                    > ul {
                        display: flex;
                        justify-content: space-between;
                        flex-wrap: wrap;
                        margin-top: 10px;
                        list-style: none;

                        .issue {
                            cursor: pointer;
                            width: 60px;
                            height: 20px;
                            font-size: 12px;
                            background-color: #0056b0;
                            border: none;
                            border-radius: 10px;
                            color: #fff;
                        }

                        li {
                            position: relative;
                            width: 48%;
                            padding: 8px 8px;
                            box-sizing: border-box;
                            margin-bottom: 18px;
                            border: 1px solid #e9e9e9;

                            .science-card {
                                // padding: 0px;
                                box-sizing: border-box;

                                > img {
                                    position: absolute;
                                    top: 0px;
                                    left: 0px;
                                    width: 20px;
                                    height: 20px;
                                }

                                .science-card4-start {
                                    width: 100px;
                                    display: flex;
                                    justify-content: space-between;

                                    > div:nth-child(1) {
                                        font-size: 9px;
                                        margin-left: 30px;
                                        white-space: nowrap;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                    }
                                }

                                .science-card4-tag0 {
                                    margin-top: 6px;
                                    font-size: 7px;
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                }

                                .science-card4-tag5 {
                                    display: none;
                                }

                                .science-card4-tag4 {
                                    margin-top: 3px;
                                    font-size: 7px;
                                    > div {
                                        width: 100%;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        display: -webkit-box;
                                        -webkit-line-clamp: 2; //行数
                                        -webkit-box-orient: vertical;
                                    }
                                }

                                .science-card4-tag1 {
                                    display: flex;
                                    margin-top: 3px;
                                    font-size: 7px;

                                    > div:nth-child(1) {
                                        margin-right: auto;
                                    }
                                }
                                .science-card4-tag2 {
                                    display: flex;
                                    align-items: center;
                                    margin-top: 3px;
                                    font-size: 7px;
                                    color: #c1c1c1;

                                    > div:nth-child(1) {
                                        font-size: 7px;
                                        color: #c1c1c1;
                                    }

                                    .science-card4-tag3 {
                                        display: flex;
                                        justify-content: space-around;
                                        margin-top: 3px;
                                        font-size: 6px;
                                        cursor: pointer;
                                        margin-left: auto;

                                        > div:nth-child(1) {
                                            display: flex;
                                            margin-right: 20px;
                                            img {
                                                width: 10px;
                                                height: 10px;
                                                margin-right: 5px;
                                            }
                                        }

                                        > div:nth-child(2) {
                                            display: flex;
                                            img {
                                                width: 9px;
                                                height: 9px;
                                                // margin-top: 3px;
                                                margin-right: 5px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    // 助企名家end

                    // 左侧内容区域分页
                    .pageDown {
                        // display: block;
                        width: 100%;
                        margin: 30px 0;
                    }
                }
                // 内容区域左侧2(成果转化 start)
                .survey-content-left2 {
                    width: 68%;
                    height: 100%;
                    margin-top: 10px;
                    // border: 1px solid blue;

                    .issue {
                        cursor: pointer;
                        width: 60px;
                        height: 20px;
                        font-size: 12px;
                        background-color: #0056b0;
                        border: none;
                        border-radius: 10px;
                        color: #fff;
                    }

                    > ul {
                        width: 100%;
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: space-between;
                        list-style: none;

                        li {
                            width: 48%;
                            height: 100px;
                            padding: 5px;
                            box-sizing: border-box;
                            margin-bottom: 18px;
                            border: 1px solid #e6e6e6;

                            .survey-left2-title1 {
                                font-size: 12px;
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            }

                            .survey-left2-title2 {
                                display: flex;
                                font-size: 6px;
                                margin-top: 6px;

                                > div:nth-child(1) {
                                    color: #666666;
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                }
                                > div:nth-child(2) {
                                    margin-left: auto;
                                    color: #666666;
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                }
                            }

                            .survey-left2-footer {
                                display: flex;
                                margin-top: 13px;
                                font-size: 7px;

                                > div:nth-child(1) {
                                    // font-size: 7px;
                                }
                                > div:nth-child(2) {
                                    display: flex;
                                    align-items: center;
                                    margin-left: auto;
                                    margin-right: 10px;
                                    cursor: pointer;

                                    > img {
                                        width: 13px;
                                        height: 9px;
                                        margin-right: 2px;
                                    }
                                }
                                > div:nth-child(3) {
                                    display: flex;
                                    align-items: center;
                                    cursor: pointer;

                                    > img {
                                        width: 10px;
                                        height: 9px;
                                        margin-right: 2px;
                                    }
                                }
                            }
                        }

                        // 成果转化分页
                        .pageDown {
                            width: 100%;
                            margin: 30px 0;
                        }
                    }
                }
                // 内容区域左侧2(成果转化 end)

                // 内容区域左侧3(就业招聘 start)
                .survey-content-left3 {
                    width: 68%;
                    height: 100%;

                    .issue {
                        cursor: pointer;
                        width: 60px;
                        height: 20px;
                        font-size: 12px;
                        background-color: #0056b0;
                        border: none;
                        border-radius: 10px;
                        color: #fff;
                    }

                    .ul2 {
                        display: flex;
                        justify-content: space-between;
                        flex-wrap: wrap;
                        margin-top: 10px;
                        list-style: none;

                        li {
                            width: 48%;
                            padding: 8px 8px;
                            box-sizing: border-box;
                            margin-bottom: 18px;
                            border: 1px solid #e6e6e6;

                            .science-card {
                                // padding: 0px;
                                box-sizing: border-box;

                                .science-card4-start {
                                    display: flex;

                                    > div {
                                        text-align: center;
                                        width: 30px;
                                        // height:5px;
                                        font-size: 7px;
                                        margin-right: 3px;
                                        color: #fff;
                                        background-color: #5daa00;
                                    }
                                }

                                .science-card4-tag1 {
                                    display: flex;
                                    margin-top: 4px;
                                    font-size: 7px;

                                    > div:nth-child(1) {
                                        color: #666666;
                                        white-space: nowrap;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                    }

                                    > div:nth-child(2) {
                                        margin-left: auto;
                                        color: #666666;
                                        white-space: nowrap;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                    }
                                }

                                .science-card4-tag3 {
                                    display: flex;
                                    align-items: center;
                                    // justify-content: space-around;
                                    margin-top: 15px;
                                    font-size: 8px;
                                    // border: 1px solid #e6e6e6;

                                    > div:nth-child(1) {
                                        cursor: pointer;
                                        font-size: 6px;
                                    }

                                    > div:nth-child(2) {
                                        cursor: pointer;
                                        display: flex;
                                        margin-left: auto;
                                        margin-right: 6px;

                                        img {
                                            width: 10px;
                                            height: 10px;
                                            margin-right: 5px;
                                        }
                                    }

                                    > div:nth-child(3) {
                                        cursor: pointer;
                                        display: flex;
                                        img {
                                            width: 9px;
                                            height: 9px;
                                            // margin-top: 3px;
                                            margin-right: 5px;
                                        }
                                    }
                                }
                            }
                        }

                        // 就业招聘分页
                        .pageDown {
                        }
                    }
                }
                // 内容区域左侧3(就业招聘 end)

                // 内容区域左侧4(招商引资、产学研合作 start)
                .survey-content-left4 {
                    width: 67%;
                    height: 100%;

                    > ul {
                        list-style: none;

                        // 左侧内容区域列表
                        li {
                            width: 100%;
                            height: 80px;
                            display: flex;
                            padding: 15px 0;
                            border-bottom: 0.5px solid #e6e6e6;

                            .survey-item-img {
                                width: 26%;
                                height: 100%;

                                img {
                                    width: 100%;
                                    height: 100%;
                                }
                            }

                            .survey-item-text {
                                width: 72%;
                                display: flex;
                                flex-direction: column;
                                margin-left: auto;
                                font-size: 9px;

                                .survey-item-text1 {
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                }

                                .survey-item-text2 {
                                    margin-top: 20px;
                                    font-size: 7px;
                                    color: #999999;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    display: -webkit-box;
                                    -webkit-line-clamp: 2; //行数
                                    -webkit-box-orient: vertical;
                                }

                                .survey-item-text3 {
                                    margin-top: auto;
                                    font-size: 8px;
                                    color: #999999;
                                }
                            }
                        }

                        // 招商引资、产学研合作左侧内容区域分页
                        .pageDown {
                            width: 100%;
                            margin: 20px auto;
                            text-align: start;
                        }
                    }
                }
                // 内容区域左侧4(招商引资 end)

                // 内容区域右侧
                .survey-content-right {
                    margin-left: auto;
                    width: 30%;
                    // border: 1px solid red;
                }
            }
        }

        /*内容区域 end*/
    }
}

//当屏幕最小宽度为992px
@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .demand {
        width: 100%;

        .minkave-wrap {
            width: 70%;
            // height: 1000px;
            margin: 0 auto;
            margin-top: 10px;
            // border: 1px solid red;

            /*顶部图片start*/
            .index-headImg {
                position: relative;
                z-index: 0;
                width: 100%;
                height: 50px;
                margin-top: 10px;
                display: flex;
                justify-content: space-between;

                // 左侧图片
                .index-headImg-left {
                    width: 48%;
                    height: 100%;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }

                // 右侧图片
                .index-headImg-right {
                    width: 48%;
                    height: 100%;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
            /*顶部图片end*/

            /*关于学会tap start*/
            .survey-nav {
                display: flex;
                align-items: center;
                width: 100%;
                padding: 10px 0;
                box-sizing: border-box;
                border-bottom: 0.5px solid #e4e4e4;

                .survey-nav-title {
                    width: 30%;
                    display: flex;
                    align-items: center;
                    // border: 1px solid red;

                    .survey-title-cn {
                        width: 60px;
                        font-size: 12px;
                        color: #0056b0;
                        margin-right: 15px;
                        // border: 1px solid red;
                    }

                    .survey-title-sn {
                        height: 10px;
                        line-height: 10px;
                        font-size: 6px;
                        color: #fff;
                        padding: 0 6px;
                        background-color: #0056b0;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }

                > ul {
                    width: 70%;
                    list-style: none;
                    display: flex;
                    // border: 1px solid red;

                    .active {
                        color: #0068d5;
                    }

                    li {
                        cursor: pointer;
                        text-align: center;
                        margin-left: 10px;
                        padding-left: 10px;
                        font-size: 7.5px;
                        border-left: 1px solid #e9e9e9;
                    }
                }
            }
            /*关于学会tap end*/

            /*内容区域 start*/
            .survey-cont {
                width: 100%;

                .survey-content {
                    display: flex;
                    width: 100%;
                    // height: 300px;
                    // border: 1px solid red;

                    // 内容区域左侧1(技术难题)
                    .survey-content-left1 {
                        width: 68%;
                        height: 100%;
                        // border: 1px solid blue;

                        > ul {
                            display: flex;
                            justify-content: space-between;
                            flex-wrap: wrap;
                            margin-top: 10px;
                            list-style: none;

                            .issue {
                                cursor: pointer;
                                width: 60px;
                                height: 20px;
                                font-size: 12px;
                                background-color: #0056b0;
                                border: none;
                                border-radius: 10px;
                                color: #fff;
                            }

                            li {
                                width: 48%;
                                padding: 8px 8px;
                                box-sizing: border-box;
                                margin-bottom: 18px;
                                border: 1px solid #e9e9e9;

                                .science-card {
                                    // padding: 0px;
                                    box-sizing: border-box;

                                    > img {
                                        position: absolute;
                                        top: 0px;
                                        left: 0px;
                                        width: 20px;
                                        height: 20px;
                                    }

                                    .science-card4-start {
                                        display: flex;
                                        justify-content: space-between;

                                        > div:nth-child(1) {
                                            font-size: 9px;
                                            margin-left: 30px;
                                        }
                                    }

                                    .science-card4-tag0 {
                                        margin-top: 3px;
                                        font-size: 7px;
                                    }

                                    .science-card4-tag4 {
                                        width: 100%;
                                        margin-top: 3px;
                                        font-size: 7px;

                                        > div {
                                            width: 100%;
                                            overflow: hidden;
                                            text-overflow: ellipsis;
                                            display: -webkit-box;
                                            -webkit-line-clamp: 1; //行数
                                            -webkit-box-orient: vertical;
                                        }
                                    }

                                    .science-card4-tag1 {
                                        display: flex;
                                        margin-top: 3px;
                                        font-size: 7px;

                                        > div:nth-child(1) {
                                            margin-right: auto;
                                        }

                                        > div:nth-child(2) {
                                            display: none;
                                        }
                                    }

                                    .science-card4-tag5 {
                                        display: block;
                                        margin-top: 3px;
                                        font-size: 7px;
                                    }

                                    .science-card4-tag2 {
                                        display: flex;
                                        align-items: center;
                                        margin-top: 3px;
                                        font-size: 7px;
                                        color: #c1c1c1;

                                        > div:nth-child(1) {
                                            font-size: 7px;
                                            color: #c1c1c1;
                                        }

                                        .science-card4-tag3 {
                                            display: flex;
                                            justify-content: space-around;
                                            margin-top: 3px;
                                            font-size: 6px;
                                            cursor: pointer;
                                            margin-left: auto;

                                            > div:nth-child(1) {
                                                display: flex;
                                                img {
                                                    width: 10px;
                                                    height: 10px;
                                                    margin-right: 5px;
                                                }
                                            }

                                            > div:nth-child(2) {
                                                display: flex;
                                                img {
                                                    width: 9px;
                                                    height: 9px;
                                                    // margin-top: 3px;
                                                    margin-right: 5px;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        // 助企名家end

                        // 左侧内容区域分页
                        .pageDown {
                            // display: block;
                            width: 100%;
                            margin: 30px 0;
                        }
                    }
                    // 内容区域左侧2(成果转化 start)
                    .survey-content-left2 {
                        width: 68%;
                        height: 100%;
                        margin-top: 10px;
                        // border: 1px solid blue;

                        .issue {
                            cursor: pointer;
                            width: 60px;
                            height: 20px;
                            font-size: 12px;
                            background-color: #0056b0;
                            border: none;
                            border-radius: 10px;
                            color: #fff;
                        }

                        > ul {
                            width: 100%;
                            display: flex;
                            flex-wrap: wrap;
                            justify-content: space-between;
                            list-style: none;

                            li {
                                width: 48%;
                                height: 90px;
                                padding: 5px;
                                box-sizing: border-box;
                                margin-bottom: 18px;
                                border: 1px solid #e6e6e6;

                                .survey-left2-title1 {
                                    font-size: 12px;
                                }

                                .survey-left2-title2 {
                                    margin-top: 3px;
                                    font-size: 7px;
                                    color: #666666;
                                }

                                .survey-left2-footer {
                                    display: flex;
                                    margin-top: 8px;
                                    font-size: 6px;

                                    > div:nth-child(1) {
                                        // font-size: 7px;
                                        white-space: nowrap;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                    }
                                    > div:nth-child(2) {
                                        display: flex;
                                        align-items: center;
                                        margin-left: auto;
                                        margin-right: 10px;
                                        cursor: pointer;

                                        > img {
                                            width: 10px;
                                            height: 9px;
                                            margin-right: 2px;
                                        }
                                    }
                                    > div:nth-child(3) {
                                        display: flex;
                                        align-items: center;
                                        cursor: pointer;

                                        > img {
                                            width: 10px;
                                            height: 9px;
                                            margin-right: 2px;
                                        }
                                    }
                                }
                            }

                            // 成果转化分页
                            .pageDown {
                                width: 100%;
                                margin: 30px 0;
                            }
                        }
                    }
                    // 内容区域左侧2(成果转化 end)

                    // 内容区域左侧3(就业招聘 start)
                    .survey-content-left3 {
                        width: 68%;
                        height: 100%;

                        .issue {
                            cursor: pointer;
                            width: 60px;
                            height: 20px;
                            font-size: 12px;
                            background-color: #0056b0;
                            border: none;
                            border-radius: 10px;
                            color: #fff;
                        }

                        .ul2 {
                            display: flex;
                            justify-content: space-between;
                            flex-wrap: wrap;
                            margin-top: 10px;
                            list-style: none;

                            li {
                                width: 48%;
                                padding: 8px 8px;
                                box-sizing: border-box;
                                margin-bottom: 18px;
                                border: 1px solid #e6e6e6;

                                .science-card {
                                    // padding: 0px;
                                    box-sizing: border-box;

                                    .science-card4-start {
                                        display: flex;

                                        > div {
                                            text-align: center;
                                            width: 30px;
                                            // height:5px;
                                            font-size: 7px;
                                            margin-right: 3px;
                                            color: #fff;
                                            background-color: #5daa00;
                                        }
                                    }

                                    .science-card4-tag1 {
                                        display: flex;
                                        margin-top: 6px;
                                        font-size: 7px;

                                        > div:nth-child(1) {
                                            color: #666666;
                                            white-space: nowrap;
                                            overflow: hidden;
                                            text-overflow: ellipsis;
                                        }

                                        > div:nth-child(2) {
                                            margin-left: auto;
                                            color: #666666;
                                            white-space: nowrap;
                                            overflow: hidden;
                                            text-overflow: ellipsis;
                                        }
                                    }
                                    .science-card4-tag3 {
                                        display: flex;
                                        align-items: center;
                                        // justify-content: space-around;
                                        margin-top: 15px;
                                        font-size: 7px;
                                        // border: 1px solid #e6e6e6;

                                        > div:nth-child(1) {
                                            display: none;
                                            cursor: pointer;
                                            font-size: 6px;
                                        }

                                        > div:nth-child(2) {
                                            cursor: pointer;
                                            display: flex;

                                            margin: 0px;
                                            margin-right: 6px;

                                            img {
                                                width: 10px;
                                                height: 10px;
                                                margin-right: 5px;
                                            }
                                        }

                                        > div:nth-child(3) {
                                            cursor: pointer;
                                            display: flex;
                                            margin-left: auto;
                                            img {
                                                width: 9px;
                                                height: 9px;
                                                // margin-top: 3px;
                                                margin-right: 5px;
                                            }
                                        }
                                    }
                                }
                            }

                            // 就业招聘分页
                            .pageDown {
                            }
                        }
                    }
                    // 内容区域左侧3(就业招聘 end)

                    // 内容区域左侧4(招商引资、产学研合作 start)
                    .survey-content-left4 {
                        width: 67%;
                        height: 100%;

                        > ul {
                            list-style: none;

                            // 左侧内容区域列表
                            li {
                                width: 100%;
                                height: 70px;
                                display: flex;
                                padding: 13px 0 10px 0;
                                border-bottom: 0.5px solid #e6e6e6;

                                .survey-item-img {
                                    width: 26%;
                                    height: 100%;

                                    img {
                                        width: 100%;
                                        height: 100%;
                                    }
                                }

                                .survey-item-text {
                                    width: 72%;
                                    display: flex;
                                    flex-direction: column;
                                    margin-left: auto;
                                    font-size: 9px;
                                    .survey-item-text1 {
                                    }

                                    .survey-item-text2 {
                                        margin-top: 10px;
                                        font-size: 7px;
                                        color: #999999;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        display: -webkit-box;
                                        -webkit-line-clamp: 2; //行数
                                        -webkit-box-orient: vertical;
                                    }

                                    .survey-item-text3 {
                                        margin-top: auto;
                                        font-size: 8px;
                                        color: #999999;
                                    }
                                }
                            }

                            // 招商引资、产学研合作左侧内容区域分页
                            .pageDown {
                                width: 100%;
                                margin: 20px auto;
                                text-align: start;
                            }
                        }
                    }
                    // 内容区域左侧4(招商引资 end)

                    // 内容区域右侧
                    .survey-content-right {
                        margin-left: auto;
                        width: 30%;
                        // border: 1px solid red;
                    }
                }
            }

            /*内容区域 end*/
        }
    }
}

//当屏幕宽度 最小为768px 最大为991px时
@media screen and (min-width: 768px) and (max-width: 1200px) {
    .demand {
        width: 100%;

        .minkave-wrap {
            width: 80%;
            // height: 1000px;
            margin: 0 auto;
            margin-top: 10px;
            margin-bottom: 40px;
            // border: 1px solid red;

            /*顶部图片start*/
            .index-headImg {
                position: relative;
                z-index: 0;
                width: 100%;
                height: 50px;
                margin-top: 10px;
                display: flex;
                justify-content: space-between;

                // 左侧图片
                .index-headImg-left {
                    width: 48%;
                    height: 100%;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }

                // 右侧图片
                .index-headImg-right {
                    width: 48%;
                    height: 100%;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
            /*顶部图片end*/

            /*关于学会tap start*/
            .survey-nav {
                display: flex;
                align-items: center;
                width: 100%;
                padding: 10px 0;
                box-sizing: border-box;
                border-bottom: 1px solid #e4e4e4;

                .survey-nav-title {
                    width: 30%;
                    display: flex;
                    align-items: center;
                    // border: 1px solid red;

                    .survey-title-cn {
                        width: 60px;
                        font-size: 10px;
                        color: #0056b0;
                        margin-right: 0px;
                    }

                    .survey-title-sn {
                        height: 10px;
                        line-height: 10px;
                        font-size: 6px;
                        color: #fff;
                        padding: 0 6px;
                        background-color: #0056b0;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }

                > ul {
                    width: 70%;
                    list-style: none;
                    display: flex;
                    // border: 1px solid red;

                    .active {
                        color: #0068d5;
                    }

                    li {
                        cursor: pointer;
                        margin-left: 5px;
                        padding-left: 5px;
                        font-size: 6px;
                        border-left: 1px solid #e9e9e9;
                    }
                }
            }
            /*关于学会tap end*/

            /*内容区域 start*/
            .survey-cont {
                width: 100%;

                .survey-content {
                    display: flex;
                    width: 100%;
                    // height: 300px;
                    // border: 1px solid red;

                    // 内容区域左侧1(技术难题)
                    .survey-content-left1 {
                        width: 68%;
                        height: 100%;
                        // border: 1px solid blue;

                        > ul {
                            display: flex;
                            justify-content: space-between;
                            flex-wrap: wrap;
                            margin-top: 10px;
                            list-style: none;

                            li {
                                width: 48%;
                                padding: 8px 8px;
                                box-sizing: border-box;
                                margin-bottom: 10px;
                                border: 0.5px solid #e6e6e6;

                                .science-card {
                                    // padding: 0px;
                                    box-sizing: border-box;

                                    > img {
                                        position: absolute;
                                        top: 0px;
                                        left: 0px;
                                        width: 20px;
                                        height: 20px;
                                    }

                                    .science-card4-start {
                                        display: flex;
                                        justify-content: space-between;

                                        > div:nth-child(1) {
                                            font-size: 9px;
                                            margin-left: 30px;
                                        }
                                    }

                                    .science-card4-tag0 {
                                        margin-top: 3px;
                                        font-size: 7px;
                                    }

                                    .science-card4-tag4 {
                                        margin-top: 3px;
                                        font-size: 7px;
                                        > div {
                                            width: 50px;
                                            white-space: nowrap;
                                            overflow: hidden;
                                            text-overflow: ellipsis;
                                        }
                                    }

                                    .science-card4-tag1 {
                                        display: flex;
                                        margin-top: 3px;
                                        font-size: 7px;

                                        > div:nth-child(1) {
                                            margin-right: auto;
                                        }

                                        > div:nth-child(2) {
                                            display: none;
                                        }
                                    }

                                    .science-card4-tag5 {
                                        display: block;
                                        margin-top: 3px;
                                        font-size: 7px;
                                    }

                                    .science-card4-tag2 {
                                        display: flex;
                                        align-items: center;
                                        margin-top: 3px;
                                        font-size: 7px;
                                        color: #c1c1c1;

                                        > div:nth-child(1) {
                                            display: none;
                                        }

                                        .science-card4-tag3 {
                                            display: flex;
                                            justify-content: space-around;
                                            margin-top: 3px;
                                            font-size: 6px;
                                            cursor: pointer;
                                            margin-left: auto;

                                            > div:nth-child(1) {
                                                display: flex;
                                                img {
                                                    width: 10px;
                                                    height: 10px;
                                                    margin-right: 5px;
                                                }
                                            }

                                            > div:nth-child(2) {
                                                display: flex;
                                                img {
                                                    width: 9px;
                                                    height: 9px;
                                                    // margin-top: 3px;
                                                    margin-right: 5px;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        // 助企名家end

                        // 左侧内容区域分页
                        .pageDown {
                            width: 100%;
                            margin: 20px auto;
                            text-align: start;
                            // border: 1px solid blue;

                            .el-pagination {
                                display: flex;
                                // border: 1px solid red !important;

                                .btn-prev {
                                    width: 40px;
                                    height: 20px;
                                    color: black;
                                    box-sizing: border-box;
                                    border-radius: 0px !important;
                                    border: 1px solid #337136 !important;

                                    > span {
                                        line-height: 20px;
                                        font-size: 10px !important;
                                    }
                                }
                                .el-pager {
                                    display: flex;
                                    .active {
                                        background-color: #95bf97 !important;
                                    }
                                    li {
                                        display: flex;
                                        justify-content: center;
                                        height: 20px;
                                        width: 20px;
                                        line-height: 20px;
                                        font-size: 7px;
                                        color: black;
                                        padding: 0;
                                        box-sizing: border-box;
                                        border-radius: 0px !important;
                                        border: 1px solid #337136 !important;
                                    }
                                }

                                .btn-next {
                                    width: 40px;
                                    height: 20px;
                                    color: black;
                                    box-sizing: border-box;
                                    border-radius: 0px !important;
                                    border: 1px solid #337136 !important;

                                    > span {
                                        line-height: 20px;
                                        font-size: 10px !important;
                                    }
                                }
                            }
                        }
                    }
                    // 内容区域左侧2(成果转化 start)
                    .survey-content-left2 {
                        width: 68%;
                        height: 100%;
                        // border: 1px solid blue;

                        > ul {
                            width: 100%;
                            display: flex;
                            flex-wrap: wrap;
                            justify-content: space-between;
                            list-style: none;

                            li {
                                width: 48%;
                                height: 80px;
                                padding: 5px;
                                box-sizing: border-box;
                                margin-bottom: 18px;
                                // border: 1px solid red;

                                .survey-left2-title1 {
                                    font-size: 12px;
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                }

                                .survey-left2-title2 {
                                    display: flex;
                                    font-size: 6px;
                                    margin-top: 4px;

                                    > div:nth-child(1) {
                                        color: #666666;
                                        white-space: nowrap;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                    }

                                    > div:nth-child(2) {
                                        margin-left: auto;
                                        color: #666666;
                                        white-space: nowrap;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                    }
                                }

                                .survey-left2-footer {
                                    display: flex;
                                    margin-top: 8px;
                                    font-size: 6px;
                                    // border: 1px solid red;

                                    > div:nth-child(1) {
                                        display: none;
                                        // font-size: 7px;
                                        white-space: nowrap;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                    }
                                    > div:nth-child(2) {
                                        display: flex;
                                        align-items: center;
                                        margin-right: 10px;
                                        margin: 0px;
                                        cursor: pointer;
                                        // border: 1px solid red;

                                        > img {
                                            width: 10px;
                                            height: 9px;
                                            margin-right: 2px;
                                        }
                                    }
                                    > div:nth-child(3) {
                                        margin-left: auto;
                                        display: flex;
                                        align-items: center;
                                        cursor: pointer;

                                        > img {
                                            width: 10px;
                                            height: 9px;
                                            margin-right: 2px;
                                        }
                                    }
                                }
                            }

                            // 成果转化分页
                            .pageDown {
                                width: 100%;
                                margin: 20px auto;
                                text-align: start;
                                // border: 1px solid blue;
                            }
                        }
                    }
                    // 内容区域左侧2(成果转化 end)

                    // 内容区域左侧3(就业招聘 start)
                    .survey-content-left3 {
                        .ul2 {
                            display: flex;
                            justify-content: space-between;
                            flex-wrap: wrap;
                            margin-top: 10px;
                            list-style: none;

                            li {
                                width: 48%;
                                padding: 8px 8px;
                                box-sizing: border-box;
                                margin-bottom: 5px;
                                // border: 1px solid orchid;

                                .science-card {
                                    // padding: 0px;
                                    box-sizing: border-box;

                                    .science-card4-start {
                                        display: flex;

                                        > div {
                                            text-align: center;
                                            width: 30px;
                                            // height:5px;
                                            font-size: 7px;
                                            margin-right: 3px;
                                            color: #fff;
                                            background-color: #5daa00;
                                        }
                                    }

                                    .science-card4-tag1 {
                                        display: flex;
                                        margin-top: 4px;
                                        font-size: 7px;

                                        > div:nth-child(1) {
                                            color: #666666;
                                            white-space: nowrap;
                                            overflow: hidden;
                                            text-overflow: ellipsis;
                                        }

                                        > div:nth-child(2) {
                                            margin-left: auto;
                                            color: #666666;
                                            white-space: nowrap;
                                            overflow: hidden;
                                            text-overflow: ellipsis;
                                        }
                                    }

                                    .science-card4-tag3 {
                                        display: flex;
                                        align-items: center;
                                        // justify-content: space-around;
                                        margin-top: 15px;
                                        font-size: 7px;
                                        // border: 1px solid #e6e6e6;

                                        > div:nth-child(1) {
                                            display: none;
                                            cursor: pointer;
                                            font-size: 6px;
                                        }

                                        > div:nth-child(2) {
                                            cursor: pointer;
                                            display: flex;

                                            margin: 0px;
                                            margin-right: 6px;

                                            img {
                                                width: 10px;
                                                height: 10px;
                                                margin-right: 5px;
                                            }
                                        }

                                        > div:nth-child(3) {
                                            cursor: pointer;
                                            display: flex;
                                            margin-left: auto;
                                            img {
                                                width: 9px;
                                                height: 9px;
                                                // margin-top: 3px;
                                                margin-right: 5px;
                                            }
                                        }
                                    }
                                }
                            }

                            // 就业招聘分页
                            .pageDown {
                                width: 100%;
                                margin: 20px auto;
                                text-align: start;
                                // border: 1px solid blue;

                                .el-pagination {
                                    display: flex;
                                    // border: 1px solid red !important;

                                    .btn-prev {
                                        width: 40px;
                                        height: 20px;
                                        color: black;
                                        box-sizing: border-box;
                                        border-radius: 0px !important;
                                        border: 1px solid #337136 !important;

                                        > span {
                                            line-height: 20px;
                                            font-size: 10px !important;
                                        }
                                    }
                                    .el-pager {
                                        display: flex;
                                        .active {
                                            background-color: #95bf97 !important;
                                        }
                                        li {
                                            display: flex;
                                            justify-content: center;
                                            height: 20px;
                                            width: 20px;
                                            line-height: 20px;
                                            font-size: 7px;
                                            color: black;
                                            padding: 0;
                                            box-sizing: border-box;
                                            border-radius: 0px !important;
                                            border: 1px solid #337136 !important;
                                        }
                                    }

                                    .btn-next {
                                        width: 40px;
                                        height: 20px;
                                        color: black;
                                        box-sizing: border-box;
                                        border-radius: 0px !important;
                                        border: 1px solid #337136 !important;

                                        > span {
                                            line-height: 20px;
                                            font-size: 10px !important;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    // 内容区域左侧3(就业招聘 end)

                    // 内容区域左侧4(招商引资、产学研合作 start)
                    .survey-content-left4 {
                        > ul {
                            list-style: none;

                            // 左侧内容区域列表
                            li {
                                width: 100%;
                                height: 60px;
                                display: flex;
                                padding: 10px 0;
                                border-bottom: 0.5px solid #999999;

                                .survey-item-img {
                                    width: 28%;
                                    height: 100%;

                                    img {
                                        width: 100%;
                                        height: 100%;
                                    }
                                }

                                .survey-item-text {
                                    width: 68%;
                                    display: flex;
                                    flex-direction: column;
                                    margin-left: auto;
                                    font-size: 9px;
                                    .survey-item-text1 {
                                        white-space: nowrap;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                    }

                                    .survey-item-text2 {
                                        margin-top: 10px;
                                        font-size: 7px;
                                        color: #999999;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        display: -webkit-box;
                                        -webkit-line-clamp: 2; //行数
                                        -webkit-box-orient: vertical;
                                    }

                                    .survey-item-text3 {
                                        margin-top: auto;
                                        font-size: 8px;
                                        color: #999999;
                                    }
                                }
                            }

                            // 招商引资、产学研合作左侧内容区域分页
                            .pageDown {
                                width: 100%;
                                margin: 20px auto;
                                text-align: start;
                                // border: 1px solid blue;
                            }
                        }
                    }
                    // 内容区域左侧4(招商引资 end)

                    // 内容区域右侧
                    .survey-content-right {
                        margin-left: auto;
                        width: 30%;
                        // border: 1px solid red;
                    }
                }
            }

            /*内容区域 end*/
        }
    }
}

//当屏幕最大宽度为767px
@media screen and (max-width: 767px) {
}
</style>
