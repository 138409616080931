<template>
    <!-- 政策法规 -->
    <div class="policy">
        <Header></Header>
        <HeadNav></HeadNav>
        <div class="prefabricate-wrap">
            <!-- 顶部图片 -->
            <Advertising></Advertising>

            <!-- 关于学会tap -->
            <div class="survey-nav">
                <!-- <Nav></Nav> -->
                <div class="survey-nav-title">
                    <div class="survey-title-cn">政策法规</div>
                    <!-- <div class="survey-title-sn">About the Institute</div> -->
                </div>

                <ul>
                    <!-- <li
                        :key="index"
                        v-for="(item, index) in lawTap"
                        @click="surveyTapEv(index)"
                        :class="currentIndex == index ? 'active' : ''"
                    >
                        {{ item.name }}
                    </li> -->
                </ul>
            </div>

            <!-- 内容区域,包含学会概况、学会章程、入会申请、通知通告、学会要闻、企业会员 -->
            <div class="survey-cont">
                <div class="survey-content">
                    <div class="survey-content-left">
                        <ul>
                            <!-- 左侧内容区域列表 -->
                            <li
                                :key="indexd"
                                v-for="(itemd, indexd) in lawListDe.data"
                                @click="goPolicyDetail(itemd, indexd)"
                            >
                                <div class="survey-item-img">
                                    <img
                                        :src="itemd.image"
                                        alt=""
                                    />
                                </div>
                                
                                <div class="survey-item-text">
                                    <div class="survey-item-text1">
                                        {{ itemd.title }}
                                    </div>
                                    <div class="survey-item-text2">
                                        {{ itemd.description }}
                                    </div>
                                    <div class="survey-item-text3">
                                        时间：{{ itemd.createtime }}
                                    </div>
                                </div>
                            </li>

                            <!-- 左侧内容区域分页 -->
                            <div class="pageDown">
                                <Pagination
                                    v-if="JSON.stringify(lawListDe) != ''"
                                    :pageNo="lawParams.page"
                                    :pageSize="lawParams.pageSize"
                                    :total="total"
                                    :continues="5"
                                    @getPageNo="getPageEv"
                                />
                            </div>
                        </ul>
                    </div>
                    <div class="survey-content-right">
                        <Right></Right>
                    </div>
                </div>
            </div>
            <!-- 内容区域 -->
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import HeadNav from "@/components/Nav.vue"; //导航
import Header from "@/components/Header.vue"; //头部
import Footer from "@/components/Footer.vue"; //尾部
import Right from "@/components/Right.vue"; //关于右侧组件
import Nav from "@/components/About/nav.vue"; //关于tap切换
import Pagination from "@/components/Pagination"; //分页组件
import Advertising from "@/components/Advertising.vue"; //广告图
import { lawlist } from "../../../network/data";
export default {
    name: "policy",
    data() {
        return {
            // 分页
            lawParams: {
                page: 1,
                pageSize: 3,
            },
            total: 10,
            // 政策法规列表数据
            lawListDe: {},
        };
    },
    computed: {},
    watch: {
        // $route(to, from) {
        //     window.location.reload(); //监测到路由发生跳转时刷新一次页面
        // },
    },

    created() {
        // 获取政策法规列表数据
        this.lawlistRe();
    },
    methods: {
        // 分页触发事件
        getPageEv(index) {
            this.lawParams.page = index;
            this.lawlistRe();
        },
        // 获取政策法规列表数据
        lawlistRe() {
            let data = {
                menuid: 41,
                page: this.lawParams.page,
                pagesize: this.lawParams.pageSize,
            };
            lawlist(data).then((res) => {
                if (res.code == 1) {
                    this.lawListDe = res.data.list;
                    this.lawParams.page = this.lawListDe.current_page;
                    this.lawParams.pageSize = this.lawListDe.per_page - 0;
                    this.total = this.lawListDe.total;
                } else {
                    this.message(res.msg);
                }
            });
        },
        // 跳转政策法规详情页
        goPolicyDetail(item, type) {
            this.$router.push({
                path: `/norm/policyDetail/${type}`,
                query: {
                    ids: item.id,
                    menu_id: item.menu_id,
                },
            });
        },
    },
    mounted() {},
    beforeCreate() {},
    beforeMount() {},
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
    activated() {},
    components: {
        Header,
        Footer,
        Right,
        Nav,
        Pagination,
        Advertising,
        HeadNav,
    },
};
</script>

<style lang="scss">
.policy {
    width: 100%;
    .prefabricate-wrap {
        width: 70%;
        // height: 1000px;
        margin: 0 auto;
        // border: 1px solid red;

        /*关于学会tap start*/
        .survey-nav {
            display: flex;
            align-items: center;
            width: 100%;
            padding: 10px 0;
            box-sizing: border-box;
            border-bottom: 1px solid #e4e4e4;

            .survey-nav-title {
                width: 35%;
                display: flex;
                align-items: center;
                // border: 1px solid red;

                .survey-title-cn {
                    width: 30%;
                    font-size: 13px;
                    color: #0056b0;
                    margin-right: 10px;
                    // border: 1px solid red;
                }
                .survey-title-sn {
                    // width: 70%;
                    height: 10px;
                    font-size: 6px;
                    color: #fff;
                    padding: 0 6px;
                    background-color: #0056b0;
                }
            }

            > ul {
                width: 70%;
                list-style: none;
                display: flex;
                // border: 1px solid red;

                .active {
                    color: #0068d5;
                }

                li {
                    cursor: pointer;
                    margin-left: 10px;
                    padding-left: 10px;
                    font-size: 10px;
                    cursor: pointer;
                    border-left: 1px solid #e9e9e9;
                }
            }
        }
        /*关于学会tap end*/

        /*内容区域 start*/
        .survey-cont {
            width: 100%;

            .survey-content {
                display: flex;
                width: 100%;
                margin: 0 auto;
                // border: 1px solid red;

                // 内容区域左侧
                .survey-content-left {
                    width: 67%;
                    height: 100%;
                    // border: 1px solid blue;

                    > ul {
                        list-style: none;

                        // 左侧内容区域列表
                        li {
                            width: 100%;
                            height: 80px;
                            display: flex;
                            padding: 15px 0;
                            cursor: pointer;
                            border-bottom: 1px solid #e4e4e4;

                            .survey-item-img {
                                width: 26%;
                                height: 100%;

                                img {
                                    width: 100%;
                                    height: 100%;
                                }
                            }

                            .survey-item-text {
                                width: 72%;
                                display: flex;
                                flex-direction: column;
                                margin-left: auto;
                                font-size: 9px;
                                .survey-item-text1 {
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                }

                                .survey-item-text2 {
                                    margin-top: 20px;
                                    font-size: 7px;
                                    color: #999999;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    display: -webkit-box;
                                    -webkit-line-clamp: 2; //行数
                                    -webkit-box-orient: vertical;
                                }

                                .survey-item-text3 {
                                    margin-top: auto;
                                    font-size: 8px;
                                    color: #999999;
                                }
                            }
                        }
                    }
                }

                // 内容区域右侧
                .survey-content-right {
                    margin-left: auto;
                    width: 30%;
                    // border: 1px solid red;
                }
            }
        }

        /*内容区域 end*/
    }
}

//当屏幕最小宽度为992px
@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .policy {
        width: 100%;
        .prefabricate-wrap {
            width: 70%;
            // height: 1000px;
            margin: 0 auto;
            // border: 1px solid red;

            /*关于学会tap start*/
            .survey-nav {
                display: flex;
                align-items: center;
                width: 100%;
                padding: 10px 0;
                box-sizing: border-box;
                border-bottom: 1px solid #e4e4e4;

                .survey-nav-title {
                    width: 140px;
                    display: flex;
                    align-items: center;
                    // border: 1px solid red;

                    .survey-title-cn {
                        width: 60px;
                        font-size: 13px;
                        color: #0056b0;
                        margin-right: 10px;
                        // border: 1px solid red;
                    }
                    .survey-title-sn {
                        width: 60px;
                        height: 10px;
                        font-size: 6px;
                        color: #fff;
                        padding: 0 6px;
                        background-color: #0056b0;
                    }
                }

                > ul {
                    width: 70%;
                    list-style: none;
                    display: flex;
                    // border: 1px solid red;

                    .active {
                        color: #0068d5;
                    }

                    li {
                        cursor: pointer;
                        margin-left: 10px;
                        padding-left: 10px;
                        font-size: 10px;
                        cursor: pointer;
                        border-left: 1px solid #e9e9e9;
                    }
                }
            }
            /*关于学会tap end*/
            /*内容区域 start*/
            .survey-cont {
                width: 100%;

                .survey-content {
                    display: flex;
                    width: 100%;
                    margin: 0 auto;
                    margin-bottom: 40px;
                    // border: 1px solid red;

                    // 内容区域左侧
                    .survey-content-left {
                        width: 67%;
                        height: 100%;
                        // border: 1px solid blue;

                        > ul {
                            list-style: none;

                            // 左侧内容区域列表
                            li {
                                width: 100%;
                                height: 60px;
                                display: flex;
                                padding: 13px 0 10px 0;
                                cursor: pointer;
                                border-bottom: 0.5px solid #e4e4e4;

                                .survey-item-img {
                                    width: 26%;
                                    height: 100%;

                                    img {
                                        width: 100%;
                                        height: 100%;
                                    }
                                }

                                .survey-item-text {
                                    width: 72%;
                                    display: flex;
                                    flex-direction: column;
                                    margin-left: auto;
                                    font-size: 9px;
                                    .survey-item-text1 {
                                        white-space: nowrap;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                    }

                                    .survey-item-text2 {
                                        margin-top: 20px;
                                        font-size: 7px;
                                        color: #999999;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        display: -webkit-box;
                                        -webkit-line-clamp: 2; //行数
                                        -webkit-box-orient: vertical;
                                    }

                                    .survey-item-text3 {
                                        margin-top: auto;
                                        font-size: 8px;
                                        color: #999999;
                                    }
                                }
                            }
                        }
                    }

                    // 内容区域右侧
                    .survey-content-right {
                        margin-left: auto;
                        width: 30%;
                        // border: 1px solid red;
                    }
                }
            }

            /*内容区域 end*/
        }
    }
}

//当屏幕宽度 最小为768px 最大为991px时
@media screen and (min-width: 768px) and (max-width: 1200px) {
    .policy {
        width: 100%;
        .prefabricate-wrap {
            width: 80%;
            // height: 1000px;
            margin: 0 auto;
            // border: 1px solid red;

            /*关于学会tap start*/
            .survey-nav {
                display: flex;
                align-items: center;
                width: 100%;
                padding: 10px 0;
                box-sizing: border-box;
                border-bottom: 1px solid #e4e4e4;

                .survey-nav-title {
                    width: 30%;
                    display: flex;
                    align-items: center;
                    // border: 1px solid red;

                    .survey-title-cn {
                        width: 50%;
                        font-size: 12px;
                        color: #0056b0;
                        margin-right: 6px;
                    }
                    .survey-title-sn {
                        width: 30%;
                        height: 10px;
                        font-size: 6px;
                        color: #fff;
                        padding: 0 6px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        background-color: #0056b0;
                    }
                }

                > ul {
                    width: 70%;
                    list-style: none;
                    display: flex;
                    // border: 1px solid red;

                    .active {
                        color: #0068d5;
                    }

                    li {
                        cursor: pointer;
                        margin-left: 10px;
                        padding-left: 10px;
                        font-size: 10px;
                        cursor: pointer;
                        border-left: 1px solid #e9e9e9;
                    }
                }
            }
            /*关于学会tap end*/

            /*内容区域 start*/
            .survey-cont {
                width: 100%;

                .survey-content {
                    display: flex;
                    width: 100%;
                    margin: 0 auto;
                    margin-bottom: 40px;
                    // border: 1px solid red;

                    // 内容区域左侧
                    .survey-content-left {
                        width: 67%;
                        height: 100%;
                        // border: 1px solid blue;

                        > ul {
                            list-style: none;

                            // 左侧内容区域列表
                            li {
                                width: 100%;
                                height: 60px;
                                display: flex;
                                padding: 8px 0;
                                cursor: pointer;
                                border-bottom: 1px solid #e4e4e4;

                                .survey-item-img {
                                    width: 27%;
                                    height: 100%;

                                    img {
                                        width: 100%;
                                        height: 100%;
                                    }
                                }

                                .survey-item-text {
                                    width: 70%;
                                    display: flex;
                                    flex-direction: column;
                                    margin-left: auto;
                                    font-size: 9px;
                                    .survey-item-text1 {
                                    }

                                    .survey-item-text2 {
                                        margin-top: 10px;
                                        font-size: 7px;
                                        color: #999999;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        display: -webkit-box;
                                        -webkit-line-clamp: 2; //行数
                                        -webkit-box-orient: vertical;
                                    }

                                    .survey-item-text3 {
                                        margin-top: auto;
                                        font-size: 8px;
                                        color: #999999;
                                    }
                                }
                            }
                        }
                    }

                    // 内容区域右侧
                    .survey-content-right {
                        margin-left: auto;
                        width: 30%;
                        // border: 1px solid red;
                    }
                }
            }

            /*内容区域 end*/
        }
    }
}

//当屏幕最大宽度为767px
@media screen and (max-width: 767px) {
}
</style>
