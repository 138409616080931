<template>
    <div class="advertising">
        <Header></Header>
        <Nav></Nav>
        <div style="width: 70%; margin: 0 auto 20px auto">
            <Advertising></Advertising>
        </div>
        <div class="survey-nav">
            <!-- <Nav></Nav> -->

            <div class="survey-nav-title">
                <div class="survey-title-cn">助企单位</div>
                <div class="survey-title-sn">Help the Institute</div>
            </div>
            <ul>
                <li :key="index" v-for="(item, index) in topList" @click="surveyTapEv(item.id, index)"
                    :class="currentIndex == index ? 'active' : ''">
                    {{ item.name }}
                </li>
            </ul>
        </div>

        <div class="introduces-wrap">
            <div class="introduces-cont-left">
                <!-- 新闻资讯内容区域 -->
                <div class="introduces-cont-box">
                    <ul>
                        <li :key="index" @click="mNewsDetail(item)" v-for="(item, index) in swiperData">
                            <div class="introduces-item-left">
                                <img :src="item.logo" alt="" />
                            </div>
                            <div class="introduces-item-right">
                                <div>{{ item.name }}</div>
                                <div>{{ item.content }}</div>
                                <div>查看详情</div>
                            </div>
                        </li>
                    </ul>

                    <!-- 左侧内容区域分页 -->
                    <div class="pageDown">
                        <Pagination :pageNo="searchParams.page" :pageSize="searchParams.pageSize" :total="total"
                            :continues="5" @getPageNo="getPageEv" />
                    </div>
                </div>
            </div>
            <div class="introduces-cont-right">
                <Right></Right>
            </div>
        </div>

        <Footer></Footer>
    </div>
</template>

<script>
import Advertising from "@/components/Advertising.vue"; //广告图
import Nav from "../../../components/Nav.vue";
import Header from "../../../components/Header.vue";
import Footer from "../../../components/Footer";
import Right from "../../../components/Right.vue";
import Pagination from "@/components/Pagination";
import {
    companycate,
    companycatelist,
    schoolcate,
    schoollistw,
    schoolCate,
    tabSchoolList,
} from "../../../network/data";
export default {
    // 组件名称
    name: "introduces",
    // 组件参数 接收来自父组件的数据
    props: {},
    // 组件状态值
    data() {
        return {
            currentIndex: "",
            // 广告页id
            // typeid==1第一个首页会员,typeid==2首页第二个会员
            typeid: "",
            // type==1会员,type==2院校
            type: "",
            // 分页
            searchParams: {
                page: 1,
                pageSize: 4,
            },
            total: 10,
            // 广告页列表
            swiperData: [],
            currentIndex: 0,
            // 头部数据
            MindexList: [],
            topList: "",
        };
    },
    // 计算属性
    computed: {},
    // 侦听器
    watch: {},
    // 组件方法
    created() {
        this.getTopNav();
        // this.nav()
        if (JSON.stringify(this.$route.query) != "{}") {
            this.typeid = this.$route.query.typeid;
            this.type = this.$route.query.type;

            if (this.type == 1) {
                // 获取会员导航栏分类
                this.companycateRe();
                // 获取会员列表数据
                this.companylistRe();
            } else {
                // 获取院校导航栏分类
                this.schoolcateRe();
                // 获取学院列表
                this.schoollistwRe();
            }
        }
    },
    methods: {
        surveyTapEv(id, index) {
            this.searchParams.page = 1
            this.currentIndex = index;
            let data = {
                cateid: id,
                page: this.searchParams.page,
                pagesize: this.searchParams.pageSize,
            };
            tabSchoolList(data).then((res) => {
                if (res.code == 1) {
                    this.swiperData = res.data.data;
                    // this.searchParams.page = res.data.current_page;
                    // this.searchParams.pageSize = res.data.per_page;
                    this.total = res.data.total;
                }
            });
        },
        //获取学院分类
        getTopNav() {
            schoolCate().then((res) => {
                if (res.code == 1) {
                    this.topList = res.data;
                    if (res.data.length > 0) {
                        this.cateid = res.data[0].id
                        this.surveyTapEv(res.data[0].id, 0)
                    }
                    console.log(res.data[0].id, '发送到了；你发了；阿斯顿你了；饭；善良的')
                }
            });
        },
        // 企业会员首页导航跳转
        nav(item, index) {
            console.log(item.id, "123456");
            this.currentIndex = index;
            this.cateid = item.id;
            this.searchParams.page = 1;
            this.searchParams.pageSize = 3;
            if (this.type == 1) {
                // 获取会员导航栏分类
                this.companycateRe();
                // 获取会员列表数据
                this.companylistRe();
            } else {
                // 获取院校导航栏分类
                this.schoolcateRe();
                // 获取学院列表
                this.schoollistwRe();
            }
        },
        // 分页点击事件
        getPageEv(e) {
            this.searchParams.page = e;
            if (this.type == 1) {
                // 获取会员导航栏分类
                this.companycateRe();
                // 获取会员列表数据
                this.companylistRe();
            } else {
                // 获取院校导航栏分类
                this.schoolcateRe();
                // 获取学院列表
                this.schoollistwRe();
            }
        },
        mNewsDetail(item) {
            if (this.type == 1) {
                sessionStorage.setItem("id", item.id);
                this.$router.push({
                    path: "/mIndex",
                });
            } else {
                sessionStorage.setItem("school_id", item.id);
                this.$router.push("/academyIndex");
                window.scroll(0, 0);
            }
        },
        // 获取院校分类
        schoolcateRe() {
            schoolcate().then((res) => {
                if (res.code == 1) {
                    console.log(res.data, "院校分类");
                    this.MindexList = res.data;
                    this.MindexList.unshift({ id: "", name: "院校首页" });
                } else {
                    this.message(res.msg);
                }
            });
        },
        // 获取广告页头部分类
        companycateRe() {
            let data = {
                typeid: this.typeid,
            };
            companycate(data).then((res) => {
                if (res.code == 1) {
                    console.log(res.data, "广告页头部数据");
                    this.MindexList = res.data;
                    this.MindexList.unshift({ id: "", name: "会员首页" });
                } else {
                    this.message(res.msg);
                }
            });
        },
        // 广告页企业会员列表
        companylistRe() {
            let data = {
                cateid: this.cateid,
                page: this.searchParams.page,
                pagesize: this.searchParams.pageSize,
                keyword: "",
            };
            companycatelist(data).then((res) => {
                if (res.code == 1) {
                    this.swiperData = res.data.data;
                    this.searchParams.page = res.data.current_page;
                    this.searchParams.pageSize = res.data.per_page;
                    this.total = res.data.total;
                } else {
                    this.message(res.msg);
                }
            });
        },
        // 企业会员学院数据列表
        schoollistwRe() {
            let data = {
                cateid: this.cateid,
                page: this.searchParams.page,
                pagesize: this.searchParams.pageSize,
                keyword: "",
            };
            schoollistw(data).then((res) => {
                if (res.code == 1) {
                    console.log(res);
                    this.swiperData = res.data.data;
                    this.searchParams.page = res.data.current_page;
                    this.searchParams.pageSize = res.data.per_page;
                    this.total = res.data.total;
                } else {
                    this.message(res.msg);
                }
            });
        },
    },
    mounted() { },
    // 局部注册的组件
    components: {
        Nav,
        Header,
        Footer,
        Pagination,
        Right,
        Advertising,
    },
};
</script>

<style lang="scss">
.advertising {
    width: 100%;

    // 导航栏
    .mHeader-nav {
        height: 30px;
        width: 100%;
        background-color: #00479d;

        >ul {
            list-style: none;
            display: flex;
            align-items: center;
            height: 100%;
            width: 70%;
            margin: auto;

            .active {
                // height: 100%;
                color: #074ca0;
                background-color: #fff;
            }

            li {
                cursor: pointer;
                text-align: center;
                line-height: 30px;
                width: 14%;
                height: 100%;
                font-size: 8px;
                color: #fff;
            }
        }
    }

    .survey-nav {
        margin: 0px auto;
        display: flex;
        align-items: center;
        width: 70%;

        box-sizing: border-box;
        border-bottom: 1px solid #e4e4e4;

        .survey-nav-title {
            width: 30%;
            display: flex;
            align-items: center;
            // border: 1px solid red;

            .survey-title-cn {
                font-size: 11px;
                color: #0056b0;
                margin-right: 5px;
                // border: 1px solid red;
            }

            .survey-title-sn {
                height: 10px;
                font-size: 6px;
                color: #fff;
                padding: 0 6px;
                background-color: #0056b0;
            }
        }

        >ul {
            width: 70%;
            list-style: none;
            display: flex;

            .active {
                color: #0068d5;
            }

            li {
                cursor: pointer;
                text-align: center;
                margin-left: 5px;
                padding-left: 5px;
                font-size: 8px;
                border-left: 1px solid #e9e9e9;
                // border: 1px solid red;
            }
        }
    }

    // 版心
    .introduces-wrap {
        display: flex;
        justify-content: space-between;
        width: 70%;
        margin: 0 auto;
        margin-bottom: 40px;
        // border: 1px solid red;

        .introduces-cont-left {
            width: 68%;
            height: 100%;
            margin-top: 20px;
            // border: 1px solid blue;

            // 新闻资讯内容区域
            .introduces-cont-box {
                height: 100%;

                >ul {
                    list-style: none;
                    display: flex;
                    justify-content: space-between;
                    flex-wrap: wrap;

                    li {
                        display: flex;
                        width: 48%;
                        height: 100px;
                        padding: 5px;
                        margin-bottom: 10px;
                        box-sizing: border-box;
                        box-shadow: 2px 2px 10px #e1e0e0;
                        // border: 1px solid red;

                        // 新闻资讯内容区域左侧
                        .introduces-item-left {
                            width: 110px;
                            height: 100%;

                            img {
                                width: 100%;
                                height: 100%;
                            }
                        }

                        // 新闻资讯内容区域右侧
                        .introduces-item-right {
                            display: flex;
                            flex-direction: column;
                            width: 90px;
                            height: 100%;
                            margin-left: auto;
                            word-break: break-word;

                            >div:nth-child(1) {
                                font-size: 10px;
                            }

                            >div:nth-child(2) {
                                margin-top: 10px;
                                font-size: 8px;
                                color: #b3b3b3;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                display: -webkit-box;
                                -webkit-line-clamp: 3; //行数
                                -webkit-box-orient: vertical;
                            }

                            >div:nth-child(3) {
                                cursor: pointer;
                                width: 40px;
                                padding: 3px 10px;
                                margin-top: auto;
                                font-size: 9px;
                                border: 1px solid #808080;
                            }
                        }
                    }
                }

                // 左侧内容区域分页
                .pageDown {}
            }
        }

        .introduces-cont-right {
            width: 30%;
        }
    }
}

//当屏幕最小宽度为992px
@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .advertising {
        width: 100%;

        // 导航栏
        .mHeader-nav {
            height: 30px;
            width: 100%;
            background-color: #00479d;

            >ul {
                list-style: none;
                display: flex;
                align-items: center;
                height: 100%;
                width: 70%;
                margin: auto;

                .active {
                    // height: 100%;
                    color: #074ca0;
                    background-color: #fff;
                }

                li {
                    cursor: pointer;
                    text-align: center;
                    line-height: 30px;
                    width: 14%;
                    height: 100%;
                    font-size: 8px;
                    color: #fff;
                }
            }
        }

        // 版心
        .introduces-wrap {
            display: flex;
            justify-content: space-between;
            width: 70%;
            margin: 0 auto;
            margin-bottom: 40px;
            // border: 1px solid red;

            .introduces-cont-left {
                width: 68%;
                height: 100%;
                margin-top: 20px;
                // border: 1px solid blue;

                // 新闻资讯内容区域
                .introduces-cont-box {
                    height: 100%;

                    >ul {
                        list-style: none;
                        display: flex;
                        justify-content: space-between;
                        flex-wrap: wrap;

                        li {
                            display: flex;
                            width: 48%;
                            height: 100px;
                            padding: 5px;
                            margin-bottom: 10px;
                            box-sizing: border-box;
                            box-shadow: 2px 2px 10px #e1e0e0;
                            // border: 1px solid red;

                            // 新闻资讯内容区域左侧
                            .introduces-item-left {
                                width: 85px;
                                height: 100%;

                                img {
                                    width: 100%;
                                    height: 100%;
                                }
                            }

                            // 新闻资讯内容区域右侧
                            .introduces-item-right {
                                display: flex;
                                flex-direction: column;
                                width: 90px;
                                height: 100%;
                                margin-left: auto;
                                word-break: break-word;

                                >div:nth-child(1) {
                                    text-align: center;
                                    font-size: 7px;
                                }

                                >div:nth-child(2) {
                                    margin-top: 10px;
                                    font-size: 8px;
                                    color: #b3b3b3;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    display: -webkit-box;
                                    -webkit-line-clamp: 3; //行数
                                    -webkit-box-orient: vertical;
                                }

                                >div:nth-child(3) {
                                    cursor: pointer;
                                    width: 35px;
                                    padding: 3px 3px;
                                    margin-top: auto;
                                    margin-left: 20px;
                                    font-size: 9px;
                                    border: 1px solid #808080;
                                    // border: 1px solid red;
                                }
                            }
                        }
                    }

                    // 左侧内容区域分页
                    .pageDown {}
                }
            }

            .introduces-cont-right {
                width: 30%;
            }
        }
    }
}

//当屏幕宽度 最小为768px 最大为991px时
@media screen and (min-width: 768px) and (max-width: 1200px) {
    .advertising {
        width: 100%;

        // 导航栏
        .mHeader-nav {
            height: 30px;
            width: 100%;
            background-color: #00479d;

            >ul {
                list-style: none;
                display: flex;
                align-items: center;
                height: 100%;
                width: 70%;
                margin: auto;

                .active {
                    // height: 100%;
                    color: #074ca0;
                    background-color: #fff;
                }

                li {
                    cursor: pointer;
                    text-align: center;
                    line-height: 30px;
                    width: 14%;
                    height: 100%;
                    font-size: 8px;
                    color: #fff;
                }
            }
        }

        // 版心
        .introduces-wrap {
            display: flex;
            justify-content: space-between;
            width: 70%;
            margin: 0 auto;
            margin-bottom: 40px;
            // border: 1px solid red;

            .introduces-cont-left {
                width: 68%;
                height: 100%;
                margin-top: 20px;
                // border: 1px solid blue;

                // 新闻资讯内容区域
                .introduces-cont-box {
                    height: 100%;

                    >ul {
                        list-style: none;
                        display: flex;
                        justify-content: space-between;
                        flex-wrap: wrap;

                        li {
                            display: flex;
                            width: 48%;
                            height: 70px;
                            padding: 5px;
                            margin-bottom: 5px;
                            box-sizing: border-box;
                            box-shadow: 2px 2px 10px #e1e0e0;

                            // 新闻资讯内容区域左侧
                            .introduces-item-left {
                                width: 65px;
                                height: 100%;

                                img {
                                    width: 100%;
                                    height: 100%;
                                }
                            }

                            // 新闻资讯内容区域右侧
                            .introduces-item-right {
                                display: flex;
                                flex-direction: column;
                                width: 50px;
                                height: 100%;
                                margin-left: auto;

                                >div:nth-child(1) {
                                    width: 100px;
                                    font-size: 7px;
                                    word-break: break-all；
                                }

                                >div:nth-child(2) {
                                    margin-top: 10px;
                                    font-size: 6px;
                                    color: #b3b3b3;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    display: -webkit-box;
                                    -webkit-line-clamp: 3; //行数
                                    -webkit-box-orient: vertical;
                                }

                                >div:nth-child(3) {
                                    cursor: pointer;
                                    text-align: center;
                                    width: 40px;
                                    padding: 3px 2px;
                                    margin-top: auto;
                                    font-size: 7px;
                                    border: 1px solid #808080;
                                }
                            }
                        }
                    }

                    // 左侧内容区域分页
                    .pageDown {}
                }
            }

            .introduces-cont-right {
                width: 30%;
            }
        }
    }
}

//当屏幕最大宽度为767px
@media screen and (max-width: 767px) {}
</style>
