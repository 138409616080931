<template>
    <!-- 产品需求---就业发布、招聘发布详情页 -->
    <div class="demandIssueq">
        <Header></Header>
        <div class="minkave-wrap">
            <!-- 顶部图片 -->
            <Advertising></Advertising>
            <!-- <div class="index-headImg">
                <div class="index-headImg-left">
                    <img src="../../../assets/pc/Index/headL.png" alt="" />
                </div>
                <div class="index-headImg-right">
                    <img src="../../../assets/pc/Index/headR.png" alt="" />
                </div>
            </div> -->

            <!-- 关于学会tap -->
            <div class="survey-nav">
                <!-- <Nav></Nav> -->
                <div class="survey-nav-title">
                    <div class="survey-title-cn">{{ navtl }}</div>
                    <!-- <div class="survey-title-sn">About the Institute</div> -->
                </div>

                <!-- <div style="font-size: 30px">{{ navtl }}---详情页</div> -->
            </div>

            <!-- 内容区域 -->
            <div class="survey-cont">
                <div class="survey-content">
                    <!-- 内容区域左侧 -->
                    <!-- 技术难题 -->
                    <div class="survey-content-left">
                        <div class="survey-detail-left">
                            <!-- 左侧 -->
                            <div class="minkaveDetails-introduce5-left">
                                <!-- 技术难题标题 -->
                                <div class="minkaveDetails-introduce5-title">
                                    <ul>
                                        <li
                                            :class="
                                                issueIndex == 0
                                                    ? 'activeli'
                                                    : ''
                                            "
                                        >
                                            就业发布
                                        </li>
                                        <li
                                            :class="
                                                issueIndex == 1
                                                    ? 'activeli'
                                                    : ''
                                            "
                                        >
                                            招聘发布
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="survey-detail-right">
                            <div class="detail-right">
                                <div class="detail-right-place">
                                    当前位置：网站首页>产业需求>{{ navtl }}
                                </div>
                                <div class="detail-right-cont">
                                    <!-- 就业发布 -->
                                    <div v-show="issueIndex == 0">
                                        <div class="detail-right-name">
                                            <label for="">*标题</label>
                                            <input
                                                class="name"
                                                type="表单类型"
                                                v-model="form.name"
                                                placeholder="请输入标题"
                                            />
                                        </div>
                                        <div class="detail-right-select">
                                            <label for="">*方向</label>
                                            <select @change="setoutChange">
                                                <option
                                                    :value="item.id"
                                                    v-for="(
                                                        item, index
                                                    ) in setoutOptionDe"
                                                    :key="index"
                                                >
                                                    {{ item.name }}
                                                </option>
                                            </select>
                                        </div>
                                        <div
                                            class="detail-right-upload"
                                            style="height: 30px"
                                        >
                                            <label for="">*上传附件</label>
                                            <Upload @url="setnewUrl"></Upload>
                                        </div>

                                        <div class="relation-right-code">
                                            <label for="">*验证码</label>
                                            <input
                                                class="mail"
                                                type="表单类型"
                                                v-model="form.code"
                                                placeholder="请输入验证码"
                                            />

                                            <div>
                                                <Code
                                                    ref="ref_validateCode"
                                                    @change="changeCode1"
                                                ></Code>
                                            </div>
                                        </div>
                                        <div>
                                            <div @click="submit">提交</div>
                                            <div @click="rewrite">重写</div>
                                        </div>
                                    </div>

                                    <!-- 招聘发布 -->
                                    <div v-show="issueIndex == 1">
                                        <div class="detail-right-title">
                                            <label for="">*标题</label>
                                            <input
                                                class="name"
                                                type="表单类型"
                                                v-model="formNew.titleNew"
                                                placeholder="请输入标题"
                                            />
                                        </div>
                                        <div class="detail-right-select1">
                                            <label for="">*方向</label>
                                            <select @change="setoutChange">
                                                <option
                                                    :value="item.id"
                                                    v-for="(
                                                        item, index
                                                    ) in setoutOptionDe"
                                                    :key="index"
                                                >
                                                    {{ item.name }}
                                                </option>
                                            </select>
                                        </div>
                                        <div class="detail-right-areaNew">
                                            <label for="">*招聘要求</label>
                                            <textarea
                                                rows="行数"
                                                cols="列数"
                                                v-model="formNew.areaNew"
                                                class="area"
                                                placeholder="请输入服务描述"
                                            >
                                            </textarea>
                                        </div>

                                        <div class="detail-right-select">
                                            <label for="">*经验/学历</label>
                                            <select @change="undergoChange">
                                                <option
                                                    :value="item.id"
                                                    v-for="(
                                                        item, index
                                                    ) in undergoOptionDe"
                                                    :key="index"
                                                >
                                                    {{ item.name }}
                                                </option>
                                            </select>
                                            <div>
                                                &nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp
                                            </div>
                                            <select @change="schoollistChange">
                                                <option
                                                    :value="item.id"
                                                    v-for="(
                                                        item, index
                                                    ) in schoolOptionDe"
                                                    :key="index"
                                                >
                                                    {{ item.name }}
                                                </option>
                                            </select>
                                        </div>

                                        <div class="detail-right-select">
                                            <label for="">*薪资范围</label>
                                            <select @change="wageChange">
                                                <option
                                                    :value="item.id"
                                                    v-for="(
                                                        item, index
                                                    ) in wageOptionDe"
                                                    :key="index"
                                                >
                                                    {{ item.name }}
                                                </option>
                                            </select>
                                            <div>&nbsp&nbsp—&nbsp&nbsp</div>
                                            <select @change="wageChange">
                                                <option
                                                    :value="item.id"
                                                    v-for="(
                                                        item, index
                                                    ) in wageOptionDe"
                                                    :key="index"
                                                >
                                                    {{ item.name }}
                                                </option>
                                            </select>
                                        </div>

                                        <div class="relation-right-mailNew">
                                            <label for="">工作地址</label>
                                            <input
                                                class="mail"
                                                type="表单类型"
                                                v-model="formNew.mailNew"
                                                placeholder="请输入电子邮件"
                                            />
                                        </div>

                                        <div class="relation-right-codeNew">
                                            <label for="">*验证码</label>
                                            <input
                                                class="mail"
                                                type="表单类型"
                                                v-model="formNew.codeNew"
                                                placeholder="请输入验证码"
                                            />

                                            <div>
                                                <Code
                                                    ref="ref_validateCode"
                                                    @change="changeCode2"
                                                ></Code>
                                            </div>
                                        </div>

                                        <div>
                                            <div @click="submitNew">提交</div>
                                            <div @click="rewriteNew">重写</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- 内容区域右侧 -->
                    <div class="survey-content-right">
                        <Right></Right>
                    </div>
                </div>
            </div>
            <!-- 内容区域 -->
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import axios from "axios";
import Header from "@/components/Header.vue"; //头部
import Footer from "@/components/Footer.vue"; //尾部
import Right from "@/components/Right.vue"; //右侧
import Upload from "@/components/Science/Upfile.vue"; //上传
// import Left3 from "@/components/Science/Left3.vue"; //左侧
import Code from "@/components/Code.vue"; //验证码
import Advertising from "@/components/Advertising.vue"; //广告图
import {
    setproblem,
    setanswer,
    jobcate,
    needWorklist,
    schoollist,
    wagelist,
    getetjob,
    setjob,
} from "../../../network/data";

export default {
    // 组件名称
    name: "recruit",
    // 组件参数 接收来自父组件的数据
    props: {},
    // 组件状态值
    data() {
        return {
            // 企业id
            id: "",
            // 分类id
            lable_id: "",
            // 左侧tap索引
            issueIndex: 0,
            // 判断是否能发布招聘信息，1==不可以，2==可以
            companyType: 0,
            // 就业发布输入框数据
            form: {
                name: "",
                code: "",
            },
            // 招聘发布输入框数据
            formNew: {
                titleNew: "",
                areaNew: "",
                mailNew: "",
                codeNew: "",
            },
            identifyCode: "", // 生成的验证
            // 选中的性别,1=先生,2=女士
            sex: "1",
            // 方向转换标签
            setoutOptionDe: [],
            // 选中的方向
            setoutId: "",
            // 经验
            undergoOptionDe: [],
            // 选中的经验
            activeUndergo: "",
            // 学历
            schoolOptionDe: [],
            // 选中的学历
            activeSchool: "",
            // 薪资
            wageOptionDe: [],
            // 选择的薪资
            activeWage: "",
            // 就业发布验证码
            checkCode1: "",
            // 附件
            file: "",
        };
    },
    // 计算属性
    computed: {
        navtl() {
            return this.issueIndex == 0 ? "就业发布" : "招聘发布";
        },
    },
    // 侦听器
    watch: {},
    // 组件方法
    created() {
        if (JSON.stringify(this.$route.query) != "{}") {
            this.issueIndex = this.$route.query.indexa;
            this.lable_id = this.$route.query.lable_id;
        }

        // 判断是否能发布招聘信息，1==不可以，2==可以
        this.companyType = sessionStorage.getItem("companyType");

        // 获取方向
        this.setoutOptionRe();
        // 获取经验
        this.needWorklistRe();
        // 获取学历
        this.schoollistRe();
        // 薪资
        this.wagelistRe();
    },
    methods: {
        // 获取需求成果转换标签
        setoutOptionRe() {
            jobcate().then((res) => {
                if (res.code == 1) {
                    // console.log(res.data);
                    this.setoutOptionDe = res.data;
                    this.setoutOptionDe.unshift({ id: 0, name: "请选择" });
                } else {
                    this.message(res.msg);
                }
            });
        },
        // 获取经验
        needWorklistRe() {
            needWorklist().then((res) => {
                if (res.code == 1) {
                    // console.log(res.data);
                    this.undergoOptionDe = res.data;
                    this.undergoOptionDe.unshift({ id: 0, name: "请选择" });
                } else {
                    this.message(res.msg);
                }
            });
        },
        // 获取学历
        schoollistRe() {
            schoollist().then((res) => {
                if (res.code == 1) {
                    // console.log(res.data);
                    this.schoolOptionDe = res.data;
                    this.schoolOptionDe.unshift({ id: 0, name: "请选择" });
                } else {
                    this.message(res.msg);
                }
            });
        },
        // 薪资
        wagelistRe() {
            wagelist().then((res) => {
                if (res.code == 1) {
                    // console.log(res.data);
                    this.wageOptionDe = res.data;
                    this.wageOptionDe.unshift({ id: 0, name: "请选择" });
                } else {
                    this.message(res.msg);
                }
            });
        },
        // 方向
        setoutChange(e) {
            this.setoutId = e.target.value;
        },
        // 经验
        undergoChange(e) {
            this.activeUndergo = e.target.value;
        },
        // 学历
        schoollistChange(e) {
            this.activeSchool = e.target.value;
        },
        // 薪资
        wageChange(e) {
            this.activeWage = e.target.value;
            // console.log(this.activeWage);
        },
        // 发布问题、我来解答切换
        issueEv(index) {
            this.issueIndex = index;
        },
        // 就业发布获取验证码
        getIdentifyCode(val) {
            this.identifyCode = val;
        },
        // 就业发布验证码
        changeCode1(value) {
            this.checkCode1 = value;
            // this.$refs["ref_validateCode"].draw();
            // console.log(value, "验证码初始化1");
        },
        // 招聘发布验证码
        changeCode2(value) {
            this.checkCode2 = value;
            // this.$refs["ref_validateCode"].draw();
            // console.log(value, "验证码初始化2");
        },

        // 就业发布重写
        rewrite() {
            this.form.name = "";
            this.form.code = "";
            this.setoutId = "";
        },

        // 招聘发布重写
        rewriteNew() {
            this.formNew.titleNew = "";
            this.lable_id = "";
            this.activeUndergo = "";
            this.formNew.codeNew = "";
            this.activeWage = "";
            this.formNew.mailNew = "";
            this.formNew.areaNew = "";
        },
        // 就业发布提交
        submit() {
            this.$refs["ref_validateCode"].draw();
            console.log(this.checkCode1, "提交验证码");

            if (this.form.name == "") {
                this.$message.warning({
                    message: "标题为空",
                    duration: 1000,
                    customClass: "myBox",
                });
                return;
            } else if (this.setoutId == "") {
                this.$message.warning({
                    message: "方向为空",
                    duration: 1000,
                    customClass: "myBox",
                });
                return;
            } else if (this.url == "") {
                this.$message.warning({
                    message: "请上传附件",
                    duration: 1000,
                    customClass: "myBox",
                });
                return;
            } else if (this.form.code != this.checkCode1) {
                this.$message.warning({
                    message: "验证码错误",
                    duration: 1000,
                    customClass: "myBox",
                });
                return;
            } else {
                this.setproblemRe();
            }
        },
        // 就业发布提交
        setproblemRe() {
            let data = {
                id: sessionStorage.getItem("companyId"), //企业ID
                title: this.form.name,
                cate_id: this.setoutId,
                file: this.file,
            };
            setjob(data).then((res) => {
                if (res.code == 1) {
                    // console.log(res.data);
                    this.$message.success({
                        message: "提交成功",
                        duration: 1000,
                        customClass: "myBox",
                    });
                } else {
                    this.$message.success({
                        message: res.msg,
                        duration: 1000,
                        customClass: "myBox",
                    });
                }
            });
        },
        // 招聘发布提交
        submitNew() {
            // console.log("我来解答测试");
            let phoneDta = this.formNew.phoneNew;
            let refPhone =
                /^[1](([3][0-9])|([4][0,1,4-9])|([5][0-3,5-9])|([6][2,5,6,7])|([7][0-8])|([8][0-9])|([9][0-3,5-9]))[0-9]{8}$/;
            if (this.formNew.titleNew == "") {
                this.$message.warning({
                    message: "标题为空",
                    duration: 1000,
                    customClass: "myBox",
                });
                return;
            } else if (this.setoutId == "") {
                this.$message.warning({
                    message: "方向为空",
                    duration: 1000,
                    customClass: "myBox",
                });
                return;
            } else if (this.formNew.areaNew == "") {
                this.$message.warning({
                    message: "招聘要求为空",
                    duration: 1000,
                    customClass: "myBox",
                });
                return;
            } else if (this.activeUndergo == "") {
                this.$message.warning({
                    message: "工作经验为空",
                    duration: 1000,
                    customClass: "myBox",
                });
                return;
            } else if (this.activeSchool == "") {
                this.$message.warning({
                    message: "学历为空",
                    duration: 1000,
                    customClass: "myBox",
                });
                return;
            } else if (this.activeWage == "") {
                this.$message.warning({
                    message: "薪资为空",
                    duration: 1000,
                    customClass: "myBox",
                });
                return;
            } else if (this.formNew.mailNew == "") {
                this.$message.warning({
                    message: "地址为空",
                    duration: 1000,
                    customClass: "myBox",
                });
                return;
            } else if (this.formNew.codeNew != this.checkCode2) {
                this.$message.warning({
                    message: "验证码错误",
                    duration: 1000,
                    customClass: "myBox",
                });
                return;
            } else {
                let data = {
                    id: sessionStorage.getItem("id"), //企业ID
                    title: this.formNew.titleNew, //标题
                    cate_id: this.setoutId, //分类ID
                    workyear: this.activeUndergo, //工作经验
                    education: this.activeSchool, //学历
                    salarymoney: this.activeWage, //薪资范围
                    address: this.formNew.mailNew, //地址
                    content: this.formNew.areaNew, //招聘要求
                };
                getetjob(data).then((res) => {
                    if (res.code == 1) {
                        this.$message.success({
                            message: "提交成功",
                            duration: 1000,
                            customClass: "myBox",
                        });
                    } else {
                        this.$message.success({
                            message: res.msg,
                            duration: 1000,
                            customClass: "myBox",
                        });
                    }
                });
            }
        },
        // 选择性别
        changeRadio(e) {
            this.sex = e.target.value;
            // console.log(e.target.value, "性别");
        },
        // 上传附件
        setnewUrl(e) {
            this.file = e;
        },
    },
    mounted() {},
    // 局部注册的组件
    components: {
        Header,
        Footer,
        Right,
        // Left3,
        Code,
        Upload,
        Advertising,
    },
};
</script>

<style lang="scss">
// 修改message弹框样式
.myBox {
    min-width: 130px !important;
    height: 25px !important;
    padding: 3px !important;
    text-align: center !important;
    display: flex !important;
    justify-content: center !important;
    .el-message__icon {
        font-size: 10px;
    }
    .el-message__content {
        font-size: 8px;
    }
}

.demandIssueq {
    width: 100%;

    .minkave-wrap {
        width: 70%;
        // height: 1000px;
        margin: 0 auto;
        margin-top: 35px;
        margin-bottom: 40px;
        // border: 1px solid red;

        /*顶部图片start*/
        .index-headImg {
            position: relative;
            z-index: 0;
            width: 100%;
            height: 50px;
            margin-top: 15px;
            display: flex;
            justify-content: space-between;

            // 左侧图片
            .index-headImg-left {
                width: 48%;
                height: 100%;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            // 右侧图片
            .index-headImg-right {
                width: 48%;
                height: 100%;

                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
        /*顶部图片end*/

        /*关于学会tap start*/
        .survey-nav {
            display: flex;
            align-items: center;
            width: 100%;
            padding: 10px 0;
            box-sizing: border-box;
            border-bottom: 0.5px solid #e4e4e4;

            .survey-nav-title {
                width: 20%;
                display: flex;
                align-items: center;
                // border: 1px solid red;

                .survey-title-cn {
                    font-size: 10px;
                    color: #0056b0;
                    margin-right: 15px;
                }

                .survey-title-sn {
                    height: 20px;
                    font-size: 6px;
                    color: #fff;
                    padding: 0 6px;
                    background-color: #0056b0;
                }
            }
        }
        /*关于学会tap end*/

        /*内容区域 start*/
        .survey-cont {
            width: 100%;

            .survey-content {
                display: flex;
                width: 100%;
                margin: 20px 0;
                // height: 300px;
                // border: 1px solid red;

                // 内容区域左侧1(技术难题)
                .survey-content-left {
                    display: flex;
                    width: 68%;
                    height: 100%;
                    // border: 1px solid blue;

                    .survey-detail-left {
                        width: 26%;
                        // border: 1px solid red;

                        // 详情左侧内容
                        .minkaveDetails-introduce5-left {
                            width: 100%;
                            // height: 216px;
                            // border: 1px solid red;

                            // 就业发布、招聘发布
                            .minkaveDetails-introduce5-title {
                                > ul {
                                    list-style: none;

                                    .activeli {
                                        color: #0056b0;
                                    }

                                    li {
                                        cursor: pointer;
                                        width: 100%;
                                        height: 20px;
                                        text-align: center;
                                        line-height: 20px;
                                        font-size: 9px;
                                        background-color: #e9e9e9;
                                        border-bottom: 0.5px dashed #d2d1d1;
                                    }
                                }
                            }
                        }
                    }

                    // 详情右侧内容
                    .survey-detail-right {
                        width: 70%;
                        margin-left: auto;
                        // border: 1px solid red;

                        .detail-right {
                            width: 100%;
                            font-size: 12px;
                            background-color: #f8f8f8;

                            .detail-right-place {
                                font-size: 10px;
                                padding: 5px;
                                box-sizing: border-box;
                                border-bottom: 0.5px solid #dedede;
                            }

                            .detail-right-cont {
                                padding: 5px;
                                font-size: 8px;

                                //就业发布
                                > div:nth-child(1) {
                                    // 难题名称
                                    .detail-right-name {
                                        display: flex;
                                        align-items: center;
                                        margin-bottom: 10px;

                                        > label {
                                            width: 50px;
                                        }

                                        > input {
                                            width: 53%;
                                            height: 15px;
                                        }
                                    }

                                    .detail-right-select {
                                        display: flex;
                                        align-items: center;
                                        margin-bottom: 10px;

                                        > label {
                                            width: 50px;
                                        }

                                        > select {
                                            width: 53%;
                                            height: 15px;
                                        }
                                    }

                                    .detail-right-upload {
                                        height: 50px;
                                        width: 100%;
                                        display: flex;
                                        align-items: center;
                                        margin-bottom: 10px;

                                        > label {
                                            width: 50px;
                                        }

                                        .upload-demo {
                                            display: flex;
                                            height: 100%;
                                            width: 150px;

                                            .el-upload {
                                                width: 40%;
                                                height: 100%;
                                                // border: 1px solid red;

                                                .el-upload-dragger {
                                                    width: 100%;
                                                    height: 100%;

                                                    .el-upload__text {
                                                        font-size: 30px;
                                                    }
                                                }
                                            }

                                            .el-upload-list {
                                                // border: 1px solid blue;
                                                width: 100px;
                                                font-size: 10px;

                                                .el-upload-list__item {
                                                    font-size: 6px;
                                                }
                                            }
                                        }
                                    }

                                    // 验证码
                                    .relation-right-code {
                                        display: flex;
                                        align-items: center;
                                        margin-bottom: 10px;

                                        > label {
                                            width: 50px;
                                        }

                                        > input {
                                            width: 80px;
                                            height: 13px;
                                        }

                                        > div {
                                            margin-left: 5px;
                                            // border: 1px solid red;
                                        }
                                    }

                                    // 提交、重写
                                    > div:last-child {
                                        display: flex;
                                        margin-left: 50px;

                                        > div:nth-child(1) {
                                            cursor: pointer;
                                            margin-right: 5px;
                                            color: #fff;
                                            padding: 3px 7px;
                                            background-color: #0056b0;
                                        }

                                        > div:nth-child(2) {
                                            cursor: pointer;
                                            margin-right: 5px;
                                            color: #fff;
                                            padding: 3px 7px;
                                            background-color: #f77132;
                                        }
                                    }
                                }

                                // 招聘发布
                                > div:nth-child(2) {
                                    // 难题名称
                                    .detail-right-title {
                                        display: flex;
                                        align-items: center;
                                        margin-bottom: 10px;

                                        > label {
                                            width: 50px;
                                        }

                                        > input {
                                            width: 200px;
                                            height: 13px;
                                        }
                                    }
                                    .detail-right-select1 {
                                        display: flex;
                                        align-items: center;
                                        margin-bottom: 10px;
                                        // border: 1px solid red;

                                        > label {
                                            width: 50px;
                                        }

                                        > select {
                                            width: 200px;
                                            height: 15px;
                                            // border: 1px solid red;
                                        }
                                    }
                                    // 难题描述
                                    .detail-right-areaNew {
                                        display: flex;
                                        margin-bottom: 10px;
                                        > label {
                                            width: 50px;
                                        }

                                        > textarea {
                                            width: 200px;
                                            height: 33px;
                                        }
                                    }

                                    // 经验学历
                                    .detail-right-select {
                                        display: flex;
                                        align-items: center;
                                        margin-bottom: 10px;

                                        > label {
                                            width: 50px;
                                        }

                                        > select {
                                            width: 25%;
                                            height: 15px;
                                        }
                                    }

                                    // 电子邮件
                                    .relation-right-mailNew {
                                        display: flex;
                                        align-items: center;
                                        margin-bottom: 10px;

                                        > label {
                                            width: 50px;
                                        }

                                        > input {
                                            width: 200px;
                                            height: 13px;
                                        }
                                    }

                                    // 验证码
                                    .relation-right-codeNew {
                                        display: flex;
                                        align-items: center;
                                        margin-bottom: 10px;

                                        > label {
                                            width: 50px;
                                        }

                                        > input {
                                            width: 100px;
                                            height: 13px;
                                        }

                                        > div {
                                            margin-left: 5px;
                                            // width: 30px;
                                            // border: 1px solid red;
                                        }
                                    }

                                    // 提交、重写
                                    > div:last-child {
                                        display: flex;
                                        margin-left: 50px;

                                        > div:nth-child(1) {
                                            cursor: pointer;
                                            margin-right: 5px;
                                            color: #fff;
                                            padding: 3px 7px;
                                            background-color: #0056b0;
                                        }

                                        > div:nth-child(2) {
                                            cursor: pointer;
                                            margin-right: 5px;
                                            color: #fff;
                                            padding: 3px 7px;
                                            background-color: #f77132;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                // 内容区域右侧
                .survey-content-right {
                    margin-left: auto;
                    width: 30%;
                    // border: 1px solid red;
                }
            }
        }

        /*内容区域 end*/
    }
}

//当屏幕最小宽度为992px
@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .demandIssueq {
        width: 100%;

        .minkave-wrap {
            width: 70%;
            // height: 1000px;
            margin: 0 auto;
            margin-top: 35px;
            margin-bottom: 40px;
            // border: 1px solid red;

            /*顶部图片start*/
            .index-headImg {
                position: relative;
                z-index: 0;
                width: 100%;
                height: 50px;
                margin-top: 15px;
                display: flex;
                justify-content: space-between;

                // 左侧图片
                .index-headImg-left {
                    width: 48%;
                    height: 100%;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }

                // 右侧图片
                .index-headImg-right {
                    width: 48%;
                    height: 100%;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
            /*顶部图片end*/

            /*关于学会tap start*/
            .survey-nav {
                display: flex;
                align-items: center;
                width: 100%;
                padding: 10px 0;
                box-sizing: border-box;
                border-bottom: 0.5px solid #e4e4e4;

                .survey-nav-title {
                    width: 20%;
                    display: flex;
                    align-items: center;
                    // border: 1px solid red;

                    .survey-title-cn {
                        font-size: 10px;
                        color: #0056b0;
                        margin-right: 15px;
                    }

                    .survey-title-sn {
                        height: 20px;
                        font-size: 6px;
                        color: #fff;
                        padding: 0 6px;
                        background-color: #0056b0;
                    }
                }
            }
            /*关于学会tap end*/

            /*内容区域 start*/
            .survey-cont {
                width: 100%;

                .survey-content {
                    display: flex;
                    width: 100%;
                    margin: 15px 0;
                    // height: 300px;
                    // border: 1px solid red;

                    // 内容区域左侧1(技术难题)
                    .survey-content-left {
                        display: flex;
                        width: 68%;
                        height: 100%;
                        // border: 1px solid blue;

                        .survey-detail-left {
                            width: 26%;
                            // border: 1px solid red;

                            // 详情左侧内容
                            .minkaveDetails-introduce5-left {
                                width: 100%;
                                // height: 216px;
                                // border: 1px solid red;

                                // 就业发布、招聘发布
                                .minkaveDetails-introduce5-title {
                                    > ul {
                                        list-style: none;

                                        .activeli {
                                            color: #0056b0;
                                        }

                                        li {
                                            cursor: pointer;
                                            width: 100%;
                                            height: 20px;
                                            text-align: center;
                                            line-height: 20px;
                                            font-size: 9px;
                                            background-color: #e9e9e9;
                                            border-bottom: 0.5px dashed #d2d1d1;
                                        }
                                    }
                                }
                            }
                        }

                        // 详情右侧内容
                        .survey-detail-right {
                            width: 70%;
                            margin-left: auto;
                            // border: 1px solid red;

                            .detail-right {
                                width: 100%;
                                font-size: 12px;
                                background-color: #f8f8f8;

                                .detail-right-place {
                                    font-size: 10px;
                                    padding: 5px;
                                    box-sizing: border-box;
                                    border-bottom: 0.5px solid #dedede;
                                }

                                .detail-right-cont {
                                    padding: 5px;
                                    font-size: 8px;

                                    //就业发布
                                    > div:nth-child(1) {
                                        // 难题名称
                                        .detail-right-name {
                                            display: flex;
                                            align-items: center;
                                            margin-bottom: 10px;

                                            > label {
                                                width: 50px;
                                            }

                                            > input {
                                                width: 53%;
                                                height: 15px;
                                            }
                                        }

                                        .detail-right-select {
                                            display: flex;
                                            align-items: center;
                                            margin-bottom: 10px;

                                            > label {
                                                width: 50px;
                                            }

                                            > select {
                                                width: 53%;
                                                height: 15px;
                                            }
                                        }

                                        .detail-right-upload {
                                            height: 50px;
                                            width: 100%;
                                            display: flex;
                                            align-items: center;
                                            margin-bottom: 10px;
                                            // border:1px solid red;

                                            > label {
                                                width: 50px;
                                            }

                                            .upload-demo {
                                                display: flex;
                                                height: 100%;
                                                width: 150px;

                                                .el-upload {
                                                    width: 40%;
                                                    height: 100%;
                                                    // border: 1px solid red;

                                                    .el-upload-dragger {
                                                        width: 100%;
                                                        height: 100%;

                                                        .el-upload__text {
                                                            font-size: 30px;
                                                        }
                                                    }
                                                }

                                                .el-upload-list {
                                                    // border: 1px solid blue;
                                                    width: 100px;
                                                    font-size: 10px;

                                                    .el-upload-list__item {
                                                        font-size: 6px;
                                                    }
                                                }
                                            }
                                        }

                                        // 验证码
                                        .relation-right-code {
                                            display: flex;
                                            align-items: center;
                                            margin-bottom: 10px;

                                            > label {
                                                width: 50px;
                                            }

                                            > input {
                                                width: 60px;
                                                height: 13px;
                                            }

                                            > div {
                                                margin-left: 5px;
                                                // border: 1px solid red;
                                            }
                                        }

                                        // 提交、重写
                                        > div:last-child {
                                            display: flex;
                                            margin-left: 50px;

                                            > div:nth-child(1) {
                                                cursor: pointer;
                                                margin-right: 5px;
                                                color: #fff;
                                                padding: 3px 7px;
                                                background-color: #0056b0;
                                            }

                                            > div:nth-child(2) {
                                                cursor: pointer;
                                                margin-right: 5px;
                                                color: #fff;
                                                padding: 3px 7px;
                                                background-color: #f77132;
                                            }
                                        }
                                    }

                                    // 招聘发布
                                    > div:nth-child(2) {
                                        // 难题名称
                                        .detail-right-title {
                                            display: flex;
                                            align-items: center;
                                            margin-bottom: 10px;

                                            > label {
                                                width: 50px;
                                            }

                                            > input {
                                                width: 200px;
                                                height: 13px;
                                            }
                                        }
                                        .detail-right-select1 {
                                            display: flex;
                                            align-items: center;
                                            margin-bottom: 10px;
                                            // border: 1px solid red;

                                            > label {
                                                width: 50px;
                                            }

                                            > select {
                                                width: 200px;
                                                height: 15px;
                                                // border: 1px solid red;
                                            }
                                        }
                                        // 难题描述
                                        .detail-right-areaNew {
                                            display: flex;
                                            margin-bottom: 10px;
                                            > label {
                                                width: 50px;
                                            }

                                            > textarea {
                                                width: 200px;
                                                height: 33px;
                                            }
                                        }

                                        // 经验学历
                                        .detail-right-select {
                                            display: flex;
                                            align-items: center;
                                            margin-bottom: 10px;

                                            > label {
                                                width: 50px;
                                            }

                                            > select {
                                                width: 25%;
                                                height: 15px;
                                            }
                                        }

                                        // 电子邮件
                                        .relation-right-mailNew {
                                            display: flex;
                                            align-items: center;
                                            margin-bottom: 10px;

                                            > label {
                                                width: 50px;
                                            }

                                            > input {
                                                width: 200px;
                                                height: 13px;
                                            }
                                        }

                                        // 验证码
                                        .relation-right-codeNew {
                                            display: flex;
                                            align-items: center;
                                            margin-bottom: 10px;

                                            > label {
                                                width: 50px;
                                            }

                                            > input {
                                                width: 80px;
                                                height: 13px;
                                            }

                                            > div {
                                                margin-left: 5px;
                                                // width: 30px;
                                                // border: 1px solid red;
                                            }
                                        }

                                        // 提交、重写
                                        > div:last-child {
                                            display: flex;
                                            margin-left: 50px;

                                            > div:nth-child(1) {
                                                cursor: pointer;
                                                margin-right: 5px;
                                                color: #fff;
                                                padding: 3px 7px;
                                                background-color: #0056b0;
                                            }

                                            > div:nth-child(2) {
                                                cursor: pointer;
                                                margin-right: 5px;
                                                color: #fff;
                                                padding: 3px 7px;
                                                background-color: #f77132;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    // 内容区域右侧
                    .survey-content-right {
                        margin-left: auto;
                        width: 30%;
                        // border: 1px solid red;
                    }
                }
            }

            /*内容区域 end*/
        }
    }
}

//当屏幕宽度 最小为768px 最大为991px时
@media screen and (min-width: 768px) and (max-width: 1200px) {
    // 修改message弹框样式
    .myBox {
        min-width: 130px !important;
        height: 25px !important;
        padding: 3px !important;
        text-align: center !important;
        display: flex !important;
        justify-content: center !important;
        .el-message__icon {
            font-size: 10px;
        }
        .el-message__content {
            font-size: 8px;
        }
    }

    .demandIssueq {
        width: 100%;

        .minkave-wrap {
            width: 80%;
            // height: 1000px;
            margin: 0 auto;
            margin-top: 35px;
            margin-bottom: 40px;
            // border: 1px solid red;

            /*顶部图片start*/
            .index-headImg {
                position: relative;
                z-index: 0;
                width: 100%;
                height: 50px;
                margin-top: 15px;
                display: flex;
                justify-content: space-between;

                // 左侧图片
                .index-headImg-left {
                    width: 48%;
                    height: 100%;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }

                // 右侧图片
                .index-headImg-right {
                    width: 48%;
                    height: 100%;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
            /*顶部图片end*/

            /*关于学会tap start*/
            .survey-nav {
                display: flex;
                align-items: center;
                width: 100%;
                padding: 10px 0;
                box-sizing: border-box;
                border-bottom: 1px solid #e4e4e4;

                .survey-nav-title {
                    width: 20%;
                    display: flex;
                    align-items: center;
                    // border: 1px solid red;

                    .survey-title-cn {
                        font-size: 10px;
                        color: #0056b0;
                        margin-right: 15px;
                    }

                    .survey-title-sn {
                        height: 20px;
                        font-size: 6px;
                        color: #fff;
                        padding: 0 6px;
                        background-color: #0056b0;
                    }
                }
            }
            /*关于学会tap end*/

            /*内容区域 start*/
            .survey-cont {
                width: 100%;

                .survey-content {
                    display: flex;
                    width: 100%;
                    margin-top: 10px;
                    // height: 300px;
                    // border: 1px solid red;

                    // 内容区域左侧1(技术难题)
                    .survey-content-left {
                        display: flex;
                        width: 68%;
                        height: 100%;
                        // border: 1px solid blue;

                        .survey-detail-left {
                            width: 26%;
                            // border: 1px solid red;

                            // 详情左侧内容
                            .minkaveDetails-introduce5-left {
                                width: 100%;
                                // height: 216px;
                                // border: 1px solid red;

                                // 发布问题、我来解答
                                .minkaveDetails-introduce5-title {
                                    > ul {
                                        list-style: none;

                                        .activeli {
                                            color: #0056b0;
                                        }

                                        li {
                                            cursor: pointer;
                                            width: 100%;
                                            height: 20px;
                                            text-align: center;
                                            line-height: 20px;
                                            font-size: 9px;
                                            border-bottom: 1px solid #e9e9e9;
                                        }
                                    }
                                }
                            }
                        }

                        // 详情右侧内容
                        .survey-detail-right {
                            width: 70%;
                            margin-left: auto;
                            // border: 1px solid red;

                            .detail-right {
                                width: 100%;
                                font-size: 12px;
                                background-color: #f8f8f8;

                                .detail-right-place {
                                    font-size: 7px;
                                    padding: 5px;
                                    box-sizing: border-box;
                                    border-bottom: 0.5px solid #e9e9e9;
                                }

                                .detail-right-cont {
                                    padding: 5px;
                                    font-size: 8px;

                                    //就业发布
                                    > div:nth-child(1) {
                                        // 难题名称
                                        .detail-right-name {
                                            display: flex;
                                            align-items: center;
                                            margin-bottom: 10px;

                                            > label {
                                                width: 50px;
                                                font-size: 7px;
                                            }

                                            > input {
                                                width: 53%;
                                                height: 15px;
                                            }
                                        }

                                        .detail-right-select {
                                            display: flex;
                                            align-items: center;
                                            margin-bottom: 10px;

                                            > label {
                                                width: 50px;
                                            }

                                            > select {
                                                width: 53%;
                                                height: 15px;
                                            }
                                        }

                                        // 验证码
                                        .relation-right-code {
                                            display: flex;
                                            align-items: center;
                                            margin-bottom: 10px;

                                            > label {
                                                width: 50px;
                                                font-size: 7px;
                                            }

                                            > input {
                                                width: 50px;
                                                height: 15px;
                                            }

                                            > div {
                                                margin-left: 5px;
                                                width: 50%;
                                            }
                                        }

                                        // 提交、重写
                                        > div:last-child {
                                            display: flex;
                                            margin-left: 50px;

                                            > div:nth-child(1) {
                                                cursor: pointer;
                                                margin-right: 5px;
                                                color: #fff;
                                                padding: 3px 7px;
                                                background-color: #0056b0;
                                            }

                                            > div:nth-child(2) {
                                                cursor: pointer;
                                                margin-right: 5px;
                                                color: #fff;
                                                padding: 3px 7px;
                                                background-color: #f77132;
                                            }
                                        }
                                    }

                                    // 招聘发布
                                    > div:nth-child(2) {
                                        // 难题名称
                                        .detail-right-title {
                                            display: flex;
                                            align-items: center;
                                            margin-bottom: 10px;

                                            > label {
                                                width: 50px;
                                                font-size: 7px;
                                            }

                                            > input {
                                                width: 200px;
                                                height: 13px;
                                            }
                                        }

                                        // 难题描述
                                        .detail-right-areaNew {
                                            display: flex;
                                            margin-bottom: 10px;
                                            > label {
                                                width: 50px;
                                                font-size: 7px;
                                            }

                                            > textarea {
                                                width: 200px;
                                                height: 33px;
                                            }
                                        }

                                        .detail-right-select {
                                            display: flex;
                                            align-items: center;
                                            margin-bottom: 10px;

                                            > label {
                                                width: 70px;
                                            }

                                            > select {
                                                width: 53%;
                                                height: 15px;
                                            }
                                        }

                                        // 电子邮件
                                        .relation-right-mailNew {
                                            display: flex;
                                            align-items: center;
                                            margin-bottom: 10px;

                                            > label {
                                                width: 50px;
                                                font-size: 7px;
                                            }

                                            > input {
                                                width: 200px;
                                                height: 13px;
                                            }
                                        }

                                        // 验证码
                                        .relation-right-codeNew {
                                            display: flex;
                                            align-items: center;
                                            margin-bottom: 10px;

                                            > label {
                                                width: 50px;
                                                font-size: 7px;
                                            }

                                            > input {
                                                width: 50px;
                                                height: 13px;
                                            }

                                            > div {
                                                margin-left: 5px;
                                                width: 60%;
                                                // border: 1px solid red;
                                            }
                                        }

                                        // 提交、重写
                                        > div:last-child {
                                            display: flex;
                                            margin-left: 50px;

                                            > div:nth-child(1) {
                                                cursor: pointer;
                                                margin-right: 5px;
                                                color: #fff;
                                                padding: 3px 7px;
                                                background-color: #0056b0;
                                            }

                                            > div:nth-child(2) {
                                                cursor: pointer;
                                                margin-right: 5px;
                                                color: #fff;
                                                padding: 3px 7px;
                                                background-color: #f77132;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    // 内容区域右侧
                    .survey-content-right {
                        margin-left: auto;
                        width: 30%;
                        // border: 1px solid red;
                    }
                }
            }

            /*内容区域 end*/
        }
    }
}

//当屏幕最大宽度为767px
@media screen and (max-width: 767px) {
}
</style>
