<template>
    <!-- 院校头部组件 -->
    <div class="academy-header">
        <!-- 院校头部 -->
        <div class="academy-he">
            <div class="academy-header-wrap">
                <div>欢迎访问河南省食品科学技术学会网站</div>
                <div @click="login">【会员登录】</div>
                <ul>
                    <li :key="index" @click="skipIndex(item.path)" v-for="(item, index) in aHeaderData">
                        {{ item.name }}
                    </li>
                </ul>
            </div>
        </div>
        <!-- 院校logo -->
        <div class="academy-logo">
            <img v-if="patternmaking != ''" style="width: 100%; height: 100%" :src="patternmaking.image" alt="" />
        </div>
        <!-- 院校导航栏 -->
        <div class="academy-nav">
            <ul>
                <li @click="academyEv(item)" v-for="(item, index) in academyNav" :key="item.id"
                    :class="currentIndex === index ? 'activeLI' : ''">
                    {{ item.name }}
                </li>
            </ul>
        </div>
        <!-- {{ academyNav }} -->
    </div>
</template>

<script>
import { schoolMenu } from "../network/data";
import { schoolInfo, topbanner } from "../network/data";
export default {
    // 组件名称
    name: "a-Index",
    // 组件参数 接收来自父组件的数据
    props: {
        currentIndex: {
            type: Number,
            default: 0,
        },
    },
    // 组件状态值
    data() {
        return {
            // 头部数据
            aHeaderData: [
                { path: "/index", name: "首页" },
                { path: "/about/survey", name: "关于学会" },
                { path: "/made/machining", name: "会员访谈" },
                { path: "/science/minkave", name: "协同创新" },
                { path: "/Special/prefabricate", name: "产业专题" },
                { path: "/observe/flesh", name: "科技前沿" },
                { path: "/science/demand", name: "需求对接" },
                { path: "/norm/standard", name: "标准 政策" },
                { path: "/consult", name: "食品资讯" },
            ],
            // 导航栏数据
            academyNav: [
                // { path: "academyIndex", name: "首页" },
                // { path: "synopsis", name: "学院概况" },
                // { path: "scientific", name: "科研平台" },
                // { path: "demeanour", name: "教授风彩" },
                // { path: "dynamic", name: "学院动态" },
            ],
            // 导航栏当前索引
            academyActive: 0,
            // 院校信息
            schoolInfoObj: {},
            patternmaking: "",
        };
    },
    // 计算属性
    computed: {},
    // 侦听器
    watch: {},
    // 组件方法
    created() {
        this.getBaner()
        // 获取院校导航栏数据
        this.schoolMenuRe();
        // 获取院校信息
        this.schoolInfoRe();
    },
    methods: {
        getBaner() {
            let data = {
                id: sessionStorage.getItem("school_id"),
            };
            topbanner(data).then((res) => {
                if (res.data[0] == undefined) {
                    this.patternmaking = "";
                } else {
                    this.patternmaking = res.data[0];
                }
            });
        },

        // 跳转到首页
        skipIndex(paths) {
            console.log(paths);
            if (paths) {
                this.$router.push(`${paths}`);
            }
        },
        // 导航栏跳转
        academyEv(item) {
            sessionStorage.setItem("menu_id", item.id);
            this.$router.push({
                path: `${item.path}`,
                query: {
                    menu_id: item.id,
                },
            });
        },
        // 获取院校导航栏数据
        schoolMenuRe() {
            schoolMenu().then((res) => {
                if (res.code == 1 && res.data.length > 0) {
                    this.academyNav = res.data;
                    this.academyNav[0].path = "/academyIndex";
                    this.academyNav[1].path = "/synopsis";
                    this.academyNav[2].path = "/scientific";
                    this.academyNav[3].path = "/demeanour";
                    this.academyNav[4].path = "/dynamic";
                } else {
                    this.message(res.msg);
                }
            });
        },
        // 获取院校信息
        schoolInfoRe() {
            let data = {
                id: sessionStorage.getItem("school_id"),
            };
            schoolInfo(data).then((res) => {
                if (res.code == 1) {
                    this.schoolInfoObj = res.data;
                } else {
                    this.message(res.msg);
                }
            });
        },
        login() {
            this.$router.push("/login");
        },
    },
    mounted() { },
    // 局部注册的组件
    components: {},
};
</script>

<style scoped lang="scss">
.academy-header {
    width: 100%;

    // 院校头部 start
    .academy-he {
        position: fixed;
        top: 0;
        background-color: #f6f6f6;
        width: 100%;
        z-index: 9999;

        .academy-header-wrap {
            cursor: pointer;
            display: flex;
            width: 70%;
            margin: 0 auto;
            padding: 5px 0;
            font-size: 8px;

            >ul {
                display: flex;
                margin-left: auto;
                list-style: none;

                li {
                    cursor: pointer;
                    margin-left: 10px;
                    color: #666666;
                    cursor: pointer;
                }
            }
        }
    }

    // 院校头部end
    .academy-logo {
        margin-top: 20px;
        display: flex;
        width: 100%;
        height: 70px;

        >img {
            width: 100%;
            height: 100%;
        }

        .academy-logo-wrap {
            display: flex;
            width: 70%;
            margin: 0 auto;

            >div:nth-child(1) {
                display: flex;
                align-items: center;
                width: 200px;
                height: 100%;

                >img {
                    width: 100%;
                    height: 60%;
                }
            }

            >div:nth-child(2) {
                font-size: 8px;
                line-height: 70px;
                margin-left: 10px;
            }
        }
    }

    // 院校导航栏 start
    .academy-nav {
        background-color: #0056b0;

        >ul {
            display: flex;
            width: 70%;
            height: 20px;
            line-height: 20px;
            margin: 0 auto;
            list-style: none;
            font-size: 9px;
            color: #fff;

            .activeLI {
                background-color: #2b85e3;
            }

            li {
                cursor: pointer;
                text-align: center;
                width: 15%;
                // border: 1px solid red;
            }
        }
    }

    // 院校end
}

//当屏幕最小宽度为992px
@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .academy-header {
        width: 100%;

        // 院校头部 start
        .academy-he {
            background-color: #f6f6f6;
            position: fixed;
            top: 0;
            background-color: #f6f6f6;
            width: 100%;
            z-index: 9999;

            .academy-header-wrap {
                display: flex;
                width: 70%;
                margin: 0 auto;
                padding: 5px 0;
                font-size: 7.5px;

                >div:nth-child(1) {
                    width: 120px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                >div:nth-child(2) {
                    width: 60px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                >ul {
                    display: flex;
                    margin-left: auto;
                    list-style: none;

                    li {
                        margin-left: 4px;
                        color: #666666;
                        cursor: pointer;
                    }
                }
            }
        }

        // 院校头部end
        .academy-logo {
            margin-top: 20px;
            display: flex;
            width: 100%;
            height: 70px;

            >img {
                width: 100%;
                height: 100%;
            }

            .academy-logo-wrap {
                width: 70%;
                margin: 0 auto;

                >div:nth-child(1) {
                    display: flex;
                    align-items: center;
                    width: 50px;
                    height: 100%;

                    >img {
                        width: 100%;
                        height: 60%;
                    }
                }

                >div:nth-child(2) {
                    font-size: 8px;
                    line-height: 70px;
                    margin-left: 10px;
                }
            }
        }

        // 院校导航栏 start
        .academy-nav {
            background-color: #0056b0;

            >ul {
                display: flex;
                width: 70%;
                height: 20px;
                line-height: 20px;
                margin: 0 auto;
                list-style: none;
                font-size: 9px;
                color: #fff;

                .activeLI {
                    background-color: #2b85e3;
                }

                li {
                    cursor: pointer;
                    text-align: center;
                    width: 15%;
                    // border: 1px solid red;
                }
            }
        }

        // 院校end
    }
}

//当屏幕宽度 最小为768px 最大为991px时
@media screen and (min-width: 768px) and (max-width: 1200px) {
    .academy-header {
        width: 100%;

        // 院校头部 start
        .academy-he {
            position: fixed;
            top: 0;
            background-color: #f6f6f6;
            width: 100%;
            z-index: 9999;
            background-color: #f6f6f6;

            .academy-header-wrap {
                display: flex;
                width: 70%;
                margin: 0 auto;
                padding: 5px 0;
                font-size: 6px;
                // border: 1px solid red;

                >div:nth-child(1) {
                    width: 80px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                >div:nth-child(2) {
                    width: 40px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                >ul {
                    display: flex;
                    margin-left: auto;
                    list-style: none;

                    li {
                        margin-left: 2px;
                        color: #666666;
                        cursor: pointer;
                    }
                }
            }
        }

        // 院校头部end
        .academy-logo {
            margin-top: 20px;
            display: flex;
            width: 100%;
            height: 70px;

            >img {
                width: 100%;
                height: 100%;
            }

            .academy-logo-wrap {
                width: 70%;
                margin: 0 auto;

                >div:nth-child(1) {
                    display: flex;
                    align-items: center;
                    width: 50px;
                    height: 100%;

                    >img {
                        width: 100%;
                        height: 60%;
                    }
                }

                >div:nth-child(2) {
                    font-size: 8px;
                    line-height: 70px;
                    margin-left: 10px;
                }
            }
        }

        // 院校导航栏 start
        .academy-nav {
            background-color: #0056b0;

            >ul {
                display: flex;
                width: 70%;
                height: 20px;
                line-height: 20px;
                margin: 0 auto;
                list-style: none;
                font-size: 9px;
                color: #fff;

                .activeLI {
                    background-color: #2b85e3;
                }

                li {
                    cursor: pointer;
                    text-align: center;
                    width: 15%;
                    // border: 1px solid red;
                }
            }
        }

        // 院校end
    }
}

//当屏幕最大宽度为767px
@media screen and (max-width: 767px) {}
</style>
