<template>
    <!-- 政策法规详情页 -->
    <div class="policyDetail">
        <Header></Header>
        <Nav></Nav>
        <div class="survey-wrap">
            <!-- 顶部图片 -->
            <Advertising></Advertising>

            <!-- 关于学会tap -->
            <div class="survey-nav">
                <!-- <Nav></Nav> -->
                <div class="survey-nav-title">
                    <div class="survey-title-cn">政策法规--详情页</div>
                    <!-- <div class="survey-title-sn">About the Institute</div> -->
                </div>
                <ul style="font-size: 22px">
                    <!-- 政策法规详情页暂未写tab切换 -->
                    <!-- <li
                        :key="index"
                        v-for="(item, index) in surveyTap"
                        @click="surveyTapEv(index)"
                        :class="currentIndex == index ? 'active' : ''"
                    >
                        {{ item.name }}
                    </li> -->
                </ul>
            </div>

            <!-- 内容区域,包含学会概况、学会章程、入会申请、通知通告、学会要闻、企业会员 -->
            <div class="survey-cont">
                <div class="survey-content">
                    <div class="survey-content-left">
                        <div class="survey-left-title">
                            <div>
                                <div>{{ lawdetailDe.title }}</div>
                                <div>{{ lawdetailDe.subtitle }}</div>
                            </div>
                            <div>
                                时间：{{ lawdetailDe.createtime }} 来源：{{
                                    lawdetailDe.source
                                }}
                                作者：{{ lawdetailDe.author }} 原文
                            </div>
                        </div>
                        <div class="survey-left-cont" v-html="lawdetailDe.content"></div>
                    </div>
                    <div class="survey-content-right">
                        <Right></Right>
                    </div>
                </div>
            </div>

            <!-- 内容区域 -->
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import Header from "@/components/Header.vue"; //头部
import Footer from "@/components/Footer.vue"; //尾部
import Nav from "@/components/Nav.vue"; //导航
import Right from "@/components/Right.vue"; //关于右侧组件
import Advertising from "@/components/Advertising.vue"; //广告图
import { lawdetail } from "../../../network/data";
export default {
    name: "policyDetail",
    data() {
        return {
            // 当前的内容id和当前面包屑id
            id: "",
            menu_id: "",
            // 政策法规详情页数据
            lawdetailDe: {},
        };
    },
    computed: {},
    watch: {
        $route(to, from) {
            window.location.reload(); //监测到路由发生跳转时刷新一次页面
        },
    },
    created() {
        if (JSON.stringify(this.$route.query) != "{}") {
            this.id = this.$route.query.ids;
            this.menu_id = this.$route.query.menu_id;
        }

        // 获取政策法规详情页数据
        this.lawdetailRe();
    },
    methods: {
        // 获取政策法规详情页数据
        lawdetailRe() {
            let data = {
                menuid: this.menu_id,
                id: this.id,
            };
            lawdetail(data).then((res) => {
                if (res.code == 1) {
                    this.lawdetailDe = res.data.work;
                } else {
                    this.message(res.msg);
                }
            });
        },
    },
    mounted() { },
    beforeCreate() { },
    beforeMount() { },
    beforeUpdate() { },
    updated() { },
    beforeDestroy() { },
    destroyed() { },
    activated() { },
    components: {
        Header,
        Footer,
        Right,
        Nav,
        Advertising,
    },
};
</script>

<style lang="scss" scoped>
.policyDetail {
    width: 100%;

    .survey-wrap {
        width: 70%;
        // height: 1000px;
        margin: 0 auto;
        margin-bottom: 40px;
        // border: 1px solid red;

        /*关于学会tap start*/
        .survey-nav {
            display: flex;
            align-items: center;
            width: 100%;
            padding: 10px 0;
            box-sizing: border-box;
            border-bottom: 1px solid #e4e4e4;

            .survey-nav-title {
                width: 100%;
                display: flex;
                align-items: center;
                // border: 1px solid red;

                .survey-title-cn {
                    font-size: 13px;
                    color: #0056b0;
                    margin-right: 10px;
                }

                // .survey-title-sn {
                //     height: 20px;
                //     font-size: 6px;
                //     color: #fff;
                //     padding: 0 6px;
                //     background-color: #0056b0;
                // }
            }

            // > ul {
            //     width: 80%;
            //     list-style: none;
            //     display: flex;
            //     // border: 1px solid red;

            //     .active {
            //         color: #0068d5;
            //     }

            //     li {
            //         cursor: pointer;
            //         margin-left: 10px;
            //         padding-left: 10px;
            //         font-size: 10px;
            //         border-left: 1px solid #e9e9e9;
            //     }
            // }
        }

        /*关于学会tap end*/

        /*内容区域 start*/
        .survey-cont {
            width: 100%;

            .survey-content {
                display: flex;
                width: 100%;
                margin: 0 auto;
                // border: 1px solid red;

                // 内容区域左侧
                .survey-content-left {
                    width: 72%;
                    height: 100%;
                    // border: 1px solid blue;

                    .survey-left-title {
                        padding: 5px;
                        font-size: 12px;
                        border-bottom: 0.5px solid #cacaca;

                        >div:nth-child(1) {
                            display: inline-block;
                            font-size: 15px;
                            white-space: pre-wrap !important;
                        }

                        >div:nth-child(2) {
                            font-size: 8px;
                            color: #999999;
                        }
                    }

                    .survey-left-cont {
                        font-size: 10px;
                        margin-top: 15px;
                    }
                }

                // 内容区域右侧
                .survey-content-right {
                    margin-left: auto;
                    width: 25%;
                    // border: 1px solid red;
                }
            }
        }

        /*内容区域 end*/
    }
}

//当屏幕最小宽度为992px
@media screen and (min-width: 1200px) {}

//当屏幕宽度 最小为768px 最大为991px时
@media screen and (min-width: 768px) and (max-width: 1200px) {
    .policyDetail {
        width: 100%;

        .survey-wrap {
            width: 80%;
            // height: 700px;
            margin: 0 auto;
            margin-bottom: 40px;
            // border: 1px solid red;

            /*关于学会tap start*/
            .survey-nav {
                display: flex;
                align-items: center;
                width: 100%;
                padding: 10px 0;
                box-sizing: border-box;
                border-bottom: 1px solid #e4e4e4;

                .survey-nav-title {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    // border: 1px solid red;

                    .survey-title-cn {
                        font-size: 10px;
                        color: #0056b0;
                        margin-right: 0px;
                    }

                    // .survey-title-sn {
                    //     height: 20px;
                    //     font-size: 6px;
                    //     color: #fff;
                    //     padding: 0 6px;
                    //     background-color: #0056b0;
                    // }
                }

                // > ul {
                //     width: 80%;
                //     list-style: none;
                //     display: flex;
                //     border: 1px solid red;

                //     .active {
                //         color: #0068d5;
                //     }

                //     li {
                //         cursor: pointer;
                //         margin-left: 5px;
                //         padding-left: 5px;
                //         font-size: 6px;
                //         border-left: 1px solid #e9e9e9;
                //     }
                // }
            }

            /*关于学会tap end*/

            /*内容区域 start*/
            .survey-cont {
                width: 100%;

                .survey-content {
                    display: flex;
                    width: 100%;
                    margin: 0 auto;
                    // border: 1px solid red;

                    // 内容区域左侧
                    .survey-content-left {
                        width: 66%;
                        height: 100%;
                        // border: 1px solid blue;

                        .survey-left-title {
                            padding: 5px;
                            font-size: 12px;
                            border-bottom: 0.5px solid #cacaca;

                            >div:nth-child(1) {
                                display: block;
                                font-size: 10px;
                            }

                            >div:nth-child(2) {
                                font-size: 8px;
                                color: #999999;
                            }
                        }

                        .survey-left-cont {
                            font-size: 10px;
                            margin-top: 10px;
                        }
                    }

                    // 内容区域右侧
                    .survey-content-right {
                        margin-left: auto;
                        width: 30%;
                        // border: 1px solid red;
                    }
                }
            }

            /*内容区域 end*/
        }
    }
}

//当屏幕最大宽度为767px
@media screen and (max-width: 767px) {}
</style>
