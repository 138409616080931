<template>
    <div>
        <router-view />
    </div>
</template>

<script>
export default {
    // 组件名称
    name: "consult",
    // 组件参数 接收来自父组件的数据
    props: {},
    // 组件状态值
    data() {
        return {};
    },
    // 计算属性
    computed: {},
    // 侦听器
    watch: {},
    // 组件方法
    created() {},
    methods: {},
    mounted() {},
    // 局部注册的组件
    components: {},
};
</script>

<style scoped></style>
