<template>
    <!-- 头部组件 -->
    <div class="header">
        <div class="memberLogin-header">
            <div class="memberLogin-header-wrap">
                <div>欢迎访问河南省食品科学技术学会网站</div>
                <div>当前时间:{{ dateFormat(newDate) }}</div>
                <div>
                    <span @click="goLogin">【会员登录】</span>
                    <span @click="goRegister">【免费注册】</span>
                </div>
            </div>
        </div>
        <div class="header-e"  :style="{ backgroundImage: `url(${bg})` }">
            <div class="header-wrap">
                <!-- 头部图片 start-->
                <div class="header-bg">
                    <div class="header-box" >
                        <img :src="siteDe.pclogo" alt="" />
                    </div>
                </div>
                <!-- 头部图片 end-->
            </div>
        </div>
    </div>
</template>

<script>
import { menu, site,getBanner } from "../network/data";
export default {
    // 组件名称
    name: "header",
    // 组件参数 接收来自父组件的数据
    props: {},
    // 组件状态值
    data() {
        return {
            newDate: new Date(),
            timer: null,
            // 站点信息数据
            siteDe: {},
            bg:''
        };
    },
    mounted() {
        //显示当前日期时间
        let _this = this; // 声明一个变量指向Vue实例this，保证作用域一致
        this.timer = setInterval(() => {
            _this.newDate = new Date(); // 修改数据date
        }, 1000);
    },
    // 侦听器
    watch: {},
    // 组件方法
    created() {
        // 获取站点信息
        this.siteRe();
        this.getBanners()
    },
    methods: {
        getBanners( ){
            getBanner().then(res=>{
                if(res.code==1) {
                    console.log(res,'1111111111')
                    this.bg= res.data[0].image
                }
            })
        },
        dateFormat(time) {
            let date = new Date(time);
            let year = date.getFullYear();
            let wk = date.getDay();
            /* 在日期格式中，月份是从0开始的，因此要加0
             * 使用三元表达式在小于10的前面加0，以达到格式统一  如 09:11:05
             * */
            let month =
                date.getMonth() + 1 < 10
                    ? "0" + (date.getMonth() + 1)
                    : date.getMonth() + 1;
            let day =
                date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
            let hours =
                date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
            let minutes =
                date.getMinutes() < 10
                    ? "0" + date.getMinutes()
                    : date.getMinutes();
            let seconds =
                date.getSeconds() < 10
                    ? "0" + date.getSeconds()
                    : date.getSeconds();
            let weeks = [
                "星期日",
                "星期一",
                "星期二",
                "星期三",
                "星期四",
                "星期五",
                "星期六",
            ];
            let week = weeks[wk];
            // 拼接
            return (
                year +
                "年" +
                month +
                "月" +
                day +
                "日" +
                " " +
                hours +
                ":" +
                minutes +
                ":" +
                seconds +
                " " +
                week
            );
        },

        // 跳转到登录
        goLogin() {
            this.$router.push("/login");
        },
        // 跳转到注册
        goRegister() {
            this.$router.push("/memberRegister");
        },
        // 获取站点信息
        siteRe() {
            site().then((res) => {
                if (res.code == 1) {
                    console.log(res.data,'11')
                    this.siteDe = res.data;
                } else {
                    this.message(res.msg);
                }
            });
        },
    },
    beforeDestroy() {
        if (this.timer) {
            clearInterval(this.timer); // 在Vue实例销毁前，清除我们的定时器
        }
    },
    // 局部注册的组件
    components: {},
};
</script>

<style scoped lang="scss">
.header {
    // 会员注册头部
    .memberLogin-header {
        position: absolute;
        top: 0;
        z-index: 1000;
        width: 100%;
        height: 20px;
        line-height: 20px;
        background-color: #eeeded;
        // border: 1px solid red;

        .memberLogin-header-wrap {
            display: flex;
            width: 70%;
            margin: 0 auto;
            font-size: 9px;
            color: #666666;
            // border: 1px solid red;

            > div:nth-child(1) {
                cursor: pointer;
                margin-right: 30px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            > div:nth-child(2) {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            > div:nth-child(3) {
                cursor: pointer;
                margin-left: auto;
            }
        }
    }
    .header-e {
        position: relative;
        z-index: 1;
        width: 100%;
        height: 90px;
        margin-top: 20px;
        background: url("../assets/pc/Index/header-bg.png") no-repeat;
        background-size: 100% 100%;
        // border: 1px solid red;

        .memberLogin-header {
            width: 100%;
            height: 20px;
            line-height: 20px;
            background-color: #f6f6f6;

            .memberLogin-header-wrap {
                display: flex;
                width: 70%;
                margin: 0 auto;
                font-size: 8px;
                color: #666666;

                > div:nth-child(1) {
                    cursor: pointer;
                    margin-right: 30px;
                }

                > div:nth-child(3) {
                    cursor: pointer;
                    margin-left: auto;
                }
            }
        }

        .header-wrap {
            width: 70%;
            margin: 0 auto;
            // margin-top: 30px;

            /*头部图片 start*/
            .header-bg {
                display: flex;
                align-items: center;
                height: 70px;

                .header-box {
                    height: 50px;
                    margin-top: 15px;

                    img {
                        height: 100%;
                    }
                }
            }
            /*头部背景 end*/
        }
    }
}

//当屏幕最小宽度为992px
@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .header {
        // 会员注册头部
        .memberLogin-header {
            width: 100%;
            height: 20px;
            line-height: 20px;
            background-color: #eeeded;

            .memberLogin-header-wrap {
                display: flex;
                width: 70%;
                margin: 0 auto;
                font-size: 8.5px;
                color: #666666;
                // border: 1px solid red;

                > div:nth-child(1) {
                    cursor: pointer;
                    margin-right: 30px;
                }

                > div:nth-child(2) {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                > div:nth-child(3) {
                    cursor: pointer;
                    margin-left: auto;
                }
            }
        }
        .header-e {
            position: relative;
            z-index: 1;
            width: 100%;
            height: 90px;
            background: url("../assets/pc/Index/header-bg.png") no-repeat;
            background-size: 100% 100%;

            .memberLogin-header {
                width: 100%;
                height: 20px;
                line-height: 20px;
                background-color: #f6f6f6;

                .memberLogin-header-wrap {
                    display: flex;
                    width: 70%;
                    margin: 0 auto;
                    font-size: 8px;
                    color: #666666;

                    > div:nth-child(1) {
                        cursor: pointer;
                        margin-right: 30px;
                    }

                    > div:nth-child(3) {
                        cursor: pointer;
                        margin-left: auto;
                    }
                }
            }

            .header-wrap {
                width: 70%;
                margin: 0 auto;
                // margin-top: 30px;

                /*头部图片 start*/
                .header-bg {
                    display: flex;
                    align-items: center;
                    height: 70px;

                    .header-box {
                        height: 50px;
                        margin-top: 15px;

                        img {
                            height: 100%;
                        }
                    }
                }
                /*头部背景 end*/
            }
        }
    }
}

//当屏幕宽度 最小为768px 最大为991px时
@media screen and (min-width: 768px) and (max-width: 1200px) {
    .header {
        // 会员注册头部
        .memberLogin-header {
            width: 100%;
            height: 20px;
            line-height: 20px;
            background-color: #eeeded;

            .memberLogin-header-wrap {
                display: flex;
                width: 80%;
                margin: 0 auto;
                font-size: 8px;
                color: #666666;
                // border: 1px solid red;

                > div:nth-child(1) {
                    cursor: pointer;
                    margin-right: 30px;
                }

                > div:nth-child(2) {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                > div:nth-child(3) {
                    cursor: pointer;
                    margin-left: auto;
                }
            }
        }
        .header-e {
            position: relative;
            z-index: 1;
            width: 100%;
            height: 60px;
            background: url("../assets/pc/Index/header-bg.png") no-repeat;
            background-size: 100% 100%;

            .memberLogin-header {
                width: 100%;
                height: 20px;
                line-height: 20px;
                background-color: #f6f6f6;

                .memberLogin-header-wrap {
                    display: flex;
                    width: 80%;
                    margin: 0 auto;
                    font-size: 8px;
                    color: #666666;

                    > div:nth-child(1) {
                        cursor: pointer;
                        margin-right: 30px;
                    }

                    > div:nth-child(3) {
                        cursor: pointer;
                        margin-left: auto;
                    }
                }
            }

            .header-wrap {
                width: 80%;
                margin: 0 auto;
                // margin-top: 30px;

                /*头部图片 start*/
                .header-bg {
                    display: flex;
                    align-items: center;
                    height: 60px;

                    .header-box {
                        height: 40px;
                        margin-top: 5px;

                        img {
                            height: 100%;
                        }
                    }
                }
                /*头部背景 end*/
            }
        }
    }
}

//当屏幕最大宽度为767px
@media screen and (max-width: 767px) {
}
</style>
