<template>
    <!-- 产业观察详情页数据 -->
    <div class="flesh">
        <Header></Header>
        <HeadNav></HeadNav>
        <div class="prefabricate-wrap">
            <!-- 顶部图片 -->
            <Advertising></Advertising>

            <!-- 关于学会tap -->
            <div class="survey-nav">
                <!-- <Nav></Nav> -->
                <div class="survey-nav-title">
                    <div class="survey-title-cn">{{ observeTap.name }}</div>
                    <div class="survey-title-sn">{{ observeTap.enname }}</div>
                </div>
                <ul>
                    <li
                        :key="index"
                        v-for="(item, index) in observeTap.list"
                        @click="observeTapEv(item, index)"
                        :class="currentIndex == index ? 'active' : ''"
                    >
                        {{ item.name }}
                    </li>
                </ul>
            </div>

            <!-- 内容区域,包含学会概况、学会章程、入会申请、通知通告、学会要闻、企业会员 -->
            <div class="survey-cont">
                <div class="survey-content">
                    <div class="survey-content-left">
                        <ul>
                            <!-- 左侧内容区域列表 -->
                            <li
                                :key="indexd"
                                v-for="(itemd, indexd) in observeListDe.data"
                                @click="fleshDetailEv(itemd, indexd)"
                            >
                                <div class="survey-item-img">
                                    <img :src="itemd.image" alt="" />
                                </div>
                                <div class="survey-item-text">
                                    <div class="survey-item-text1">
                                        {{ itemd.title }}
                                    </div>
                                    <div class="survey-item-text2">
                                        {{ itemd.description }}
                                    </div>
                                    <div class="survey-item-text3">
                                        时间：{{ itemd.createtime }}
                                    </div>
                                </div>
                            </li>

                            <!-- 左侧内容区域分页 -->
                            <div class="pageDown">
                                <Pagination
                                    v-if="JSON.stringify(observeListDe) != ''"
                                    :pageNo="observeParams.page"
                                    :pageSize="observeParams.pageSize"
                                    :total="total"
                                    :continues="5"
                                    @getPageNo="getPageEv"
                                />
                            </div>
                        </ul>
                    </div>
                    <div class="survey-content-right">
                        <Right></Right>
                    </div>
                </div>
            </div>
            <!-- 内容区域 -->
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import HeadNav from "@/components/Nav.vue"; //导航
import Header from "@/components/Header.vue"; //头部
import Footer from "@/components/Footer.vue"; //尾部
import Right from "@/components/Right.vue"; //关于右侧组件
import Nav from "@/components/About/nav.vue"; //关于tap切换
import Pagination from "@/components/Pagination"; //分页组件
import Advertising from "@/components/Advertising.vue"; //广告图
import { navlist, observelist } from "../../../network/data";
export default {
    name: "prefabricate",
    data() {
        return {
            // 当前tap索引
            currentIndex: 0,
            // 切换tap栏id,默认27
            id: 27,
            // 产业观察tap数据
            observeTap: [],
            // 产业观察列表数据
            observeListDe: {},
            // 分页
            observeParams: {
                page: 1,
                pageSize: 3,
            },
            total: 10,
        };
    },
    computed: {},
    watch: {
        $route(to, from) {
            window.location.reload(); //监测到路由发生跳转时刷新一次页面
        },

        // 定义 total 侦听器，指向一个配置对象
        observeListDe: {
            // handler 属性用来定义侦听器的 function 处理函数
            handler(newVal) {
                if (newVal) {
                    if (newVal.current_page) {
                        this.observeParams.page = newVal.current_page;
                    }
                    if (newVal.per_page) {
                        this.observeParams.pageSize = newVal.per_page - 0;
                    }
                    if (newVal.total) {
                        this.total = newVal.total;
                    }
                }
            },
            // immediate 属性用来声明此侦听器，是否在页面初次加载完毕后立即调用
            immediate: true,
        },
    },
    created() {
        if (JSON.stringify(this.$route.query) != "{}") {
            this.currentIndex = this.$route.query.type;
            this.id = this.$route.query.menuid;
        }

        // 获取产业观察面包屑数据
        this.observeTapRe();
        // 获取产业观察列表数据
        this.observelistRe();
    },
    methods: {
        // 面包屑点击事件
        observeTapEv(item, index) {
            this.id = item.id;
            // this.surveyData = [];
            this.currentIndex = index;
            this.observeParams.page = 1;
            this.observelistRe();
        },
        // 分页触发事件
        getPageEv(index) {
            this.observeParams.page = index;
            this.observelistRe();
        },
        // 跳转到产业观察详情页
        fleshDetailEv(item, type) {
            this.$router.push({
                path: `/observe/fleshDetail/${type}`,
                query: {
                    ids: item.id,
                    menu_id: item.menu_id,
                },
            });
        },
        // 获取产业观察tap栏数据
        observeTapRe() {
            navlist(27).then((res) => {
                if (res.code == 1) {
                    this.observeTap = res.data;
                } else {
                    this.message(res.msg);
                }
            });
        },
        // 获取产业专题列表数据
        observelistRe() {
            let data = {
                menuid: this.id,
                page: this.observeParams.page,
                pagesize: this.observeParams.pageSize,
            };
            observelist(data).then((res) => {
                if (res.code == 1) {
                    this.observeListDe = res.data.list;
                } else {
                    this.message(res.msg);
                }
            });
        },
    },
    mounted() {},
    beforeCreate() {},
    beforeMount() {},
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
    activated() {},
    components: {
        Header,
        Footer,
        Right,
        Nav,
        Pagination,
        Advertising,
        HeadNav,
    },
};
</script>

<style lang="scss">
.flesh {
    width: 100%;
    .prefabricate-wrap {
        width: 70%;
        // height: 1000px;
        margin: 0 auto;
        margin-bottom: 40px;
        // border: 1px solid red;

        /*关于学会tap start*/
        .survey-nav {
            display: flex;
            align-items: center;
            width: 100%;
            padding: 10px 0;
            box-sizing: border-box;
            border-bottom: 1px solid #e4e4e4;

            .survey-nav-title {
                width: 30%;
                display: flex;
                align-items: center;
                // border: 1px solid red;

                .survey-title-cn {
                    font-size: 13px;
                    color: #0056b0;
                    margin-right: 10px;
                }

                .survey-title-sn {
                    height: 10px;
                    font-size: 6px;
                    color: #fff;
                    padding: 0 6px;
                    background-color: #0056b0;
                }
            }

            > ul {
                width: 80%;
                list-style: none;
                display: flex;
                // border: 1px solid red;

                .active {
                    color: #0068d5;
                }

                li {
                    cursor: pointer;
                    margin-left: 10px;
                    padding-left: 10px;
                    font-size: 10px;
                    border-left: 1px solid #e9e9e9;
                }
            }
        }
        /*关于学会tap end*/

        /*内容区域 start*/
        .survey-cont {
            width: 100%;

            .survey-content {
                display: flex;
                width: 100%;
                margin: 0 auto;
                // border: 1px solid red;

                // 内容区域左侧
                .survey-content-left {
                    width: 68%;
                    height: 100%;
                    // border: 1px solid blue;

                    > ul {
                        list-style: none;

                        // 左侧内容区域列表
                        li {
                            width: 100%;
                            height: 80px;
                            display: flex;
                            padding: 15px 0;
                            cursor: pointer;
                            border-bottom: 1px solid #e4e4e4;

                            .survey-item-img {
                                width: 26%;
                                height: 100%;

                                img {
                                    width: 100%;
                                    height: 100%;
                                }
                            }

                            .survey-item-text {
                                width: 72%;
                                display: flex;
                                flex-direction: column;
                                margin-left: auto;
                                font-size: 9px;
                                .survey-item-text1 {
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                }

                                .survey-item-text2 {
                                    margin-top: 20px;
                                    font-size: 7px;
                                    color: #999999;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    display: -webkit-box;
                                    -webkit-line-clamp: 2; //行数
                                    -webkit-box-orient: vertical;
                                }

                                .survey-item-text3 {
                                    margin-top: auto;
                                    font-size: 8px;
                                    color: #999999;
                                }
                            }
                        }
                    }
                }

                // 内容区域右侧
                .survey-content-right {
                    margin-left: auto;
                    width: 30%;
                    // border: 1px solid red;
                }
            }
        }

        /*内容区域 end*/
    }
}

//当屏幕最小宽度为992px
@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .flesh {
        width: 100%;
        .prefabricate-wrap {
            width: 70%;
            // height: 1000px;
            margin: 0 auto;
            margin-bottom: 40px;
            // border: 1px solid red;

            /*关于学会tap start*/
            .survey-nav {
                display: flex;
                align-items: center;
                width: 100%;
                padding: 10px 0;
                box-sizing: border-box;
                border-bottom: 1px solid #e4e4e4;

                .survey-nav-title {
                    width: 30%;
                    display: flex;
                    align-items: center;
                    // border: 1px solid red;

                    .survey-title-cn {
                        text-align: center;
                        width: 50px;
                        font-size: 10px;
                        color: #0056b0;
                        // border: 1px solid red;
                        // margin-right: 10px;
                    }

                    .survey-title-sn {
                        width: 60px;
                        height: 10px;
                        font-size: 6px;
                        color: #fff;
                        padding: 0 6px;
                        background-color: #0056b0;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }

                > ul {
                    width: 70%;
                    list-style: none;
                    display: flex;
                    // border: 1px solid red;

                    .active {
                        color: #0068d5;
                    }

                    li {
                        cursor: pointer;
                        text-align: center;
                        width: 50px;
                        margin-left: 0px;
                        padding-left: 0px;
                        font-size: 7.5px;
                        border-left: 1px solid #e9e9e9;
                    }
                }
            }
            /*关于学会tap end*/
            /*内容区域 start*/
            .survey-cont {
                width: 100%;

                .survey-content {
                    display: flex;
                    width: 100%;
                    margin: 0 auto;
                    // border: 1px solid red;

                    // 内容区域左侧
                    .survey-content-left {
                        width: 67%;
                        height: 100%;
                        // border: 1px solid blue;

                        > ul {
                            list-style: none;

                            // 左侧内容区域列表
                            li {
                                width: 100%;
                                height: 60px;
                                display: flex;
                                padding: 13px 0 10px 0;
                                cursor: pointer;
                                border-bottom: 0.5px solid #e4e4e4;

                                .survey-item-img {
                                    width: 26%;
                                    height: 100%;

                                    img {
                                        width: 100%;
                                        height: 100%;
                                    }
                                }

                                .survey-item-text {
                                    width: 72%;
                                    display: flex;
                                    flex-direction: column;
                                    margin-left: auto;
                                    font-size: 9px;
                                    .survey-item-text1 {
                                    }

                                    .survey-item-text2 {
                                        margin-top: 20px;
                                        font-size: 7px;
                                        color: #999999;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        display: -webkit-box;
                                        -webkit-line-clamp: 2; //行数
                                        -webkit-box-orient: vertical;
                                    }

                                    .survey-item-text3 {
                                        margin-top: auto;
                                        font-size: 8px;
                                        color: #999999;
                                    }
                                }
                            }
                        }
                    }

                    // 内容区域右侧
                    .survey-content-right {
                        margin-left: auto;
                        width: 30%;
                        // border: 1px solid red;
                    }
                }
            }

            /*内容区域 end*/
        }
    }
}

//当屏幕宽度 最小为768px 最大为991px时
@media screen and (min-width: 768px) and (max-width: 1200px) {
    .flesh {
        width: 100%;
        .prefabricate-wrap {
            width: 80%;
            // height: 1000px;
            margin: 0 auto;
            margin-bottom: 40px;
            // border: 1px solid red;

            /*关于学会tap start*/
            .survey-nav {
                display: flex;
                align-items: center;
                width: 100%;
                padding: 10px 0;
                box-sizing: border-box;
                border-bottom: 1px solid #e4e4e4;

                .survey-nav-title {
                    width: 30%;
                    display: flex;
                    align-items: center;
                    // border: 1px solid red;

                    .survey-title-cn {
                        width: 50%;
                        font-size: 12px;
                        color: #0056b0;
                        margin-right: 6px;
                    }
                    .survey-title-sn {
                        width: 30%;
                        height: 10px;
                        font-size: 6px;
                        color: #fff;
                        padding: 0 6px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        background-color: #0056b0;
                    }
                }

                > ul {
                    width: 80%;
                    list-style: none;
                    display: flex;
                    // border: 1px solid red;

                    .active {
                        color: #0068d5;
                    }

                    li {
                        cursor: pointer;
                        margin-left: 5px;
                        padding-left: 5px;
                        font-size: 6px;
                        border-left: 1px solid #e9e9e9;
                    }
                }
            }
            /*关于学会tap end*/

            /*内容区域 start*/
            .survey-cont {
                width: 100%;

                .survey-content {
                    display: flex;
                    width: 100%;
                    margin: 0 auto;
                    // border: 1px solid red;

                    // 内容区域左侧
                    .survey-content-left {
                        width: 67%;
                        height: 100%;
                        // border: 1px solid blue;

                        > ul {
                            list-style: none;

                            // 左侧内容区域列表
                            li {
                                width: 100%;
                                height: 60px;
                                display: flex;
                                padding: 8px 0;
                                border-bottom: 1px solid #e4e4e4;

                                .survey-item-img {
                                    width: 27%;
                                    height: 100%;

                                    img {
                                        width: 100%;
                                        height: 100%;
                                    }
                                }

                                .survey-item-text {
                                    width: 70%;
                                    display: flex;
                                    flex-direction: column;
                                    margin-left: auto;
                                    font-size: 9px;
                                    .survey-item-text1 {
                                        font-size: 10px;
                                    }

                                    .survey-item-text2 {
                                        margin-top: 10px;
                                        font-size: 7px;
                                        color: #999999;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        display: -webkit-box;
                                        -webkit-line-clamp: 2; //行数
                                        -webkit-box-orient: vertical;
                                    }

                                    .survey-item-text3 {
                                        margin-top: auto;
                                        font-size: 8px;
                                        color: #999999;
                                    }
                                }
                            }
                        }
                    }

                    // 内容区域右侧
                    .survey-content-right {
                        margin-left: auto;
                        width: 30%;
                        // border: 1px solid red;
                    }
                }
            }

            /*内容区域 end*/
        }
    }
}

//当屏幕最大宽度为767px
@media screen and (max-width: 767px) {
}
</style>
