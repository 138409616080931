<template>
    <!-- 导航栏组件 -->
    <div class="header-list">
        <div class="header-list-tap">
            <ul class="ullist">
                <li :key="index" class="libt" v-for="(item, index) in menus">
                    <div
                        href="#"
                        @click="skip1(item.path)"
                        @mouseover="listEnter($event)"
                        @mouseout="listLeave($event)"
                    >
                        {{ item.name }}
                    </div>
                    <ul
                        :key="indexa"
                        class="droplist"
                        v-for="(itema, indexa) in item.son"
                    >
                        <li
                            @click="skip(itema, itema.type)"
                            @mouseover="sonlistEnter($event)"
                            @mouseout="sonLeave($event)"
                            class="sonLibt"
                        >
                            <a href="#">{{ itema.name }}</a>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import { menu, site } from "../network/data";
export default {
    // 组件名称
    name: "header",
    // 组件参数 接收来自父组件的数据
    props: {},
    // 组件状态值
    data() {
        return {
            newDate: new Date(),
            timer: null,
            // 站点信息数据
            siteDe: {},
            // 顶部导航栏数据
            menus: [
                //     { name: "首页", path: "index", url: "#" },
                //     {
                //         name: "关于学会",
                //         url: "#",
                //         subMenus: [
                //             { name: "学会概况", path: "/about/survey", type: 0 },
                //             { name: "学会章程", path: "/about/survey", type: 1 },
                //             { name: "学会工作", path: "/about/survey", type: 2 },
                //             { name: "分支机构", path: "/about/survey", type: 3 },
                //             { name: "入会申请", path: "/about/survey", type: 4 },
                //             { name: "通知通告", path: "/about/survey", type: 5 },
                //             { name: "学会要闻", path: "/about/survey", type: 6 },
                //             { name: "企业会员", path: "/about/survey", type: 7 },
                //         ],
                //     },
                //     {
                //         name: "产业智造",
                //         url: "#",
                //         subMenus: [
                //             { name: "食品加工", path: "/made/machining", type: 0 },
                //             { name: "科学研究", path: "/made/machining", type: 1 },
                //             { name: "原辅料", path: "/made/machining", type: 2 },
                //             { name: "智能装备", path: "/made/machining", type: 3 },
                //             { name: "冷链云仓 ", path: "/made/machining", type: 4 },
                //         ],
                //     },
                //     {
                //         name: "科学赋能",
                //         url: "#",
                //         subMenus: [
                //             { name: "科技资源", path: "/science", url: "#" },
                //             { name: "助企名家", path: "/science/minkave" },
                //             { name: "助企活动", path: "/science/activity" },
                //             { name: "产业需求", path: "/science/demand" },
                //         ],
                //     },
                //     {
                //         name: "产业专题",
                //         url: "#",
                //         subMenus: [
                //             {
                //                 name: "预制食品",
                //                 path: "/Special/prefabricate",
                //                 type: 0,
                //             },
                //             {
                //                 name: "药食同源",
                //                 path: "/Special/prefabricate",
                //                 type: 1,
                //             },
                //             {
                //                 name: "品控质量",
                //                 path: "/Special/prefabricate",
                //                 type: 2,
                //             },
                //             {
                //                 name: "食品营养",
                //                 path: "/Special/prefabricate",
                //                 type: 3,
                //             },
                //         ],
                //     },
                //     {
                //         name: "产业观察",
                //         url: "#",
                //         subMenus: [
                //             { name: "肉制食品", path: "/observe/flesh", type: 0 },
                //             { name: "米面制品", path: "/observe/flesh", type: 1 },
                //             { name: "酒水饮品", path: "/observe/flesh", type: 2 },
                //             { name: "调味制品", path: "/observe/flesh", type: 3 },
                //             { name: "粮油制品", path: "/observe/flesh", type: 4 },
                //             { name: "特色农产品", path: "/observe/flesh", type: 5 },
                //         ],
                //     },
                //     {
                //         name: "产业需求",
                //         url: "#",
                //         subMenus: [
                //             { name: "技术难题", path: "/science/demand", type: 0 },
                //             { name: "成果转化", path: "/science/demand", type: 1 },
                //             { name: "就业招聘", path: "/science/demand", type: 2 },
                //             { name: "招商引资", path: "/science/demand", type: 3 },
                //             {
                //                 name: "产学研合作",
                //                 path: "/science/demand",
                //                 type: 4,
                //             },
                //         ],
                //     },
                //     {
                //         name: "行业规范",
                //         url: "#",
                //         subMenus: [
                //             { name: "行业标准", path: "/norm/standard" },
                //             { name: "政策法规", path: "/norm/policy" },
                //         ],
                //     },
                //     { name: "食品咨询", path: "consult" },
            ],
        };
    },
    mounted() {
        //显示当前日期时间
        let _this = this; // 声明一个变量指向Vue实例this，保证作用域一致
        this.timer = setInterval(() => {
            _this.newDate = new Date(); // 修改数据date
        }, 1000);
    },
    // 侦听器
    watch: {},
    // 组件方法
    created() {
        // 获取导航栏数据
        this.menuRe();
        // 获取站点信息
        this.siteRe();
    },
    methods: {
        dateFormat(time) {
            let date = new Date(time);
            let year = date.getFullYear();
            let wk = date.getDay();
            /* 在日期格式中，月份是从0开始的，因此要加0
             * 使用三元表达式在小于10的前面加0，以达到格式统一  如 09:11:05
             * */
            let month =
                date.getMonth() + 1 < 10
                    ? "0" + (date.getMonth() + 1)
                    : date.getMonth() + 1;
            let day =
                date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
            let hours =
                date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
            let minutes =
                date.getMinutes() < 10
                    ? "0" + date.getMinutes()
                    : date.getMinutes();
            let seconds =
                date.getSeconds() < 10
                    ? "0" + date.getSeconds()
                    : date.getSeconds();
            let weeks = [
                "星期日",
                "星期一",
                "星期二",
                "星期三",
                "星期四",
                "星期五",
                "星期六",
            ];
            let week = weeks[wk];
            // 拼接
            return (
                year +
                "年" +
                month +
                "月" +
                day +
                "日" +
                " " +
                hours +
                ":" +
                minutes +
                ":" +
                seconds +
                " " +
                week
            );
        },
        // 一级跳转
        skip1(paths) {
            if (paths) {
                this.$router.push(`/${paths}`);
            }
        },
        // 二级跳转
        skip(paths, types) {
            console.log(paths)
            this.$router.push({
                path: paths.path,
                query: {
                    type: types,
                    menuid: paths.menuid ? paths.menuid : "",
                },
            });
        },
        // 跳转到登录
        goLogin() {
            this.$router.push("/login");
        },
        // 跳转到注册
        goRegister() {
            this.$router.push("/memberRegister");
        },
        // 父导航栏点击事件
        listEnter(e) {
            e.target.className = "ran";
        },
        // 父导航离开触发
        listLeave(e) {
            // console.log("离开");
            e.target.className = "";
        },
        // 子导航离开触发
        sonLeave(e) {
            let li = e.target.parentElement;
            let ul = li.parentElement;
            let part = ul.parentElement;
            let node = part.firstElementChild;
            node.className = "";
        },
        // 子导航栏触发
        sonlistEnter(e) {
            let li = e.target.parentElement;
            let ul = li.parentElement;
            let part = ul.parentElement;
            let node = part.firstElementChild;
            node.className = "ran bg";
        },
        // 获取导航栏数据
        menuRe() {
            menu().then((res) => {
                if (res.code == 1) {
                    this.menus = res.data;

                    // 添加首页
                    this.menus.unshift({
                        name: "首页",
                        path: "index",
                        url: "#",
                    });
                    // 添加关于学会路由
                    this.menus[1].son.forEach((item, index) => {
                        item.path = "/about/survey";
                        item.type = index;
                    });
                    // 添加产业智造路由
                    this.menus[2].son.forEach((item, index) => {
                        item.path = "/made/machining";
                        item.type = index;
                        item.menuid = item.id;
                    });
                    // 添加科学赋能路由
                    this.menus[3].son.forEach((item, index) => {
                        if (index == 0) {
                            item.path = "/advertising";
                            item.type = 2;
                        } else if (index == 1) {
                            item.path = "/science/minkave";
                        } else if (index == 2) {
                            item.path = "/science/activity";
                        } else if (index == 3) {
                            item.path = "/science/demand";
                        }
                    });
                    // 添加产业专题路由
                    this.menus[4].son.forEach((item, index) => {
                        item.path = "/Special/prefabricate";
                        item.type = index;
                        item.menuid = item.id;
                    });
                    // 添加产业观察路由
                    this.menus[5].son.forEach((item, index) => {
                        item.path = "/observe/flesh";
                        item.type = index;
                        item.menuid = item.id;
                    });
                    // 添加产业需求路由
                    this.menus[6].son.forEach((item, index) => {
                        item.path = "/science/demand";
                        item.type = index;
                    });
                    // 添加行业规范路由
                    this.menus[7].son.forEach((item, index) => {
                        if (index == 0) {
                            item.path = "/norm/standard";
                        } else if ((index = 1)) {
                            item.path = "/norm/policy";
                        }
                    });
                    // 添加食品咨询路由
                    this.menus[8].son.forEach((item, index) => {
                        item.path = "/consult/cousultHot";
                        item.type = index;
                        item.menuid = item.id;
                    });
                    console.log(this.menus);
                } else {
                    this.$message(res.msg);
                }
            });
        },
        // 获取站点信息
        siteRe() {
            site().then((res) => {
                if (res.code == 1) {
                    // console.log(res.data);
                    this.siteDe = res.data;
                } else {
                    this.message(res.msg);
                }
            });
        },
    },
    beforeDestroy() {
        if (this.timer) {
            clearInterval(this.timer); // 在Vue实例销毁前，清除我们的定时器
        }
    },
    // 局部注册的组件
    components: {},
};
</script>

<style scoped lang="scss">
.header-list {
    position: sticky;
    top: 0;
    z-index: 999;
    height: 30px;
    width: 100%;
    line-height: 30px;
    margin: 0px !important;
    background-color: #0056b0;
    // border: 1px solid red;

    .header-list-tap {
        width: 70%;
        height: 100%;
        margin: 0 auto;
        box-sizing: border-box;

        .ullist {
            list-style: none;
            display: flex;
        }

        .libt {
            /* 设置它的宽高 */
            width: 12%;
            height: 100%;
            /* 让它里面的字水平垂直居中 */
            text-align: center;
            font-size: 10px;
            color: #ffffff;
            cursor: pointer;
            // border: 1px solid red;
        }

        /* 设置鼠标悬停时背景色为灰色 */
        .libt div:hover {
            background: #003d7c;
        }

        .droplist {
            /* 我们给它背景色，给它阴影 */
            background: #0056b0;
            /* 这里是隐藏 */
            display: none;
            list-style: none;

            li {
                height: 20px;
                line-height: 20px;

                > a {
                    font-size: 6px;
                    color: #ffffff;
                    /* 用上边框线把每一项隔开 */
                    // border-top: 1px solid #ccc;
                    /* 把链接改成块显示 */
                    display: block;
                    height: 100%;
                    text-decoration: none;
                }
            }
        }

        // 添加头部样式
        .ran::before {
            content: "";
            display: block;
            height: 6px;
            width: 118%;
            position: relative;
            top: -5px;
            left: -6px;
            right: 10px;
            margin-bottom: -6px;
            background: url("../assets/pc/Index/cew.png") no-repeat;
            background-size: 100% 100%;
        }

        .bg {
            background: #003d7c;
        }

        /* 这里是显示 */
        .libt:hover .droplist {
            display: block;
        }

        .droplist li:hover {
            background: #194d83;
        }
    }
}

//当屏幕最小宽度为992px
@media screen and (min-width: 1200px) and (max-width: 1600px) {
    /*导航栏 start*/
    .header-list {
        height: 30px;
        width: 100%;
        line-height: 30px;
        margin: 0px auto;
        margin-top: 20px;
        background-color: #0056b0;

        .header-list-tap {
            width: 70%;
            height: 100%;
            margin: 0 auto;
            box-sizing: border-box;

            .ullist {
                list-style: none;
                display: flex;
            }

            .libt {
                /* 设置它的宽高 */
                width: 12%;
                height: 100%;
                /* 让它里面的字水平垂直居中 */
                text-align: center;
                font-size: 9px;
                color: #ffffff;
                cursor: pointer;
                // border: 1px solid red;
            }

            /* 设置鼠标悬停时背景色为灰色 */
            .libt div:hover {
                background: #003d7c;
            }

            .droplist {
                /* 我们给它背景色，给它阴影 */
                background: #0056b0;
                /* 这里是隐藏 */
                display: none;
                list-style: none;

                li {
                    height: 20px;
                    line-height: 20px;

                    > a {
                        font-size: 6px;
                        color: #ffffff;
                        /* 用上边框线把每一项隔开 */
                        // border-top: 1px solid #ccc;
                        /* 把链接改成块显示 */
                        display: block;
                        height: 100%;
                        text-decoration: none;
                    }
                }
            }

            // 添加头部样式
            .ran::before {
                content: "";
                display: block;
                height: 6px;
                width: 118%;
                position: relative;
                top: -5px;
                left: -5px;
                right: 10px;
                margin-bottom: -6px;
                background: url("../assets/pc/Index/cew.png") no-repeat;
                background-size: 100% 100%;
            }

            .bg {
                background: #003d7c;
            }

            /* 这里是显示 */
            .libt:hover .droplist {
                display: block;
            }

            .droplist li:hover {
                background: #194d83;
            }
        }
    }
    /*导航栏 end*/
}

//当屏幕宽度 最小为768px 最大为991px时
@media screen and (min-width: 768px) and (max-width: 1200px) {
    /*导航栏 start*/
    .header-list {
        height: 25px;
        width: 100%;
        line-height: 25px;
        margin: 0px auto;
        // margin-top: 20px;
        background-color: #0056b0;

        .header-list-tap {
            width: 80%;
            height: 100%;
            margin: 0 auto;
            box-sizing: border-box;

            .ullist {
                list-style: none;
                display: flex;
            }

            .libt {
                /* 设置它的宽高 */
                width: 12%;
                height: 100%;
                /* 让它里面的字水平垂直居中 */
                text-align: center;
                font-size: 8px;
                color: #ffffff;
                cursor: pointer;
                // border: 1px solid red;
            }

            /* 设置鼠标悬停时背景色为灰色 */
            .libt div:hover {
                background: #003d7c;
            }

            .droplist {
                /* 我们给它背景色，给它阴影 */
                background: #0056b0;
                /* 这里是隐藏 */
                display: none;
                list-style: none;

                li {
                    height: 20px;
                    line-height: 20px;

                    > a {
                        font-size: 6px;
                        color: #ffffff;
                        /* 用上边框线把每一项隔开 */
                        // border-top: 1px solid #ccc;
                        /* 把链接改成块显示 */
                        display: block;
                        height: 100%;
                        text-decoration: none;
                    }
                }
            }

            // 添加头部样式
            .ran::before {
                content: "";
                display: block;
                height: 6px;
                width: 118%;
                position: relative;
                top: -6px;
                left: -4.5px;
                right: 10px;

                margin-bottom: -6px;
                background: url("../assets/pc/Index/cew.png") no-repeat;
                background-size: 100% 100%;
            }

            /* 这里是显示 */
            .libt:hover .droplist {
                display: block;
            }

            .droplist li:hover {
                background: #194d83;
            }
        }
    }
    /*导航栏 end*/
}

//当屏幕最大宽度为767px
@media screen and (max-width: 767px) {
}
</style>
