<template>
    <div class="advertising">
        <!-- 非首页顶部图片 -->
        <div class="index-headImg">
            <div class="index-headImg-left">
                <a :href="advimageDe[0].url"><img :src="advimageDe[0].image" alt="" /></a>
            </div>
            <div class="index-headImg-right">
                <a :href="advimageDe[1].url"><img :src="advimageDe[1].image" alt="" /></a>
            </div>
        </div>
    </div>
</template>

<script>
import { advimagenew } from "../network/data";
export default {
    // 组件名称
    name: "advertising",
    // 组件参数 接收来自父组件的数据
    props: {},
    // 组件状态值
    data() {
        return {
            // 广告图数据
            advimageDe: {},
        };
    },
    // 计算属性
    computed: {},
    // 侦听器
    watch: {},
    // 组件方法
    created() {
        // 获取广告图
        this.advimageRe();
    },
    methods: {
        // 获取广告图
        advimageRe() {
            let data = {
                type:1
            }
            advimagenew(data).then(res=>{
                if(res.code==1) {
                   this.advimageDe = res.data.indextop
                }
            })
        },
    },
    mounted() {},
    // 局部注册的组件
    components: {},
};
</script>

<style scoped lang="scss">
.survey .survey-wrap > div:nth-child(1) {
    margin: 0 !important;
}

.advertising {
    /*顶部图片start*/
    .index-headImg {
        position: relative;
        z-index: 0;
        width: 100%;
        height: 50px;
        margin-top: 10px !important;
        display: flex;
        justify-content: space-between;

        // 左侧图片
        .index-headImg-left {
            width: 48%;
            height: 100%;

            img {
                width: 100%;
                height: 100%;
            }
        }

        // 右侧图片
        .index-headImg-right {
            width: 48%;
            height: 100%;

            img {
                width: 100%;
                height: 100%;
            }
        }
    }
    /*顶部图片end*/
}

//当屏幕最小宽度为992px
@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .advertising {
        /*顶部图片start*/
        .index-headImg {
            position: relative;
            z-index: 0;
            width: 100%;
            height: 45px;
            margin-top: 40px;
            display: flex;
            justify-content: space-between;
            // border: 1px solid red;

            // 左侧图片
            .index-headImg-left {
                width: 48%;
                height: 100%;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            // 右侧图片
            .index-headImg-right {
                width: 48%;
                height: 100%;

                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
        /*顶部图片end*/
    }
}

//当屏幕宽度 最小为768px 最大为991px时
@media screen and (min-width: 768px) and (max-width: 1200px) {
    /*顶部图片start*/
    .advertising {
        /*顶部图片start*/
        .index-headImg {
            position: relative;
            z-index: 0;
            width: 100%;
            height: 35px;
            margin-top: 30px;
            display: flex;
            justify-content: space-between;

            // 左侧图片
            .index-headImg-left {
                width: 48%;
                height: 100%;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            // 右侧图片
            .index-headImg-right {
                width: 48%;
                height: 100%;

                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
        /*顶部图片end*/
    }
    /*顶部图片end*/
}

//当屏幕最大宽度为767px
@media screen and (max-width: 767px) {
}
</style>
