<template>
    <!-- 企业会员轮播图 -->
    <div class="academy">
        <AHeader :currentIndex="0"></AHeader>
        <!-- 轮播图 -->
        <div class="academy-swiper">
            <MeSwiper></MeSwiper>
        </div>
        <!-- 学院概况背景 -->
        <div class="academy-synopsis-bg">
            <div class="academy-synopsis-wrap">
                <span>Welcome to Lcoln School</span>
                <img src="../../../assets/pc/academy/three-point.png"></img>
            </div>
        </div>
        <!-- 学院概况 -->
        <div class="academy-synopsis">
            <div class="academy-synopsis-wrap" @click="goSynopsis">
                <div class="academy-synopsis-left">
                    <div>COLLEGE PROFILE</div>
                    <div>
                        <img src="../../../assets/pc/academy/lineae.png" />
                        <h6>学院概况</h6>
                        <img src="../../../assets/pc/academy/lineae.png" />
                    </div>
                    <div>{{ schoolAbout.description }}</div>
                    <div>Read more</div>
                </div>
                <div class="academy-synopsis-right">
                    <img :src="schoolAbout.image"></img>
                </div>
            </div>
        </div>
        <!-- 科研平台 -->
        <div class="academy-scientific">
            <!-- 科研平台标题 -->
            <div class="academy-scientific-title">
                <div>SCIENTIFIC RESEARCH PLATFORM</div>
                <div>
                    <img src="../../../assets/pc/academy/lineae.png" />
                    <h6>科研平台</h6>
                    <img src="../../../assets/pc/academy/lineae.png" />
                </div>
            </div>

            <!-- 科研平台内容 -->
            <div class="journalism-body">
                <div class="journalism-body1">
                    <!-- 左侧 -->
                    <div class="journalism-body1-left" @click="goAcademyDetail(newsleft)">
                        <div class="journalism-left-img">
                            <img :src="newsleft.image" alt="" />
                        </div>
                        <h3 class="journalism-left-title">
                            {{ newsleft.title }}
                        </h3>
                        <div class="journalism-left-describe">
                            {{ newsleft.description }}<span>《更多》</span>
                        </div>
                    </div>
                    <!-- 右侧 -->
                    <div class="journalism-body1-right">
                        <!-- 科研平台列表 -->
                        <div :key="indexb" class="describe-right-item" @click="goAcademyDetail(itemb)"
                            v-for="(itemb, indexb) in newsright">
                            <div class="journalism-right-time">
                                <span>{{ itemb.year }}</span>
                                <span>{{ itemb.day }}</span>
                            </div>
                            <div class="journalism-right-describe">
                                <h3 class="journalism-right-title">
                                    {{ itemb.title }}
                                </h3>
                                <div class="journalism-right-text">
                                    {{ itemb.description }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 教授风范 -->
        <div class="academy-demeanour">
            <!-- 教授风范标题 -->
            <div class="academy-demeanour-title">
                <div>PROFESSORSHIP</div>
                <div>
                    <img src="../../../assets/pc/academy/lineae.png" />
                    <h6>教授风采</h6>
                    <img src="../../../assets/pc/academy/lineae.png" />
                </div>
            </div>

            <!-- 教授风采内容 -->
            <div class="academy-demeanour-content">
                <ul>
                    <li @click="demeanourEv(deIndex)" v-for="(item, deIndex) in demeanour"
                        :class="demeanourCurrent == deIndex ? 'demeanourActive' : ''" :key="deIndex">
                        {{ item.name }}
                    </li>
                </ul>
                <ul>
                    <li v-for="(item, index) in demeanourList" @click="demeanourDetail(item)" :key="index">
                        <div>
                            <img :src="item.image" alt="">
                        </div>
                        <div>
                            <span>{{ item.title }}</span>
                            <span>{{ item.rank }}</span>
                        </div>
                    </li>
                </ul>
            </div>
        </div>

        <!-- 学院动态 -->
        <div class="academy-dynamic">
            <div class="academy-dynamic-wrap">
                <!-- 学院动态标题 -->
                <div class="academy-dynamic-title">
                    <div>PROFESSORSHIP</div>
                    <div>
                        <img src="../../../assets/pc/academy/lineae.png" />
                        <h6>学院动态</h6>
                        <img src="../../../assets/pc/academy/lineae.png" />
                    </div>
                </div>

                <!-- 学院动态内容 -->
                <div class="academy-dynamic-content">
                    <div class="academy-dynamic-top">
                        <div class="academy-dynamic-left" @click="academyDetail(dynamicDeTop)">
                            <img src="../../../assets/pc/academy/tag.png" alt="">
                            <div>{{ dynamicDeTop.title }}</div>
                            <div>{{ dynamicDeTop.description }}</div>
                            <div>发表日期：{{ dynamicDeTop.createtime }} | 阅读人数：{{ dynamicDeTop.looknum }}</div>
                        </div>
                        <div class="academy-dynamic-right">
                            <Swiper :pic_src="pic_src" v-if="pic_src.length > 0"></Swiper>
                        </div>
                    </div>
                    <div class="academy-dynamic-bottom">
                        <ul>
                            <li v-for="(itema, indexa) in dynamicDe" @click="academyDetail(itema)" :key="indexa">
                                <div>{{ itema.title }}</div>
                                <div>{{ itema.description }}</div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import AHeader from "@/components/Aheader.vue";
import Footer from "@/components/Footer.vue"
import Swiper from "@/components/Swiper.vue"
import MeSwiper from "../../../components/College/swiper.vue";
import { schoolIndex, schoolBanner } from '../../../network/data';

export default {
    // 组件名称
    name: "aIndex",
    // 组件参数 接收来自父组件的数据
    props: {},
    // 组件状态值
    data() {
        return {
            // 学院概况数据
            schoolAbout: {},
            // 科研平台左侧数据
            newsleft: {},
            // 科研平台右侧数据
            newsright: [],
            // 教授风采
            demeanour: [],
            // 教授风采列表数据
            demeanourList: [],
            // 当前教授风采索引
            demeanourCurrent: 0,
            // 学院动态数据
            dynamicDe: [],
            // 学院动态数据头部
            dynamicDeTop: {},
            // 学院动态轮播图数据
            pic_src: [],
            // 首页轮播图数据
            swiperData: [],
        };
    },
    // 计算属性
    computed: {},
    // 侦听器
    watch: {},
    // 组件方法
    created() {
        // 获取院校首页数据
        this.schoolIndexRe()
        // 获取院校banner图数据
        this.schoolBannerRe()
    },
    methods: {
        // 教授风采切换
        demeanourEv(index) {
            this.demeanourCurrent = index
            // 获取院校首页数据
            this.schoolIndexRe()
        },
        // 获取院校首页数据
        schoolIndexRe() {
            let data = {
                id: sessionStorage.getItem("school_id")
            }
            schoolIndex(data).then((res) => {
                if (res.code == 1) {
                    this.schoolAbout = res.data.about.info
                    if (res.data.research.list.length > 0) {
                        this.newsleft = res.data.research.list[0]
                    }
                    if (res.data.research.list.length > 0) {
                        this.newsright = res.data.research.list
                    }

                    if (res.data.teach.list.length > 0) {
                        this.demeanour = res.data.teach.list
                        if (this.demeanourCurrent != '') {
                            this.demeanourList = res.data.teach.list[this.demeanourCurrent].list
                        }
                    }

                    if (res.data.news.list.length > 0) {
                        this.dynamicDeTop = res.data.news.list[0]
                    }
                    if (res.data.news.list.length > 0) {
                        this.dynamicDe = res.data.news.list
                        this.pic_src = this.dynamicDe
                    }
                } else {
                    //    this.message(res.msg);
                }
            });
        },
        // 获取院校banner图数据
        schoolBannerRe() {
            let data = {
                id: sessionStorage.getItem("school_id"),
                menu_id: 1
            }
            schoolBanner(data).then((res) => {
                if (res.code == 1) {
                    //   console.log(res.data);
                    this.swiperData = res.data
                } else {
                    //   this.message(res.msg);
                }
            });
        },
        // 跳转到院校科研平台详情页
        goAcademyDetail(item) {
            this.$router.push({
                path: "/AcademyDetail",
                query: {
                    sonId: item.id,
                    // 导航栏选中样式索引
                    current: "2",
                },
            });
            window.scrollTo(0, 0);
        },
        // 跳转到院校科研平台详情页
        demeanourDetail(item) {
            this.$router.push({
                path: "/AcademyDetail",
                query: {
                    sonId: item.id,
                    // 导航栏选中样式索引
                    current: "3",
                },
            });
            window.scrollTo(0, 0);
        },
        // 跳转到院校科研平台详情页
        academyDetail(item) {
            this.$router.push({
                path: "/AcademyDetail",
                query: {
                    sonId: item.id,
                    // 导航栏选中样式索引
                    current: "4",
                },
            });
            window.scrollTo(0, 0);
        },
        // 跳转到学院概况页
        goSynopsis() {
            this.$router.push({
                path: "/synopsis",
                query: {
                    menu_id: 2
                },
            });
            window.scrollTo(0, 0);
        }
    },
    mounted() { },
    // 局部注册的组件
    components: { AHeader, Footer, Swiper, MeSwiper },
};
</script>

<style lang="scss">
.academy {

    // 轮播图 start
    .academy-swiper {}

    // 轮播图 end

    /* 学院概况背景 start*/
    .academy-synopsis-bg {
        height: 70px;
        background-color: #0056b0;

        .academy-synopsis-wrap {
            width: 70%;
            margin: 0 auto;

            >span {
                margin-right: 5px;
                font-size: 13px;
                color: #fff;
            }
        }
    }

    /* 学院概况 end*/

    /* 学院概况 start */
    .academy-synopsis {
        display: flex;
        height: 220px;
        margin-top: -20px;

        .academy-synopsis-wrap {
            display: flex;
            justify-content: space-around;
            width: 70%;
            margin: 0 auto;
        }

        // 学会概况左侧
        .academy-synopsis-left {
            width: 40%;
            height: 100%;
            padding: 10px;
            box-sizing: border-box;
            background-color: #fff;
            box-shadow: -5px 5px 10px -4px #e5e5e5, 5px 5px 10px -4px #e5e5e5;
            // border:1px solid red;

            >div:nth-child(1) {
                font-size: 7px;
                margin-left: 13px;
                color: #ececec
            }

            >div:nth-child(2) {
                display: flex;
                align-items: center;

                >h6 {
                    font-size: 12px;
                    margin: 0 5px;
                }
            }

            >div:nth-child(3) {
                margin-top: 10px;
                margin-bottom: 20px;
                font-size: 8px;
                color: #999999
            }

            >div:nth-child(4) {
                width: 50px;
                height: 18px;
                line-height: 18px;
                text-align: center;
                font-size: 8px;
                color: #fff;
                background-color: #0056b0;
            }
        }

        // 学会概况右侧
        .academy-synopsis-right {
            width: 56%;
            height: 100%;
            // border:1px solid red;

            >img {
                width: 100%;
                height: 100%;
            }
        }
    }

    /* 学院概况 end */

    /*科研平台 start*/
    .academy-scientific {
        width: 70%;
        margin: 0 auto;
        margin-top: 40px;
        // border: 1px solid red;

        // 科研平台标题
        .academy-scientific-title {
            text-align: center;

            >div:nth-child(1) {
                font-size: 7px;
                color: #DDDDDD
            }

            >div:nth-child(2) {
                display: flex;
                align-items: center;
                justify-content: center;

                >h6 {
                    font-size: 10px;
                    margin: 0 5px;
                }
            }

        }

        /*科研平台内容 start*/
        .journalism-body {
            width: 100%;
            height: 246px;
            margin-top: 10px;
            margin-bottom: 100px;

            .journalism-body1 {
                display: flex;

                // 科研平台左侧
                .journalism-body1-left {
                    overflow: hidden;
                    width: 40%;
                    cursor: pointer;

                    // 图片
                    .journalism-left-img {
                        width: 100%;
                        height: 180px;

                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }

                    // 标题
                    .journalism-left-title {
                        padding: 10px 0 8px 0;
                        font-size: 10px;
                        border-bottom: 1px solid #e1e1e1;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }

                    // 描述
                    .journalism-left-describe {
                        margin-top: 3px;
                        font-size: 8px;
                        color: #999999;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2; //行数
                        -webkit-box-orient: vertical;

                        >span {
                            cursor: pointer;
                            color: #337136;
                        }
                    }
                }

                // 科研平台右侧
                .journalism-body1-right {
                    margin-left: auto;
                    width: 57%;

                    // border: 1px solid red;
                    .tapActive {
                        color: #337136;
                    }

                    .describe-right-item:hover {
                        color: #337136;
                    }

                    // 列表
                    .describe-right-item {
                        display: flex;
                        margin-bottom: 10px;
                        cursor: pointer;

                        // 日期时间
                        .journalism-right-time {
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-end;
                            padding: 12px 10px;
                            border-right: 1px solid #e1e1e1;

                            span:nth-child(1) {
                                display: block;
                                font-size: 10px;
                                color: #337136;
                            }

                            span:nth-child(2) {
                                font-size: 10px;
                                color: #337136;
                                margin-left: auto;
                            }
                        }

                        // 描述
                        .journalism-right-describe {
                            padding: 0 10px;

                            .journalism-right-title {
                                width: 300px;
                                margin-bottom: 5px;
                                font-size: 10px;
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            }

                            .journalism-right-text {
                                font-size: 10px;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                display: -webkit-box;
                                -webkit-line-clamp: 2; //行数
                                -webkit-box-orient: vertical;
                            }
                        }
                    }
                }
            }
        }

        /*科研平台内容 end*/
    }

    /*科研平台 end*/


    /*教授风采 start*/
    .academy-demeanour {
        width: 70%;
        margin: 0 auto;
        margin-top: 30px;

        .academy-demeanour-title {
            text-align: center;

            >div:nth-child(1) {
                font-size: 7px;
                color: #DDDDDD
            }

            >div:nth-child(2) {
                display: flex;
                align-items: center;
                justify-content: center;

                >h6 {
                    font-size: 10px;
                    margin: 0 5px;
                }
            }
        }

        .academy-demeanour-content {
            margin-top: 15px;

            >ul:nth-child(1) {
                display: flex;
                justify-content: space-around;
                list-style: none;
                padding: 0 60px;
                font-size: 12px;
                border-bottom: 1px solid #B3B3B3;

                .demeanourActive {
                    border-bottom: 2px solid #0056B0;
                    ;
                    margin-bottom: -1px;
                }

                li {
                    padding: 10px 0;
                    cursor: pointer;
                }
            }

            >ul:nth-child(2) {
                list-style: none;
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                // margin-top:30px;
                padding: 0 30px;
                box-sizing: border-box;

                li {
                    width: 13%;
                    height: 120px;
                    margin: 0 20px;
                    margin-top: 30px;
                    // border:1px solid red;

                    >div:nth-child(1) {
                        height: 77%;

                        >img {
                            width: 100%;
                            height: 100%;
                        }
                    }

                    >div:nth-child(2) {
                        height: 23%;
                        font-size: 10px;

                        >span:nth-child(1) {
                            display: block !important;
                            text-align: center;
                            margin-top: 2px;
                            font-size: 10px;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }

                        >span:nth-child(2) {
                            display: block !important;
                            text-align: center;
                            font-size: 9px;
                            color: #999999;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                    }
                }
            }
        }
    }

    /*教授风采 end*/

    /*学院动态 start*/
    .academy-dynamic {
        width: 100%;
        // height:330px;
        background-color: #F8F9FD;
        margin-top: 30px;
        margin-bottom: 20px;

        .academy-dynamic-wrap {
            width: 70%;
            height: 100%;
            margin: 0 auto;
            padding: 30px 0;

            // 学院动态标题
            .academy-dynamic-title {
                text-align: center;
                margin-bottom: 20px;

                >div:nth-child(1) {
                    font-size: 7px;
                    color: #DDDDDD
                }

                >div:nth-child(2) {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    >h6 {
                        font-size: 10px;
                        margin: 0 5px;
                    }
                }
            }

            //学院动态内容
            .academy-dynamic-content {
                cursor: pointer;
                width: 100%;
                height: 100%;
                // border:1px solid red;

                .academy-dynamic-top {
                    display: flex;
                    height: 60%;
                    background-color: #fff;

                    .academy-dynamic-left {
                        width: 50%;
                        height: 100%;
                        padding: 10px;
                        box-sizing: border-box;
                        // border:1px solid red;

                        >div:nth-child(2) {
                            font-size: 12px;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }

                        >div:nth-child(3) {
                            margin-top: 5px;
                            line-height: 16px;
                            font-size: 8px;
                            color: #999999;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 4; //行数
                            -webkit-box-orient: vertical;
                        }

                        >div:nth-child(4) {
                            margin: 20px 0;
                            font-size: 8px;
                            color: #999999
                        }
                    }

                    .academy-dynamic-right {
                        height: 200px;
                        width: 50%;
                        // height:100%;
                        // border:1px solid red;
                    }
                }

                .academy-dynamic-bottom {
                    width: 100%;
                    height: 40%;

                    >ul {
                        list-style: none;
                        display: flex;
                        justify-content: space-between;
                        margin-top: 10px;

                        li {
                            width: 32%;
                            height: 60px;
                            padding: 5px;
                            box-sizing: border-box;
                            // border:1px solid red;
                            background-color: #fff;

                            >div:nth-child(1) {
                                font-size: 9px;
                                color: #0056B0;
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            }

                            >div:nth-child(2) {
                                margin-top: 5px;
                                line-height: 15px;
                                font-size: 8px;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                display: -webkit-box;
                                -webkit-line-clamp: 2; //行数
                                -webkit-box-orient: vertical;
                            }
                        }
                    }
                }
            }
        }
    }

    /*学院动态 end*/
}


//当屏幕最小宽度为992px
@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .academy {

        // 轮播图 start
        .academy-swiper {}

        // 轮播图 end

        /* 学院概况背景 start*/
        .academy-synopsis-bg {
            height: 70px;
            background-color: #0056b0;

            .academy-synopsis-wrap {
                width: 70%;
                margin: 0 auto;

                >span {
                    margin-right: 5px;
                    font-size: 13px;
                    color: #fff;
                }
            }
        }

        /* 学院概况 end*/

        /* 学院概况 start */
        .academy-synopsis {
            display: flex;
            height: 180px;
            margin-top: -20px;

            .academy-synopsis-wrap {
                display: flex;
                justify-content: space-around;
                width: 70%;
                margin: 0 auto;
            }

            // 学会概况左侧
            .academy-synopsis-left {
                width: 40%;
                height: 100%;
                padding: 10px;
                box-sizing: border-box;
                background-color: #fff;
                box-shadow: -5px 5px 10px -4px #e5e5e5, 5px 5px 10px -4px #e5e5e5;
                // border:1px solid red;

                >div:nth-child(1) {
                    font-size: 7px;
                    margin-left: 13px;
                    color: #ececec
                }

                >div:nth-child(2) {
                    display: flex;
                    align-items: center;


                    >h6 {
                        font-size: 12px;
                        margin: 0 5px;
                    }
                }

                >div:nth-child(3) {
                    margin-top: 10px;
                    margin-bottom: 20px;
                    font-size: 8px;
                    color: #999999;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 7; //行数
                    -webkit-box-orient: vertical;
                }

                >div:nth-child(4) {
                    width: 50px;
                    height: 18px;
                    line-height: 18px;
                    text-align: center;
                    font-size: 8px;
                    color: #fff;
                    background-color: #0056b0;
                }
            }

            // 学会概况右侧
            .academy-synopsis-right {
                width: 56%;
                height: 100%;
                // border:1px solid red;

                >img {
                    width: 100%;
                    height: 100%;
                }
            }
        }

        /* 学院概况 end */

        /*科研平台 start*/
        .academy-scientific {
            width: 70%;
            margin: 0 auto;
            margin-top: 40px;
            // border: 1px solid red;

            // 科研平台标题
            .academy-scientific-title {
                text-align: center;

                >div:nth-child(1) {
                    font-size: 7px;
                    color: #DDDDDD
                }

                >div:nth-child(2) {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    >h6 {
                        font-size: 10px;
                        margin: 0 5px;
                    }
                }

            }

            /*科研平台内容 start*/
            .journalism-body {
                width: 100%;
                height: 206px;
                margin-top: 20px;
                // border:1px solid red;

                .journalism-body1 {
                    display: flex;

                    // 科研平台左侧
                    .journalism-body1-left {
                        overflow: hidden;
                        width: 40%;

                        // 图片
                        .journalism-left-img {
                            width: 100%;
                            height: 120px;

                            img {
                                width: 100%;
                                height: 100%;
                            }
                        }

                        // 标题
                        .journalism-left-title {
                            padding: 10px 0 8px 0;
                            font-size: 10px;
                            border-bottom: 1px solid #e1e1e1;
                        }

                        // 描述
                        .journalism-left-describe {
                            margin-top: 3px;
                            font-size: 8px;
                            color: #999999;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 2; //行数
                            -webkit-box-orient: vertical;

                            >span {
                                cursor: pointer;
                                color: #337136;
                            }
                        }
                    }

                    // 科研平台右侧
                    .journalism-body1-right {
                        margin-left: auto;
                        width: 57%;

                        // border: 1px solid red;
                        .tapActive {
                            color: #337136;
                        }

                        .describe-right-item:hover {
                            color: #337136;
                        }

                        // 列表
                        .describe-right-item {
                            display: flex;
                            margin-bottom: 10px;

                            // 日期时间
                            .journalism-right-time {
                                display: flex;
                                flex-direction: column;
                                justify-content: flex-end;
                                padding: 12px 10px;
                                border-right: 1px solid #e1e1e1;

                                span:nth-child(1) {
                                    display: block;
                                    font-size: 8px;
                                    color: #337136;
                                }

                                span:nth-child(2) {
                                    font-size: 8px;
                                    color: #337136;
                                    margin-left: auto;
                                }
                            }

                            // 描述
                            .journalism-right-describe {
                                padding: 0 10px;

                                .journalism-right-title {
                                    width: 200px;
                                    margin-bottom: 5px;
                                    font-size: 10px;
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                }

                                .journalism-right-text {
                                    font-size: 8px;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    display: -webkit-box;
                                    -webkit-line-clamp: 2; //行数
                                    -webkit-box-orient: vertical;
                                }
                            }
                        }
                    }
                }
            }

            /*科研平台内容 end*/
        }

        /*科研平台 end*/


        /*教授风采 start*/
        .academy-demeanour {
            width: 70%;
            margin: 0 auto;
            margin-top: 20px;
            // border:1px solid red;

            .academy-demeanour-title {
                text-align: center;

                >div:nth-child(1) {
                    font-size: 7px;
                    color: #DDDDDD
                }

                >div:nth-child(2) {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    >h6 {
                        font-size: 10px;
                        margin: 0 5px;
                    }
                }
            }

            .academy-demeanour-content {
                margin-top: 15px;

                >ul:nth-child(1) {
                    display: flex;
                    justify-content: space-around;
                    list-style: none;
                    padding: 0 60px;
                    font-size: 10px;
                    border-bottom: 1px solid #B3B3B3;

                    .demeanourActive {
                        border-bottom: 1px solid #0056B0;
                        ;
                        margin-bottom: -1px;
                    }

                    li {
                        padding: 5px 0;
                        cursor: pointer;
                    }
                }

                >ul:nth-child(2) {
                    list-style: none;
                    display: flex;
                    justify-content: space-between;
                    flex-wrap: wrap;
                    // margin-top:30px;
                    padding: 0 30px;
                    box-sizing: border-box;

                    li {
                        width: 18%;
                        height: 140px;
                        margin: 0 10px;
                        margin-top: 30px;
                        // border:1px solid red;

                        >div:nth-child(1) {
                            height: 77%;

                            >img {
                                width: 100%;
                                height: 100%;
                            }
                        }

                        >div:nth-child(2) {
                            height: 23%;
                            font-size: 10px;

                            >span:nth-child(1) {
                                display: block !important;
                                text-align: center;
                                margin-top: 2px;
                                font-size: 10px;
                            }

                            >span:nth-child(2) {
                                display: block;
                                text-align: center;
                                font-size: 9px;
                                color: #999999;
                            }
                        }
                    }
                }
            }
        }

        /*教授风采 end*/

        /*学院动态 start*/
        .academy-dynamic {
            width: 100%;
            // height:300px;
            background-color: #F8F9FD;
            margin-top: 30px;
            margin-bottom: 20px;

            .academy-dynamic-wrap {
                width: 70%;
                height: 100%;
                margin: 0 auto;
                padding: 30px 0;
                // border:1px solid red;

                // 学院动态标题
                .academy-dynamic-title {
                    text-align: center;
                    margin-bottom: 20px;

                    >div:nth-child(1) {
                        font-size: 7px;
                        color: #DDDDDD
                    }

                    >div:nth-child(2) {
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        >h6 {
                            font-size: 10px;
                            margin: 0 5px;
                        }
                    }
                }

                //学院动态内容
                .academy-dynamic-content {
                    width: 100%;
                    height: 100%;
                    // border:1px solid red;

                    .academy-dynamic-top {
                        display: flex;
                        height: 50%;
                        background-color: #fff;

                        .academy-dynamic-left {
                            width: 50%;
                            height: 100%;
                            padding: 10px;
                            box-sizing: border-box;
                            // border:1px solid red;

                            >div:nth-child(2) {
                                font-size: 12px;
                            }

                            >div:nth-child(3) {
                                line-height: 16px;
                                font-size: 8px;
                                color: #999999
                            }

                            >div:nth-child(4) {
                                margin: 20px 0;
                                font-size: 8px;
                                color: #999999
                            }
                        }

                        .academy-dynamic-right {
                            width: 50%;
                            height: 100%;
                            height: 200px;
                            // border:1px solid red;
                        }
                    }

                    .academy-dynamic-bottom {
                        width: 100%;
                        height: 40%;
                        // margin-bottom: 40px;;

                        >ul {
                            list-style: none;
                            display: flex;
                            justify-content: space-between;
                            margin-top: 10px;

                            li {
                                width: 32%;
                                height: 60px;
                                padding: 5px;
                                box-sizing: border-box;
                                // border:1px solid red;
                                background-color: #fff;

                                >div:nth-child(1) {
                                    font-size: 9px;
                                    color: #0056B0;
                                }

                                >div:nth-child(2) {
                                    line-height: 15px;
                                    font-size: 8px;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    display: -webkit-box;
                                    -webkit-line-clamp: 2; //行数
                                    -webkit-box-orient: vertical;
                                }
                            }
                        }
                    }
                }
            }
        }

        /*学院动态 end*/
    }
}

//当屏幕宽度 最小为768px 最大为991px时
@media screen and (min-width: 768px) and (max-width: 1200px) {
    .academy {

        // 轮播图 start
        .academy-swiper {}

        // 轮播图 end

        /* 学院概况背景 start*/
        .academy-synopsis-bg {
            height: 50px;
            line-height: 20px;
            background-color: #0056b0;

            .academy-synopsis-wrap {
                width: 70%;
                margin: 0 auto;

                >span {
                    margin-right: 5px;
                    font-size: 10px;
                    color: #fff;
                }

                >img {
                    width: 8px;
                    height: 8px;
                }
            }
        }

        /* 学院概况 end*/

        /* 学院概况 start */
        .academy-synopsis {
            display: flex;
            height: 150px;
            margin-top: -20px;

            .academy-synopsis-wrap {
                display: flex;
                justify-content: space-around;
                width: 70%;
                margin: 0 auto;
            }

            // 学会概况左侧
            .academy-synopsis-left {
                width: 40%;
                height: 100%;
                padding: 10px;
                box-sizing: border-box;
                background-color: #fff;
                box-shadow: -5px 5px 10px -4px #e5e5e5, 5px 5px 10px -4px #e5e5e5;


                >div:nth-child(1) {
                    font-size: 7px;
                    margin-left: 13px;
                    color: #ececec
                }

                >div:nth-child(2) {
                    display: flex;
                    align-items: center;


                    >h6 {
                        font-size: 12px;
                        margin: 0 5px;
                    }
                }

                >div:nth-child(3) {
                    margin-top: 10px;
                    margin-bottom: 10px;
                    font-size: 8px;
                    color: #999999;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 6; //行数
                    -webkit-box-orient: vertical;
                }

                >div:nth-child(4) {
                    width: 50px;
                    height: 18px;
                    line-height: 18px;
                    text-align: center;
                    font-size: 8px;
                    color: #fff;
                    background-color: #0056b0;
                }
            }

            // 学会概况右侧
            .academy-synopsis-right {
                width: 56%;
                height: 100%;
                // border:1px solid red;

                >img {
                    width: 100%;
                    height: 100%;
                }
            }
        }

        /* 学院概况 end */

        /*科研平台 start*/
        .academy-scientific {
            width: 70%;
            margin: 0 auto;
            margin-top: 20px;
            // border: 1px solid red;

            // 科研平台标题
            .academy-scientific-title {
                text-align: center;

                >div:nth-child(1) {
                    font-size: 7px;
                    color: #DDDDDD
                }

                >div:nth-child(2) {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    >h6 {
                        font-size: 10px;
                        margin: 0 5px;
                    }
                }

            }

            /*科研平台内容 start*/
            .journalism-body {
                width: 100%;
                height: 146px;
                margin-top: 10px;

                .journalism-body1 {
                    display: flex;

                    // 科研平台左侧
                    .journalism-body1-left {
                        overflow: hidden;
                        width: 40%;

                        // 图片
                        .journalism-left-img {
                            width: 100%;
                            height: 100px;

                            img {
                                width: 100%;
                                height: 100%;
                            }
                        }

                        // 标题
                        .journalism-left-title {
                            padding: 10px 0 8px 0;
                            font-size: 10px;
                            border-bottom: 1px solid #e1e1e1;
                        }

                        // 描述
                        .journalism-left-describe {
                            margin-top: 3px;
                            font-size: 8px;
                            color: #999999;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 2; //行数
                            -webkit-box-orient: vertical;

                            >span {
                                cursor: pointer;
                                color: #337136;
                            }
                        }
                    }

                    // 科研平台右侧
                    .journalism-body1-right {
                        margin-left: auto;
                        width: 57%;

                        .tapActive {
                            color: #337136;
                        }

                        .describe-right-item:hover {
                            color: #337136;
                        }

                        // 列表
                        .describe-right-item {
                            display: flex;
                            margin-bottom: 5px;

                            // 日期时间
                            .journalism-right-time {
                                display: flex;
                                flex-direction: column;
                                justify-content: flex-end;
                                padding: 8px 10px;
                                border-right: 1px solid #e1e1e1;

                                span:nth-child(1) {
                                    display: block;
                                    font-size: 8px;
                                    color: #337136;
                                }

                                span:nth-child(2) {
                                    font-size: 8px;
                                    color: #337136;
                                    margin-left: auto;
                                }
                            }

                            // 描述
                            .journalism-right-describe {
                                padding: 0 10px;

                                .journalism-right-title {
                                    width: 150px;
                                    margin-bottom: 5px;
                                    font-size: 10px;
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                }

                                .journalism-right-text {
                                    font-size: 6px;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    display: -webkit-box;
                                    -webkit-line-clamp: 2; //行数
                                    -webkit-box-orient: vertical;
                                }
                            }
                        }
                    }
                }
            }

            /*科研平台内容 end*/
        }

        /*科研平台 end*/


        /*教授风采 start*/
        .academy-demeanour {
            width: 70%;
            margin: 0 auto;
            margin-top: 40px;
            // border:1px solid red;

            .academy-demeanour-title {
                text-align: center;

                >div:nth-child(1) {
                    font-size: 7px;
                    color: #DDDDDD
                }

                >div:nth-child(2) {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    >h6 {
                        font-size: 10px;
                        margin: 0 5px;
                    }
                }
            }

            .academy-demeanour-content {
                margin-top: 15px;
                // border:1px solid red;

                >ul:nth-child(1) {
                    display: flex;
                    justify-content: space-around;
                    list-style: none;
                    padding: 0 40px;
                    font-size: 12px;
                    border-bottom: 1px solid #B3B3B3;

                    .demeanourActive {
                        border-bottom: 2px solid #0056B0;
                        ;
                        margin-bottom: -1px;
                    }

                    li {
                        padding: 10px 0;
                        cursor: pointer;
                    }
                }

                >ul:nth-child(2) {
                    list-style: none;
                    display: flex;
                    justify-content: space-between;
                    flex-wrap: wrap;
                    padding: 0 10px;
                    box-sizing: border-box;

                    li {
                        width: 18%;
                        height: 90px;
                        margin: 0 10px;
                        margin-top: 10px;
                        // border:1px solid red;

                        >div:nth-child(1) {
                            height: 77%;

                            >img {
                                width: 100%;
                                height: 100%;
                            }
                        }

                        >div:nth-child(2) {
                            height: 23%;
                            font-size: 10px;

                            >span:nth-child(1) {
                                display: block !important;
                                text-align: center;
                                margin-top: 2px;
                                font-size: 8px;
                            }

                            >span:nth-child(2) {
                                display: block;
                                text-align: center;
                                font-size: 7px;
                                color: #999999;
                            }
                        }
                    }
                }
            }
        }

        /*教授风采 end*/

        /*学院动态 start*/
        .academy-dynamic {
            width: 100%;
            // height:230px;
            box-sizing: border-box;
            background-color: #F8F9FD;
            margin-top: 20px;

            .academy-dynamic-wrap {
                width: 70%;
                height: 100%;
                margin: 0 auto;
                padding: 10px 0;

                // 学院动态标题
                .academy-dynamic-title {
                    text-align: center;
                    margin-bottom: 10px;
                    // border:1px solid red;

                    >div:nth-child(1) {
                        font-size: 7px;
                        color: #DDDDDD
                    }

                    >div:nth-child(2) {
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        >h6 {
                            font-size: 10px;
                            margin: 0 5px;
                        }
                    }
                }

                //学院动态内容
                .academy-dynamic-content {
                    width: 100%;
                    // height:230px;
                    // border:1px solid red;

                    .academy-dynamic-top {
                        display: flex;
                        height: 60%;
                        background-color: #fff;

                        .academy-dynamic-left {
                            width: 50%;
                            height: 100%;
                            padding: 10px;
                            box-sizing: border-box;
                            // border:1px solid red;

                            >div:nth-child(2) {
                                font-size: 12px;
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            }

                            >div:nth-child(3) {
                                line-height: 16px;
                                font-size: 8px;
                                color: #999999;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                display: -webkit-box;
                                -webkit-line-clamp: 2; //行数
                                -webkit-box-orient: vertical;
                            }

                            >div:nth-child(4) {
                                // margin:20px 0;
                                font-size: 8px;
                                color: #999999
                            }
                        }

                        .academy-dynamic-right {
                            width: 50%;
                            height: 100%;
                            height: 150px;
                            // border:1px solid red;
                        }
                    }

                    .academy-dynamic-bottom {
                        width: 100%;
                        height: 30%;

                        >ul {
                            height: 100%;
                            list-style: none;
                            display: flex;
                            justify-content: space-between;
                            margin-top: 10px;

                            li {
                                width: 32%;
                                height: 60px;
                                padding: 5px;
                                box-sizing: border-box;
                                // border:1px solid red;
                                background-color: #fff;

                                >div:nth-child(1) {
                                    font-size: 9px;
                                    color: #0056B0;
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                }

                                >div:nth-child(2) {
                                    line-height: 15px;
                                    font-size: 8px;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    display: -webkit-box;
                                    -webkit-line-clamp: 2; //行数
                                    -webkit-box-orient: vertical;
                                }
                            }
                        }
                    }
                }
            }
        }

        /*学院动态 end*/
    }
}

//当屏幕最大宽度为767px
@media screen and (max-width: 767px) {}
</style>
