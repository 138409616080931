<template>
    <!-- 底部组件 -->
    <div class="footer">
        <div class="footer-warp">
            <!-- 头部链接跳转 -->
            <div class="footer-top">
                <div><a href="">友情链接:</a></div>
                <div class="item" v-for="(item, index) in linksDe" :key="index">
                    <a :href="item.url" target="_blank">{{ item.name }}</a>
                </div>
            </div>
            <div class="footer-top">
                <div><a href="">主管单位:</a></div>
                <div class="item" v-for="(item, index) in siteDe.competentunit" :key="index">
                    <a :href="item.url" target="_blank">{{ item.name }}</a>
                </div>
                <div class="item" v-for="(item, index) in siteDe.hostunit" :key="index">
                    <a :href="item.url" target="_blank">主办单位:{{ item.name }}</a>
                </div>
                <div class="item" style="color: #ffffff;">网站域名:{{ siteDe.url }}</div>
                <div class="item"><a style="color: #ffffff; text-decoration: none"
                        href="https://beian.miit.gov.cn/target=_blank">备案信息:{{ siteDe.recordnum }}</a></div>
            </div>
            <div class="footer-top">
                <div><a href="">协办单位:</a></div>
                <div class="item" v-for="(item, index) in siteDe.organizerunit" :key="index">
                    <a :href="item.url" target="_blank">{{ item.name }}</a>
                </div>
            </div>
            <!-- <div class="footer-add">
                <div>网站名称:</div>
                <div>
                    河南省食品科学技术学会官网
                    河南省食品产业科技赋能服务平台行，<a
                        style="color: #ffffff; text-decoration: none"
                        href="zscyw.com.cn"
                        >备案信息:豫ICP备2022027892号一</a
                    >
                </div>
            </div> -->
            <div class="footer-add">
                <div>学会地址:</div>
                <div>
                    郑州市农业路60-2号 服务电话:0371-60339765(秘书处)
                    13703952477(企业服务中心)
                </div>
            </div>
            <!-- 联系方式 -->
            <div class="footer-center">
                <!-- 热线、邮箱、地址 -->
                <div class="footer-center-way1" v-if="false">
                    <div>热线:{{ siteDe.faxnum }}</div>
                    <div>手机:{{ siteDe.phone }}</div>
                </div>
                <!-- 手机、传真 -->
                <div class="footer-center-way2">
                    <!-- <div>手机:{{ siteDe.phone }}</div> -->
                    <!-- <div>传真: 0371-XXXXXXXX</div> -->
                </div>
                <!-- 二维码 -->
                <!-- <div class="footer-center-code">
                    <div class="footer-code1">
                        <div>
                            <img :src="siteDe.ercode" alt="" />
                        </div>
                        <div>二维码</div>
                    </div>
                    <div class="footer-code1">
                        <div>
                            <img :src="siteDe.qrcode" alt="" />
                        </div>
                        <div>二维码</div>
                    </div>
                </div> -->
            </div>

            <!-- 备案信息 -->
            <div class="footer-put" v-if="false">
                备案信息: {{ siteDe.recordnum }}
            </div>
        </div>
    </div>
</template>

<script>
import { links, site } from "../network/data";
export default {
    name: "footer",
    data() {
        return {
            // 底部友情链接数据
            linksDe: [],
            // 站点数据
            siteDe: {},
        };
    },
    computed: {},
    watch: {},
    created() {
        // 获取底部友情链接数据
        this.linksRe();
        // 获取站点信息
        this.siteRe();
    },
    methods: {
        // 获取底部友情链接数据
        linksRe() {
            links().then((res) => {
                if (res.code == 1) {
                    // console.log(res.data);
                    this.linksDe = res.data;
                } else {
                    this.$message(res.msg);
                }
            });
        },
        // 获取站点信息
        siteRe() {
            site().then((res) => {
                if (res.code == 1) {
                    this.siteDe = res.data;
                } else {
                    this.message(res.msg);
                }
            });
        },
    },
    mounted() { },
    beforeCreate() { },
    beforeMount() { },
    beforeUpdate() { },
    updated() { },
    beforeDestroy() { },
    destroyed() { },
    activated() { },
    components: {},
};
</script>

<style lang="scss" scoped>
.clearfix::after {
    content: "";
    clear: both;
    display: block;
}

.footer {
    position: relative;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 130px;
    background-color: #004b99;
    overflow: hidden;

    .footer-warp {
        width: 70%;
        margin: 0 auto;
        overflow: hidden;

        // padding: 20px 0 20px 0;
        .footer-add {
            display: flex;

            // font-weight: 400;
            color: #fff;
            margin-top: 5px;

            >div:nth-child(1) {
                width: 47px;
                font-size: 9px;
                padding-right: 5px;
            }

            >div:nth-child(2) {
                width: 90%;
                font-size: 8px;
            }
        }

        // 头部链接跳转
        .footer-top:first-child {
            margin-top: 15px;
        }

        .footer-top {
            overflow: hidden;

            a {
                color: #ffffff;
                text-decoration: none;
            }

            >div:nth-child(1) {
                width: 42px;
                height: 20px;
                float: left;

                font-size: 9px;
            }

            .item {
                font-size: 8px;
                margin-left: 10px;
                float: left;
            }
        }

        // 联系方式
        .footer-center {
            position: absolute;
            right: 20px;
            bottom: 50px;
            display: flex;
            flex-direction: row;
            margin-top: 10px;
            color: #fff;

            .footer-center-way1 {
                margin-right: 90px;
                font-size: 8px;

                div {
                    margin-bottom: 5px;
                }
            }

            .footer-center-way2 {
                font-size: 8px;

                div {
                    margin-bottom: 5px;
                }
            }

            // 二维码
            .footer-center-code {
                display: flex;
                margin-left: auto;
                font-size: 8px;

                .footer-code1 {
                    width: 50px;
                    height: 50px;
                    margin-left: auto;

                    div:nth-child(2) {
                        text-align: center;
                    }

                    img {
                        width: 100%;
                        height: 10%;
                    }
                }

                .footer-code1:nth-child(1) {
                    margin-right: 30px;
                }
            }
        }

        .footer-put {
            text-align: center;
            margin-top: 0px;
            font-size: 8px;
            color: #fff;
        }
    }
}

//当屏幕最小宽度为992px
@media screen and (min-width: 1200px) {}

//当屏幕宽度 最小为768px 最大为991px时
@media screen and (min-width: 768px) and (max-width: 1200px) {
    .clearfix::after {
        content: "";
        clear: both;
        display: block;
    }

    .footer {
        // margin-top: 200px;
        width: 100%;
        height: 130px;
        background-color: #004b99;

        .footer-warp {
            width: 80%;
            margin: 0 auto;
            padding: 10px 0 10px 0;
            box-sizing: border-box;

            .footer-add {
                display: flex;

                // font-weight: 400;
                color: #fff;
                margin-top: 5px;

                >div:nth-child(1) {
                    width: 35px;
                    font-size: 6px;
                    padding-right: 5px;
                }

                >div:nth-child(2) {
                    width: 90%;
                    font-size: 6px;
                }
            }

            // 头部链接跳转
            .footer-top {
                overflow: hidden;

                a {
                    color: #ffffff;
                    text-decoration: none;
                }

                >div:nth-child(1) {
                    width: 35px;
                    height: 20px;
                    float: left;

                    font-size: 6px;
                }

                .item {
                    font-size: 6px;
                    margin-left: 5px;
                    float: left;
                }
            }

            // 联系方式
            .footer-center {
                position: absolute;
                right: 20px;
                bottom: 20px;
                display: flex;
                flex-direction: row;
                margin-top: 5px;
                color: #fff;
                // border: 1px solid red;

                .footer-center-way1 {
                    margin-right: 90px;
                    font-size: 6px;

                    div {
                        margin-bottom: 5px;
                    }
                }

                .footer-center-way2 {
                    font-size: 6px;

                    div {
                        margin-bottom: 5px;
                    }
                }

                // 二维码
                .footer-center-code {
                    display: flex;
                    margin-left: auto;
                    font-size: 7px;
                    // border: 1px solid red;

                    .footer-code1 {
                        width: 30px;
                        height: 30px;
                        margin-left: auto;

                        div:nth-child(2) {
                            text-align: center;
                        }

                        img {
                            width: 100%;
                            height: 10%;
                        }
                    }

                    .footer-code1:nth-child(1) {
                        margin-right: 30px;
                    }
                }
            }

            .footer-put {
                text-align: center;
                margin-top: 0px;
                font-size: 7px;
                color: #fff;
            }
        }
    }
}

//当屏幕最大宽度为767px
@media screen and (max-width: 767px) {}
</style>
