<template>
    <!-- 学院概况 -->
    <div class="academy-synopsis">
        <AHeader :currentIndex="1"></AHeader>
        <MeSwiper></MeSwiper>

        <!-- 内容区域 -->
        <div class="academy-synopsis-content">
            <div>您当前的位置：首页>学院概况</div>
            <div>
                <h6>学院</h6>
                <span>概况</span>
            </div>
            <div v-html="schoolAboutDe.content"></div>
        </div>

        <Footer></Footer>
    </div>
</template>

<script>
import AHeader from "@/components/Aheader.vue";
import Footer from "@/components/Footer.vue";
import MeSwiper from "../../../components/College/swiper.vue";
import { schoolAbout } from "../../../network/data";
export default {
    // 组件名称
    name: "synopsis",
    // 组件参数 接收来自父组件的数据
    props: {},
    // 组件状态值
    data() {
        return {
            // 院校导航栏id
            menu_id: "",
            // 院校概况数据
            schoolAboutDe: {},
        };
    },
    // 计算属性
    computed: {},
    // 侦听器
    watch: {},
    // 组件方法
    created() {
        if (this.$route.query != "") {
            this.menu_id = this.$route.query.menu_id;

            // 获取院校概况数据
            this.schoolAboutRe();
        }
    },
    methods: {
        // 获取院校概况数据
        schoolAboutRe() {
            let data = {
                id: sessionStorage.getItem("school_id"),
                menuid: this.menu_id,
            };
            schoolAbout(data).then((res) => {
                if (res.code == 1) {
                    // console.log(res.data);
                    this.schoolAboutDe = res.data.info;
                } else {
                    this.message(res.msg);
                }
            });
        },
    },
    mounted() {},
    // 局部注册的组件
    components: { AHeader, Footer, MeSwiper },
};
</script>

<style lang="scss">
.academy-synopsis {
    .academy-synopsis-img {
        width: 100%;
        height: 150px;

        > img {
            width: 100%;
            height: 100%;
        }
    }

    .academy-synopsis-content {
        width: 70%;
        margin: 0 auto;

        > img {
            width: 100%;
        }

        > div:nth-child(1) {
            margin: 10px 0 10px 0;
            font-size: 8px;
            color: #999999;
        }

        > div:nth-child(2) {
            display: flex;
            padding: 5px 0;
            border-bottom: 1px solid #0056b0;

            > h6 {
                font-size: 12px;
                color: #0056b0;
            }

            > span {
                margin-top: 1px;
                margin-left: 3px;
                font-size: 8px;
            }
        }

        > div:nth-child(3) {
            // height: 300px;
            font-size: 10px;
            margin: 20px 0 60px 0;

            img {
                width: 100%;
            }
        }
    }
}
</style>
