<template>
    <div class="obtain">
        <pdf
            ref="pdf"
            :src="url"
            :page="pageNum"
            :rotate="pageRotate"
            @progress="loadedRatio = $event"
            @page-loaded="pageLoaded($event)"
            @num-pages="pageTotalNum = $event"
            @error="pdfError($event)"
            @link-clicked="page = $event"
        >
        </pdf>
        <div class="tools">
            <button
                :theme="'default'"
                type="submit"
                :title="'基础按钮'"
                @click.stop="prePage"
                class="mr10"
            >
                上一页
            </button>
            <button
                :theme="'default'"
                type="submit"
                :title="'基础按钮'"
                @click.stop="nextPage"
                class="mr10"
            >
                下一页
            </button>
            <div class="page">{{ pageNum }}/{{ pageTotalNum }}</div>
            <button
                :theme="'default'"
                type="submit"
                :title="'基础按钮'"
                @click.stop="clock"
                class="mr10"
            >
                顺时针
            </button>
            <button
                :theme="'default'"
                type="submit"
                :title="'基础按钮'"
                @click.stop="counterClock"
                class="mr10"
            >
                逆时针
            </button>
            <button @click.stop="download" class="mr10">下载</button>
        </div>
    </div>
</template>

<script>
import pdf from "vue-pdf";
import { jobdetail } from "../../../network/data";
import download from "../../../common/download.js";
export default {
    name: "Home",
    components: {
        pdf,
    },
    data() {
        return {
            // 就业详情页id
            id: "",
            url: "",
            pageNum: 1,
            pageTotalNum: 1,
            pageRotate: 0,
            // 加载进度
            loadedRatio: 0,
            curPageNum: 0,
            xieceshi: 0,
        };
    },
    mounted: function () {},
    created() {
        if (JSON.stringify(this.$route.query) != "{}") {
            this.id = this.$route.query.id;
        }
        this.jobdetailRe();
    },
    methods: {
        // 就业详情数据
        async jobdetailRe() {
            let data = {
                id: this.id,
            };
            await jobdetail(data).then((res) => {
                if (res.code == 1) {
                    let str = res.data.info.file;
                    str = str.replace(/admin./, "");
                    this.url = str;
                    // this.url ="http://shipin.dd371.com/uploads/20221230/63900779635695ff2499fe3554108bb7.pdf";
                } else {
                    this.message(res.msg);
                }
            });
        },
        download() {
            download(this.url);
        },

        // 上一页函数，
        prePage() {
            var page = this.pageNum;
            page = page > 1 ? page - 1 : this.pageTotalNum;
            this.pageNum = page;
        },
        // 下一页函数
        nextPage() {
            var page = this.pageNum;
            page = page < this.pageTotalNum ? page + 1 : 1;
            this.pageNum = page;
        },
        // 页面顺时针翻转90度。
        clock() {
            this.pageRotate += 90;
        },
        // 页面逆时针翻转90度。
        counterClock() {
            this.pageRotate -= 90;
        },
        // 页面加载回调函数，其中e为当前页数
        pageLoaded(e) {
            this.curPageNum = e;
        },
        // 其他的一些回调函数。
        pdfError(error) {
            console.error(error);
        },
    },
};
</script>

<style lang="scss" scoped>
.obtain {
    width: 100%;

    .tools {
        display: flex;
        justify-content: center;
        margin-bottom: 10px;

        .page {
            margin-left: 10px;
            font-size: 10px;
        }

        button {
            margin-left: 10px;
        }
    }
}
</style>
