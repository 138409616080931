<template>
    <!-- 地图组件 -->
    <div id="container"></div>
</template>

<script>
import AMapLoader from "@amap/amap-jsapi-loader";
window._AMapSecurityConfig = {
    securityJsCode: "dac4d755e5341aa8eea89c6f6533ab3c",
};
export default {
    data() {
        return {
            map: null,
        };
    },
    props: {
        longitude: {
            type: String,
            default: "113.771989",
        },
        latitude: {
            type: String,
            default: "34.768571",
        },
    },
    methods: {
        initMap() {
            AMapLoader.load({
                key: "21b7139d62820d21f2db740051f4c68c", // 申请好的Web端开发者Key，首次调用 load 时必填
                version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
                plugins: [""], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
            })
                .then((AMap) => {
                    this.map = new AMap.Map("container", {
                        //设置地图容器id
                        viewMode: "3D", //是否为3D地图模式
                        zoom: 10, //初始化地图级别
                        center: [this.longitude, this.latitude], //初始化地图中心点位置
                    });
                    // 点标记start
                    let marker = new AMap.Marker({
                        position: new AMap.LngLat(
                            this.longitude,
                            this.latitude
                        ), // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
                        title: "郑州",
                    });
                    this.map.add(marker);
                    // 点标记end
                })
                .catch((e) => {
                    console.log(e);
                });
        },
    },
    mounted() {
        //DOM初始化完成进行地图初始化
        this.initMap();
    },
};
</script>

<style lang="scss">
#container {
    padding: 0px;
    margin: 0px;
    width: 100%;
    height: 100%;
}
</style>
