<template>
    <!-- 登录页 -->
    <div class="login">
        <Header></Header>
        <div class="login-cont">
            <div class="login-cont-box">
                <div>会员登录</div>
                <div class="login-name">
                    <label for=""><span>*</span>用户名:</label>
                    <input
                        type="text"
                        placeholder="请输入用户名"
                        v-model="form.name"
                    />
                </div>
                <div class="login-pass">
                    <label for=""><span>*</span>密码:</label>
                    <input
                        type="text"
                        placeholder="请输入密码"
                        v-model="form.pass"
                    />
                </div>
                <div class="login-code">
                    <label for=""><span>*</span>验证码:</label>
                    <input
                        type="text"
                        placeholder="请输入验证码"
                        v-model="form.code"
                    />
                    <div>
                        <Code
                            ref="ref_validateCode"
                            @change="changeCode"
                        ></Code>
                    </div>
                </div>
                <div class="login-btn" @click="loginRe">登录</div>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import Header from "@/components/Header.vue"; //头部
import Footer from "@/components/Footer.vue"; //尾部
import Code from "@/components/Code.vue"; //验证码
import { login } from "../../network/data"; //
export default {
    // 组件名称
    name: "login",
    // 组件参数 接收来自父组件的数据
    props: {},
    // 组件状态值
    data() {
        return {
            form: {
                name: "",
                pass: "",
                code: "",
            },
            // 验证码
            checkCode: "",
        };
    },
    // 计算属性
    computed: {},
    // 侦听器
    watch: {},
    // 组件方法
    created() {},
    methods: {
        changeCode(value) {
            this.checkCode = value;
            // this.$refs["ref_validateCode"].draw();
            console.log(value, "验证码初始化");
        },
        // 请求登录
        loginRe() {
            if (this.form.name == "") {
                this.$message.warning({
                    message: "用户名为空",
                    duration: 1000,
                    customClass: "myBox",
                });
            } else if (this.form.pass == "") {
                this.$message.warning({
                    message: "密码为空",
                    duration: 1000,
                    customClass: "myBox",
                });
            } else if (this.checkCode != this.form.code) {
                this.$message.warning({
                    message: "验证码不正确",
                    duration: 1000,
                    customClass: "myBox",
                });
            } else {
                let data = {
                    account: this.form.name,
                    password: this.form.pass,
                };
                login(data).then((res) => {
                    if (res.code == 1) {
                        sessionStorage.setItem("token", res.data.token);
                        // 产业需求企业ID
                        sessionStorage.setItem("companyId", res.data.member.id);
                        // 判断是否显示企业登录
                        sessionStorage.setItem("companyType", res.data.type);
                        // console.log(res.data);
                        this.$message.warning({
                            message: res.msg,
                            duration: 1000,
                            customClass: "myBox",
                        });
                        if (res.data.type == 1) {
                            sessionStorage.setItem(
                                "specialist_id",
                                res.data.member.id
                            );
                            this.$router.push({
                                path: "/science/minkaveDetails",
                                query: {
                                    index: 0,
                                },
                            });
                        } else if (res.data.type == 2) {
                            sessionStorage.setItem("id", res.data.member.id);
                            this.$router.push({
                                path: "/mIndex",
                            });
                            window.scrollTo(0, 0);
                        }
                    } else {
                        this.$message.warning({
                            message: res.msg,
                            duration: 1000,
                            customClass: "myBox",
                        });
                    }
                });
            }
        },
    },
    mounted() {},
    // 局部注册的组件
    components: {
        Header,
        Footer,
        Code,
    },
};
</script>

<style scoped lang="scss">
.login {
    // border: 1px solid red;
    .login-cont {
        display: flex;
        align-items: center;
        height: 248px;
        width: 100%;
        // background-color: #fff;
        border: 1px solid #e6e6e6;
        background-color: #e6e6e6;
        // border: 1px solid red;

        .login-cont-box {
            width: 320px;
            height: 200px;
            margin: 0 auto;
            padding: 10px 20px;
            margin: 35px auto 10px auto;
            box-sizing: border-box;
            background-color: #fff;
            border: 1px solid #f6f6f6;

            > div:nth-child(1) {
                font-size: 12px;
                padding: 7px 0;
                margin-bottom: 15px;
                border-bottom: 0.5px solid #e6e6e6;
            }

            .login-name,
            .login-pass {
                display: flex;
                width: 80%;
                margin-top: 10px;
                margin: 0 auto;
                margin-bottom: 10px;
                font-size: 10px;

                > label {
                    display: block;
                    width: 30%;
                    text-align: end;
                    box-sizing: border-box;
                    padding-right: 10px;
                    // border: 1px solid red;
                }

                > input {
                    width: 70%;
                    height: 15px;
                }
            }

            .login-code {
                display: flex;
                width: 80%;
                margin-top: 10px;
                margin: 0 auto;
                margin-bottom: 10px;
                font-size: 10px;

                > label {
                    display: block;
                    width: 30%;
                    text-align: end;
                    box-sizing: border-box;
                    padding-right: 10px;
                    // border: 1px solid red;
                }

                > input {
                    width: 34%;
                    height: 15px;
                    margin-right: 10px;
                }
            }

            .login-btn {
                cursor: pointer;
                text-align: center;
                width: 45px;
                padding: 2px;
                font-size: 10px;
                margin-left: 34%;
                box-sizing: border-box;
                border: 1px solid #e6e6e6;
            }
        }
    }
}

//当屏幕最小宽度为992px
@media screen and (min-width: 1200px) {
}

//当屏幕宽度 最小为768px 最大为991px时
@media screen and (min-width: 768px) and (max-width: 1200px) {
    .login {
        .login-cont {
            display: flex;
            align-items: center;
            height: 248px;
            width: 70%;
            margin: 35px auto 42vh auto;
            border: 1px solid #e6e6e6;

            .login-cont-box {
                width: 320px;
                height: 200px;
                margin: 0 auto;
                padding: 10px 20px;
                box-sizing: border-box;
                border: 1px solid #f6f6f6;

                > div:nth-child(1) {
                    font-size: 12px;
                    padding: 7px 0;
                    margin-bottom: 15px;
                    border-bottom: 0.5px solid #e6e6e6;
                }

                .login-name,
                .login-pass {
                    display: flex;
                    width: 80%;
                    margin-top: 10px;
                    margin: 0 auto;
                    margin-bottom: 10px;
                    font-size: 10px;

                    > label {
                        display: block;
                        width: 30%;
                        text-align: end;
                        box-sizing: border-box;
                        padding-right: 10px;
                        // border: 1px solid red;
                    }

                    > input {
                        width: 70%;
                        height: 15px;
                    }
                }

                .login-code {
                    display: flex;
                    width: 80%;
                    margin-top: 10px;
                    margin: 0 auto;
                    margin-bottom: 10px;
                    font-size: 10px;

                    > label {
                        display: block;
                        width: 30%;
                        text-align: end;
                        box-sizing: border-box;
                        padding-right: 10px;
                        // border: 1px solid red;
                    }

                    > input {
                        width: 34%;
                        height: 15px;
                        margin-right: 10px;
                    }
                }

                .login-btn {
                    cursor: pointer;
                    text-align: center;
                    width: 45px;
                    padding: 2px;
                    font-size: 10px;
                    margin-left: 34%;
                    box-sizing: border-box;
                    // border: 1px solid red;
                }
            }
        }
    }
}

//当屏幕最大宽度为767px
@media screen and (max-width: 767px) {
}
</style>
