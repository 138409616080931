<template>
    <div class="member-left">
        <!-- 企业会员内容区域 -->
        <div class="introduces-cont-box">
            <ul>
                <template v-if="JSON.stringify(companylistDe) != ''">
                    <li
                        :key="index"
                        @click="mNewsDetail()"
                        v-for="(item, index) in companylistDe.data"
                    >
                        <div class="introduces-item-left">
                            <div @click="member(item)">查看详情</div>
                            <img :src="item.logo" alt="" />
                        </div>
                        <div class="introduces-item-right">
                            <div>{{ item.name }}</div>
                            <div>{{ item.content }}</div>
                            <div>{{ "主营产品：" + item.zhuproduct }}</div>
                        </div>
                    </li>
                </template>

                <!-- 左侧内容区域分页 -->
                <div class="pageDown">
                    <Pagination
                        v-if="JSON.stringify(companylistDe) != ''"
                        :pageNo="searchParams.page"
                        :pageSize="searchParams.pageSize"
                        :total="total"
                        :continues="5"
                        @getPageNo="getPageEv"
                    />
                </div>
            </ul>
        </div>
    </div>
</template>

<script>
import Pagination from "@/components/Pagination";
export default {
    // 组件名称
    name: "memberLeft",
    // 组件参数 接收来自父组件的数据
    props: {
        companylistDe: {
            type: Object,
            default: {},
        },
    },
    // 组件状态值
    data() {
        return {
            searchParams: {
                page: 1,
                pageSize: 5,
            },
            total: 10,
        };
    },
    // 计算属性
    computed: {},
    // 侦听器
    watch: {
        // 定义 total 侦听器，指向一个配置对象
        companylistDe: {
            // handler 属性用来定义侦听器的 function 处理函数
            handler(newVal) {
                this.searchParams.page = newVal.current_page;
                if (newVal.per_page) {
                    this.searchParams.pageSize = newVal.per_page - 0;
                }
                if (newVal.total) {
                    this.total = newVal.total;
                }
            },
            // immediate 属性用来声明此侦听器，是否在页面初次加载完毕后立即调用
            immediate: true,
        },
    },
    // 组件方法
    created() {},
    methods: {
        // 分页触发事件
        getPageEv(index) {
            this.searchParams.page = index;
            this.$emit("page", index);
        },
        // 跳转到企业会员首页
        member(item) {
            sessionStorage.setItem("id", item.id);
            // 待更换
            // sessionStorage.setItem("id", 1);
            this.$router.push({
                path: "/mIndex",
            });
        },
    },
    mounted() {},
    // 局部注册的组件
    components: { Pagination },
};
</script>

<style lang="scss">
.member-left {
    .el-carousel__container {
        height: 200px;
    }

    .el-carousel__indicators {
        display: none;
    }
    .el-carousel__item h3 {
        color: #475669;
        font-size: 18px;
        opacity: 0.75;
        line-height: 300px;
        margin: 0;
    }

    .el-carousel__item:nth-child(2n) {
        background-color: #99a9bf;
    }

    .el-carousel__item:nth-child(2n + 1) {
        background-color: #d3dce6;
    }
    .introduces-cont-box {
        height: 100%;
        margin-top: 10px;

        > ul {
            list-style: none;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;

            li {
                display: flex;
                width: 32%;
                height: 80px;
                padding: 6px;
                margin-bottom: 20px;
                box-sizing: border-box;
                box-shadow: 2px 2px 10px #e1e0e0;

                // 新闻资讯内容区域左侧
                .introduces-item-left {
                    width: 35%;
                    height: 100%;

                    > div {
                        text-align: center;
                        padding: 1px 2px;
                        font-size: 9px;
                        color: #fff;
                        border-radius: 2px;
                        background-color: #0056b0;
                    }

                    img {
                        width: 100%;
                        line-height: 0px;
                    }
                }

                // 新闻资讯内容区域右侧
                .introduces-item-right {
                    display: flex;
                    flex-direction: column;
                    width: 58%;
                    height: 100%;
                    margin-left: auto;

                    > div:nth-child(1) {
                        font-size: 8px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }

                    > div:nth-child(2) {
                        margin-top: 3px;
                        font-size: 7px;
                        color: #b3b3b3;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2; //行数
                        -webkit-box-orient: vertical;
                    }
                    > div:nth-child(3) {
                        margin-top: 3px;
                        font-size: 7px;
                        color: #b3b3b3;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2; //行数
                        -webkit-box-orient: vertical;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .member-left {
        .introduces-cont-box {
            height: 100%;
            margin-top: 10px;

            > ul {
                list-style: none;
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;

                li {
                    display: flex;
                    width: 32%;
                    height: 75px;
                    padding: 6px;
                    margin-bottom: 20px;
                    box-sizing: border-box;
                    box-shadow: 2px 2px 10px #e1e0e0;
                    // border: 1px solid red;

                    // 新闻资讯内容区域左侧
                    .introduces-item-left {
                        width: 35%;
                        height: 100%;

                        > div {
                            text-align: center;
                            padding: 0px;
                            font-size: 8px;
                            color: #fff;
                            border-radius: 2px;
                            background-color: #0056b0;
                        }

                        img {
                            width: 100%;
                            line-height: 0px;
                        }
                    }

                    // 新闻资讯内容区域右侧
                    .introduces-item-right {
                        display: flex;
                        flex-direction: column;
                        width: 58%;
                        height: 100%;
                        margin-left: auto;

                        > div:nth-child(1) {
                            font-size: 8px;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }

                        > div:nth-child(2) {
                            margin-top: 3px;
                            font-size: 7px;
                            color: #b3b3b3;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 2; //行数
                            -webkit-box-orient: vertical;
                        }
                        > div:nth-child(3) {
                            margin-top: 3px;
                            font-size: 7px;
                            color: #b3b3b3;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 2; //行数
                            -webkit-box-orient: vertical;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 768px) and (max-width: 1200px) {
    .member-left {
        .introduces-cont-box {
            height: 100%;
            margin-top: 10px;

            > ul {
                list-style: none;
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;

                li {
                    display: flex;
                    width: 32%;
                    height: 60px;
                    padding: 6px;
                    margin-bottom: 20px;
                    box-sizing: border-box;
                    box-shadow: 2px 2px 10px #e1e0e0;

                    // 新闻资讯内容区域左侧
                    .introduces-item-left {
                        width: 35%;
                        height: 100%;

                        > div {
                            text-align: center;
                            padding: 0px;
                            font-size: 6px;
                            color: #fff;
                            border-radius: 2px;
                            background-color: #0056b0;
                        }

                        img {
                            width: 100%;
                            line-height: 0px;
                        }
                    }

                    // 新闻资讯内容区域右侧
                    .introduces-item-right {
                        display: flex;
                        flex-direction: column;
                        width: 58%;
                        height: 100%;
                        margin-left: auto;

                        > div:nth-child(1) {
                            font-size: 6px;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }

                        > div:nth-child(2) {
                            margin-top: 3px;
                            font-size: 6px;
                            color: #b3b3b3;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 2; //行数
                            -webkit-box-orient: vertical;
                        }
                        > div:nth-child(3) {
                            margin-top: 3px;
                            font-size: 6px;
                            color: #b3b3b3;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 2; //行数
                            -webkit-box-orient: vertical;
                        }
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 767px) {
}
</style>
