<template>
    <div class="mShow">
        <Mheader :currentIndex="3"></Mheader>

        <!-- 轮播图 -->
        <div class="mIndex-swiper">
            <MeSwiper></MeSwiper>
        </div>

        <div class="introduces-wrap">
            <div class="introduces-cont">
                <div class="introduces-cont-title">
                    <div>{{ productcateTitle.name }}</div>
                    <div>{{ productcateTitle.enname }}</div>
                </div>
                <!-- 产品展示 -->
                <div class="mIndex-show">
                    <!-- 产品展示左侧 -->
                    <div class="mIndex-show-left">
                        <div class="mIndex-left-title">产品展示</div>
                        <ul>
                            <li
                                :key="indexb"
                                @click="showEv(indexb, itemb)"
                                :class="showCurrent == indexb ? 'liactive' : ''"
                                v-for="(itemb, indexb) in showData"
                            >
                                {{ itemb.name }}
                            </li>
                        </ul>
                    </div>

                    <!-- 产品展示右侧 -->
                    <div class="mIndex-show-right">
                        <ul>
                            <li
                                :key="indexc"
                                @click="goProductDetail(itemc.id)"
                                v-for="(itemc, indexc) in productsList"
                            >
                                <div class="mIndex-item-img">
                                    <img :src="itemc.image" alt="" />
                                </div>
                                <div class="mIndex-item-ms">
                                    <div>{{ itemc.title }}</div>
                                    <div>{{ itemc.description }}</div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <!-- 左侧内容区域分页 -->
                <div class="pageDown">
                    <Pagination
                        :pageNo="searchParams.page"
                        :pageSize="searchParams.pageSize"
                        :total="total"
                        :continues="5"
                        @getPageNo="getPageEv"
                    />
                </div>
            </div>
        </div>

        <Footer></Footer>
    </div>
</template>

<script>
import Mheader from "../../../components/Mheader.vue";
import Footer from "../../../components/Footer";
import MeSwiper from "../../../components/Member/swiper.vue";
import { productcate, products } from "../../../network/data";
import Pagination from "@/components/Pagination";
export default {
    // 组件名称
    name: "introduces",
    // 组件参数 接收来自父组件的数据
    props: {},
    // 组件状态值
    data() {
        return {
            // 当前导航栏id
            menu_id: "",
            // 当前左侧栏目id,默认为1
            productsId: 1,
            // 分页
            searchParams: {
                page: 1,
                pageSize: 6,
            },
            total: 10,
            // 产品展示左侧索引
            showCurrent: 0,
            // 首页轮播图数据
            swiperData: [
                // {
                //     image: "http://47.92.152.75/mswiper.png",
                // },
                // {
                //     image: "http://47.92.152.75/mswiper.png",
                // },
                // {
                //     image: "http://47.92.152.75/mswiper.png",
                // },
            ],
            // 产品展示左侧数据
            showData: [],
            // 产品展示头部数据
            productcateTitle: {},
            // 产品展示列表数据
            productsList: [],
            // 判断第一次加载
            toLoad:'',
        };
    },
    // 计算属性
    computed: {},
    // 侦听器
    watch: {},
    // 组件方法
    created() {
        if (this.$route.query != "") {
            this.menu_id = this.$route.query.menu_id;

            // 获取产品展示数据
            this.productcateRe();
            // 判断是否第一次加载来获取第一条数据
            this.toLoad = 1;
        }
    },
    methods: {
        // 产品展示左侧切换事件
        showEv(indexb, item) {
            this.showCurrent = indexb;
            this.productsId = item.id;
            // 每次切换分类page需要重置
            this.searchParams.page = 1;
            this.productsRe();
        },
        // 分页点击事件
        getPageEv(e) {
            this.searchParams.page = e;
            this.productcateRe();
        },
        // 获取产品展示数据
        productcateRe() {
            let data = {
                id: sessionStorage.getItem("id"),
                menuid: this.menu_id,
            };
            productcate(data).then((res) => {
                if (res.code == 1) {
                    this.productcateTitle = res.data.menu;
                    this.showData = res.data.list;
                    //第一次加载才会赋值
                    if(this.toLoad == 1){
                        this.productsId = res.data.list[0].id;
                        // console.log(this.toLoad,'aaaaa')
                        this.toLoad = '';
                    }
                    // console.log(this.toLoad,'bbbbb')
                    // 获取产品列表数据
                    this.productsRe();
                } else {
                    this.message(res.msg);
                }
            });
        },
        // 获取产品展示列表数据
        productsRe() {
            let data = {
                id: sessionStorage.getItem("id"),
                menuid: this.menu_id,
                cateid: this.productsId,
                page: this.searchParams.page,
                pagesize: this.searchParams.pageSize,
            };
            products(data).then((res) => {
                if (res.code == 1) {
                    this.searchParams.page = res.data.list.current_page;
                    this.searchParams.pageSize = res.data.list.per_page;
                    this.total = res.data.list.total;
                    this.productsList = res.data.list.data;
                } else {
                    this.message(res.msg);
                }
            });
        },
        // 跳转到企业会员产品详情页
        goProductDetail(id) {
            this.$router.push({
                path: `/productDetail`,
                query: {
                    menu_id: id,
                },
            });
        },
    },
    mounted() {},
    // 局部注册的组件
    components: {
        Mheader,
        Footer,
        Pagination,
        MeSwiper,
    },
};
</script>

<style lang="scss">
.mShow {
    width: 100%;

    img {
        display: block;
        height: 100%;
        width: 100%;
    }

    // 版心
    .introduces-wrap {
        width: 80%;
        margin: 0 auto;

        .introduces-cont {
            width: 100%;
            height: 530px;
            margin-top: 20px;
            // border: 1px solid red;

            .introduces-cont-title {
                text-align: center;

                > div:nth-child(1) {
                    font-size: 12px;
                }

                > div:nth-child(2) {
                    font-size: 10px;
                    color: #999999;
                }
            }

            /*产品展示 start*/
            .mIndex-show {
                display: flex;
                width: 100%;
                /* height: 300px; */
                margin-top: 30px;
                // border: 1px solid red;

                // 产品展示左侧
                .mIndex-show-left {
                    width: 20%;
                    height: 300px;
                    overflow: scroll;
                    padding: 0 0 15px 0;
                    border: 1px solid #ebebeb;

                    .mIndex-left-title {
                        padding: 6px 10px;
                        font-size: 10px;
                        color: #fff;
                        background-color: #00479d;
                    }

                    > ul {
                        list-style: none;
                        padding: 0 10px;
                        font-size: 10px;

                        .liactive {
                            color: #00479d;
                        }

                        .liactive::before {
                            background-color: #00479d;
                        }

                        li::before {
                            content: "";
                            width: 5px;
                            height: 5px;
                            // border-radius: 50%;
                            margin-right: 5px;
                            background-color: #333333;
                            display: block;
                        }
                        li {
                            cursor: pointer;
                            display: flex;
                            align-items: center;
                            margin-top: 20px;
                            color: #a9a9a9;
                        }
                    }
                }

                // 产品展示右侧
                .mIndex-show-right {
                    width: 78%;
                    height: 100%;
                    margin-left: auto;
                    // border: 1px solid blue;

                    > ul {
                        list-style: none;
                        display: flex;
                        justify-content: space-between;
                        flex-wrap: wrap;

                        li {
                            cursor: pointer;
                            width: 32%;
                            height: 145px;
                            // border: 1px solid red;
                            margin-bottom: 5px;

                            .mIndex-item-img {
                                width: 100%;
                                height: 70%;

                                img {
                                    width: 100%;
                                    height: 100%;
                                }
                            }

                            .mIndex-item-ms {
                                width: 100%;
                                height: 30%;
                                padding: 5px;
                                text-align: center;

                                > div:nth-child(1) {
                                    font-size: 10px;
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                }

                                > div:nth-child(2) {
                                    font-size: 8px;
                                    color: #a2a2a2;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    display: -webkit-box;
                                    -webkit-line-clamp: 2; //行数
                                    -webkit-box-orient: vertical;
                                }
                            }
                        }
                    }
                 }
            }
             // 左侧内容区域分页
             .pageDown {
                display: flex;
                justify-content: center;
            }
            /*产品展示 end*/

            // 左侧内容区域分页
            .pageDown {
                display: flex;
                justify-content: center;
            }
        }
    }
}

//当屏幕最小宽度为992px
@media screen and (min-width: 1200px) {
}

//当屏幕宽度 最小为768px 最大为991px时
@media screen and (min-width: 768px) and (max-width: 1200px) {
    .mShow {
        width: 100%;

        img {
            display: block;
            height: 100%;
            width: 100%;
        }

        // 版心
        .introduces-wrap {
            width: 80%;
            margin: 0 auto;

            .introduces-cont {
                width: 100%;
                height: 410px;
                margin-top: 20px;
                // border: 1px solid red;

                .introduces-cont-title {
                    text-align: center;

                    > div:nth-child(1) {
                        font-size: 12px;
                    }

                    > div:nth-child(2) {
                        font-size: 10px;
                    }
                }

                /*产品展示 start*/
                .mIndex-show {
                    display: flex;
                    width: 100%;
                    /* height: 200px; */
                    margin-top: 30px;
                    // border: 1px solid red;

                    // 产品展示左侧
                    .mIndex-show-left {
                        width: 20%;
                        height: 180px;
                        overflow: scroll;
                        padding: 0 0 15px 0;
                        border: 1px solid #ebebeb;

                        .mIndex-left-title {
                            padding: 6px 10px;
                            font-size: 10px;
                            color: #fff;
                            background-color: #00479d;
                        }

                        > ul {
                            list-style: none;
                            padding: 0 10px;
                            font-size: 10px;

                            .liactive {
                                color: #00479d;
                            }

                            .liactive::before {
                                background-color: #00479d;
                            }

                            li::before {
                                content: "";
                                width: 5px;
                                height: 5px;
                                // border-radius: 50%;
                                margin-right: 5px;
                                background-color: #333333;
                                display: block;
                            }
                            li {
                                cursor: pointer;
                                display: flex;
                                align-items: center;
                                margin-top: 10px;
                                font-size: 8px;
                                color: #a9a9a9;
                            }
                        }
                    }

                    // 产品展示右侧
                    .mIndex-show-right {
                        width: 78%;
                        height: 100%;
                        margin-left: auto;
                        // border: 1px solid blue;

                        > ul {
                            list-style: none;
                            display: flex;
                            justify-content: space-between;
                            flex-wrap: wrap;

                            li {
                                width: 32%;
                                height: 85px;
                                // border: 1px solid red;
                                margin-bottom: 5px;

                                .mIndex-item-img {
                                    width: 100%;
                                    height: 68%;

                                    img {
                                        width: 100%;
                                        height: 100%;
                                    }
                                }

                                .mIndex-item-ms {
                                    width: 100%;
                                    height: 40%;
                                    padding: 5px;
                                    text-align: center;

                                    > div:nth-child(1) {
                                        font-size: 10px;
                                    }

                                    > div:nth-child(2) {
                                        width: 80px;
                                        font-size: 8px;
                                        color: #a2a2a2;
                                        white-space: nowrap;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                    }
                                }
                            }
                        }
                    }
                }
                /*产品展示 end*/
            }
        }
    }
}

//当屏幕最大宽度为767px
@media screen and (max-width: 767px) {
}
</style>
