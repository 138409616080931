import Vue from "vue";
import VueRouter from "vue-router";

// 首页
import Index from "../views/Index/Index.vue";
// 关于
import About from "../views/About/About.vue";
import Survey from "../views/About/AboutSom/Survey/Survey.vue";
import AnnunciateDetail from "../views/About/AboutSom/AboutDetail/AnnunciateDetail";
import LearnDetail from "../views/About/AboutSom/AboutDetail/LearnDetail.vue";
import BranchDetail from "../views/About/AboutSom/AboutDetail/BranchDetail.vue";
import NewsDetail from "../views/About/AboutSom/AboutDetail/NewsDetail.vue";
//产业智造
import Made from "../views/Made/Made.vue";
import Machining from "../views/Made/MadeCom/Machining/Machining.vue";
import MadeDetail from "../views/Made/MadeCom/MadeDetail/MadeDetail.vue";
// 科技赋能
import Science from "../views/Science/Science.vue";
import Minkave from "../views/Science/ScienceCom/Minkave.vue";
import Activity from "../views/Science/ScienceCom/Activity.vue";
import Activitysub from "../views/Science/ScienceCom/Activitysub.vue";
import Demand from "../views/Science/ScienceCom/Demand.vue";
import MinkaveDetails from "../views/Science/ScienceCom/MinkaveDetails.vue";
import ActivityDetails from "../views/Science/ScienceCom/ActivityDetails.vue";
import DemandDetails from "../views/Science/ScienceCom/DemandDetail.vue";
import DemandIssue from "../views/Science/ScienceCom/DemandIssue.vue";
import DemandResultDetail from "../views/Science/ScienceCom/DemandResultDetail.vue";
import DemandResultIssue from "../views/Science/ScienceCom/DemandResultIssue.vue";
import DemandInviteDetail from "../views/Science/ScienceCom/DemandInviteDetail.vue";
import Workdetail from "../views/Science/ScienceCom/Workdetail.vue";
import Compile from "../views/Science/ScienceCom/Compile.vue";
// 产业专题
import Special from "../views/Special/Special.vue";
import Prefabricate from "../views/Special/SpecialCom/Prefabricate.vue";
// import Consanguinity from "../views/Special/SpecialCom/Consanguinity.vue";
// import Quality from "../views/Special/SpecialCom/Quality.vue";
// import Nutrition from "../views/Special/SpecialCom/Nutrition.vue";
import SpecialDetail from "../views/Special/SpecialCom/SpecialDetail.vue";

// 产业观察
import Observe from "../views/Observe/Observe.vue";
import Flesh from "../views/Observe/ObserveCom/Flesh.vue";
import FleshDetail from "../views/Observe/ObserveCom/FleshDetail.vue";

// 行业规范
import Norm from "../views/Norm/Norm.vue";
import Standard from "../views/Norm/NormCom/Standard.vue";
import Policy from "../views/Norm/NormCom/Policy.vue";
import StandardDetail from "../views/Norm/NormCom/StandardDetail.vue";
import PolicyDetail from "../views/Norm/NormCom/PolicyDetail";

// 食品规范
import Consult from "../views/Consult/Consult.vue";
import ConsultHot from "../views/Consult/ConsultHot.vue";
import CousultDetail from "../views/Consult/CousultDetail.vue";

// 企业会员首页
import MIndex from "../views/Member/MIndex/MIndex.vue";
// 企业会员公司简介
import MIntroduces from "../views/Member/MIntroduces/MIntroduces.vue";
// 企业会员公司荣誉
import MHonor from "../views/Member/MHonor/MHonor.vue";
// 企业会员产品展示
import MShow from "../views/Member/MShow/MShow.vue";
import ProductDetail from "../views/Member/MShow/ProductDetail.vue";
// 企业会员新闻资讯
import MNews from "../views/Member/MNews/MNews.vue";
import MNewsDetail from "../views/Member/MNews/MNewsDetail.vue";
// 企业会员招聘
import MInvite from "../views/Member/MInvite/MInvite.vue";
// 企业会员联系我们
import MContact from "../views/Member/MContact/MContact.vue";
// 企业会员广告页
import Advertising from "../views/Member/Advertising/Advertising.vue";
// 会员注册
import MemberRegister from "../views/MemberRegister/MemberRegister.vue";
// 登录
import Login from "../views/Login/Login.vue";
//院校首页
import academyIndex from "../views/Academy/AIndex/AIndex.vue";
// 院校概况
import Synopsis from "../views/Academy/Synopsis/Synopsis.vue";
// 院校科研平台
import Scientific from "../views/Academy/Scientific/Scientific.vue";
// 院校教师风采
import Demeanour from "../views/Academy/Demeanour/Demeanour.vue";
// 院校学院动态
import Dynamic from "../views/Academy/Dynamic/Dynamic.vue";
// 院校详情页
import AcademyDetail from "../views/Academy/AcademyDetail/AcademyDetail.vue";
// 招聘发布，就业发布
import Recruit from "../views/Science/ScienceCom/Recruit.vue";
// 就业详情页
import Obtain from "../views/Science/ScienceCom/Obtain.vue";

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        redirect: "index",
    },
    // pc
    {
        // 首页
        path: "/index",
        name: "index",
        component: Index,
    },
    {
        // 关于
        path: "/about",
        name: "about",
        component: About,
        children: [
            // 关于主页
            {
                path: "survey",
                name: "survey",
                component: Survey,
            },
            // 通告详情页
            {
                path: "annunciateDetail/:index",
                name: "annunciateDetail",
                component: AnnunciateDetail,
            },
            //学会工作详情页
            {
                path: "learnDetail/:type",
                name: "learnDetail",
                component: LearnDetail,
            },
            // 分支机构详情页
            {
                path: "branchDetail/:type",
                name: "branchDetail",
                component: BranchDetail,
            },
            // 学会要闻详情页
            {
                path: "newsDetail/:type",
                name: "newsDetail",
                component: NewsDetail,
            },
        ],
    },
    {
        // 产业智造
        path: "/made",
        name: "made",
        component: Made,
        children: [
            {
                // 产业制造主页
                path: "machining",
                name: "machining",
                component: Machining,
            },
            {
                // 产业制造详情页
                path: "madeDetail/:index",
                name: "madeDetail",
                component: MadeDetail,
            },
        ],
    },
    {
        // 科技赋能
        path: "/science",
        name: "science",
        component: Science,
        children: [
            {
                //助企名家
                path: "minkave",
                name: "minkave",
                component: Minkave,
            },
            {
                // 助企活动
                path: "activity",
                name: "activity",
                component: Activity,
            },
            {
                // 产业需求
                path: "demand",
                name: "demand",
                component: Demand,
            },

            {
                // 助企名家---详情页
                path: "minkaveDetails",
                name: "minkaveDetails",
                component: MinkaveDetails,
            },
            {
                // 助企活动---下一级
                path: "activitysub",
                name: "activitysub",
                component: Activitysub,
            },
            {
                //助企活动---详情页
                path: "activityDetails",
                name: "activityDetails",
                component: ActivityDetails,
            },
            {
                // 产业需求---技术难题详情页
                path: "demandDetails",
                name: "demandDetails",
                component: DemandDetails,
            },
            {
                // 产业需求---难题发布、解答页面
                path: "demandIssue",
                name: "demandIssue",
                component: DemandIssue,
            },
            {
                // 产业需求---成果转化详情页
                path: "demandResultDetail",
                name: "demandResultDetail",
                component: DemandResultDetail,
            },
            {
                //产业需求---成果转化发布页面
                path: "demandResultIssue",
                name: "demandResultIssue",
                component: DemandResultIssue,
            },
            {
                //产业需求---招聘详情页
                path: "demandInviteDetail",
                name: "demandInviteDetail",
                component: DemandInviteDetail,
            },
            {
                //助企名家--论文论著详情页
                path: "Workdetail",
                name: "workdetail",
                component: Workdetail,
            },
            {
                //去发布编辑页面
                path: "compile",
                name: "compile",
                component: Compile,
            },
            {
                //招聘发布、就业发布
                path: "recruit",
                name: "recruit",
                component: Recruit,
            },
            {
                //就业详情
                path: "obtain",
                name: "obtain",
                component: Obtain,
            },
        ],
    },
    {
        // 产业专题
        path: "/special",
        name: "special",
        component: Special,
        children: [
            {
                path: "prefabricate",
                name: "prefabricate",
                component: Prefabricate,
            },
            // {
            //     path: "consanguinity",
            //     name: "consanguinity",
            //     component: Consanguinity,
            // },
            // {
            //     path: "quality",
            //     name: "quality",
            //     component: Quality,
            // },
            // {
            //     path: "nutrition",
            //     name: "nutrition",
            //     component: Nutrition,
            // },
            // 产业专题详情页
            {
                path: "specialDetail/:type",
                name: "specialDetail",
                component: SpecialDetail,
            },
        ],
    },
    {
        // 产业观察
        path: "/observe",
        name: "observe",
        component: Observe,
        children: [
            {
                path: "flesh",
                name: "flesh",
                component: Flesh,
            },
            {
                path: "fleshDetail/:type",
                name: "fleshDetail",
                component: FleshDetail,
            },
        ],
    },
    {
        // 行业规范
        path: "/norm",
        name: "norm",
        component: Norm,
        children: [
            //行业标准
            {
                path: "standard",
                name: "standard",
                component: Standard,
            },
            //政策法规
            {
                path: "policy",
                name: "policy",
                component: Policy,
            },
            //行业标准详情页
            {
                path: "standardDetail/:type",
                name: "standardDetail",
                component: StandardDetail,
            },
            {
                path: "policyDetail/:type",
                name: "policyDetail",
                component: PolicyDetail,
            },
        ],
    },
    {
        // 食品咨询
        path: "/consult",
        name: "consult",
        component: Consult,
        children: [
            // 食品资讯详情页
            {
                path: "cousultHot",
                name: "cousultHot",
                component: ConsultHot,
            },
            // 食品资讯详情页
            {
                path: "cousultDetail",
                name: "cousultDetail",
                component: CousultDetail,
            },
        ],
    },
    // 企业会员首页
    {
        path: "/mIndex",
        name: "mIndex",
        component: MIndex,
    },
    // 企业会员公司简介
    {
        path: "/mIntroduce",
        name: "mIntroduce",
        component: MIntroduces,
    },
    // 企业会员公司荣誉
    {
        path: "/mHonor",
        name: "mHonor",
        component: MHonor,
    },
    // 企业会员产品展示
    {
        path: "/mShow",
        name: "mShow",
        component: MShow,
        // children: [],
    },
    // 企业会员产品详情页
    {
        path: "/productDetail",
        name: "productDetail",
        component: ProductDetail,
    },
    // 企业会员新闻资讯
    {
        path: "/mNews",
        name: "mNews",
        component: MNews,
    },
    // 企业会员新闻咨询详情页
    {
        path: "/mNewsDetail",
        name: "mNewsDetail",
        component: MNewsDetail,
    },
    // 企业会员招聘
    {
        path: "/mInvite",
        name: "mInvite",
        component: MInvite,
    },
    // 企业会员联系我们
    {
        path: "/mContact",
        name: "mContact",
        component: MContact,
    },
    // 企业会员广告页
    {
        path: "/advertising",
        name: "advertising",
        component: Advertising,
    },
    // 会员注册
    {
        path: "/memberRegister",
        name: "MemberRegister",
        component: MemberRegister,
    },
    // 会员登录
    {
        path: "/login",
        name: "Login",
        component: Login,
    },
    // 院校首页
    {
        path: "/academyIndex",
        name: "academyIndex",
        component: academyIndex,
    },
    // 院校概况
    {
        path: "/synopsis",
        name: "synopsis",
        component: Synopsis,
    },
    //院校科研平台
    {
        path: "/scientific",
        name: "scientific",
        component: Scientific,
    },
    // 院校教师风采
    {
        path: "/demeanour",
        name: "Demeanour",
        component: Demeanour,
    },
    // 院校学院动态
    {
        path: "/dynamic",
        name: "dynamic",
        component: Dynamic,
    },
    //院校详情页
    {
        path: "/academyDetail",
        name: "academyDetail",
        component: AcademyDetail,
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

export default router;
